//  Actions
import { setProcessValidateSessionAuthenticationReducer } from "../storage/reducers/authentication/authentication.actions"

// Components - shared
import ErrorToastComponent from "../shared/components/toast/error-toast/error-toast.component";

// Constants
import AlertConst from "../core/constants/alerts.const";

// Libraries
import { Navigate, Outlet } from "react-router-dom"
import { connect } from "react-redux"
import { useEffect } from 'react'

// Services
import { clearKeysAuthenticationService, getTokenService } from "../services/authentication.services"

// Utils
import LoadingHomeComponent from "../shared/components/loadings/loading-home/loading-home.component"

const authProcess = () => {
	let token = getTokenService()

	if (token) {
		return {
			isAuth: true
		}
	} else {
		return {
			isAuth: false
		}
	}
}

const ProtectedRoutes = (props) => {

	const {
		// Actions
		setProcessValidateSessionAuthenticationReducer,
		// Variables
		isCheckingSession,
		isValidSession,
		tokenReducer
	} = props

	useEffect(() => {
		processValidationToken()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	async function processValidationToken() {
		try {
			let token = getTokenService()
			if (!token) {
				token = tokenReducer
			}
			//TODO: It is required to be implemented when the functionality is ready in the Surtilicores backend project.
			// const isValidSession = await validateSessionService(token)
			const isValidSession = true;
			setProcessValidateSessionAuthenticationReducer(true, isValidSession)
		} catch (err) {
			ErrorToastComponent({
				title:`${err.code} ${err.message}`,
			  position: AlertConst.TOP_END_POSITION_TEXT,
			  timer: 1500
			})
			setProcessValidateSessionAuthenticationReducer(true, false)
		}
	}

	if (!isCheckingSession && !isValidSession) {
		return <LoadingHomeComponent />
	} else if (isCheckingSession && !isValidSession) {
		clearKeysAuthenticationService()
		return <Navigate replace to="/login" />
	} else {
		const { isAuth } = authProcess()
		return isAuth ? (
			<Outlet />
		) : (
			<Navigate to="/login" />
		)
	}
}

const mapStateToProps = ({ AuthenticationReducer }) => {
	const { isCheckingSession, isValidSession, userLogged } = AuthenticationReducer

	let tokenReducer

	if (userLogged) {
		const { authentication: { token } } = userLogged
		tokenReducer = token
	}

	return {
		isCheckingSession,
		isValidSession,
		tokenReducer
	}
}

const mapStateToPropsActions = {
	setProcessValidateSessionAuthenticationReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(ProtectedRoutes)