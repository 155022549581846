//Actions
import { setItemCashClosingWholesaleReducer, setStateCashClosingWholesaleReducer } from '../../../../storage/reducers/cash-closing-wholesale/cash-closing-wholesale.actions';

//Assets
import { Assets } from '../../../../assets'

//Components - Shared
import ErrorAlertComponent from '../../../../shared/components/alerts/error-alert/error-alert.component';

//Libraries
import { NumericFormat } from 'react-number-format';
import React, { useEffect, useRef, useState } from 'react'
import { Input, Select } from 'antd';
import { connect } from 'react-redux';

//Services
import { deleteLiquidationService } from '../../../../services/cash-closing-wholesale.services';

//Styles
import './daily-payrolls.component.scss'

//Utils
import { spreadsheets } from '../../../../utils/cash-closing-wholesale.utils';

const DailyPayrollsComponent = (props) => {

  const {
    //Actions
    setItemCashClosingWholesaleReducer,
    setStateCashClosingWholesaleReducer,
    //Variables
    listTable, sellersList
  } = props

  const INITIAL_STATE = {
    scrollEnd: false,
    payrolls: spreadsheets
  };

  const [state, setState] = useState(INITIAL_STATE)

  const { payrolls, scrollEnd } = state

  const formRef = useRef(null);

  useEffect(() => {
    if (formRef.current && scrollEnd) {
      const miDiv = formRef.current;
      miDiv.scrollTop = miDiv.scrollHeight;
    }
  }, [scrollEnd, payrolls?.length]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      payrolls: listTable ? listTable : spreadsheets
    }))
  }, [listTable])

  const addPayrolls = () => {
    const objectDisabled = payrolls.find(object => object.hasOwnProperty("disabled") && object.disabled === true);
    if (!objectDisabled) {
      const newArray = [
        ...payrolls,
        {
          ...spreadsheets[0],
          disabled: false
        } 
      ]
      setState({
        ...state,
        scrollEnd: true,
        payrolls: newArray
      })
    } else {
      const newArray = payrolls.map(object => object === objectDisabled ? { ...object, disabled: false } : object);
      setState({ ...state, payrolls: newArray })
    };
  };

  const onChanges = (e, index, name) => {
    let value = e.target ? e.target.value || '' : e;
    let expenseModified = payrolls
    if (name === "creditNote" || name === "priceTemplate") {
      value = parseInt((value || "0")?.replace(/[^0-9]/g, ''),10)
    }
    
    if (name === "codeTemplate") {
      let newValue = Number(value) ? Number(value) : ""
      expenseModified[index][name] = newValue
    } else {
      expenseModified[index][name] = value;
    }

    setState((prevState)=>({
      ...prevState,
      payrolls: expenseModified,
    }))

    setItemCashClosingWholesaleReducer("liquidationList",index, expenseModified[index]);
  }

  const sumPrices = (list,name) => {
    return (list || []).reduce((accumulator, product) => {
      const price = !isNaN(Number(product[name])) ? Number(product[name]) : Number(product[name]?.replace(/[^0-9]/g, ''));
      return accumulator + (isNaN(price) ? 0 : price);
    }, 0);
  };

  const deleteExpenses = async (index) => {
    try {
      let liquidationModified = JSON.parse(JSON.stringify(payrolls));

      const initialItem = {
        banknote: 0,
        codeLiquidation: 0,
        codeTemplate: "",
        coin: 0,
        credit: 0,
        creditNote: 0,
        driverLiquidationTemplate: { label: '', value: '' },
        expense: 0,
        idTypeLiquidation: "",
        personLiquidationTemplate: { label: '', value: '' },
        priceTemplate: 0,
        transfer: 0,
        disabled: true
      };

      if (liquidationModified[index].id) {
        await deleteLiquidationService({ liquidationTemplate: [liquidationModified[index]?.id] })
      }

      liquidationModified[index] = initialItem;
      const removedItem = liquidationModified.splice(index, 1)[0];
      liquidationModified.push(removedItem);

      setState((prevState) => ({
        ...prevState,
        payrolls: liquidationModified
      }))

      setStateCashClosingWholesaleReducer({ liquidationList: liquidationModified, isDeletingRecords: true });

    } catch (error) {
      ErrorAlertComponent()
    }
  }

  return (
    <div>
      <div className='grid-x'>
        <div className='grid-x small-12'>
          <span className='small-2 daily-payrolls__subtitle'>Nombre del vendedor</span>
          <span className='small-2 daily-payrolls__subtitle'>Nro. Planilla</span>
          <span className='small-2 text-center daily-payrolls__subtitle'>Valor de planilla</span>
          <span className='small-2 text-center daily-payrolls__subtitle'>Devolución</span>
          <span className='small-2 text-center daily-payrolls__subtitle'>Nota crédito</span>
          <span className='small-2 text-center daily-payrolls__subtitle'>Diferencia</span>
        </div>
      </div>
      <div className='daily-payrolls__summary__content'>
        {payrolls?.map((item, index) => {

          item.refundValue = item?.priceTemplate - (item?.banknote + item?.coin  + item?.credit + item?.transfer + item?.expense) - item?.creditNote
          let liquidatedValue = item?.priceTemplate - item.refundValue - item?.creditNote
          item.difference = item?.priceTemplate - liquidatedValue - item.refundValue - item?.creditNote

          return (
            <div key={index} className='grid-x daily-payrolls__payments__container'>
              <div className='grid-x small-12'>
                <div className='small-2'>
                  <Select
                    onChange={(event,e) => onChanges(e, index, "personLiquidationTemplate")}
                    placeholder='Selección'
                    optionLabelProp='label'
                    popupMatchSelectWidth={false}
                    options={sellersList || []}
                    disabled={item?.disabled}
                    className='daily-payrolls__payments__select'
                    popupClassName='daily-payrolls__payments__select__popup'
                    value={item?.personLiquidationTemplate?.value ? item?.personLiquidationTemplate : undefined}
                    suffixIcon={
                      <div className='daily-payrolls__payments__select__icon'>
                        <img
                          alt={item.disabled ? "icon_disabled_arrow" : "icon_blue_arrow"}
                          src={item.disabled ? Assets.SharedIcons.icon_disabled_arrow : Assets.SharedIcons.icon_blue_arrow}
                        />
                      </div>
                    }
                  />
                </div>
                <div className='small-2'>
                  <Input
                    onChange={(e) => onChanges(e, index, "codeTemplate")}
                    disabled={item?.disabled}
                    value={item?.codeTemplate}
                    placeholder='Descripción'
                    className='daily-payrolls__payments__input'
                    prefix={<img src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit} alt="icon_add" />}
                  />
                </div>
                <div className='grid-x small-2'>
                  <div className='grid-x small-12 daily-payrolls__payments__content-input-price'>
                    <NumericFormat
                      onChange={(e) => onChanges(e, index, "priceTemplate")}
                      type='text'
                      prefix={"$ "}
                      placeholder='$ 0'
                      value={item?.priceTemplate || ""}
                      thousandSeparator=","
                      className='input-price'
                      disabled={item?.disabled}
                      thousandsGroupStyle="thousand"
                    />
                    <div className='daily-payrolls__payments__content-icon-price'>
                      <img
                        className='daily-payrolls__payments__icon'
                        alt={item.disabled ? "icon_add" : "icon_edit"}
                        src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                      />
                    </div>
                  </div>
                </div>
                <div className='grid-x small-2'>
                  <div className='grid-x small-12 daily-payrolls__payments__content-input-price daily-payrolls__payments__content-input-price--mod'>
                    <NumericFormat
                      disabled
                      type='text'
                      prefix={"$ "}
                      placeholder='$ 0'
                      value={item.refundValue}
                      thousandSeparator=","
                      className='input-price'
                      thousandsGroupStyle="thousand"
                    />
                  </div>
                </div>
                <div className='grid-x small-2'>
                  <div className='grid-x small-12 daily-payrolls__payments__content-input-price'>
                    <NumericFormat
                      type='text'
                      prefix={"$ "}
                      placeholder='$ 0'
                      value={item?.creditNote || ""}
                      thousandSeparator=","
                      className='input-price'
                      disabled={item?.disabled}
                      thousandsGroupStyle="thousand"
                      onChange={(e) => onChanges(e, index, "creditNote")}
                    />
                    <div className='daily-payrolls__payments__content-icon-price'>
                      <img
                        className='daily-payrolls__payments__icon'
                        alt={item.disabled ? "icon_add" : "icon_edit"}
                        src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                      />
                    </div>
                  </div>
                </div>
                <div className='grid-x small-2'>
                  <div className='grid-x small-10 daily-payrolls__payments__content-input-price daily-payrolls__payments__content-input-price--mod'>
                    <NumericFormat
                      type='text'
                      prefix={"$ "}
                      placeholder='$ 0'
                      thousandSeparator=","
                      className='input-price'
                      thousandsGroupStyle="thousand"
                      value={item.difference}
                      disabled
                    />
                  </div>
                  <div className='grid-x small-2 justify-content-end'>
                    <button
                      className='daily-payrolls__button-income__button-delete'
                      disabled={item?.disabled}
                      onClick={() => deleteExpenses(index)}
                    >
                      <img
                        className='daily-payrolls__button-income__button-delete__icon'
                        alt={item?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                        src={item?.disabled ? Assets.SharedIcons.icon_deleted_disabled : Assets.SharedIcons.icon_deleted_active}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className='grid-x small-12'>
        <div className='daily-payrolls__summary__line daily-payrolls__summary__line--mod'></div>
        <div className='grid-x small-4'>
          <span className='grid-x align-middle small-10 justify-content-end liquidation__subtitle'>
            Totalizadores
          </span>
        </div>
        <div className='grid-x small-2'>
          <div className='grid-x daily-payrolls__payments__content-input-price daily-payrolls__payments__content-input-price--mod'>
            <NumericFormat
              disabled
              type='text'
              prefix={"$ "}
              placeholder='$ 0'
              value={sumPrices(payrolls,"priceTemplate")}
              thousandSeparator=","
              className='input-price'
              thousandsGroupStyle="thousand"
            />
          </div>
        </div>
        <div className='grid-x small-2'>
          <div className='grid-x daily-payrolls__payments__content-input-price daily-payrolls__payments__content-input-price--mod'>
            <NumericFormat
              disabled
              type='text'
              prefix={"$ "}
              placeholder='$ 0'
              value={sumPrices(payrolls,"refundValue")}
              thousandSeparator=","
              className='input-price'
              thousandsGroupStyle="thousand"
            />
          </div>
        </div>
        <div className='grid-x small-2'>
          <div className='grid-x daily-payrolls__payments__content-input-price daily-payrolls__payments__content-input-price--mod'>
            <NumericFormat
              disabled
              type='text'
              prefix={"$ "}
              placeholder='$ 0'
              value={sumPrices(payrolls,"creditNote")}
              thousandSeparator=","
              className='input-price'
              thousandsGroupStyle="thousand"
            />
          </div>
        </div>
        <div className='grid-x small-2'>
          <div className='grid-x small-10 daily-payrolls__payments__content-input-price daily-payrolls__payments__content-input-price--mod'>
            <NumericFormat
              disabled
              type='text'
              prefix={"$ "}
              placeholder='$ 0'
              value={sumPrices(payrolls,"difference")}
              thousandSeparator=","
              className='input-price'
              thousandsGroupStyle="thousand"
            />
          </div>
        </div>
      </div>
      <div>
        <div className='daily-payrolls__summary__line daily-payrolls__summary__line--mod'></div>
        <div className='grid-x align-middle small-6'>
          <button
            onClick={() => addPayrolls()}
          >
            <img
              src={Assets.SharedIcons.icon_income}
              alt='icon_income'
            />
            <span className='daily-payrolls__button-income'>
              Agregar Planilla
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}

const mapStateToPropsActions = {
  setItemCashClosingWholesaleReducer,
  setStateCashClosingWholesaleReducer
};

export default connect(null, mapStateToPropsActions)(DailyPayrollsComponent);