//Assets
import { Assets } from '../../../assets/index';

// Components - shared
import SuccessAlertComponent from '../../../shared/components/alerts/success-alert/success-alert.component';
import ErrorAlertComponent from '../../../shared/components/alerts/error-alert/error-alert.component';

// libraries
import React from "react";
import { Input } from 'antd';
import { useFormik } from "formik";
import { connect } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";

//Services
import { changePasswordService } from "../../../services/authentication.services";

//Styles
import "./new-password.page.scss";

// Utils
import { validatePassword } from "../../../utils/validate-recovery-password.utils";

const NewPasswordPage = (props) => {

  const { idValidateUser } = props;

  let history = useNavigate();

  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
    initialErrors: {
      password: "",
      passwordConfirmation: "",
    },
    validate: validatePassword,
    onSubmit: () => processChangePassword()
  });

  const processChangePassword = async () => {
    try {
      await changePasswordService({
        password: values.password,
        passwordValidate: values.passwordConfirmation,
        idUser: idValidateUser
      });
      history("/login", { replace: true });
      SuccessAlertComponent(undefined, 'Contraseña cambiada exitosamente!');
    } catch (error) {
      ErrorAlertComponent(undefined, 'Por favor, verifica e inténtalo nuevamente');
    }
  };

  return (
    <div className='grid-x'>
      <div className='cell medium-6 flex-container align-center-middle new-password'>
        <img
          alt="icon_logo_text"
          src={Assets.SharedIcons.icon_logo_text}
        />
      </div>
      <div className='cell medium-6 new-password__main-container'>
        <div className='grid-padding-x new-password__main-container__welcome-text'>
          Bienvenido al portal de Surtilicores, aquí podrás reportar de una manera más efectiva tu labor.
        </div>
        <form onSubmit={handleSubmit}>
          <div className='new-password__container'>
            <div>
              <div className='new-password__input__container'>
                <div className='new-password__input__content-title'>
                  <span className='new-password__input__title'>Crear nueva contraseña</span>
                </div>
                <Input.Password
                  value={values.password}
                  placeholder='Nueva contraseña'
                  className='new-password__input'
                  onChange={(value) => handleChange('password')(value)}
                  prefix={
                    <div className='new-password__input__content-icon'>
                      <img
                        alt="icon_user"
                        src={Assets.AuthenticationIcons.icon_password}
                      />
                    </div>
                  }
                />
                <span className='new-password__input__error'>{errors.password}</span>
              </div>
              <div className='new-password__input__container'>
                <div className='new-password__input__content-title'>
                  <span className='new-password__input__title'>Confirma tu nueva contraseña</span>
                </div>
                <Input.Password
                  className='new-password__input'
                  value={values.passwordConfirmation}
                  placeholder='Confirmar tu contraseña'
                  onChange={(value) => handleChange('passwordConfirmation')(value)}
                  prefix={
                    <div className='new-password__input__content-icon'>
                      <img
                        alt="icon_user"
                        src={Assets.AuthenticationIcons.icon_password}
                      />
                    </div>
                  }
                />
                <span className='new-password__input__error'>{errors.passwordConfirmation}</span>
              </div>
            </div>
            <div className='grid-y justify-content-center new-password__password-requirements-box'>
              <div className='new-password__password-requirements-box__content-title'>
                <span className='new-password__password-requirements-box__title'>
                  Tu contraseña debería tener
                </span>
              </div>
              <span className={`new-password__password-requirements-box${values.password.length >= 8 ? '__description-success' : '__description-error'}`}>
                Por lo menos 8 dígitos.
              </span>
              <span className={`new-password__password-requirements-box${/^(?=.*[0-9])/.test(values.password) ? '__description-success' : '__description-error'}`}>
                Por lo menos un número.
              </span>
              <span className={`new-password__password-requirements-box${/^(?=.*[a-z])/.test(values.password) ? '__description-success' : '__description-error'}`}>
                Por lo menos una minuscula.
              </span>
              <span className={`new-password__password-requirements-box${/^(?=.*[A-Z])/.test(values.password) ? '__description-success' : '__description-error'}`}>
                Por lo menos una mayúscula.
              </span>
              <span className={`new-password__password-requirements-box${/^(?=.*[!¡'"·=@#$%^&*()_+{}[\]:;<>,.¿?~\\/-])/.test(values.password) ? '__description-success' : '__description-error'}`}>
                Por lo menos 1 carácter especial.
              </span>
            </div>
            <div className='grid-x small-12 justify-content-center new-password__content-button'>
              <button
                type='submit'
                className='button'
                style={{ background: '#1499F2' }}
                disabled={Object.keys(errors).length !== 0 || !(values.password.length >= 8 && /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!¡'"·=@#$%^&*()_+{}[\]:;<>,.¿?~\\/-])/.test(values.password))}
              >
                Cambiar contraseña
              </button>
              <Link to='/validateCode' className='new-password__content-button__link'>
                Regresar
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = ({ AuthenticationReducer }) => {
  const { idValidateUser } = AuthenticationReducer;
  return { idValidateUser };
};

export default connect(mapStateToProps, null)(NewPasswordPage);
