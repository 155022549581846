// Constants
import AlertConst from "../../../../core/constants/alerts.const";
import AlertErrorsConst from "../../../../core/constants/errors/alerts-app/alerts-error.const";

// Libraries
import Swal from "sweetalert2";

// Models - DA0
import { createErrorInternalApp } from "../../../../models/aggregates/build-error-internal-app/build-error-internal-app.dao";

/**
* Error toast component
* 
* @param props - Contains configuration properties
* @param {string} [props.title = ''] - Title toast toast alert.
* @param {string} [props.text] - Content text toast alert.
* @param {number} [props.timer = 1500] - Numeric value of the time (milliseconds) in which the alert will be displayed.
* @param {string} [props.position = position] - Text value of the popup window position.
* @param {boolean} [props.timerProgressBar = false] - boolean value show a progress bar on the pop-up window.
*
* @returns {Object} Returns a SweetAlert2 popup, error toast alert
*/

const ErrorToastComponent = (props) => {

  if (!props) {
    throw createErrorInternalApp(AlertErrorsConst.paramsRequiredBuildToasts)
  }

  let {
    title = "",
    text,
    timer = 1500,
    position,
    timerProgressBar = false
  } = props

  const Toast = Swal.mixin({
    toast: true,
    position,
    showConfirmButton: false,
    timer,
    timerProgressBar,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  
  return Toast.fire({
    icon: AlertConst.ERROR_ICON_TEXT,
    title,
    text
  })
}

export default ErrorToastComponent