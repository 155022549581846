const AdministrationPanelEndPoints = {
    GET_ALL_USERS: `/api/administrationPanel/`,
    CREATE_USER: `/api/administrationPanel/`,
    UPDATE_USER: `/api/administrationPanel/user/`,
    GET_USER_BY_ID: `/api/administrationPanel/user/:idUser`,
    GET_USER_PERMISSION_BY_ID: `/api/administrationPanel/permission/:idUser`,
    CREATE_USER_PERMISSION: `/api/administrationPanel/permissions`,
    UPDATE_USER_PERMISSION: `/api/administrationPanel/permissions`,
    GET_ALL_HEADQUARTER: `/api/administrationPanel/headquarter`,
    GET_HEADQUARTER_BY_ID: `/api/administrationPanel/headquarter/:idHeadquarter`,
    CREATE_HEADQUARTER: `/api/administrationPanel/headquarter/`,
    UPDATE_HEADQUARTER: `/api/administrationPanel/headquarter/`,
    UPDATE_USER_HEADQUARTER: `/api/administrationPanel/userChargeHeadquarter/`,
    UPDATE_STORE_HEADQUARTER: `/api/administrationPanel/storeHeadquarter/`,
}

export default AdministrationPanelEndPoints;