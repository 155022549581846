const CashClosingEndPoints =  {
    GET_NAME_HEADQUARTERS: `/api/reportCashClosingHeadquarter/findHeadQuarterUserChargeHeadQuarter`,
    GET_CASH_CLOSING: `/api/reportCashClosingHeadquarter/findOneCashRegister`,
    CREATE_CASH_EXPENSE: `/api/reportCashClosingHeadquarter/cashExpense/create/:id`,
    UPDATE_CASH_EXPENSE: `/api/reportCashClosingHeadquarter/cashExpense/update`,
    CREATE_CASH_TRANSFERS: `/api/reportCashClosingHeadquarter/cashReceiptMoneyTransfers/create/:id`,
    UPDATE_CASH_TRANSFERS: `/api/reportCashClosingHeadquarter/cashReceiptMoneyTransfers/update`,
    CREATE_CASH_EXPENSE_PROVIDER: `/api/reportCashClosingHeadquarter/cashExpenseProvider/create/:id`,
    UPDATE_CASH_EXPENSE_PROVIDER: `/api/reportCashClosingHeadquarter/cashExpenseProvider/update`,
    CREATE_CASH_RECEIPT_PAYMENT_OF_BONDSMEN: `/api/reportCashClosingHeadquarter/cashReceiptPaymentOfBondsmen/create/:id`,
    UPDATE_CASH_RECEIPT_PAYMENT_OF_BONDSMEN: `/api/reportCashClosingHeadquarter/cashReceiptPaymentOfBondsmen/update`,
    CREATE_CASH_EXPENSE_TRUST: `/api/reportCashClosingHeadquarter/cashExpenseTrust/create/:id`,
    UPDATE_CASH_EXPENSE_TRUST: `/api/reportCashClosingHeadquarter/cashExpenseTrust/update`,
    UPDATE_CASH_RECEIPT_BOTTLE_CONTROL: `/api/reportCashClosingHeadquarter/cashReceiptBottlesControl/update`,
    CREATE_RECEIPT_CARD_READERS: `/api/reportCashClosingHeadquarter/CashReceiptCardReaders/create/:id`,
    UPDATE_RECEIPT_CARD_READERS: `/api/reportCashClosingHeadquarter/cashReceiptCardReaders/update`,
    UPDATE_CASH_REGISTERS_OBSERVATION: `/api/reportCashClosingHeadquarter/cashRegisters/observation/:id`,
    SAVE_CLOSING_CASH_REGISTER: `/api/reportCashClosingHeadquarter/closingCashRegister/save/:id`,
    PUBLISH_CLOSING_CASH_REGISTER: `/api/reportCashClosingHeadquarter/closingCashRegister/publish/:id`,
    UPDATE_CASH_REGISTERS_INITIAL_BALANCE: `/api/reportCashClosingHeadquarter/cashRegisters/initialBalance/:id`,
    UPDATE_CASH_REGISTERS_SALES_TNS: `/api/reportCashClosingHeadquarter/cashRegisters/salesTNSCloud/:id`,
    UPDATE_CASH_REGISTERS_SALES_PF: `/api/reportCashClosingHeadquarter/cashRegisters/salesPF/:id`,
    UPDATE_CASH_REGISTERS_KITCHEN: `/api/reportCashClosingHeadquarter/cashRegisters/kitchen/:id`,
    UPDATE_CASH_REGISTERS_REAL_CASH: `/api/reportCashClosingHeadquarter/closingCashRegister/realCash/:id`,
    CREATE_CASH_RETURN: `/api/reportCashClosingHeadquarter/cashExpenseReturns/create/:id`,
    UPDATE_CASH_RETURN: `/api/reportCashClosingHeadquarter/cashExpenseReturns/update`,
    CREATE_INTERNAL_CONSUMPTION: `/api/reportCashClosingHeadquarter/domesticConsumptions/create/:id`,
    UPDATE_INTERNAL_CONSUMPTION: `/api/reportCashClosingHeadquarter/domesticConsumptions/update/`,
    DELETED_RECORD_DATAPONES_TABLE: `/api/reportCashClosingHeadquarter/cashReceiptCardReader`,
    DELETED_RECORD_REFUNDS_TABLE: `/api/reportCashClosingHeadquarter/cashExpenseReturns`,
    DELETED_RECORD_TRANSFERS_TABLE: `/api/reportCashClosingHeadquarter/cashReceiptMoneyTransfer`,
    DELETED_RECORD_EXPENSES_TABLE: `/api/reportCashClosingHeadquarter/cashExpense`,
    DELETED_RECORD_PAYMENT_OF_BONDSMEN_TABLE: `/api/reportCashClosingHeadquarter/cashReceiptPaymentOfBondsmen`,
    DELETED_RECORD_LOANS_TABLE: `/api/reportCashClosingHeadquarter/cashExpenseTrust`,
    DELETED_RECORD_PROVIDERS_TABLE: `/api/reportCashClosingHeadquarter/cashExpenseProvider`,
    DELETED_RECORD_INTERNAL_CONSUMPTION_TABLE: `/api/reportCashClosingHeadquarter/domesticConsumption`,
}

export default CashClosingEndPoints