// Assets
import { Assets } from "../../../../assets";

// Libraries
import { Tooltip } from "antd";

export const userPermissionMenuItems = (permissionsAssociateByUser) => {
    const items = [];
    const functionality = [];
    for (let index = 0; index < permissionsAssociateByUser.length; index++) {
        const { key, label, route } = permissionsAssociateByUser[index];
        if (permissionsAssociateByUser[index].moduleFunctionality?.length > 1) {
            const { icon, moduleFunctionality } = permissionsAssociateByUser[index];
            const children = moduleFunctionality.map((value) => {
                functionality.push({ key: value.key, route: value.route, label: value.label })
                return getItem(value.key, value.label, value.route, value.icon);
            })
            items.push(getItem(key, label, null, icon, children))
        } else {
            const [functionalityChildren] = permissionsAssociateByUser[index].moduleFunctionality;
            functionality.push({ key: functionalityChildren.key, route: functionalityChildren.route, label: functionalityChildren.label });
            items.push(getItem(functionalityChildren.key, label, route, functionalityChildren.icon))
        }
    }
    return { items, functionality };
}

const getItem = (key, label, route, icon, children = null) => ({
    key,
    label: (<Tooltip title={label}>{label}</Tooltip>),
    route,
    children,
    icon: <img src={Assets.SharedIcons[icon]} alt={icon} />
})
