import * as cashClosingWholesaleTypes from './cash-closing-wholesale.types';

export const setStateCashClosingWholesaleReducer = (prop) => (dispatch) => {
	dispatch({ type: cashClosingWholesaleTypes.SET_STATE_CLASH_CLOSING_WHOLESALE, payload: { prop } });
};

export const setItemCashClosingWholesaleReducer = (prop,key, value) => (dispatch) => {
	dispatch({ type: cashClosingWholesaleTypes.SET_ITEM_CLASH_CLOSING_WHOLESALE, payload: { prop, key, value } });
};

export const resetCashClosingWholesaleReducer = () => (dispatch) => {
	dispatch({ type: cashClosingWholesaleTypes.RESET_CASH_CLOSING_WHOLESALE });
}