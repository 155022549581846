// Assets
import { Assets } from '../../../../../assets';

// Components - shared
import PaginationComponent from '../../../../../shared/components/pagination/pagination.component';
import ErrorAlertComponent from '../../../../../shared/components/alerts/error-alert/error-alert.component';

// Libraries
import React, { useEffect, useState } from 'react';
import { Checkbox, Dropdown, Input } from 'antd';
import { useNavigate } from 'react-router-dom';

// Services
import { getAllHeadquarterService } from '../../../../../services/administration-panel.services';

// Styles
import './business-headquarters.component.scss';

const BusinessHeadquartersComponent = () => {

  let history = useNavigate()

  const [filterKey, setFilterKey ] = useState();

  const INITIAL_STATE = {
    listOfHeadquarter: [],
    checkedItems: [],
    isHoverSelected: false,
    paginatorState:{
      totalPages: 1,
      page: 0
    },
  }
  const [state, setState] = useState(INITIAL_STATE);
  const { listOfHeadquarter, checkedItems, isHoverSelected, paginatorState } = state;

  useEffect(() => {
    getListHeadquarter("",paginatorState?.page);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatorState?.page]);

  const getListHeadquarter = async (text,page) => {
    try {
      const listOfHeadquarterRes = await getAllHeadquarterService(text,page);
      setState((prevState) => ({
        ...prevState,
        listOfHeadquarter: listOfHeadquarterRes.headquarter,
        paginatorState: {
          totalPages: listOfHeadquarterRes.total,
          page: page || 0
        },
      }));
    } catch (error) {
      ErrorAlertComponent();
    }
  };

  const handleCheckboxChange = (idHeadquarter) => {
    const checkedItem = checkedItems.includes(idHeadquarter) ? checkedItems.filter(itemIndex => itemIndex !== idHeadquarter) : [...checkedItems, idHeadquarter];

    setState((prevState) => ({
      ...prevState,
      checkedItems: checkedItem
    }))
  };

  const isItemChecked = (idHeadquarter) => checkedItems.includes(idHeadquarter);

  const items = [
    {
      key: '0',
      label:
        <div className='business-headquarters__content-search__select-headquarter__checkbox'>
          <Checkbox
            style={{ width: '100%' }}
            checked={filterKey === "0"}
            onChange={() => handleFilter("0")}
          >
            Ascendente A-Z
          </Checkbox>
        </div>
    },
    {
      key: '1',
      label:
        <div className='business-headquarters__content-search__select-headquarter__checkbox'>
          <Checkbox
            style={{ width: '100%' }}
            checked={filterKey === "1"}
            onChange={() => handleFilter("1")}
          >
            Descendente Z-A
          </Checkbox>
        </div>
    },
    {
      key: '2',
      label:
        <div className='business-headquarters__content-search__select-headquarter__checkbox'>
          <Checkbox
            style={{ width: '100%' }}
            checked={filterKey === "2"}
            onChange={() => handleFilter("2")}
          >
            Mayor a menor
          </Checkbox>
        </div>
    },
    {
      key: '3',
      label:
        <div className='business-headquarters__content-search__select-headquarter__checkbox'>
          <Checkbox
            style={{ width: '100%' }}
            checked={filterKey === "3"}
            onChange={() => handleFilter("3")}
          >
            Menor a mayor
          </Checkbox>
        </div>
    },
  ];

  const handleFilter = (key) => {
    let sortedHeadquarter = [...listOfHeadquarter];
    switch (key) {
      case '0':
        sortedHeadquarter.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case '1':
        sortedHeadquarter.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case '2':
        sortedHeadquarter.sort((a, b) => b.initialBalance - a.initialBalance);
        break;
      case '3':
        sortedHeadquarter.sort((a, b) => a.initialBalance - b.initialBalance);
        break;
      default:
        break;
    };
    setFilterKey(key);
    setState({ ...state, listOfHeadquarter: sortedHeadquarter });
  }

  const filterHeadquarter = async(e) => {
    const { value } = e.target;
    if (value.length > 2 || value.length === 0) {
      try {
        await getListHeadquarter(value);
        setFilterKey();
      } catch (error) {
        ErrorAlertComponent()
      }
    }
  }

  return (
    <div className='business-headquarters__container'>
      <div className='grid-x align-content-end justify-content-between business-headquarters__content-search'>
        <Input
          onChange={(e) => {filterHeadquarter(e)}}
          className='business-headquarters__content-search__input'
          placeholder='Buscar sedes por nombre'
          prefix={<img src={Assets.SharedIcons.icon_search_magnifier} alt="icon_search_magnifier" />}
        />
        <div className='grid-x'>
          <div className='business-headquarters__content-search__select-headquarter'>
            <Dropdown
              menu={{ items }}
              trigger={['click']}
              className='grid-x small-12'
            >
              <div
                onMouseOver={() => setState({...state,isHoverSelected:true})}
                onMouseOut={() => setState({...state,isHoverSelected:false})}
                className='grid-x align-middle justify-content-center business-headquarters__content-search__select-headquarter__content-selected'
              >
                <img
                  alt={isHoverSelected ? "icon_filter_hover" : "icon_filter"}
                  src={isHoverSelected ? Assets.SharedIcons.icon_filter_hover : Assets.SharedIcons.icon_filter}
                />
                <span className={`business-headquarters__content-search__select-headquarter__text${isHoverSelected ? '__hover' : ''}`}>
                  Ordenar por
                </span>
              </div>
            </Dropdown>
          </div>
          <button
            className='business-headquarters__content-search__button'
            onClick={() => history('/headquarterBusiness/createEdit', { state: { titleHeadquarter: 'Agregar nueva sede' } })}
          >
            <img src={Assets.SharedIcons.icon_add_white} alt="icon_search" />
            <span className='business-headquarters__content-search__button__text'>Agregar nueva sede</span>
          </button>
        </div>
      </div>
      <div className='business-headquarters__table'>
        <div className='grid-x align-middle business-headquarters__table__container-title'>
          <span className='business-headquarters__table__title business-headquarters__table__width-column__initial'>Nombre de la sede</span>
          <span className='business-headquarters__table__title business-headquarters__table__width-column__third'>Inversión inicial</span>
          <span className='business-headquarters__table__title business-headquarters__table__width-column__third'>Bodega</span>
          <span className='business-headquarters__table__title business-headquarters__table__width-column__fourth'>Nro. cuenta bancaria</span>
          <span className='business-headquarters__table__title business-headquarters__table__width-column__third'>Responsable de la sede</span>
          <span className='business-headquarters__table__title business-headquarters__table__width-column__fifth'>Acciones</span>
        </div>
        <div className='business-headquarters__table__container'>
          {listOfHeadquarter?.map((headquarter, index) => (
            <div key={index} className={`grid-x align-middle business-headquarters__table__information-row${isItemChecked(headquarter.id) ? '__selected' : ''}`}>
              <div className='grid-x align-center business-headquarters__table__width-column__first'>
                <div className='business-headquarters__table__checkbox'>
                  <Checkbox
                    checked={isItemChecked(headquarter?.id)}
                    onChange={() => handleCheckboxChange(headquarter?.id)}
                  />
                </div>
              </div>
              <div className='grid-x business-headquarters__table__width-column__second'>
                <span className='business-headquarters__table__description'>
                  {headquarter?.name}
                </span>
              </div>
              <div className='grid-x business-headquarters__table__width-column__third'>
                <span className='business-headquarters__table__description'>
                  {(parseFloat(Number(headquarter?.initialBalance)?.toFixed(2)) || 0)?.toLocaleString()}
                </span>
              </div>
              <div className='grid-x business-headquarters__table__width-column__third'>
                {headquarter?.headquarterStore.map((store, index) => (
                  <span key={index} className='business-headquarters__table__description--mod'>
                    {store?.code}
                    {index < headquarter?.headquarterStore.length - 1 && ', '}
                  </span>
                ))}
              </div>
              <div className='grid-x business-headquarters__table__width-column__fourth'>
                {headquarter?.headquarterPaymentMethodDailyTransaction.map((paymentMethod, index) => (
                  <span key={index} className='business-headquarters__table__description'>
                    {paymentMethod?.name}
                    {index < headquarter?.headquarterPaymentMethodDailyTransaction.length - 1 && ', '}
                  </span>
                ))}
              </div>
              <div className='grid-x business-headquarters__table__width-column__third'>
                <span className={`business-headquarters__table__description${!headquarter?.headQuarterUserChargeHeadQuarter?.name ? '--unassigned' : ''}`}>
                  {headquarter?.headQuarterUserChargeHeadQuarter?.name || 'Sin asignar'}
                </span>
              </div>
              <div className='grid-x business-headquarters__table__width-column__fifth'>
                <button
                  className='grid-x align-middle business-headquarters__table__button'
                  onClick={() => history(`/headquarterBusiness/${headquarter?.id}`, { state: { idHeadquarter: headquarter?.id, titleHeadquarter: `${headquarter?.name}` } })}
                >
                  <span>Ver detalles</span>
                  <img className='business-headquarters__table__icon-arrow' src={Assets.SharedIcons.icon_arrow} alt="icon_arrow" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='grid-x align-middle justify-content-between business-headquarters__content-pagination'>
        <span className='business-headquarters__content-pagination__text'>
          Páginas: <span className='business-headquarters__content-pagination__text--mod'>{paginatorState?.page + 1} </span>
          de <span className='business-headquarters__content-pagination__text--mod'>{Math.ceil(paginatorState?.totalPages / 10)}</span>
        </span>
        <PaginationComponent 
          pageItem={10}
          page={(paginatorState?.page + 1)}
          totalItems={paginatorState?.totalPages}
          onPage={(page)=>{
            setState({
              ...state,
              paginatorState: {
                ...state.paginatorState,
                page: page - 1
              }
            })
          }}
        />
      </div>
    </div>
  )
}

export default BusinessHeadquartersComponent;