import * as authenticationTypes from './authentication.types';

export const setUserLoginAuthenticationReducer = (value) => (dispatch) => {
	dispatch({ type: authenticationTypes.LOGIN, payload: value });
};

export const setLogoutAuthenticationReducer = () => (dispatch) => {
	dispatch({ type: authenticationTypes.LOGOUT});
};

export const setErrorLoginAuthenticationReducer = (value) => (dispatch) => {
	dispatch({ type: authenticationTypes.SET_ERROR_LOGIN, payload: value });
};

export const setProcessValidateSessionAuthenticationReducer = (isCheckingSession, isValidSession) => (dispatch) => {
	dispatch({ type: authenticationTypes.SET_PROCESS_VALIDATE_SESSION, payload: { isCheckingSession, isValidSession } });
};

export const setStateAuthenticationReducer = (prop, value) => (dispatch) => {
	dispatch({ type: authenticationTypes.SET_STATE_AUTHENTICATION, payload: { prop, value } });
};

export const resetStateAuthenticationReducer = () => async (dispatch) => {
	dispatch({ type: authenticationTypes.RESET_STATE });
};