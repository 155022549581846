const InventoryEndPoints =  {
    GET_DOWNLOAD_PRODUCT_TEMPLATE: `/api/inventoryInventoryControl/downloadProductTemplate`,
    GET_DOWNLOAD_INVENTORY_TEMPLATE: `/api/inventoryInventoryControl/downloadInventoryTemplate`,
    VALIDATE_PRODUCT_FILE: `/api/inventoryInventoryControl/extractProducts`,
    VALIDATE_WAREHOUSE_FILE: `/api/inventoryInventoryControl/extractProductsByStore`,
    GET_HEAD_QUARTERS: `/api/inventoryInventoryControl/extractDataFromStores`,
    GET_INVENTORY_IN_STORE: `/api/inventoryInventoryControl/inventoryInStores`,
    GET_DOWNLOAD_INVENTORY: `/api/inventoryInventoryControl/downloadInventory`,
    CREATE_TRANSFER_EXTRACT: `/api/inventoryInventoryControl/extractTransfer`,
    GET_TRANSFER_EXTRACT: `/api/inventoryInventoryControl/getTransfers`,
    GET_DOWNLOAD_TRANSFER: `/api/inventoryInventoryControl/downloadTransfers`,
    GET_DOWNLOAD_TEMPLATE_TRANSFER: `/api/inventoryInventoryControl/downloadTransfersTemplate`,
    VALIDATE_SALES_REPORTS_CLOUD: `/api/inventoryInventoryControl/extractSaleAndDifferentialToCloud`,
    VALIDATE_UTILITIES_CLOUD: `/api/inventoryInventoryControl/extractUtilityToCloud`,
    VALIDATE_SALES_REPORTS_COLD_POINT: `/api/inventoryInventoryControl/extractSaleToPP`,
    VALIDATE_UTILITIES_COLD_POINT: `/api/inventoryInventoryControl/extractUtilityToPP`,
    GET_REPORTS_VALUATIONS:  `/api/inventoryInventoryControl/extractReportValuations`,
    GET_DOWNLOAD_UTILITIES: `/api/inventoryInventoryControl/downloadReportValuations`,
    GET_SALES_REPORTS: `/api/inventoryInventoryControl/downloadDifferentialCloud`,
    GET_SALES_REPORTS_PF: `/api/inventoryInventoryControl/downloadSalesPf`,
    GET_INVOICING_PROFIT: `/api/inventoryInventoryControl/downloadUtilityCloud`,
    GET_UTILITY_REPORTS_PF: `/api/inventoryInventoryControl/downloadUtilityPf`,
};

export default InventoryEndPoints;