// Actions
import { setItemCashClosingWholesaleReducer } from '../../../../storage/reducers/cash-closing-wholesale/cash-closing-wholesale.actions';

//Assets
import { Assets } from '../../../../assets';

//Components - Shared
import ErrorAlertComponent from '../../../../shared/components/alerts/error-alert/error-alert.component';
import SuccessAlertComponent from '../../../../shared/components/alerts/success-alert/success-alert.component';

//Libraries
import { connect } from 'react-redux';
import { AutoComplete, Input } from 'antd';
import { NumericFormat } from "react-number-format";
import React, { useEffect, useRef, useState } from 'react'

//Services
import { deleteFinancialMovementService } from '../../../../services/cash-closing-wholesale.services';

//Styles
import './consignments-or-transfers.component.scss'

const ConsignmentsOrTransfersComponent = (props) => {

  const {
    //Actions
    setItemCashClosingWholesaleReducer,
    //Variables
    financeItem,
    keyFinanceTable,
    optionsBanks

  } = props;
  
  const INITIAL_STATE = {
    scrollEnd: false,
    consignments: {},
    optionsConcept: []
  };

  const [state, setState] = useState(INITIAL_STATE)

  const { consignments, scrollEnd } = state

  const formRef = useRef(null);

  useEffect(() => {
    if (formRef.current && scrollEnd) {
      const miDiv = formRef.current;
      miDiv.scrollTop = miDiv.scrollHeight;
    }
  }, [scrollEnd, consignments.length]);

  useEffect(() => {
    setState((prevState)=> ({
      ...prevState,
      consignments: financeItem
    }));
  }, [financeItem])

  const addConsignments = () => {

    let financeListingModified = consignments
    const objectDisabled = financeListingModified?.financialMovement.find(object => object.hasOwnProperty("disabled") && object.disabled === true);

    if (!objectDisabled) {
      const newArray = [
        ...financeListingModified.financialMovement,
        {
          idTable: "",
          price: "",
          concept: "",
          disabled: true,
          value: ""
        },
      ]
      financeListingModified.financialMovement = newArray
      setState((prevState)=>({
        ...prevState,
        consignments: consignments,
        scrollEnd: true
      }))
      setItemCashClosingWholesaleReducer("shipmentRecords", keyFinanceTable, financeListingModified);
    } else {
      const newArray = financeListingModified?.financialMovement.map(object => object === objectDisabled ? { ...object, disabled: false } : object);
      financeListingModified.financialMovement = newArray
      setState ((prevState)=>({
        ...prevState,
        consignments: financeListingModified,
      }))
      setItemCashClosingWholesaleReducer("shipmentRecords", keyFinanceTable, financeListingModified);
    };
  };

  const handleOnChange = (e, index, name) => {
    let value = e && e.target ? e.target.value || '' : e;
    let financeListingModified = consignments
    if(name === 'concept'){
      financeListingModified.financialMovement[index][name] = { value };
    } else {
      financeListingModified.financialMovement[index][name] = value;
    }
    setState((prevState)=> ({
      ...prevState,
      consignments: financeListingModified,
    }))
    setItemCashClosingWholesaleReducer("shipmentRecords", keyFinanceTable, financeListingModified);
  };

  const deleteConsignments = async(index) => {
    try {
      let financeListingModified = consignments

      if (financeListingModified.financialMovement[index].id) {
        await deleteFinancialMovementService({financialMovement:[consignments?.financialMovement[index]?.id]})
        SuccessAlertComponent()
      }

      financeListingModified.financialMovement.splice(index, 1)

      if (financeListingModified.financialMovement.length <= 7) {
        financeListingModified.financialMovement = [
          ...financeListingModified.financialMovement,
          {
            idTable: "",
            price: "",
            concept: "",
            disabled: true,
            value: "" 
          }
        ]
      }

      setState((prevState)=>({
        ...prevState,
        consignments: financeListingModified
      }))

      setItemCashClosingWholesaleReducer("shipmentRecords", keyFinanceTable, financeListingModified);
    } catch (error) {
      ErrorAlertComponent()
    }
  }

  const TotalTransfers = (list) => {
    let sum = 0;
    list?.forEach(product => {
      sum += (Number(product?.price) >= 0 ) ? Number(product?.price) : Number(product?.price?.replace(/[^0-9]/g, ''))
    });
    
    return sum || 0;
  };

  const filterBanks = (concept ) => {
    const listConcept = optionsBanks?.filter(object => 
      object?.value?.toLowerCase()?.includes(concept?.toLowerCase())
    )
    setState(prevState => ({
      ...prevState,
      optionsConcept: listConcept,
    }))
  }

  return (
    <div className='consignments-or-transfers__container'>
      <div className='grid-y'>
        <div className='grid-x consignments-or-transfers__consignments__content-titles__primary'>
          <div className='grid-x align-middle small-6'>
            <img src={Assets.SharedIcons.icon_information} alt="icon_information" />
            <span
              className='consignments-or-transfers__consignments__titles consignments-or-transfers__consignments__titles__space'
            >
              {financeItem?.name.charAt(0).toUpperCase() + financeItem?.name.slice(1).toLowerCase()}
            </span>
          </div>
          <div className='grid-x align-middle small-6 justify-content-end'>
            <span className='consignments-or-transfers__consignments__titles'>Total:</span>
            <div className='grid-x align-middle consignments-or-transfers__summary__details'>
              <div className='consignments-or-transfers__consignments__table__content-icon-price'>
                <img
                  alt="icon_income"
                  src={Assets.SharedIcons.icon_income}
                  className='consignments-or-transfers__summary__price__icon'
                />
              </div>
              <NumericFormat
                type='text'
                prefix={"$ "}
                disabled
                placeholder='$ 0'
                thousandSeparator=","
                className='input-price'
                value={TotalTransfers(consignments?.financialMovement)}
                thousandsGroupStyle="thousand"
              />
            </div>
          </div>
        </div>
        <div className='grid-x consignments-or-transfers__consignments__content-titles__second'>
          <span className='grid-x small-3 consignments-or-transfers__consignments__titles consignments-or-transfers__consignments__titles--second'>
            Nº Liquidación
          </span>
          <span className="grid-x small-6 consignments-or-transfers__consignments__titles--title-second">Concepto</span>
          <span className='grid-x small-3 justify-content-end consignments-or-transfers__consignments__titles--title-second'>Valor</span>
        </div>
      </div>

      <div ref={formRef} className='consignments-or-transfers__consignments__info'>
        {consignments?.financialMovement?.map((item, index) => {
          return (
            <div key={index} className='grid-x consignments-or-transfers__consignments__table'>
              <div className='grid-x small-3'>
                <div className='grid-x consignments-or-transfers__consignments__table__content-inputs'>
                  <NumericFormat
                    type='text'
                    prefix={""}
                    placeholder='N° Liquidación'
                    value={item?.value}
                    thousandSeparator=""
                    className='input-price'
                    disabled={item?.disabled}
                    thousandsGroupStyle="thousand"
                    onChange={(e) => handleOnChange(e, index, 'value')}
                  />
                  <div className='consignments-or-transfers__consignments__table__content-icon-price'>
                    <img
                      alt="icon_add"
                      src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                    />
                  </div>
                </div>
              </div>
              <div className="small-5">
                <AutoComplete 
                  onSearch={(e) => {
                    const trimmedValue = e.trim();
                    if (trimmedValue) {
                      filterBanks(trimmedValue)
                    } else {
                      handleOnChange(e, index, "concept")
                      setState({ ...state, optionsConcept: [] });
                    }
                  }}
                  disabled={item?.disabled}
                  value={item?.concept.value}
                  options={consignments.type !== 2 ? optionsBanks : []}
                  onSelect={(value)=> handleOnChange(value, index, "concept")}
                  popupClassName='consignments-or-transfers__input-autocomplete'
                  className='consignments-or-transfers__consignments__table__content-inputs'
                >
                  <Input
                    onChange={(e)=> handleOnChange(e, index, "concept")}
                    className='consignments-or-transfers__input'
                    placeholder='Descripción'
                    prefix={<img src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit} alt="icon_add" />}
                  />

                </AutoComplete>
              </div>
              <div className='grid-x small-3'>
                <div className='grid-x consignments-or-transfers__consignments__table__content-inputs'>
                  <NumericFormat
                    type='text'
                    prefix={"$ "}
                    placeholder='$ 0'
                    value={item?.price}
                    thousandSeparator=","
                    className='input-price'
                    disabled={item?.disabled}
                    thousandsGroupStyle="thousand"
                    onChange={(e) => handleOnChange(e, index, 'price')}
                  />
                  <div className='consignments-or-transfers__consignments__table__content-icon-price'>
                    <img
                      alt="icon_add"
                      src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                    />
                  </div>
                </div>
              </div>
              <div className='grid-x small-1 justify-content-end'>
                <button
                  className='consignments-or-transfers__consignments__button-delete'
                  disabled={item?.disabled}
                  onClick={() => deleteConsignments(index)}
                >
                  <img
                    className='consignments-or-transfers__consignments__button-delete__icon'
                    alt={item?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                    src={item?.disabled ? Assets.SharedIcons.icon_deleted_disabled : Assets.SharedIcons.icon_deleted_active}
                  />
                </button>
              </div>
            </div>
          )
        })}
      </div>
      <div className='grid-x consignments-or-transfers__consignments__button'>
        <div className='consignments-or-transfers__consignments__line'></div>
        <button
          onClick={() => addConsignments()}
          className='grid-x align-middle consignments-or-transfers__consignments__type'
        >
          <img
            alt='icon_income'
            src={Assets.SharedIcons.icon_income}
          />
          <span className='consignments-or-transfers__consignments__type__name'>
            Agregar otro
          </span>
        </button>
      </div>
    </div>
  )
}

const mapStateToPropsActions = {
  setItemCashClosingWholesaleReducer
};

export default connect (null,mapStateToPropsActions)(ConsignmentsOrTransfersComponent);
