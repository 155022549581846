const HeadquartersEndPoints =  {
    GET_TOTAL_BY_HEADQUARTERS: `/api/finalBalanceInventoryControl/getTotalByHeadquarters/:id/:date`,
    GET_ORDER_TYPES: `/api/finalBalanceInventoryControl/getConceptTypes`,
    GET_CONCEPTS: `/api/finalBalanceInventoryControl/getConcepts/:id/:date`,
    GET_HEADQUARTERS_EXPENSES: `/api/finalBalanceInventoryControl/getHeadquartersExpenses/:headQuarterId/:date`,
    GET_CHARGES: `/api/finalBalanceInventoryControl/getCharges/:id/:date`,
    GET_TEXAS: `/api/finalBalanceInventoryControl/getTaxes/:headQuarterId/:date`,
    UPDATE_TEXAS: `/api/finalBalanceInventoryControl/setTaxes/:headQuarterId/:date`,
    CREATE_CONCEPTS: `/api/finalBalanceInventoryControl/setConcepts/:id/:date`,
    GET_CONSUMPTION_PROVISIONS: `/api/finalBalanceInventoryControl/getConsumptionProvisions/:headQuarterId/:date`,
    GET_IVA_PROVISIONS: `/api/finalBalanceInventoryControl/getIvaProvisions/:headQuarterId/:date`,
    GET_PROJECTIONS: `/api/finalBalanceInventoryControl/controlPanel/:date`,
    UPDATE_PROJECTIONS: `/api/finalBalanceInventoryControl/controlPanel`,
    UPDATE_CONSUMPTION_PROVISIONS: `/api/finalBalanceInventoryControl/setConsumptionProvisions/:headQuarterId`,
    UPDATE_CONSUMPTION_IVA: `/api/finalBalanceInventoryControl/setIvaProvisions/:headQuarterId`,
    GET_ALL_HEADQUARTERS: `/api/finalBalanceInventoryControl/all/:date`,
    GET_PORTFOLIO_LIST: `/api/finalBalanceInventoryControl/getTotalTreasury/:headQuarterId/:date`,
    GET_TOTAL_UTILITY: `/api/finalBalanceInventoryControl/getTotalUtility/:headQuarterId/:date`,
    GET_TOTAL_EXPENSES: `/api/finalBalanceInventoryControl/getTotalExpenses/:headQuarterId/:date`,
    GET_TOTAL_TREASURY: `/api/finalBalanceInventoryControl/getTotalTreasury/:headQuarterId/:date`,
    GET_TOTAL_INVENTORY: `/api/finalBalanceInventoryControl/getTotalInventory/:headQuarterId/:date`,
    GET_TOTAL_SALES: `/api/finalBalanceInventoryControl/getTotalSales/:headQuarterId/:date`,
    GET_TOTAL_DIFFERENTIAL: `/api/finalBalanceInventoryControl/getTotalDifferential/:headQuarterId/:date`,
    GET_TOTAL_CREDITS: `/api/finalBalanceInventoryControl/getTotalCredit/:headQuarterId/:date`
}

export default HeadquartersEndPoints