// Actions
import { resetWholesaleFinalBalanceReducer, setMultipleStatesWholesaleFinalBalanceReducer } from '../../storage/reducers/wholesale-final-balance/wholesale-final-balance.actions';

// Assets
import { Assets } from '../../assets';

// Components
import ErrorAlertComponent from '../../shared/components/alerts/error-alert/error-alert.component';

// Libraries
import { Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

// Services
import { getFindAllWholesalerService } from '../../services/cash-closing-wholesale.services';

// Styles
import './final-adjustment.page.scss';

const FinalAdjustmentPage = (props) => {

    const { resetWholesaleFinalBalanceReducer } = props;

    const history = useNavigate();

    const INITIAL_STATE = {
        checkedItems: [],
        isHoverName: false,
        listOfHeadquarters: []
    }

    const [state, setState] = useState(INITIAL_STATE)

    const {
        isHoverName,
        checkedItems,
        listOfHeadquarters
    } = state;

    useEffect(() => {
        getAllHeadquarters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    const getAllHeadquarters = async () => {
        try {
            const listOfHeadquarters = await getFindAllWholesalerService()
            setState({ ...state, listOfHeadquarters })
        } catch (error) {
            ErrorAlertComponent()
        }
    };

    const handleCheckboxChange = (headquarter) => {
        const prevItem = checkedItems.includes(headquarter) ? checkedItems.filter(itemIndex => itemIndex !== headquarter) : [...checkedItems, headquarter];
        setState((prevState) => ({
            ...prevState,
            checkedItems: prevItem
        }))
    };

    const isItemChecked = (headquarter) => checkedItems.includes(headquarter);

    const viewCashClosing = (headquarter) => {
        resetWholesaleFinalBalanceReducer()
        history(`/finalBalanceWholesaler/${headquarter?.id}`, { state: { nameHeadquarter: headquarter?.name, idHeadquarter: headquarter?.id } });
    }

    const isSortedAscendingByAttribute = (list, attributeName) => {
        return list.every((item, index) => index === 0 || item[attributeName] >= list[index - 1][attributeName]);
    };

    const optionFilter = (nameFilter, listOfHeadquarters) => {
        let sortedColdPointValuation = [...listOfHeadquarters];
        const isSorted = isSortedAscendingByAttribute(listOfHeadquarters, nameFilter);
        const sortOrder = isSorted ? 1 : -1;
        sortedColdPointValuation.sort((a, b) => (sortOrder * (b.name.localeCompare(a.name))))
        setState((prevState) => ({
            ...prevState,
            listOfHeadquarters: sortedColdPointValuation,
        }));
    };

    return (
        <div className='grid-x align-center final-adjustment__container'>
            <div className='grid-x align-middle final-adjustment__title__content'>
                <span className='final-adjustment__title'>Cuadre de Sedes</span>
            </div>
            <div className='final-adjustment__box'>
                <div className='grid-x align-middle justify-content-between final-adjustment__box__content-titles'>
                    <div className='grid-x align-center-middle'>
                        <button
                            onClick={() => optionFilter('name', listOfHeadquarters)}
                            onMouseOver={() => setState({ ...state, isHoverName: true })}
                            onMouseOut={() => setState({ ...state, isHoverName: false })}
                            className='grid-x align-center-middle final-adjustment__box__button'
                        >
                            <img
                                alt={isHoverName ? "icon_blue_arrow_down" : "icon_blue_arrow"}
                                src={isHoverName ? Assets.SharedIcons.icon_blue_arrow_down : Assets.SharedIcons.icon_blue_arrow}
                                className={`final-adjustment__box__button__icon${isSortedAscendingByAttribute(listOfHeadquarters, 'name') ? '__active' : ''}`}
                            />
                            <span className={`final-adjustment__box__title${isHoverName ? '__active' : ''}`}>
                                Nombre de la sede
                            </span>
                        </button>
                    </div>
                    <span className='final-adjustment__box__title'>
                        Acciones
                    </span>
                </div>
                <div className='grid-x final-adjustment__box__content-information'>
                    {listOfHeadquarters.map((headquarter, index) => {
                        return (
                            <div key={index} className={`grid-x align-middle justify-content-between final-adjustment__box__info${isItemChecked(headquarter) ? '__selected' : ''}`}>
                                <div className='grid-x align-middle small-7'>
                                    <div className='final-adjustment__box__info__checkbox'>
                                        <Checkbox
                                            checked={isItemChecked(headquarter)}
                                            onChange={() => handleCheckboxChange(headquarter)}
                                        />
                                    </div>
                                    <div>
                                        <span className='final-adjustment__box__info__text'>
                                            {headquarter?.name}
                                        </span>
                                    </div>
                                </div>
                                <div className='grid-x align-middle justify-content-end small-5'>
                                    <button
                                        className='final-adjustment__box__info__button'
                                        onClick={() => viewCashClosing(headquarter)}
                                    >
                                        <span>Ver y editar resumen</span>
                                        <img
                                            alt="icon_arrow"
                                            src={Assets.SharedIcons.icon_arrow}
                                            className='final-adjustment__box__info__button__icon'
                                        />
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )

}

const mapStateToPropsActions = {
  setMultipleStatesWholesaleFinalBalanceReducer,
  resetWholesaleFinalBalanceReducer
};
  
export default connect(null, mapStateToPropsActions)(FinalAdjustmentPage);