// Constants
import FinalReconciliationEndPoints from "./constants/final-reconciliation.end-points";

// Infrastructures
import Http from "./infrastructure/http.infrastructure"

// Services
import { getTokenService } from "./authentication.services";

export async function getAllListOfTabsService(id) {
    try {
        let token = getTokenService()
        const urlWithParams = FinalReconciliationEndPoints.GET_ALL_LIST_OF_TABS.replace(":wholesalerId", id)
        const {data: responseGetAllListOfTabs, error} = await Http.get(urlWithParams, null, token)
        if (responseGetAllListOfTabs && responseGetAllListOfTabs.status) {
            return responseGetAllListOfTabs.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getInfoTabByIdService(body) {
    try {
        let token = getTokenService()
        const { data: responseGetInfoTabById, error } = await Http.get(FinalReconciliationEndPoints.GET_INFO_TAB_BY_ID, body, token)
        if (responseGetInfoTabById && responseGetInfoTabById.status) {
            return responseGetInfoTabById.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getRegisterMontlyClosingService(date, id) {
    try {
        let token = getTokenService()
        const urlWithParams = FinalReconciliationEndPoints.GET_REGISTER_MONTHLY_CLOSING.replace(":wholesalerId", id).replace(":date", date)
        const {data: responseGetRegisterMonthlyClosing, error} = await Http.get(urlWithParams, null, token)
        if (responseGetRegisterMonthlyClosing && responseGetRegisterMonthlyClosing.status) {
            return responseGetRegisterMonthlyClosing.data 
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createRegisterMonthlyClosingService(body, id) {
    const token = getTokenService()
    try {
        const urlWithParams = FinalReconciliationEndPoints.CREATE_REGISTER_MONTHLY_CLOSING.replace(":wholesalerId", id)
        const {data: responseCreateRegisterMonthlyClosing, error} = await Http.post(urlWithParams, body, token)
        if (responseCreateRegisterMonthlyClosing && responseCreateRegisterMonthlyClosing.status) {
            return responseCreateRegisterMonthlyClosing.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getGeneralSummaryService(id,date) {
    try {
        let token = getTokenService()
        const urlWithParams = FinalReconciliationEndPoints.GET_GENERAL_SUMMARY.replace(":id", id).replace(":date", date)
        const {data: responseGetRegisterMonthlyClosing, error} = await Http.get(urlWithParams, null, token)
        if (responseGetRegisterMonthlyClosing && responseGetRegisterMonthlyClosing.status) {
            return responseGetRegisterMonthlyClosing.data 
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createGeneralSummaryService(id,data) {
    try {
        let token = getTokenService()
        const urlWithParams = FinalReconciliationEndPoints.CREATE_GENERAL_SUMMARY.replace(":id", id)
        const {data: responseUpdateTotals, error} = await Http.post(urlWithParams,data, token)
        if (responseUpdateTotals && responseUpdateTotals.status) {
            return responseUpdateTotals.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateGeneralSummaryService(data) {
    try {
        let token = getTokenService()
        const {data: responseUpdateTotals, error} = await Http.put(FinalReconciliationEndPoints.UPDATE_GENERAL_SUMMARY,data, token)
        if (responseUpdateTotals && responseUpdateTotals.status) {
            return responseUpdateTotals.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createRecordsInTheTablesService(body, id) {
    try {
        let token = getTokenService()
        const urlWithParams = FinalReconciliationEndPoints.CREATE_RECORDS_IN_THE_TABLES.replace(":wholesalerId", id)
        const { data: responseCreateRecordsInTheTables, error } = await Http.post(urlWithParams, body, token)
        if (responseCreateRecordsInTheTables && responseCreateRecordsInTheTables.status) {
            return responseCreateRecordsInTheTables.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateRecordsInTheTablesService(body) {
    try {
        let token = getTokenService()
        const { data: responseUpdateRecordsInTheTables, error } = await Http.put(FinalReconciliationEndPoints.UPDATE_RECORDS_IN_THE_TABLES, body, token)
        if (responseUpdateRecordsInTheTables && responseUpdateRecordsInTheTables.status) {
            return responseUpdateRecordsInTheTables.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function deletedRecordsInTheTablesService(id, isExpense) {
    try {
        let token = getTokenService()
        const urlWithParams = FinalReconciliationEndPoints.DELETED_RECORDS_IN_THE_TABLES.replace(":balanceId", id).replace(":isExpense", isExpense)
        const { data: responseDeletedRecordsInTheTables, error } = await Http.delete(urlWithParams, null, token)
        if (responseDeletedRecordsInTheTables && responseDeletedRecordsInTheTables.status) {
            return responseDeletedRecordsInTheTables.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};