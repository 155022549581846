// Libraries
import * as Yup from 'yup';

export const addUserSchema = Yup.object().shape({
    name: Yup.string()
        .required('Requerido'),
    nickName: Yup.string()
        .min(8, 'Mínimo 8 caracteres')
        .required('Requerido'),
    identificationNumber: Yup.string()
        .matches(/^[0-9]+$/, 'No es un número')
        .min(4, 'Mínimo 6 dígitos')
        .max(11, 'Máximo 10 dígitos')
        .required('Requerido'),
    phoneNumber: Yup.string()
        .matches(/^[0-9]+$/, 'No es un número')
        .length(10, 'Número no valido')
        .required('Requerido'),
    email: Yup.string()
        .email('Correo electrónico no valido')
        .test('valid-domain', 'Correo electrónico no valido', function (value) {
            if (value) {
                const domain = value.split('@')[1];
                return domain && domain.match(/\.[a-zA-Z]{2,}$/) !== null;
            }
            return false;
        })
        .required('Requerido'),
    position: Yup.string()
        .required('Requerido'),
    password: Yup.string()
        .required('Requerido'),
    confirmPassword: Yup.string()
        .required('Requerido')
        .min(8, 'Mínimo 8 caracteres')
        .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden'),
})

export const editUserSchema = Yup.object().shape({
    name: Yup.string()
        .required('Requerido'),
    nickName: Yup.string()
        .min(8, 'Mínimo 8 caracteres')
        .required('Requerido'),
    identificationNumber: Yup.string()
        .matches(/^[0-9]+$/, 'No es un número')
        .min(4, 'Mínimo 6 dígitos')
        .max(11, 'Máximo 10 dígitos')
        .required('Requerido'),
    phoneNumber: Yup.string()
        .matches(/^[0-9]+$/, 'No es un número')
        .length(10, 'Número no valido')
        .required('Requerido'),
    email: Yup.string()
        .email('Correo electrónico no valido')
        .test('valid-domain', 'Correo electrónico no valido', function (value) {
            if (value) {
                const domain = value.split('@')[1];
                return domain && domain.match(/\.[a-zA-Z]{2,}$/) !== null;
            }
            return false;
        })
        .required('Requerido'),
    position: Yup.string()
        .required('Requerido')
})