// Components
import FileUploadToUtilitiesComponent from './components/file-upload-to-utilities/file-upload-to-utilities.component';
import ListOfTnsValuationsComponent from './components/list-of-tns-valuations/list-of-tns-valuations.component';
import ErrorAlertComponent from '../../../../shared/components/alerts/error-alert/error-alert.component';

// Libraries
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';

// Services
import { getReportsValuationService } from '../../../../services/inventory.services';

// Styles
import './utilities.component.scss';

const UtilitiesComponent = (props) => {

  const {
    // Props
    isUpdated,
    isUpdateStateReports
  } = props;

  const INITIAL_STATE = {
    isLoading: false,
    isLoadingView: true,
    isListOfTnsValuations: false,
    cloudValuationInfo: {},
    coldPointValuationInfo: {}
  };

  const [state, setState] = useState(INITIAL_STATE);

  const {
    isLoading,
    isLoadingView,
    isListOfTnsValuations,
    cloudValuationInfo,
    coldPointValuationInfo
  } = state;

  useEffect(() => {
    getListOfTnsValuations(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getListOfTnsValuations = async (isGetInitial) => {
    try {
      isGetInitial && setState((prevState) => ({ ...prevState, isLoadingView: true }));
      const { ppAndCloudTableData, lastModifiedDate } = await getReportsValuationService();
      if (ppAndCloudTableData?.cloud?.data?.length && ppAndCloudTableData?.pp?.data?.length) {
        if (isGetInitial) {
          setTimeout(() => {
            setState((prevState) => ({
              ...prevState,
              isLoadingView: false,
              isListOfTnsValuations: true,
              cloudValuationInfo: ppAndCloudTableData?.cloud,
              coldPointValuationInfo: ppAndCloudTableData?.pp
            }));
            isUpdateStateReports({ isUtilities: true, isUpdated: false, lastUpdate: lastModifiedDate });
          }, 500);
        } else {
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            isListOfTnsValuations: true,
            cloudValuationInfo: ppAndCloudTableData?.cloud,
            coldPointValuationInfo: ppAndCloudTableData?.pp
          }));
          isUpdateStateReports({ isUtilities: true, isUpdated: false, lastUpdate: lastModifiedDate });
        }
      } else {
        setTimeout(() => {
          setState((prevState) => ({ ...prevState, isLoadingView: false, isListOfTnsValuations: false, isLoading: false }));
        }, 500);
      }
    } catch (error) {
      ErrorAlertComponent();
    }
  };
  
  return (
    <>
      {isLoadingView
        ?
          (<div className='grid-y align-center-middle utilities__content-loading-view'>
            <Spin size='large' />
            <span className='utilities__content-loading-view__text'>Cargando...</span>
          </div>)
        :
          (<div className='utilities__container'>
          {isLoading &&
            (<>
              <div className='utilities__spinner__container' />
              <Spin size='large' className='utilities__spinner' />
            </>)
          }
          {isListOfTnsValuations && !isUpdated
            ?
              (<ListOfTnsValuationsComponent
                cloudValuationInfo={cloudValuationInfo}
                coldPointValuationInfo={coldPointValuationInfo}
              />)
            :
              (<FileUploadToUtilitiesComponent
                getListOfTnsValuations={() => getListOfTnsValuations(false)}
                updateStateUtilities={(newState) => setState((prevState) => ({ ...prevState, ...newState}))}
              />)
          }
          </div>)
      }
    </>
  )
}

export default UtilitiesComponent;