// Assets
import { Assets } from '../../../../assets';

// Libraries
import { notification } from 'antd';

// Styles
import '../alert.component.scss';

const InformationAlertComponent = (
  message='Aviso!',
  description='Tu información está al día.'
) => {
  notification.info({
    message,
    description,
    icon: <img src={Assets.SharedIcons.icon_alert_info} alt="icon_alert_info" />,
    closeIcon: <img src={Assets.SharedIcons.icon_deleted_active} alt="icon_deleted_active" />,
    className:'notification-alert__info'
  });
}

export default InformationAlertComponent