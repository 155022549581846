// Components
import SitesAndPubsComponent from '../sites-and-pubs/sites-and-pubs.component';
import TableHistoryOfSitesAnPubsComponent from '../table-history-of-sites-and-pubs/table-history-of-sites-and-pubs.component';
import ErrorAlertComponent from '../../../../../../shared/components/alerts/error-alert/error-alert.component';

// Libraries
import React, { useEffect, useState } from 'react'

// Services
import { getInventoryInStoreService } from '../../../../../../services/inventory.services';

// Styles
import './inventory-list-by-headquarters.component.scss';

const InventoryListByHeadquartersComponent = (props) => {

  const {
    // props
    headQuartersAndPubs,
    updateStateGeneralInventory,
  } = props;

  const INITIAL_STATE = {
    filterKey: '',
    isLoading: true,
    isFilterExist: false,
    isFilterUnitCost: false,
    isFilterTotalCost: false,
    isFilterWarehouse: false,
    isFilterNameProduct: false,
    selectedHeadQuarterId: '',
    filterHeadQuartersText: '',
    headquartersHistoryList: [],
    headquartersHistoryListCopy: [],
    listHeadQuarterStoresData: [],
    isSelectedHeadquarterItem: null
  };

  const [state, setState] = useState(INITIAL_STATE);

  const {
    isLoading,
    filterKey,
    isFilterExist,
    isFilterUnitCost,
    isFilterWarehouse,
    isFilterTotalCost,
    isFilterNameProduct,
    selectedHeadQuarterId,
    filterHeadQuartersText,
    headquartersHistoryList,
    isSelectedHeadquarterItem,
    listHeadQuarterStoresData,
    headquartersHistoryListCopy,
  } = state;

  useEffect(() => {
    selectHistoryByHeadquarters(headQuartersAndPubs?.structuredByHeadQuartes[0]?.headQuarterId, headQuartersAndPubs?.structuredByHeadQuartes[0]?.storesData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headQuartersAndPubs]);

  const selectHistoryByHeadquarters = async (headQuarterId, headQuarterStoresData) => {
    setState((prevState) => ({
      ...prevState,
      filterKey: '',
      isLoading: true,
      filterHeadQuartersText: '',
      isSelectedHeadquarterItem: null,
      selectedHeadQuarterId: headQuarterId,
      listHeadQuarterStoresData: headQuarterStoresData
    }));
    try {
      const headquartersHistoryList = await getInventoryInStoreService(headQuarterId);
      setState((prevState) => ({
        ...prevState,
        isFilterExist: false,
        isFilterUnitCost: false,
        isFilterTotalCost: false,
        isFilterNameProduct: false,
        isLoading: false,
        headquartersHistoryList,
        headquartersHistoryListCopy: headquartersHistoryList
      }));
    } catch (error) {
      ErrorAlertComponent();
    }
  };

  const optionFilter = (param, optionName, storeName) => {
    let sortedHeadQuarters = [...headquartersHistoryListCopy];
    switch (param) {
      case 1:
        if (!isFilterNameProduct) {
          sortedHeadQuarters.sort((a, b) => b.name.localeCompare(a.name));
        } else {
          sortedHeadQuarters.sort((a, b) => a.name.localeCompare(b.name));
        }
        break;
      case 2:
        if (!isFilterExist) {
          sortedHeadQuarters.sort((a, b) => parseInt(b.amount) - parseInt(a.amount));
        } else {
          sortedHeadQuarters.sort((a, b) => parseInt(a.amount) - parseInt(b.amount));
        }
        break;
      case 3:
        sortedHeadQuarters = sortedHeadQuarters.filter((item) => item.store === storeName);
        break;
      case 4:
        if (!isFilterUnitCost) {
          sortedHeadQuarters.sort((a, b) => parseInt(b.unitCost) - parseInt(a.unitCost));
        } else {
          sortedHeadQuarters.sort((a, b) => parseInt(a.unitCost) - parseInt(b.unitCost));
        }
        break;
      case 5:
        if (!isFilterTotalCost) {
          sortedHeadQuarters.sort((a, b) => parseInt(b.totalCost) - parseInt(a.totalCost));
        } else {
          sortedHeadQuarters.sort((a, b) => parseInt(a.totalCost) - parseInt(b.totalCost));
        }
        break;
      default:

        break;
    };
    setState((prevState) => ({
      ...prevState,
      filterKey: optionName !== 'warehouse' && '',
      isFilterExist: (optionName === 'exist' && !isFilterExist) ? true : false,
      isFilterUnitCost: (optionName === 'unitCost' && !isFilterUnitCost) ? true : false,
      isFilterWarehouse: (optionName === 'warehouse' && !isFilterWarehouse) ? true : false,
      isFilterNameProduct: (optionName === 'nameProduct' && !isFilterNameProduct) ? true : false,
      isFilterTotalCost: (optionName === 'totalCost' && !isFilterTotalCost) ? true : false,
      headquartersHistoryList: sortedHeadQuarters,
    }));
  };

  const updateState = (newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }))
  };

  return (
    <>
      <div className='grid-x justify-content-between inventory-list-by-headquarters__content-info'>
        <SitesAndPubsComponent
          headQuartersAndPubs={headQuartersAndPubs}
          selectedHeadQuarterId={selectedHeadQuarterId}
          updateStateGeneralInventory={updateStateGeneralInventory}
          selectHistoryByHeadquarters={selectHistoryByHeadquarters}
        />
        <TableHistoryOfSitesAnPubsComponent
          isLoading={isLoading}
          filterKey={filterKey}
          optionFilter={optionFilter}
          setUpdateState={updateState}
          isFilterExist={isFilterExist}
          isFilterUnitCost={isFilterUnitCost}
          isFilterWarehouse={isFilterWarehouse}
          isFilterTotalCost={isFilterTotalCost}
          isFilterNameProduct={isFilterNameProduct}
          selectedHeadQuarterId={selectedHeadQuarterId}
          filterHeadQuartersText={filterHeadQuartersText}
          headquartersHistoryList={headquartersHistoryList}
          listHeadQuarterStoresData={listHeadQuarterStoresData}
          isSelectedHeadquarterItem={isSelectedHeadquarterItem}
        />
      </div>
    </>
  )
};

export default InventoryListByHeadquartersComponent;