//Libraries
import React, { useEffect } from 'react';

//Styles
import './dashboard.page.scss';

//Components
import DailyCashClosingComponent from './components/daily-cash-closing/daily-cash-closing.component';
import CashClosingDashboardComponent from './components/cash-closing-dashboard/cash-closing-dashboard.component';
import QuotationByHeadquarterComponent from './components/quotation-by-headquarter/quotation-by-headquarter.component';
import GeneralSummaryByHeadquartersComponent from './components/general-summary-by-headquarters/general-summary-by-headquarters.component';

//Services
import { setModuleNameService } from '../../services/authentication.services';

const DashboardPage = () => {

  useEffect(() => {
    //Send route name
    setModuleNameService('Dashboard');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <div className="grid-x dashboard__container">
      <div className="dashboard__content">
        <GeneralSummaryByHeadquartersComponent/>
        <div className="grid-x small-12">
          <div className="small-5 dashboard__box">
            <CashClosingDashboardComponent/>
          </div>
          <div className="small-7 dashboard__box">
            <QuotationByHeadquarterComponent/>
          </div>
        </div>
      </div>
      <div className="grid-x justify-content-end">
        <DailyCashClosingComponent/>
      </div>
    </div>
  )
};

export default DashboardPage;