// Components
import FileUploadToInventoryComponent from './components/file-upload-to-Inventory/file-upload-to-Inventory.component';
import InventoryListByHeadquartersComponent from './components/inventory-list-by-headquarters/inventory-list-by-headquarters.component';
import ErrorAlertComponent from '../../../../shared/components/alerts/error-alert/error-alert.component';

// Libraries
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';

// Services
import { getHeadQuartersService } from '../../../../services/inventory.services';

// Styles
import './general-inventory.component.scss';

const GeneralInventoryComponent = (props) => {

  const {
    // Props
    isUpdated,
    isUpdateStateReports
  } = props;

  const INITIAL_STATE = {
    isLoading: false,
    isLoadingView: true,
    headQuartersAndPubs: {
      structuredByHeadQuartes: [],
      totalInInventory: 0,
    },
    isInventoryViewByHeadquarters: false
  };

  const [state, setState] = useState(INITIAL_STATE);

  const {
    isLoading,
    isLoadingView,
    headQuartersAndPubs,
    isInventoryViewByHeadquarters
  } = state;

  useEffect(() => {
    getHeadQuarters(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getHeadQuarters = async (isGetInitial) => {
    try {
      isGetInitial && setState((prevState) => ({ ...prevState, isLoadingView: true }));
      const { structuredByHeadQuartes, totalInInventory, lastModifiedDate } = await getHeadQuartersService();
      if (isGetInitial) {
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            headQuartersAndPubs: {
              structuredByHeadQuartes,
              totalInInventory
            },
            isLoadingView: false,
            isInventoryViewByHeadquarters: true
          }));
          isUpdateStateReports({
            isUpdated: false,
            isGeneralInventory: true,
            lastUpdate: lastModifiedDate
          });
        }, 500);
      } else {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          headQuartersAndPubs: {
            structuredByHeadQuartes,
            totalInInventory
          },
          isInventoryViewByHeadquarters: true
        }));
        isUpdateStateReports({
          isUpdated: false,
          isGeneralInventory: true,
          lastUpdate: lastModifiedDate
        });
      };
    } catch (error) {
      setTimeout(() => {
        if (error.code !== 'INVY_009' && error.message !== 'Not exist inventory') {
          ErrorAlertComponent();
        }
        setState((prevState) => ({
          ...prevState,
          isLoadingView: false,
          isInventoryViewByHeadquarters: false
        }));
      }, 500);
    }
  };

  return (
    (<>
      {isLoadingView
        ?
          (<div className='grid-y align-center-middle general-inventory__content-loading-view'>
            <Spin size='large' />
            <span className='general-inventory__content-loading-view__text'>Cargando...</span>
          </div>)
        :
          (<div className='general-inventory__container'>
            {isLoading &&
              (<>
                <div className='general-inventory__spinner__container' />
                <Spin size='large' className='general-inventory__spinner' />
              </>)
            }
            {isInventoryViewByHeadquarters && !isUpdated
              ? 
                (<InventoryListByHeadquartersComponent
                  headQuartersAndPubs={headQuartersAndPubs}
                  updateStateGeneralInventory={(newState) => setState((prevState) => ({ ...prevState, ...newState }))}
                />)
              :
                (<FileUploadToInventoryComponent
                  getHeadQuarters={() => getHeadQuarters(false)}
                  updateStateGeneralInventory={(newState) => setState((prevState) => ({ ...prevState, ...newState }))}
                />)
            }
          </div>)
      }
    </>)
  )
};

export default GeneralInventoryComponent;