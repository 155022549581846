// Assets
import { Assets } from '../../../assets';

// Components - shared
import PaginationComponent from '../../../shared/components/pagination/pagination.component';
import ErrorAlertComponent from '../../../shared/components/alerts/error-alert/error-alert.component';

// Libraries
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { Checkbox, DatePicker, Dropdown, Input } from 'antd';

// Services
import { setModuleNameService } from '../../../services/authentication.services';
import { getListBanksService } from '../../../services/banks.services';

// Styles
import './banks.page.scss';

// Utils
import { formattedNumber } from '../../../utils/shared.utils';

const BanksPage = () => {

  let history = useNavigate();

  const [filterKey, setFilterKey ] = useState();

  const INITIAL_STATE = {
    listOfBanks: [],
    selectedYear: dayjs(),
    checkedItems: [],
    isHoverSelected: false,
    paginatorState:{
      totalPages: 1,
      page: 1
    },
  }
  const [state, setState] = useState(INITIAL_STATE);
  const { listOfBanks, selectedYear, checkedItems, isHoverSelected, paginatorState } = state;

  useEffect(() => {
    //Send route name
    setModuleNameService('Bancos');
    getListBanks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  const getListBanks = async(value) => {
    try {
      const listBanks = await getListBanksService(dayjs(selectedYear).format('YYYY-MM-DD'), value)
      setState({
        ...state,
        listOfBanks: listBanks,
        paginatorState: {
          totalPages: Math.ceil(listBanks.length / 10),
          page: 1
        },
      })
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  }

  const goToYear = (year) => {
    const newDate = dayjs(selectedYear).add(year, 'year');
    const currentDate = dayjs();

    if (newDate.isBefore(currentDate, 'month') || newDate.isSame(currentDate, 'month')) {
      setState({
        ...state,
        selectedYear: newDate
      });
    }
  };

  const disabledDate = (current) => {
    return current && current.isAfter(dayjs(), 'year');
  };

  const handleCheckboxChange = (idBank) => {
    // Clones the array of selected items and adds or removes the item according to its current state
    const checkedItem = checkedItems.includes(idBank) ? checkedItems.filter(itemIndex => itemIndex !== idBank) : [...checkedItems, idBank];

    setState((prevState) => ({
      ...prevState,
      checkedItems: checkedItem
    }))
  };

  const isItemChecked = (idBank) => checkedItems.includes(idBank);

  const items = [
    {
      key: '0',
      label:
        <div className='banks-page__content-search__select-bank__checkbox'>
          <Checkbox
            style={{ width: '100%' }}
            checked={filterKey === "0"}
            onChange={() => handleFilter("0")}
          >
            Ascendente A-Z
          </Checkbox>
        </div>
    },
    {
      key: '1',
      label:
        <div className='banks-page__content-search__select-bank__checkbox'>
          <Checkbox
            style={{ width: '100%' }}
            checked={filterKey === "1"}
            onChange={() => handleFilter("1")}
          >
            Descendente Z-A
          </Checkbox>
        </div>
    },
    {
      key: '2',
      label:
        <div className='banks-page__content-search__select-bank__checkbox'>
          <Checkbox
            style={{ width: '100%' }}
            checked={filterKey === "2"}
            onChange={() => handleFilter("2")}
          >
            Mayor a menor
          </Checkbox>
        </div>
    },
    {
      key: '3',
      label:
        <div className='banks-page__content-search__select-bank__checkbox'>
          <Checkbox
            style={{ width: '100%' }}
            checked={filterKey === "3"}
            onChange={() => handleFilter("3")}
          >
            Menor a mayor
          </Checkbox>
        </div>
    },
  ];

  const handleFilter = (key) => {
    let sortedBanks = [...listOfBanks];
    switch (key) {
      case '0':
        sortedBanks.sort((a, b) => a.bank.localeCompare(b.bank));
        break;
      case '1':
        sortedBanks.sort((a, b) => b.bank.localeCompare(a.bank));
        break;
      case '2':
        sortedBanks.sort((a, b) => b.currentBalance - a.currentBalance);
        break;
      case '3':
        sortedBanks.sort((a, b) => a.currentBalance - b.currentBalance);
        break;
      default:
        break;
    };
    setFilterKey(key);
    setState({ ...state, listOfBanks: sortedBanks });
  }


  const handleSearch = async(e) => {
    const { value } = e.target;
    if (value.length > 1 || value.length === 0) {
      try {
        await getListBanks(value);
      } catch (error) {
        ErrorAlertComponent()
      }
    }
  }

  const arrayFilterPage = (page,array) => {
    const startIndex = (page - 1) * 10;
    const endIndex = startIndex + 10;

    return array?.slice(startIndex, endIndex);
  }

  return (
    <div className='banks-page__container'>
      <div className='grid-x small-12 justify-content-between'>
        <span className='banks-page__title'>Bancos</span>
        <div className='grid-x align-middle'>
          <span className='banks-page__content-date-picker__title'>Seleccionar año:</span>
          <div className='grid-x align-middle justify-content-between banks-page__content-date-picker__date'>
            <button onClick={() => goToYear(-1)}>
              <img
                alt='icon_arrow'
                src={Assets.SharedIcons.icon_arrow}
                className='banks-page__content-date-picker__arrow'
              />
            </button>
            <DatePicker
              picker='year'
              bordered={false}
              suffixIcon={null}
              allowClear={false}
              inputReadOnly={true}
              value={selectedYear}
              defaultValue={dayjs()}
              disabledDate={disabledDate}
              onChange={(date) => 
                setState({
                  ...state, 
                  selectedYear: date
                })
              }
              className='banks-page__content-date-picker__year'
              format={(value) => {
                return `${dayjs(value)?.format('YYYY')}`;
              }}
            />
            <button
              onClick={() => goToYear(1)}
              className='banks-page__content-date-picker__button-next'
              disabled={(dayjs(selectedYear).add(1, 'year').isAfter(dayjs(), 'year'))}
            >
              <img
                alt={(dayjs(selectedYear).add(1, 'year').isAfter(dayjs(), 'year')) ? 'icon_arrow_picker_disabled' : 'icon_arrow'}
                src={(dayjs(selectedYear).add(1, 'year').isAfter(dayjs(), 'year')) ? Assets.SharedIcons.icon_arrow_picker_disabled : Assets.SharedIcons.icon_arrow}
              />
            </button>
          </div>
        </div>
      </div>
      <div className='grid-x align-content-end banks-page__content-search'>
        <Input
          onChange={(e) => {
            handleSearch(e);
          }}
          className='banks-page__content-search__input'
          placeholder='Buscar bancos por operación, código o número de cuenta'
          prefix={<img src={Assets.SharedIcons.icon_search_magnifier} alt="icon_search_magnifier" />}
        />
        <div className='banks-page__content-search__select-bank'>
          <Dropdown
            menu={{ items }}
            trigger={['click']}
            className='grid-x small-12'
          >
            <div
              onMouseOver={() => setState({...state,isHoverSelected:true})}
              onMouseOut={() => setState({...state,isHoverSelected:false})}
              className='grid-x align-middle justify-content-center banks-page__content-search__select-bank__content-selected'
            >
              <img
                alt={isHoverSelected ? "icon_filter_hover" : "icon_filter"}
                src={isHoverSelected ? Assets.SharedIcons.icon_filter_hover : Assets.SharedIcons.icon_filter}
              />
              <span className={`banks-page__content-search__select-bank__text${isHoverSelected ? '__hover' : ''}`}>
                ordenar por
              </span>
            </div>
          </Dropdown>
        </div>
        <button
          className='banks-page__content-search__button'
          onClick={() => history('/addBank', { state: { isDisabled: false, titleBank: 'Agregar banco' } })}
        >
          <img src={Assets.SharedIcons.icon_add_white} alt="icon_search" />
          <span className='banks-page__content-search__button__text'>Agregar banco</span>
        </button>
      </div>
      <div className='banks-page__table'>
        <div className='grid-x align-middle banks-page__table__container-title'>
          <div className='grid-x justify-content-end banks-page__table__width-column__first'>
            <span className='banks-page__table__title--mod'>Nombre del banco</span>
          </div>
          <span className='banks-page__table__title banks-page__table__width-column__second'>Número de cuenta</span>
          <span className='banks-page__table__title banks-page__table__width-column__third'>Código</span>
          <span className='banks-page__table__title banks-page__table__width-column__second'>Tipo de cuenta</span>
          <span className='banks-page__table__title banks-page__table__width-column__fourth'>Sede</span>
          <span className='banks-page__table__title banks-page__table__width-column__second'>Saldo actual</span>
          <span className='banks-page__table__title banks-page__table__width-column__fifth'>Acciones</span>
        </div>
        <div style={{ height: '90%', width: '100%', overflowY: "scroll" }}>
          {arrayFilterPage(paginatorState.page,listOfBanks).map((bank, index) => (
            <div key={index} className={`grid-x align-middle banks-page__table__information-row${isItemChecked(bank.id) ? '__selected' : ''}`}>
              <div className='grid-x banks-page__table__width-column__first'>
                <div className='grid-x align-middle justify-content-center banks-page__table__information-row__width-row__first'>
                  <div className='banks-page__table__checkbox'>
                    <Checkbox
                      checked={isItemChecked(bank.id)}
                      onChange={() => handleCheckboxChange(bank.id)}
                    />
                  </div>
                </div>
                <div className='grid-x align-middle banks-page__table__information-row__width-row__second'>
                  <span className='banks-page__table__description'>
                    {bank.bank}
                  </span>
                </div>
              </div>
              <div className='grid-x banks-page__table__width-column__second'>
                <span className='banks-page__table__description'>
                  {bank.accountNumber}
                </span>
              </div>
              <div className='grid-x banks-page__table__width-column__third'>
                <span className='banks-page__table__description'>
                  {bank.code}
                </span>
              </div>
              <div className='grid-x banks-page__table__width-column__second'>
                <span className='banks-page__table__description'>
                  {bank.accountType}
                </span>
              </div>
              <div className='grid-x banks-page__table__width-column__fourth'>
                <span className='banks-page__table__description'>
                  {bank.headquarters}
                </span>
              </div>
              <div className='grid-x banks-page__table__width-column__second'>
                <span className='banks-page__table__description--mod'>
                  {formattedNumber(bank.currentBalance)}
                </span>
              </div>
              <div className='grid-x banks-page__table__width-column__fifth'>
                <button
                  className='grid-x align-middle banks-page__table__button'
                  onClick={() => history(`/banksTreasury/${bank.id}`, { state: { idBank: bank.id, isDisabled: false, titleBank: 'Detalles del banco' } })}
                >
                  <span>Editar Banco</span>
                  <img src={Assets.SharedIcons.icon_arrow} alt="icon_arrow" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='grid-x align-middle justify-content-between banks-page__content-pagination'>
        <span className='banks-page__content-pagination__text'>
          Páginas:  <span style={{ fontWeight: 'bold' }}>{paginatorState.page}</span> de <span style={{ fontWeight: 'bold' }}>{paginatorState.totalPages}</span>
        </span>
        <PaginationComponent 
          pageItem={10}
          page={paginatorState.page}
          totalItems={listOfBanks?.length}
          onPage={(page)=>{
            setState({
              ...state,
              paginatorState: {
                ...state.paginatorState,
                page: page
              }
            })
          }}
        />
      </div>
    </div>
  )
}

export default BanksPage;