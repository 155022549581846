// Assets
import { Assets } from '../../../assets'

// Libraries
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { NumericFormat } from "react-number-format";
import { Input, DatePicker } from 'antd';
import { Link, useLocation, useParams } from 'react-router-dom';

// Services
import { getAllPortfolioService, getSummaryByHeadquarterService, getSummaryByHeadquarterWalletService } from '../../../services/portfolio.services';
import { setModuleNameService } from '../../../services/authentication.services';

// Styles
import './portfolio-detail.page.scss'

// Utils
import { dataPayment } from '../../../utils/portfolio-detail.page'

const PortfolioDetailPage = () => {

  const { id } = useParams(); // Get the site ID from the URL
  const { state: routeState } = useLocation();
  const dateSelected = routeState?.dateSelected;

  const INITIAL_STATE = {
    headquartersList: [],
    summaryByHeadquarter: {
      cashReceipt: dataPayment,
      cashExpense: dataPayment
    }
  }

  const [state, setState] = useState(INITIAL_STATE)
  const { headquartersList, summaryByHeadquarter } = state;
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(dayjs(dateSelected));

  useEffect(() => {
    //Send route name
    setModuleNameService('Carteras');

  }, [])

  useEffect(() => {
    dataPortfolioDetail(dateSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dataPortfolioDetail = async (dateSelect) => {
    let services = [getAllPortfolioService()];
    if (routeState?.key && routeState?.key === 6) {
      services.push(
        getSummaryByHeadquarterWalletService({
          date: dayjs(dateSelect).format('YYYY-MM-DD'), 
          idHeadQuarter: id 
        })
      )
    }else{
      services.push(
        getSummaryByHeadquarterService({
          date: dayjs(dateSelect).endOf('month').format('YYYY-MM-DD'), 
          idHeadQuarter: id 
        })
      )
    }

    await Promise.allSettled(services)
      .then((res) => {
        let headquartersList = [];
        let summaryByHeadquarter = {};
        let cashReceipt = [];
        let cashExpense = [];

        for (let index = 0; index < res.length; index++) {
          const elementResponse = res[index];

          if (elementResponse.status === "fulfilled") {
            switch (index) {
              case 0:
                headquartersList = elementResponse?.value
                break;
              case 1:
                summaryByHeadquarter = elementResponse?.value
                cashReceipt = [...summaryByHeadquarter?.cashReceipt];
                cashExpense = [...summaryByHeadquarter?.cashExpense];
                const newCashReceipt = [...INITIAL_STATE?.summaryByHeadquarter?.cashReceipt];
                const newCashExpense = [...INITIAL_STATE?.summaryByHeadquarter?.cashExpense];
                while (cashReceipt.length < 10 && newCashReceipt.length > 0) {
                  cashReceipt.push(newCashReceipt.shift())
                }
                while (cashExpense.length < 10 && newCashExpense.length > 0) {
                  cashExpense.push(newCashExpense.shift())
                }
                break;
              default:
                break;
            }
          }
        };
        setState((prevState) => ({
          ...prevState,
          headquartersList,
          summaryByHeadquarter: {
            ...summaryByHeadquarter,
            cashReceipt,
            cashExpense
          }
        }));
        setDataLoaded(true);
      })
      .catch(() => {
        // TODO: Implement error alert with code error.
      })
  }

  if (!dataLoaded) {
    return <div>Cargando...</div>;
  }
  const headquarters = headquartersList?.find((headquarters) => headquarters.id === id);

  if (!headquarters) {
    return <div>Sede no encontrada.</div>;
  };

  const goToMonth = (amount) => {
    const newDate = dayjs(selectedMonth).add(amount, 'month');
    const currentDate = dayjs();

    if (newDate.isBefore(currentDate, 'month') || newDate.isSame(currentDate, 'month')) {
      setSelectedMonth(newDate);
      dataPortfolioDetail(dayjs(newDate).format('YYYY-MM-DD'));
    }
  };

  const onChangeDate = (date) => {
    setSelectedMonth(date);
    dataPortfolioDetail(dayjs(date).format('YYYY-MM-DD'));
  };

  const disabledDate = (current) => {
    return current && current.isAfter(dayjs(), 'month');
  };

  return (
    <div className='portfolio-detail-page__container'>
      <div className='grid-x small-12 portfolio-detail-page__content-header'>
        <div className='grid-x align-middle'>
          <Link
            to="/wallet"
            className="grid-x portfolio-detail-page__content-header__link"
          >
            Histórico de carteras
            <img
              alt='icon_arrow'
              src={Assets.SharedIcons.icon_arrow}
              style={{ marginLeft: '10px' }}
            />
          </Link>
          <span className='portfolio-detail-page__name'>
            {headquarters.name}
          </span>
        </div>
      </div>
      <div className='grid-x small-12 align-bottom portfolio-detail-page__content-date-picker'>
        <div>
          <div className='grid-x align-middle justify-content-between portfolio-detail-page__content-date-picker__date'>
            <button onClick={() => goToMonth(-1)}>
              <img
                alt='icon_arrow'
                src={Assets.SharedIcons.icon_arrow}
                className='portfolio-detail-page__content-date-picker__arrow'
              />
            </button>
            <DatePicker
              picker='month'
              bordered={false}
              suffixIcon={null}
              allowClear={false}
              inputReadOnly={true}
              value={selectedMonth}
              defaultValue={dayjs()}
              disabledDate={disabledDate}
              onChange={(date) => onChangeDate(date)}
              className='portfolio-detail-page__content-date-picker__month'
              format={(value) => {
                return `${dayjs(value)?.format('MMMM, YYYY')}`;
              }}
            />
            <button
              onClick={() => goToMonth(1)}
              className='portfolio-detail-page__content-date-picker__button-next'
              disabled={(dayjs(selectedMonth).add(1, 'month').isAfter(dayjs(), 'month'))}
            >
              <img
                alt={(dayjs(selectedMonth).add(1, 'month').isAfter(dayjs(), 'month'))  ? 'icon_arrow_picker_disabled' : 'icon_arrow'}
                src={(dayjs(selectedMonth).add(1, 'month').isAfter(dayjs(), 'month')) ? Assets.SharedIcons.icon_arrow_picker_disabled : Assets.SharedIcons.icon_arrow}
              />
            </button>
          </div>
        </div>
        <div className='grid-x portfolio-detail-page__content-header__totalizers'>
          <div className='grid-x small-5 portfolio-detail-page__content-header__box'>
            <div className='small-6 portfolio-detail-page__content-header__box__padding-box'>
              <span className="portfolio-detail-page__content-header__text">
                <img
                  alt='icon_income'
                  src={Assets.SharedIcons.icon_income}
                  style={{ height: '15px', width: '15px', marginRight: '3px' }}
                />
                Total de Abonos
              </span>
              <div className="grid-x portfolio-detail-page__content-header__details">
                <NumericFormat
                  disabled
                  type='text'
                  prefix={"$ "}
                  placeholder='$ 0'
                  thousandSeparator=","
                  className='input-price'
                  thousandsGroupStyle="thousand"
                  value={summaryByHeadquarter?.totals?.cashReceived}
                />
              </div>
            </div>
            <div className='small-6 portfolio-detail-page__content-header__box__padding-box'>
              <span className="portfolio-detail-page__content-header__text">
                <img
                  src={Assets.SharedIcons.icon_egress}
                  alt='icon_egress'
                  style={{ height: '15px', width: '15px', marginRight: '3px' }}
                />
                Total de Cobros
              </span>
              <div className="grid-x portfolio-detail-page__content-header__details">
                <NumericFormat
                  type='text'
                  prefix={"$ "}
                  disabled={true}
                  placeholder='$ 0'
                  thousandSeparator=","
                  className='input-price'
                  thousandsGroupStyle="thousand"
                  value={summaryByHeadquarter?.totals?.cashOutflow}
                />
              </div>
            </div>
          </div>
          <div className='small-3 portfolio-detail-page__content-header__box'>
            <span className="portfolio-detail-page__content-header__text">Total a la fecha</span>
            <div className="grid-x portfolio-detail-page__content-header__details">
              <NumericFormat
                disabled
                type='text'
                prefix={"$ "}
                placeholder='$ 0'
                thousandSeparator=","
                className='input-price'
                thousandsGroupStyle="thousand"
                value={summaryByHeadquarter?.totals?.total}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='grid-x small-12 justify-content-between portfolio-detail-page__content-tables'>
        <div className='portfolio-detail-page__content-tables__table'>
          <div className='grid-x align-middle justify-content-between portfolio-detail-page__content-tables__content-values'>
            <div className='grid-x align-middle'>
              <img
                alt='icon_income'
                src={Assets.SharedIcons.icon_income}
              />
              <span className='portfolio-detail-page__content-tables__title'>Abonos</span>
            </div>
            <div className='grid-x align-middle'>
              <span className='portfolio-detail-page__content-tables__title'>Total del mes</span>
              <div className='grid-x align-middle portfolio-detail-page__content-tables__values'>
                <div className='portfolio-detail-page__content-tables__values__content-icon'>
                  <img
                    alt="icon_income"
                    src={Assets.SharedIcons.icon_income}
                    className='portfolio-detail-page__content-tables__values__content-icon__icon'
                  />
                </div>
                <NumericFormat
                  disabled
                  value={summaryByHeadquarter?.totals?.subTotal}
                  type='text'
                  prefix={"$ "}
                  placeholder='$ 0'
                  thousandSeparator=","
                  className='input-price'
                  thousandsGroupStyle="thousand"
                />
              </div>
            </div>
          </div>
          <div className='grid-x small-12 portfolio-detail-page__content-tables__content-info'>
            <div className='grid-x align-content-end portfolio-detail-page__content-tables__content-info__content-title'>
              <span className='small-3 portfolio-detail-page__content-tables__content-info__title'>Fecha</span>
              <span className='small-3 portfolio-detail-page__content-tables__content-info__title'>Concepto</span>
              <span className='small-3 portfolio-detail-page__content-tables__content-info__title'>Tipo de pago</span>
              <span className='small-3 portfolio-detail-page__content-tables__content-info__title'>Valor</span>
            </div>
            <div className='portfolio-detail-page__content-tables__content-info__content-scroll'>
              {summaryByHeadquarter?.cashReceipt.map((item, index) => {
                return (
                  <div
                    key={index}
                    className='grid-x align-middle portfolio-detail-page__content-tables__content-info__information-row'
                  >
                    <div className={`small-3 portfolio-detail-page__content-tables__content-input${!item?.date ? '__disabled' : ''}`}>
                      <DatePicker
                        disabled
                        clearIcon={null}
                        suffixIcon={null}
                        format="YYYY/MM/DD"
                        placeholder="AAAA/MM/DD"
                        value={item?.date ? dayjs(item?.date, 'YYYY:MM:DD') : null}
                      />
                    </div>
                    <div className={`small-3 portfolio-detail-page__content-tables__content-input${!item?.conceptDescription ? '__disabled' : ''}`}>
                      <Input
                        disabled
                        prefix={null}
                        placeholder='Descripción'
                        value={item?.conceptDescription}
                      />
                    </div>
                    <div className={`small-3 portfolio-detail-page__content-tables__content-input${!item?.PaymentMethod?.name ? '__disabled' : ''}`}>
                      <Input
                        disabled
                        prefix={null}
                        placeholder='Tipo de pago'
                        value={item?.PaymentMethod?.name}
                      />
                    </div>
                    <div className={`grid-x small-3 justify-content-end portfolio-detail-page__content-tables__content-input${!item.price ? '__disabled' : ''}`}>
                      <NumericFormat
                        disabled
                        type='text'
                        prefix={"$ "}
                        placeholder='$ 0'
                        value={item?.price}
                        thousandSeparator=","
                        className='input-price'
                        thousandsGroupStyle="thousand"
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className='portfolio-detail-page__content-tables__table'>
          <div className='grid-x align-middle justify-content-between portfolio-detail-page__content-tables__content-values'>
            <div className='grid-x align-middle'>
              <img
                alt='icon_egress'
                src={Assets.SharedIcons.icon_egress}
              />
              <span className='portfolio-detail-page__content-tables__title'>Cobros y créditos</span>
            </div>
            <div className='grid-x align-middle'>
              <span className='portfolio-detail-page__content-tables__title'>Total del mes</span>
              <div className='grid-x portfolio-detail-page__content-tables__values--mod'>
                <div className='portfolio-detail-page__content-tables__values__content-icon'>
                  <img
                    alt="icon_egress"
                    src={Assets.SharedIcons.icon_egress}
                    className='portfolio-detail-page__content-tables__values__content-icon__icon'
                  />
                </div>
                <NumericFormat
                  disabled
                  value={summaryByHeadquarter?.totals?.totalOtherProducts}
                  type='text'
                  prefix={"$ "}
                  placeholder='$ 0'
                  thousandSeparator=","
                  className='input-price'
                  thousandsGroupStyle="thousand"
                />
              </div>
            </div>
          </div>
          <div className='grid-x small-12 portfolio-detail-page__content-tables__content-info'>
            <div className='grid-x align-content-end portfolio-detail-page__content-tables__content-info__content-title'>
              <span className='small-3 portfolio-detail-page__content-tables__content-info__title'>Fecha</span>
              <span className='small-3 portfolio-detail-page__content-tables__content-info__title'>Concepto</span>
              <span className='small-3 portfolio-detail-page__content-tables__content-info__title'>Tipo de pago</span>
              <span className='small-3 portfolio-detail-page__content-tables__content-info__title'>Valor</span>
            </div>
            <div className='portfolio-detail-page__content-tables__content-info__content-scroll'>
              {summaryByHeadquarter.cashExpense.map((item, index) => {
                return (
                  <div
                    key={index}
                    className='grid-x align-middle portfolio-detail-page__content-tables__content-info__information-row'
                  >
                    <div className={`small-3 portfolio-detail-page__content-tables__content-input${!item?.date ? '__disabled' : ''}`}>
                      <DatePicker
                        disabled
                        clearIcon={null}
                        suffixIcon={null}
                        format="YYYY/MM/DD"
                        placeholder="AAAA/MM/DD"
                        value={item?.date ? dayjs(item?.date, 'YYYY:MM:DD') : null}
                      />
                    </div>
                    <div className={`small-3 portfolio-detail-page__content-tables__content-input${!item?.conceptDescription ? '__disabled' : ''}`}>
                      <Input
                        disabled
                        prefix={null}
                        placeholder='Descripción'
                        value={item?.conceptDescription}
                      />
                    </div>
                    <div className={`small-3 portfolio-detail-page__content-tables__content-input${!item?.PaymentMethod?.name ? '__disabled' : ''}`}>
                      <Input
                        disabled
                        prefix={null}
                        placeholder='Tipo de pago'
                        value={item?.PaymentMethod?.name}
                      />
                    </div>
                    <div className={`grid-x small-3 justify-content-end portfolio-detail-page__content-tables__content-input${!item.price ? '__disabled' : ''}`}>
                      <NumericFormat
                        disabled
                        type='text'
                        prefix={"$ "}
                        placeholder='$ 0'
                        value={item?.price}
                        thousandSeparator=","
                        className='input-price'
                        thousandsGroupStyle="thousand"
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioDetailPage;