// Assets
import { Assets } from '../../../assets'

//Components
import WeekSelectorComponent from '../../components/week-selector/week-selector.component';

//Components - Shared
import ErrorAlertComponent from '../../../shared/components/alerts/error-alert/error-alert.component';

// Libraries
import dayjs from 'dayjs';
import { Checkbox, Input } from 'antd';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";

// Services
import { getAllPortfolioWalletService, getHistoricalPortfoliosService } from '../../../services/portfolio.services';
import { setModuleNameService } from '../../../services/authentication.services';

// Styles
import './portfolio.page.scss'

// Utils
import { formattedNumber } from '../../../utils/shared.utils';

const PortfolioPage = () => {

  let history = useNavigate();

  const { state: routeState } = useLocation();

  const INITIAL_STATE = {
    headquartersList: [],
    dateSelected: dayjs(),
    daySelected: dayjs()
  }

  const [checkedItems, setCheckedItems] = useState([]);
  const [state, setState] = useState(INITIAL_STATE)
  const { dateSelected, daySelected , headquartersList} = state;

  const handleDate = (date) => {
    setState({
      ...state,
      dateSelected: date
    })
  }
  const previousDate = () => {
    const yearNew = dateSelected.subtract(1, 'year')
    setState({
      ...state,
      dateSelected: yearNew
    })
  }

  const nextDate = () => {
    const yearNew = dateSelected.add(1, 'year')
    setState({
      ...state,
      dateSelected: yearNew
    })
  }

  const previousWeek = () => {
    const week = dateSelected.subtract(1, 'week')
    setState({
      ...state,
      dateSelected: week
    })
  }

  const nextWeek = () => {
    const week = dateSelected.add(1, 'week')
    setState({
      ...state,
      dateSelected: week
    })
  }

  const selectDay = (day) => {
    setState({
      ...state,
      daySelected: day
    })
  }

  useEffect(() => {
    //Send route name
    setModuleNameService('Carteras');

  }, [])

  useEffect(() => {
    if (routeState?.key && routeState?.key === 6) {
      getPortfolio(dayjs(daySelected).format('YYYY-MM-DD'))
    } else {
      getPortfolioByDate(dayjs(daySelected).format('YYYY-MM-DD'));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daySelected]);

  const getPortfolio = async (date, filterValue) => {
    try {
      const res = await getAllPortfolioWalletService(date, filterValue);
      setState((prevState) => ({
        ...prevState,
        headquartersList: res
      }))
    } catch (error) {
      ErrorAlertComponent();
    }
  }

  const getPortfolioByDate = async (date, filterValue) => {
    try {
      const res = await getHistoricalPortfoliosService(date, filterValue);
      setState((prevState) => ({
        ...prevState,
        headquartersList: res
      }))
    } catch (error) {
      ErrorAlertComponent();
    }
  }

  const viewPortfolio = async (idHeadquarter) => {
    try {
      history(`/wallet/${idHeadquarter}`, { state: { dateSelected: dayjs(daySelected).format('YYYY-MM-DD'), key: routeState?.key } });
    } catch (error) {
      ErrorAlertComponent();
    }
  }

  const handleCheckboxChange = (index) => {
    // Clones the array of selected items and adds or removes the item according to its current state
    setCheckedItems((prevItems) => {
      const isChecked = prevItems.includes(index);
      return isChecked ? prevItems.filter(itemIndex => itemIndex !== index) : [...prevItems, index];
    });
  };

  const isItemChecked = (index) => checkedItems.includes(index);

  const handleSearch = async(e) => {
    const { value } = e.target;
    if (value.length > 1 || value.length === 0) {
      try {
        if (routeState?.key && routeState?.key === 6) {
          await getPortfolio(dayjs(daySelected).format('YYYY-MM-DD'),value)
        } else {
          await getPortfolioByDate(dayjs(daySelected).format('YYYY-MM-DD'),value);
        }
      } catch (error) {
        ErrorAlertComponent()
      }
    }
  }

  return (
    <div className='portfolio-page__container'>
      <WeekSelectorComponent
        handleDate={(date) => handleDate(date)}
        previousDate={() => previousDate()}
        nextDate={() => nextDate()}
        previousWeek={() => previousWeek()}
        nextWeek={() => nextWeek()}
        selectDay={(date) => selectDay(date)}
        dateSelected={dateSelected}
        daySelected={daySelected}
        typeDate='año'
      />
      <div className='grid-x portfolio-page__container-headquarters'>
        <div className='grid-x small-12 portfolio-page__content-search'>
          <span className='portfolio-page__content-search__title'>Histórico de carteras</span>
          <Input
            onChange={(e) => handleSearch(e)}
            placeholder='Buscar por sede o negocio'
            className='portfolio-page__content-search__input'
            prefix={<img src={Assets.SharedIcons.icon_search_magnifier} alt="icon_search_magnifier" />}
          />
        </div>
        <div className='grid-x small-12 portfolio-page__content-result'>
          <div className='grid-x align-middle portfolio-page__content-result__container-title'>
            <div className='grid-x justify-content-end portfolio-page__content-result__title__content--mod'>
              <span className='portfolio-page__content-result__title--mod'>
                Nombre de la sede o negocio
              </span>
            </div>
            <span className='portfolio-page__content-result__title__content portfolio-page__content-result__title'>
              Estado
            </span>
            <div className='grid-x portfolio-page__content-result__title__content'>
              <span className='portfolio-page__content-result__title'>Total de Gastos</span>
              <img src={Assets.SharedIcons.icon_falling_graph} alt="icon_falling_graph" />
            </div>
            <div className='grid-x portfolio-page__content-result__title__content'>
              <span className='portfolio-page__content-result__title'>Total de Abonos</span>
              <img src={Assets.SharedIcons.icon_income_graph} alt="icon_income_graph" />
            </div>
            <span className='portfolio-page__content-result__title__content portfolio-page__content-result__title'>
              Total a la fecha
            </span>
          </div>
          <div className='portfolio-page__content-result__content-info'>
            {headquartersList.map((headquarters, index) => (
              <div key={index} className={`grid-x align-middle portfolio-page__content-result__content-info__information-row${isItemChecked(index) ? '__selected' : ''}`}>
                <div className='grid-x portfolio-page__content-result__content-info__content-fragment--mod'>
                  <div className='grid-x align-middle justify-content-center portfolio-page__content-result__content-info__content-checkbox'>
                    <div className='portfolio-page__content-result__content-info__checkbox'>
                      <Checkbox checked={isItemChecked(index)} onChange={() => handleCheckboxChange(index)}/>
                    </div>
                  </div>
                  <div className='grid-x align-middle portfolio-page__content-result__content-info__content-location'>
                    <span className='portfolio-page__content-result__content-info__description'>
                      {headquarters?.name}
                    </span>
                  </div>
                </div>
                <div className='grid-x portfolio-page__content-result__content-info__content-status portfolio-page__content-result__content-info__content-fragment'>
                  <div className={`grid-x align-middle justify-content-center portfolio-page__content-result__content-info__box-status${headquarters?.isActive ? '__active' : '__inactive'}`}>
                    <div className={`portfolio-page__content-result__content-info__box-status__pin${headquarters?.isActive ? '__active' : '__inactive'}`} />
                    <span>{headquarters?.isActive ? 'Activo' : 'Inactivo'}</span>
                  </div>
                </div>
                <div className='grid-x portfolio-page__content-result__content-info__content-fragment'>
                  <span className='portfolio-page__content-result__content-info__description'>
                    {formattedNumber(headquarters?.cashOutflow)}
                  </span>
                </div>
                <div className='grid-x portfolio-page__content-result__content-info__content-fragment'>
                  <span className='portfolio-page__content-result__content-info__description'>
                    {formattedNumber(headquarters?.cashReceived)}
                  </span>
                </div>
                <div className='grid-x portfolio-page__content-result__content-info__content-fragment'>
                  <span className='portfolio-page__content-result__content-info__description'>
                    {formattedNumber(headquarters?.cashOutflow - headquarters?.cashReceived)}
                  </span>
                </div>
                <div className='grid-x justify-content-end portfolio-page__content-result__content-info__content-fragment--mod-second'>
                  <button
                    className='grid-x align-middle portfolio-page__content-result__content-info__button'
                    onClick={() => viewPortfolio(headquarters.id)}
                  >
                    <span>Ver Cartera</span>
                    <img src={Assets.SharedIcons.icon_arrow} alt="icon_arrow" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PortfolioPage