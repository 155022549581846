import { getTokenService } from "./authentication.services";
import HeadquartersEndPoints from "./constants/headquarters.end-points";
import Http from "./infrastructure/http.infrastructure";


export async function getTotalByHeadquartersService(id,date) {
    try {
        const token = getTokenService();
        const urlWithParams = HeadquartersEndPoints.GET_TOTAL_BY_HEADQUARTERS.replace(":id", id).replace(":date", date)
        const { data: getTotalByHeadquarters, error } = await Http.get(urlWithParams,null, token)
        if (getTotalByHeadquarters && getTotalByHeadquarters.data) {
            return getTotalByHeadquarters?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getOrderTypesService() {
    try {
        const token = getTokenService();
        const { data: getOrderTypes, error } = await Http.get(HeadquartersEndPoints.GET_ORDER_TYPES ,null, token)
        if (getOrderTypes && getOrderTypes.data) {
            return getOrderTypes?.data?.concepTypesInOrder
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getConceptsService(id, date) {
    try {
        const token = getTokenService();
        const urlWithParams = HeadquartersEndPoints.GET_CONCEPTS.replace(":id", id).replace(":date", date)
        const { data: getConcepts, error } = await Http.get(urlWithParams ,null, token)
        if (getConcepts && getConcepts.data) {
            return getConcepts?.data?.conceptOrganizedByType
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getHeadquartersExpensesService(id, date) {
    try {
        const token = getTokenService();
        const urlWithParams = HeadquartersEndPoints.GET_HEADQUARTERS_EXPENSES.replace(":headQuarterId", id).replace(":date", date)
        const { data: responseGetHeadquartersExpenses, error } = await Http.get(urlWithParams, null, token)
        if (responseGetHeadquartersExpenses && responseGetHeadquartersExpenses.data) {
            return responseGetHeadquartersExpenses?.data?.expensesOrganizedByType
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getChargesService(id, date) {
    try {
        const token = getTokenService();
        const urlWithParams = HeadquartersEndPoints.GET_CHARGES.replace(":id", id).replace(":date", date)
        const { data: getCharges, error } = await Http.get(urlWithParams ,null, token)
        if (getCharges && getCharges.data) {
            return getCharges?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createAndUpdateChangesService(id, date, data) {
    try {
        const token = getTokenService();
        const urlWithParams = HeadquartersEndPoints.CREATE_CONCEPTS.replace(":id", id).replace(":date", date)
        const { data: createConcepts, error } = await Http.post(urlWithParams ,data, token)
        if (createConcepts && createConcepts.status) {
            return createConcepts?.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getTexasService(id, date) {
    try {
        const token = getTokenService();
        const urlWithParams = HeadquartersEndPoints.GET_TEXAS.replace(":headQuarterId", id).replace(":date", date)
        const { data: responseGetTexas, error } = await Http.get(urlWithParams, null, token)
        if (responseGetTexas && responseGetTexas.data) {
            return responseGetTexas?.data?.organizeTaxesByType
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateTexasService(body, id, date) {
    try {
        const token = getTokenService();
        const urlWithParams = HeadquartersEndPoints.UPDATE_TEXAS.replace(":headQuarterId", id).replace(":date", date)
        const { data: responseUpdateTexas, error } = await Http.post(urlWithParams, body, token)
        if (responseUpdateTexas && responseUpdateTexas.status) {
            return responseUpdateTexas.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getConsumptionProvisionsService(id, date) {
    try {
        const token = getTokenService();
        const urlWithParams = HeadquartersEndPoints.GET_CONSUMPTION_PROVISIONS.replace(":headQuarterId", id).replace(":date", date)
        const { data: responseGetConsumptionProvisions, error } = await Http.get(urlWithParams, null, token)
        if (responseGetConsumptionProvisions && responseGetConsumptionProvisions.data) {
            return responseGetConsumptionProvisions?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getIvaProvisionsService(id, date) {
    try {
        const token = getTokenService();
        const urlWithParams = HeadquartersEndPoints.GET_IVA_PROVISIONS.replace(":headQuarterId", id).replace(":date", date)
        const { data: responseGetIvaProvisions, error } = await Http.get(urlWithParams, null, token)
        if (responseGetIvaProvisions && responseGetIvaProvisions.data) {
            return responseGetIvaProvisions?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateConsumptionProvisionsService(body, id) {
    try {
        const token = getTokenService();
        const urlWithParams = HeadquartersEndPoints.UPDATE_CONSUMPTION_PROVISIONS.replace(":headQuarterId", id);
        const { data: responseUpdateConsumptionProvisions, error } = await Http.post(urlWithParams, body, token)
        if (responseUpdateConsumptionProvisions && responseUpdateConsumptionProvisions.status) {
            return responseUpdateConsumptionProvisions.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateIvaProvisionsService(body, id) {
    try {
        const token = getTokenService();
        const urlWithParams = HeadquartersEndPoints.UPDATE_CONSUMPTION_IVA.replace(":headQuarterId", id);
        const { data: responseUpdateProvisionsIva, error } = await Http.post(urlWithParams, body, token)
        if (responseUpdateProvisionsIva && responseUpdateProvisionsIva.status) {
            return responseUpdateProvisionsIva.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getProjectionsService(id,date) {
    try {
        const token = getTokenService();
        const urlWithParams = HeadquartersEndPoints.GET_PROJECTIONS.replace(":date", date)
        const { data: responseGetProjections, error } = await Http.get(urlWithParams,{ idHeadQuarter:id }, token)
        if (responseGetProjections && responseGetProjections.data) {
            return responseGetProjections?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateProjectionsService(data) {
    try {
        const token = getTokenService();
        const { data: responseGetProjections, error } = await Http.put(HeadquartersEndPoints.UPDATE_PROJECTIONS, data, token)
        if (responseGetProjections && responseGetProjections.status) {
            return responseGetProjections?.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getAllHeadquartersService(date) {
    try {
        const token = getTokenService();
        const urlWithParams = HeadquartersEndPoints.GET_ALL_HEADQUARTERS.replace(":date", date)
        const { data: responseGetHeadquarters, error } = await Http.get(urlWithParams, null,  token)
        if (responseGetHeadquarters && responseGetHeadquarters.data) {
            const responseListHeadquarter = responseGetHeadquarters.data.map((item) => ({
                cashReceivedReportCashClosing: item.cashReceivedReportCashClosing,
                id: item.id,
                name: item.name,
                percentageExpenditure: item.percentageExpenditure,
                percentageSales: item.percentageSales,
                totalExpense: item.totalExpense,
                treasuryPortfolio: item.cashReceivedDailyTransaction - item.cashOutflowDailyTransaction,
            }));
            return responseListHeadquarter
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getPortfoliosListServices(id, date) {
    try {
        const token = getTokenService();
        const urlWithParams = HeadquartersEndPoints.GET_PORTFOLIO_LIST.replace(":headQuarterId", id).replace(":date", date)
        const { data: responseGetPortfolios, error } = await Http.get(urlWithParams, null, token)
        if (responseGetPortfolios && responseGetPortfolios.data) {
            return responseGetPortfolios?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getTotalizerUtilityServices(id, date) {
    try {
        const token = getTokenService();
        const urlWithParams = HeadquartersEndPoints.GET_TOTAL_UTILITY.replace(":headQuarterId", id).replace(":date", date)
        const { data: responseGetTotalUtility, error } = await Http.get(urlWithParams, null, token)
        if (responseGetTotalUtility && responseGetTotalUtility.data) {
            return responseGetTotalUtility?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getTotalizerExpensesServices(id, date) {
    try {
        const token = getTokenService();
        const urlWithParams = HeadquartersEndPoints.GET_TOTAL_EXPENSES.replace(":headQuarterId", id).replace(":date", date)
        const { data: responseGetTotalExpenses, error } = await Http.get(urlWithParams, null, token)
        if (responseGetTotalExpenses && responseGetTotalExpenses.data) {
            return responseGetTotalExpenses?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getTotalizerTreasuryServices(id, date) {
    try {
        const token = getTokenService();
        const urlWithParams = HeadquartersEndPoints.GET_TOTAL_TREASURY.replace(":headQuarterId", id).replace(":date", date)
        const { data: responseGetTotalTreasury, error } = await Http.get(urlWithParams, null, token)
        if (responseGetTotalTreasury && responseGetTotalTreasury.data) {
            return responseGetTotalTreasury?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getTotalizerInventoryServices(id, date) {
    try {
        const token = getTokenService();
        const urlWithParams = HeadquartersEndPoints.GET_TOTAL_INVENTORY.replace(":headQuarterId", id).replace(":date", date)
        const { data: responseGetTotalInventory, error } = await Http.get(urlWithParams, null, token)
        if (responseGetTotalInventory && responseGetTotalInventory.data) {
            return responseGetTotalInventory?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getTotalizerSalesServices(id, date) {
    try {
        const token = getTokenService();
        const urlWithParams = HeadquartersEndPoints.GET_TOTAL_SALES.replace(":headQuarterId", id).replace(":date", date)
        const { data: responseGetTotalSales, error } = await Http.get(urlWithParams, null, token)
        if (responseGetTotalSales && responseGetTotalSales.data) {
            return responseGetTotalSales?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getTotalizerDifferentialServices(id, date) {
    try {
        const token = getTokenService();
        const urlWithParams = HeadquartersEndPoints.GET_TOTAL_DIFFERENTIAL.replace(":headQuarterId", id).replace(":date", date)
        const { data: responseGetTotalDifferential, error } = await Http.get(urlWithParams, null, token)
        if (responseGetTotalDifferential && responseGetTotalDifferential.data) {
            return responseGetTotalDifferential?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getTotalizerCreditsService(id, date) {
    try {
        const token = getTokenService();
        const urlWithParams = HeadquartersEndPoints.GET_TOTAL_CREDITS.replace(":headQuarterId", id).replace(":date", date)
        const { data: responseGetTotalCredits, error } = await Http.get(urlWithParams, null, token)
        if (responseGetTotalCredits && responseGetTotalCredits.data) {
            return responseGetTotalCredits?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err 
    }
};