/**
* Secret Key to create (#Wiedii-Lions@2022) SHA256
*/
const LocalStorageConst = {
    TOKEN_SESSION: "4379CBCFE061E632AD3C178F1881412AD97EF2D567B5D55EC58C5FCDF5139002",
    PERMISSIONS_USER: "78d979e5bb026280edb3bed752daf39206211b1d028f74e703359923bdadd921",
    LOGGED_USER: "a05dea56954aba8e11e726f68704383990387d3072b572a47ab08012b1e2dd6d",
    MODULE_NAME: "8a06d4e03cbedd4a1433c7a55993eec6964e928d4603180ac4316aa7187f1628",
}

export default LocalStorageConst