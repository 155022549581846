//Assets
import { Assets }from "../../../../assets/index";

//Libraries
import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";

// Services
import { 
    getLoggedUserService, getModuleNameService
} from "../../../../services/authentication.services";

//Styles
import './navbar-main.component.scss'

const NavbarMainComponent = () => {

    const location = useLocation();

    const INITIAL_STATE = {
        loggedUser: '',
        nameModule: ''
    }

    const [state, setState] = useState(INITIAL_STATE)

    const { loggedUser, nameModule } = state

    useEffect(() => {   
        getInfoOfTheHeadquarters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const getInfoOfTheHeadquarters = async () => {
        try {
            const loggedUser = await getLoggedUserService();
            const nameModule = await getModuleNameService();
            setState({
                ...state,
                loggedUser,
                nameModule
            })
        } catch (err) {
            throw err
        }
    };

    return (
        <div className='grid-x small-12'>
            <button className='navbar-main__button__sidebar'>
                <span className="navbar-main__button__sidebar__text">
                    SURTILICORES
                </span>
            </button>
            <div className="grid-x navbar-main__content justify-content-between">
                <div className="grid-y navbar-main__container-welcome">
                    <span className="navbar-main__title">{`Hola ${loggedUser},`}</span>
                    <span className="navbar-main__title__info">{`Bienvenido al módulo de ${nameModule}`}</span>
                </div>
                <div className='grid-x navbar-main__user'>
                    <div className="grid-x align-middle  navbar-main__user__content">
                        <img
                            src={Assets.SharedIcons.icon_user}
                            alt='icon_user'
                        />
                        <span className="navbar-main__user__name">
                            {loggedUser}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavbarMainComponent