//Assets
import { Assets } from '../../../../assets';

//Components
import ErrorAlertComponent from '../../../../shared/components/alerts/error-alert/error-alert.component';

//Libraries
import React, { useEffect, useState } from 'react';
import { Collapse, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

//Services
import { getCashClosingReportsService } from '../../../../services/dashboard.services';
import { getUserPermissionsService } from '../../../../services/authentication.services';

//Styles
import './daily-cash-closing.component.scss';

//Utils
import { formattedNumber } from '../../../../utils/shared.utils'

const DailyCashClosingComponent = () => {

  const INITIAL_STATE = {
    dataClashClosing: undefined,
    daySelected: dayjs(),
    isButtonRedirect: false,
    disabledChangeDate: true
  }
  let history = useNavigate()

  const [state, setState] = useState(INITIAL_STATE)
  const { dataClashClosing, daySelected, isButtonRedirect,disabledChangeDate } = state;

  useEffect(() => {
    getDataClashClosing()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daySelected])

  const getDataClashClosing = async () => {
    try {
      const responseDataClashClosing = await getCashClosingReportsService(dayjs(daySelected)?.format('YYYY-MM-DD'))
      if (responseDataClashClosing) {
        const isPermitResponse = await permitButton()
        setState({
          ...state,
          dataClashClosing: {
            dailyCashOutflow: responseDataClashClosing?.dailyCashOutflow,
            dailyCashReceived: responseDataClashClosing?.dailyCashReceived,
            previousBalance: responseDataClashClosing?.previousBalance,
            totalDailyCashOutflow: responseDataClashClosing?.totalDailyCashOutflow,
            totalDailyCashReceived: responseDataClashClosing?.totalDailyCashReceived,
            totalFinalCash: (responseDataClashClosing?.previousBalance) + responseDataClashClosing?.totalDailyCashReceived - responseDataClashClosing?.totalDailyCashOutflow,
          },
          isButtonRedirect: isPermitResponse,
          disabledChangeDate: false
        })
      }else{
        setState({
          ...state,
          dataClashClosing: undefined,
          disabledChangeDate: false
        })
      }
    } catch (error) {
      ErrorAlertComponent()
      setState({
        ...state,
        dataClashClosing: undefined,
        disabledChangeDate: false
      })
    }
  }

  const dataCashReceived = [
    {
      key: '1',
      label:
        <>
          <img
            src={Assets.SharedIcons.icon_income}
            alt="icon_income"
            height={"23px"}
            width={"23px"}
            className='cash-closing-treasury__icon-income' /> Efectivo Recibido
        </>,
      children:
        <div>
          <div className='grid-x align-center-middle justify-content-between cash-closing-treasury__content-cash-headquearter'>
            <div className='cash-closing-treasury__text-headquarter'>Saldo del día anterior</div>
            <div className='cash-closing-treasury__cash-headquarter'>
              {formattedNumber(dataClashClosing?.previousBalance)}
            </div>
          </div>
          {dataClashClosing?.dailyCashReceived?.map((headquarters, index) => (
            <div key={index} className='grid-x align-center-middle justify-content-between cash-closing-treasury__content-cash-headquearter'>
              <div className='grid-x align-center-middle cash-closing-treasury__text-headquarter'>
                <img
                  alt="icon_liquor"
                  src={Assets.SharedIcons.icon_liquor_blue}
                />
                {headquarters?.name}
              </div>
              <div className='cash-closing-treasury__cash-headquarter'>
                {formattedNumber(headquarters?.cashReceived)}
              </div>
            </div>
          ))}
        </div>
      ,
    },
  ];

  const dataCashOutflows = [
    {
      key: '1',
      label:
        <>
          <img
            src={Assets.SharedIcons.icon_egress}
            alt="icon_egress"
            height={"23px"}
            width={"23px"}
            className='cash-closing-treasury__icon_egress'
          /> Pagos o salidas de efectivo
        </>,
      children:
        <div>
          {dataClashClosing?.dailyCashOutflow?.map((headquarters, index) => (
            <div key={index} className='grid-x align-center-middle justify-content-between cash-closing-treasury__content-cash-headquearter'>
              <div className='grid-x align-center-middle cash-closing-treasury__text-headquarter'>
                <span>{headquarters?.concept}</span>
              </div>
              <div className='cash-closing-treasury__cash-headquarter'>
                {formattedNumber(headquarters?.cashOutflow)}
              </div>
            </div>
          ))}
        </div>
      ,
    },
  ];

  const expandIcon = ({ isActive }) => (
    isActive ?
      <img
        alt="icon_disabled_arrow"
        src={Assets.SharedIcons.icon_blue_arrow_up}
      /> :
      <img
        alt="icon_disabled_arrow"
        src={Assets.SharedIcons.icon_blue_arrow_down}
      />
  );

  const goToDate = (amount) => {
    const newDate = dayjs(daySelected).add(amount, 'day');
    const currentDate = dayjs();
    if (newDate.isBefore(currentDate, 'day') || newDate.isSame(currentDate, 'day')) {
      setState({
        ...state,
        daySelected: newDate,
        disabledChangeDate: true
      })
    }
  };

  const disabledDate = (current) => {
    return current && current.isAfter(dayjs(), 'day');
  };

  const permitButton = async() => {
    try {
      const permissionsAssociateByUser = await getUserPermissionsService()
      return permissionsAssociateByUser.find((permission) => permission.type === 2)
    } catch (error) {
      ErrorAlertComponent()
    }
  }

  return (
    <div className='grid-y daily-cash-closing__container'>
      <div className='text-center daily-cash-closing__cash-title'>
        Cierre de caja diario (Efectivo)
      </div>
      <div className='grid-x justify-content-center'>
        <div className='grid-x align-middle justify-content-between daily-cash-closing__date'>
          <button
            onClick={() => goToDate(-1)}
            disabled={disabledChangeDate}
          >
            <img
              alt='icon_arrow'
              src={Assets.SharedIcons.icon_arrow}
              className='daily-cash-closing__arrow'
            />
          </button>
          <DatePicker
            picker="date"
            bordered={false}
            suffixIcon={null}
            allowClear={false}
            value={daySelected}
            inputReadOnly={true}
            defaultValue={dayjs()}
            onChange={(date) => {
              setState({
                ...state,
                daySelected: date,
                disabledChangeDate: true
              })
            }}
            className='daily-cash-closing__month'
            popupClassName='daily-cash-closing__month__popup'
            format={(value) => {
              return `${dayjs(value)?.format('dddd DD/MM/YYYY')}`;
            }}
            disabledDate={disabledDate || disabledChangeDate}
          />
          <button
            onClick={() => goToDate(1)}
            className='daily-cash-closing__button-next'
            disabled={(dayjs(daySelected).add(1, 'day').isAfter(dayjs(), 'day')) || disabledChangeDate}
          >
            <img
              alt='icon_arrow'
              src={Assets.SharedIcons.icon_arrow}
            />
          </button>
        </div>
      </div>
      { dataClashClosing ?
        <>
          <div className='daily-cash-closing__container-daily-movements'>
            <div className='text-center'>
              <div className='daily-cash-closing__text'>Efectivo Final</div>
              <div className='daily-cash-closing__cash-title'>{formattedNumber(dataClashClosing?.totalFinalCash)}</div>
              <div className='grid-x daily-cash-closing__container-totalizer'>
                <div className='grid-x small-6 daily-cash-closing__container-cash-received'>
                  <img
                    src={Assets.SharedIcons.icon_income}
                    alt="icon_income"
                    height={"23px"}
                    width={"23px"}
                    className='daily-cash-closing__icon-income'
                  />
                  <div className='daily-cash-closing__content-cash-received text-left'>
                    <div className='daily-cash-closing__cash-received '>Efectivo recibido</div>
                    <div className='daily-cash-closing__currency-received'>{formattedNumber(dataClashClosing?.previousBalance + dataClashClosing?.totalDailyCashReceived)}</div>
                  </div>
                </div>
                <div className='grid-x small-6 align-center daily-cash-closing__container-cash-outflows'>
                  <img
                    src={Assets.SharedIcons.icon_egress}
                    alt="icon_egress"
                    height={"23px"}
                    width={"23px"}
                    className='daily-cash-closing__icon_egress'
                  />
                  <div className='daily-cash-closing__content-cash-outflows text-left'>
                    <div className='daily-cash-closing__cash-outflows'>Salidas de efectivo</div>
                    <div className='daily-cash-closing__currency-outflows'>{formattedNumber(dataClashClosing?.totalDailyCashOutflow)}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='daily-cash-closing__title text-center'>
              Detalle del movimiento diario
            </div>
            <div className='daily-cash-closing__container-collapse'>
              <Collapse
                items={dataCashReceived}
                defaultActiveKey={['1']}
                expandIconPosition='end'
                expandIcon={expandIcon}
              />
              <div className='grid-x align-right daily-cash-closing__container-total'>
                <div className='grid-x align-center-middle daily-cash-closing__cash-headquarter'>Total:</div>
                <div className='daily-cash-closing__total-cash-received justify-content-between'>
                  <div className='daily-cash-closing__content-icon-price'>
                    =
                  </div>
                  <div className='grid-x align-center-middle justify-content-end daily-cash-closing__input-price'>
                    {formattedNumber(dataClashClosing?.previousBalance + dataClashClosing?.totalDailyCashReceived)}
                  </div>
                </div>
              </div>
            </div>
            <div className='daily-cash-closing__container-collapse'>
              <Collapse
                items={dataCashOutflows}
                defaultActiveKey={['1']}
                expandIconPosition='end'
                expandIcon={expandIcon}
              />
              <div className='grid-x align-right daily-cash-closing__container-total'>
                <div className='grid-x align-center-middle daily-cash-closing__cash-headquarter'>Total:</div>
                <div className='daily-cash-closing__total-cash-received justify-content-between'>
                  <div className='daily-cash-closing__content-icon-price'>
                    =
                  </div>
                  <div className='grid-x align-center-middle justify-content-end daily-cash-closing__input-price'>
                    {formattedNumber(dataClashClosing?.totalDailyCashOutflow)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          { isButtonRedirect &&
            <div className='daily-cash-closing__container-button-historical'>
              <button
                className='daily-cash-closing__button'
                onClick={()=>history("/wallet", { replace: true })}
              >
                <span>Ver histórico de carteras</span>
              </button>
            </div>
          }
        </>
        : 
        <div className='grid-x align-center-middle daily-cash-closing__not-data'>
          <span className='daily-cash-closing__not-data__text'>No hay Información disponible</span>
        </div>
      }
    </div>
  )
};

export default DailyCashClosingComponent;