// Assets
import { Assets } from '../../../../../assets';

// Libraries
import React from 'react'
import { NumericFormat } from 'react-number-format';
import { DatePicker, Input } from 'antd';
import dayjs from "dayjs";

// Styles
import './headquarters-expenses.component.scss';

const HeadquartersExpensesComponent = (props) => {

  const {
    headquarters
  } = props;

  return (
    <div className="headquarters-expenses__content-tables__table">
      <div className="grid-x align-middle justify-content-between headquarters-expenses__content-tables__content-values">
        <div className="grid-x align-middle">
          <img alt="icon_information" src={Assets.SharedIcons.icon_information} />
          <span className="headquarters-expenses__content-tables__title">
            {headquarters?.title}
          </span>
        </div>
        <div className="grid-x align-middle">
          <span className="headquarters-expenses__content-tables__title">
            Total:
          </span>
          <div className="grid-x headquarters-expenses__content-tables__values--mod">
            <div className="headquarters-expenses__content-tables__values__content-icon">
              <img
                alt="icon_egress"
                src={Assets.SharedIcons.icon_egress}
                className="headquarters-expenses__content-tables__values__content-icon__icon"
              />
            </div>
            <NumericFormat
              disabled
              value={headquarters?.total}
              type="text"
              prefix={"$ "}
              placeholder="$ 0"
              thousandSeparator=","
              className="input-price"
              thousandsGroupStyle="thousand"
            />
          </div>
        </div>
      </div>
      <div className="grid-x small-12 headquarters-expenses__content-tables__content-info">
        <div className="grid-x align-content-end justify-content-end headquarters-expenses__content-tables__content-info__content-title">
          <span className="small-4 headquarters-expenses__content-tables__content-info__title">
            Concepto
          </span>
          <span className="small-3 headquarters-expenses__content-tables__content-info__title">
            Fecha
          </span>
          <span className="small-4 headquarters-expenses__content-tables__content-info__title">
            Valor
          </span>
        </div>
        <div className="headquarters-expenses__content-tables__content-info__content-scroll">
          {headquarters?.data?.map((item, index) => {
            return (
              <div
                key={index}
                className="grid-x align-middle headquarters-expenses__content-tables__content-info__information-row"
              >
                <div
                  className="small-1 headquarters-expenses__content-tables__content-input"
                >
                  <div className={`headquarters-expenses__icon ${item?.type ? "" : "headquarters-expenses__icon--mod"}`}>
                    <img
                      alt="icon_money"
                      src={item.type ? Assets.SharedIcons.icon_money : Assets.SharedIcons.icon_liquor_active}
                    />
                  </div>
                </div>
                <div
                  className={`small-4 headquarters-expenses__content-tables__content-input${
                    !item?.concept ? "__disabled" : ""
                  }`}
                >
                  <Input
                    disabled
                    prefix={null}
                    placeholder="Descripción"
                    value={item?.concept}
                  />
                </div>
                <div
                  className={`small-3 headquarters-expenses__content-tables__content-input${
                    !item?.date ? "__disabled" : ""
                  }`}
                >
                  <DatePicker
                    disabled
                    clearIcon={null}
                    suffixIcon={null}
                    format="YYYY/MM/DD"
                    placeholder="AAAA/MM/DD"
                    value={item?.date ? dayjs(item?.date, "YYYY:MM:DD") : null}
                  />
                </div>
                <div
                  className={`grid-x small-4 justify-content-end headquarters-expenses__content-tables__content-input${
                    !item.price ? "__disabled" : ""
                  }`}
                >
                  <NumericFormat
                    disabled
                    type="text"
                    prefix={"$ "}
                    placeholder="$ 0"
                    value={item?.price}
                    thousandSeparator=","
                    className="input-price"
                    thousandsGroupStyle="thousand"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default HeadquartersExpensesComponent