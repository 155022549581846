import * as wholesaleFinalBalanceTypes from './wholesale-final-balance.types';

export const setStateWholesaleFinalBalanceReducer = (prop, value) => (dispatch) => {
    dispatch({ type: wholesaleFinalBalanceTypes.SET_STATE_WHOLESALE_FINAL_BALANCE, payload: { prop, value } });
};

export const setMultipleStatesWholesaleFinalBalanceReducer = (prop) => (dispatch) => {
	dispatch({ type: wholesaleFinalBalanceTypes.SET_MULTIPLE_STATES_WHOLESALE_FINAL_BALANCE, payload: { prop } });
};

export const resetWholesaleFinalBalanceReducer = () => (dispatch) => {
    dispatch({ type: wholesaleFinalBalanceTypes.RESET_WHOLESALE_FINAL_BALANCE });
}