// Assets
import { Assets } from '../../../../assets';

// Libraries
import { Input } from 'antd';
import React, { useState } from 'react';
import { NumericFormat } from 'react-number-format';

// Styles
import './they-owe-us-treasury.component.scss';

const TheyOweUsTreasuryComponent = (props) => {

    const {
        //Functions
        updateState,
        //Props
        realCash,
        totalOutflow,
        listOfBanks,
        totalOfBanks,
        listOfPortfolios,
        totalOfPortfolios,
        listOfOutflowNotReported,
        totalOfOutflowNotReported
    } = props;
    
    const INITIAL_STATE = {
        isCollapsedBank: false,
        isCollapsedNotReported: false,
        isCollapsedWallet: false
    };

    const [state, setState] = useState(INITIAL_STATE);

    const { isCollapsedBank, isCollapsedWallet, isCollapsedNotReported } = state;

    const onChange = (data) => {
        let value = data && data.target ? data.target.value || '' : data;
        value = parseFloat(value.replace(/[^0-9.]/g, '')) || 0;
        updateState({ realCash: value });
    }

    return (
        <div className='they-owe-us-treasury__container'>
            <div className='grid-x align-middle justify-content-between they-owe-us-treasury__initial-investment__content'>
                <span className='they-owe-us-treasury__initial-investment__title'>Total</span>
                <NumericFormat
                    disabled
                    type='text'
                    prefix={"$ "}
                    placeholder='$ 0'
                    thousandSeparator=","
                    value={totalOutflow}
                    className='input-price'
                    thousandsGroupStyle="thousand"
                />
            </div>
            <div className='they-owe-us-treasury__table'>
                <div className='grid-x align-middle justify-content-between they-owe-us-treasury__table__title__content'>
                    <span className='they-owe-us-treasury__table__title'>Bancos</span>
                    <button 
                        onClick={() => setState({ ...state, isCollapsedBank: !isCollapsedBank })}>
                        <img
                            alt='icon_blue_arrow_up'
                            src={Assets.SharedIcons.icon_blue_arrow_up}
                            className={`they-owe-us-treasury__table__icon ${isCollapsedBank ? 'rotate' : ''}`}
                        />
                    </button>
                </div>
                <div className={`they-owe-us-treasury__table__box ${isCollapsedBank ? 'collapsed' : ''}`}>
                    <div className='grid-x align-middle justify-content-between they-owe-us-treasury__table__subtitle__content'>
                        <span className='small-4 they-owe-us-treasury__table__subtitle'>Sede o negocio</span>
                        <span className='small-4 they-owe-us-treasury__table__subtitle'>Nombre del banco</span>
                        <span className='small-4 they-owe-us-treasury__table__subtitle text-end'>Valor</span>
                    </div>
                    <div className='they-owe-us-treasury__table__content-info'>
                        {listOfBanks.map((bankHeadquarter, index) => {
                            return (
                                <div key={index} className='grid-x align-middle justify-content-between they-owe-us-treasury__table__add__content'>
                                    <Input
                                        disabled
                                        prefix={<></>}
                                        className='they-owe-us-treasury__table__add__input'
                                        value={bankHeadquarter?.headquarterPaymentMethodDailyTransaction?.name}
                                    />
                                    <Input
                                        disabled
                                        prefix={<></>}
                                        className='they-owe-us-treasury__table__add__input'
                                        value={ `${bankHeadquarter?.bankPaymentMethodDailyTransaction?.name} (${bankHeadquarter?.accountNumber}) `  }
                                    />
                                    <div className='they-owe-us-treasury__table__add__inputs-price__disabled'>
                                        <NumericFormat
                                            disabled
                                            type='text'
                                            prefix={"$ "}
                                            placeholder='$ 0'
                                            thousandSeparator=","
                                            className='input-price'
                                            thousandsGroupStyle="thousand"
                                            value={bankHeadquarter?.totalPrice}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className='grid-x align-middle justify-content-between they-owe-us-treasury__table__total__content'>
                    <div className='grid-x align-middle justify-content-end they-owe-us-treasury__table__total__text__content'>
                        <span className='they-owe-us-treasury__table__total__text'>Total</span>
                    </div>
                    <div className='grid-x align-middle they-owe-us-treasury__table__total__content-value'>
                        <NumericFormat
                            disabled
                            type='text'
                            prefix={"$ "}
                            placeholder='$ 0'
                            thousandSeparator=","
                            value={totalOfBanks}
                            className='input-price'
                            thousandsGroupStyle="thousand"
                        />
                    </div>
                </div>
            </div>
            <div className='grid-x align-middle justify-content-between they-owe-us-treasury__real-cash-content'>
                <div className='they-owe-us-treasury__real-cash-content__title__content'>
                    <span className='they-owe-us-treasury__real-cash-content__title'>Efectivo real</span>
                </div>
                <div className='grid-x they-owe-us-treasury__real-cash-content__input'>
                    <NumericFormat
                        disabled
                        type='text'
                        prefix={"$ "}
                        value={realCash}
                        placeholder='$ 0'
                        thousandSeparator=","
                        className='input-price'
                        thousandsGroupStyle="thousand"
                        onChange={(data) => onChange(data)}
                    />
                    <div className='they-owe-us-treasury__real-cash-content__input__icon'>
                        <img
                            alt="icon_add"
                            src={Assets.SharedIcons.icon_add}
                        />
                    </div>
                </div>
            </div>
            <div className='they-owe-us-treasury__table'>
                <div className='grid-x align-middle justify-content-between they-owe-us-treasury__table__title__content'>
                    <span className='they-owe-us-treasury__table__title'>Carteras</span>
                    <button 
                        onClick={() => setState({ ...state, isCollapsedWallet: !isCollapsedWallet })}>
                        <img
                            alt='icon_blue_arrow_up'
                            src={Assets.SharedIcons.icon_blue_arrow_up}
                            className={`they-owe-us-treasury__table__icon ${isCollapsedWallet ? 'rotate' : ''}`}
                        />
                    </button>
                </div>
                <div className={`they-owe-us-treasury__table__box ${isCollapsedWallet ? 'collapsed' : ''}`}>
                    <div className='grid-x align-middle justify-content-between they-owe-us-treasury__table__subtitle__content'>
                        <span className='they-owe-us-treasury__table__subtitle'>Sede o negocio</span>
                        <span className='they-owe-us-treasury__table__subtitle'>Valor</span>
                    </div>
                    {listOfPortfolios.map((portfolio, index) => {
                        return (
                            <div key={index} className='grid-x align-middle justify-content-between they-owe-us-treasury__table__add__content'>
                                <Input
                                    disabled
                                    prefix={<></>}
                                    value={portfolio.name}
                                    placeholder={'Nombre de la sede'}
                                    className='they-owe-us-treasury__table__add__input__portfolio'
                                />
                                <div className='they-owe-us-treasury__table__add__inputs-price__disabled__portfolio'>
                                    <NumericFormat
                                        disabled
                                        type='text'
                                        prefix={"$ "}
                                        placeholder='$ 0'
                                        thousandSeparator=","
                                        className='input-price'
                                        value={portfolio.total}
                                        thousandsGroupStyle="thousand"
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className='grid-x align-middle justify-content-between they-owe-us-treasury__table__total__content'>
                    <div className='grid-x align-middle justify-content-end they-owe-us-treasury__table__total__text__content'>
                        <span className='they-owe-us-treasury__table__total__text'>Total</span>
                    </div>
                    <div className='grid-x align-middle they-owe-us-treasury__table__total__content-value'>
                        <NumericFormat
                            disabled
                            type='text'
                            prefix={"$ "}
                            placeholder='$ 0'
                            thousandSeparator=","
                            className='input-price'
                            value={totalOfPortfolios}
                            thousandsGroupStyle="thousand"
                        />
                    </div>
                </div>
            </div>
            <div className='they-owe-us-treasury__table'>
                <div className='grid-x align-middle justify-content-between they-owe-us-treasury__table__title__content'>
                    <span className='they-owe-us-treasury__table__title'>Otros</span>
                    <button 
                        onClick={() => setState({ ...state, isCollapsedNotReported: !isCollapsedNotReported })}>
                        <img
                            alt='icon_blue_arrow_up'
                            src={Assets.SharedIcons.icon_blue_arrow_up}
                            className={`they-owe-us-treasury__table__icon ${isCollapsedNotReported ? 'rotate' : ''}`}
                        />
                    </button>
                </div>
                <div className={`they-owe-us-treasury__table__box ${isCollapsedNotReported ? 'collapsed' : ''}`}>
                    <div className='grid-x align-middle justify-content-between they-owe-us-treasury__table__subtitle__content'>
                        <span className='they-owe-us-treasury__table__subtitle__not-reported'>Sede o negocio</span>
                        <span className='they-owe-us-treasury__table__subtitle__not-reported'>Concepto</span>
                        <span className='they-owe-us-treasury__table__subtitle__not-reported text-end'>Valor</span>
                    </div>
                    {listOfOutflowNotReported.map((OutflowNotReported, index) => {
                        return (
                            <div key={index} className='grid-x align-middle justify-content-between they-owe-us-treasury__table__add__content'>
                                <Input
                                    disabled
                                    prefix={<></>}
                                    placeholder='Nombre de la sede'
                                    className='they-owe-us-treasury__table__add__input'
                                    value={OutflowNotReported?.headQuarterTransaction?.name}
                                />
                                <Input
                                    disabled
                                    prefix={<></>}
                                    placeholder='Concepto'
                                    value={OutflowNotReported?.concept}
                                    className='they-owe-us-treasury__table__add__input'
                                />
                                <div className='they-owe-us-treasury__table__add__inputs-price__disabled'>
                                    <NumericFormat
                                        disabled
                                        type='text'
                                        prefix={"$ "}
                                        placeholder='$ 0'
                                        thousandSeparator=","
                                        className='input-price'
                                        thousandsGroupStyle="thousand"
                                        value={OutflowNotReported?.cashOutflow}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className='grid-x align-middle justify-content-between they-owe-us-treasury__table__total__content'>
                    <div className='grid-x align-middle justify-content-end they-owe-us-treasury__table__total__text__content'>
                        <span className='they-owe-us-treasury__table__total__text'>Total</span>
                    </div>
                    <div className='grid-x align-middle they-owe-us-treasury__table__total__content-value'>
                        <NumericFormat
                            disabled
                            type='text'
                            prefix={"$ "}
                            placeholder='$ 0'
                            thousandSeparator=","
                            className='input-price'
                            thousandsGroupStyle="thousand"
                            value={totalOfOutflowNotReported}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TheyOweUsTreasuryComponent;