// Actions
import { setItemCashClosingWholesaleReducer, setStateCashClosingWholesaleReducer } from '../../../../storage/reducers/cash-closing-wholesale/cash-closing-wholesale.actions';

//Assets
import { Assets } from '../../../../assets'

//Components - Shared
import ErrorAlertComponent from '../../../../shared/components/alerts/error-alert/error-alert.component';

//Libraries
import { NumericFormat } from 'react-number-format';
import React, { useEffect, useRef, useState } from 'react';
import { Input, Select } from 'antd'
import { connect } from 'react-redux';

//Services
import { deleteLiquidationService } from '../../../../services/cash-closing-wholesale.services';

//Styles
import './receipts.component.scss'

//Utils
import { spreadsheets } from '../../../../utils/cash-closing-wholesale.utils';

const ReceiptsComponent = (props) => {

  const { 
    // Actions
    setItemCashClosingWholesaleReducer,
    setStateCashClosingWholesaleReducer,
    // Variables
    listTable, driversList 
  } = props

  const INITIAL_STATE = {
    scrollEnd: false,
    received: spreadsheets
  };

  const [state, setState] = useState(INITIAL_STATE)

  const { received, scrollEnd } = state

  const formRef = useRef(null);

  useEffect(() => {
    if (formRef.current && scrollEnd) {
      const miDiv = formRef.current;
      miDiv.scrollTop = miDiv.scrollHeight;
    }
  }, [scrollEnd, received.length]);

  useEffect(() => {
    setState((prevState)=>({
      ...prevState,
      received: listTable ? listTable : spreadsheets
    }))
  }, [listTable])

  const addPayrolls = () => {
    const objectDisabled = received.find(object => object.hasOwnProperty("disabled") && object.disabled === true);
    if (!objectDisabled) {
      const newArray = [
        ...received,
        {
          ...spreadsheets[0],
          disabled: false
        }
      ]
      setState({
        ...state,
        scrollEnd: true,
        received: newArray
      })
    } else {
      const newArray = received.map(object => object === objectDisabled ? { ...object, disabled: false } : object);
      setState({ ...state, received: newArray })
    };
  };


  const onChanges = (e, index, name) => {
    let value = e.target ? e.target.value || '' : e;
    let receivedListModified = received
    if (name !== "codeLiquidation" && name !== "driverLiquidationTemplate") {
      value = parseInt((value || "0")?.replace(/[^0-9]/g, ''),10)
    }

    if (name === "codeTemplate") {
      let newValue = Number(value) ? Number(value) : ""
      receivedListModified[index][name] = newValue
    } else {
      receivedListModified[index][name] = value;
    }
    
    setState((prevState)=>({
      ...prevState,
      received: receivedListModified,
    }))

    setItemCashClosingWholesaleReducer("receivedList",index, receivedListModified[index]);
  }

  const deleteExpenses = async(index) => {
    try {
      let receivedListModified = received

      if (receivedListModified[index].id) {
        await deleteLiquidationService({liquidationTemplate:[receivedListModified[index]?.id]})
      }

      receivedListModified.splice(index, 1)

      if (receivedListModified.length <= 6) {
        receivedListModified = [
          ...receivedListModified,
          {
            ...spreadsheets[0],
            disabled: true
          }
        ]
      }

      setState((prevState)=>({
        ...prevState,
        received: receivedListModified
      }))

      setStateCashClosingWholesaleReducer({
        receivedList: receivedListModified
      })
    } catch (error) {
      ErrorAlertComponent()
    }
  }

  const sumPrices = (list,name) => {
    return (list || []).reduce((accumulator, product) => {
      const price = !isNaN(Number(product[name])) ? Number(product[name]) : Number(product[name]?.replace(/[^0-9]/g, ''));
      return accumulator + (isNaN(price) ? 0 : price);
    }, 0);
  };

  return (
    <div>
      <div className='grid-x'>
        <div className='grid-x small-12 justify-content-between align-items-end'>
          <span className='small-2 receipts__subtitle'> Nombre del Conductor </span>
          <span className='small-2 receipts__subtitle'> Nro. Liquidación </span>
          <span className='small-1 text-end receipts__subtitle'> Valor de Venta </span>
          <span className='small-1 text-end receipts__subtitle'> Billetes </span>
          <span className='small-1 text-end receipts__subtitle'> Monedas </span>
          <span className='small-1 text-end receipts__subtitle'> Créditos </span>
          <span className='small-1 text-end receipts__subtitle'> Consignación </span>
          <span className='small-2 text-center receipts__subtitle'> Sobrante </span>
        </div>
      </div>
      <div className='receipts__summary__content'>
        {received.map((item, index) => {
          item.salesValue = item?.banknote + item?.coin  + item?.credit + item?.transfer + item?.expense
          return (
            <div key={index} className='grid-x receipts__payments__container'>
              <div className='grid-x small-12 justify-content-between'>
                <div className='small-2'>
                  <Select
                    onChange={(e, event) => onChanges(event, index, "driverLiquidationTemplate")}
                    placeholder='Selección'
                    optionLabelProp='label'
                    popupMatchSelectWidth={false}
                    options={driversList}
                    disabled={item?.disabled}
                    className='receipts__payments__select'
                    popupClassName='receipts__payments__select__popup'
                    value={item?.driverLiquidationTemplate?.value ? item?.driverLiquidationTemplate : undefined}
                    suffixIcon={
                      <div className='receipts__payments__select__icon'>
                        <img
                          alt={item.disabled ? "icon_disabled_arrow" : "icon_blue_arrow"}
                          src={item.disabled ? Assets.SharedIcons.icon_disabled_arrow : Assets.SharedIcons.icon_blue_arrow}
                        />
                      </div>
                    }
                  />
                </div>
                <div className='small-2'>
                  <Input
                    onChange={(event) => onChanges(event, index, "codeTemplate")}
                    disabled={item?.disabled}
                    value={item?.codeTemplate}
                    placeholder='Descrpción'
                    className='receipts__payments__input'
                    prefix={<img src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit} alt="icon_add" />}
                  />
                </div>
                <div className='grid-x small-1'>
                  <div className='grid-x small-12 receipts__payments__content-receipts receipts__payments__content-receipts--mod'>
                    <NumericFormat
                      type='text'
                      prefix={"$ "}
                      placeholder='$ 0'
                      value={item?.salesValue}
                      thousandSeparator=","
                      className='input-price'
                      disabled
                      thousandsGroupStyle="thousand"
                    />
                  </div>
                </div>
                <div className='grid-x small-1'>
                  <div className='grid-x small-12 receipts__payments__content-receipts'>
                    <NumericFormat
                      onChange={(event) => onChanges(event, index, "banknote")}
                      type='text'
                      prefix={"$ "}
                      placeholder='$ 0'
                      value={item?.banknote || ""}
                      thousandSeparator=","
                      className='input-price'
                      disabled={item?.disabled}
                      thousandsGroupStyle="thousand"
                    />
                    <div className='receipts__payments__content-icon-price'>
                      <img
                        className='receipts__payments__icon'
                        alt={item.disabled ? "icon_add" : "icon_edit"}
                        src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                      />
                    </div>
                  </div>
                </div>
                <div className='grid-x small-1'>
                  <div className='grid-x small-12 receipts__payments__content-receipts'>
                    <NumericFormat
                      onChange={(event) => onChanges(event, index, "coin")}
                      type='text'
                      prefix={"$ "}
                      placeholder='$ 0'
                      value={item?.coin || ""}
                      thousandSeparator=","
                      className='input-price'
                      disabled={item?.disabled}
                      thousandsGroupStyle="thousand"
                    />
                    <div className='receipts__payments__content-icon-price'>
                      <img
                        className='receipts__payments__icon'
                        alt={item.disabled ? "icon_add" : "icon_edit"}
                        src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                      />
                    </div>
                  </div>
                </div>
                <div className='grid-x small-1'>
                  <div className='grid-x small-12 receipts__payments__content-receipts receipts__payments__content-receipts--mod'>
                    <NumericFormat
                      type='text'
                      prefix={"$ "}
                      placeholder='$ 0'
                      value={item?.credit}
                      thousandSeparator=","
                      className='input-price'
                      disabled
                      thousandsGroupStyle="thousand"
                    />
                  </div>
                </div>
                <div className='grid-x small-1'>
                  <div className='grid-x small-12 receipts__payments__content-receipts receipts__payments__content-receipts--mod'>
                    <NumericFormat
                      type='text'
                      prefix={"$ "}
                      placeholder='$ 0'
                      value={item?.transfer}
                      thousandSeparator=","
                      className='input-price'
                      disabled
                      thousandsGroupStyle="thousand"
                    />
                  </div>
                </div>
                <div className='grid-x small-2'>
                  <div className='grid-x small-10 receipts__payments__content-receipts receipts__payments__content-receipts--mod'>
                    <NumericFormat
                      type='text'
                      prefix={"$ "}
                      placeholder='$ 0'
                      value={item?.expense}
                      thousandSeparator=","
                      className='input-price'
                      disabled
                      thousandsGroupStyle="thousand"
                    />
                  </div>
                  <div className='grid-x small-2 justify-content-end'>
                    <button
                      className='receipts__button-delete'
                      disabled={item?.disabled}
                      onClick={() => deleteExpenses(index)}
                    >
                      <img
                        className='receipts__button-delete__icon'
                        alt={item?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                        src={item?.disabled ? Assets.SharedIcons.icon_deleted_disabled : Assets.SharedIcons.icon_deleted_active}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className='grid-x small-12 justify-content-between'>
        <div className='receipts__summary__line receipts__summary__line--mod'></div>
        <div className='grid-x small-4'>
          <span className='grid-x align-middle small-11 justify-content-end receipts__subtitle'>
            Totalizadores
          </span>
        </div>
        <div className='grid-x small-1'>
          <div className='grid-x small-12 receipts__payments__content-input-price'>
            <NumericFormat
              type='text'
              prefix={"$ "}
              placeholder='$ 0'
              value={sumPrices(received,"salesValue")}
              thousandSeparator=","
              className='input-price'
              thousandsGroupStyle="thousand"
            />
          </div>
        </div>
        <div className='grid-x small-1'>
          <div className='grid-x small-12 receipts__payments__content-input-price'>
            <NumericFormat
              type='text'
              prefix={"$ "}
              placeholder='$ 0'
              value={sumPrices(received,"banknote")}
              thousandSeparator=","
              className='input-price'
              thousandsGroupStyle="thousand"
            />
          </div>
        </div>
        <div className='grid-x small-1'>
          <div className='grid-x small-12 receipts__payments__content-input-price'>
            <NumericFormat
              type='text'
              prefix={"$ "}
              placeholder='$ 0'
              value={sumPrices(received,"coin")}
              thousandSeparator=","
              className='input-price'
              thousandsGroupStyle="thousand"
            />
          </div>
        </div>
        <div className='grid-x small-1'>
          <div className='grid-x small-12 receipts__payments__content-input-price'>
            <NumericFormat
              type='text'
              prefix={"$ "}
              placeholder='$ 0'
              value={sumPrices(received,"credit")}
              thousandSeparator=","
              className='input-price'
              thousandsGroupStyle="thousand"
            />
          </div>
        </div>
        <div className='grid-x small-1'>
          <div className='grid-x small-12 receipts__payments__content-input-price'>
            <NumericFormat
              type='text'
              prefix={"$ "}
              placeholder='$ 0'
              value={sumPrices(received,"transfer")}
              thousandSeparator=","
              className='input-price'
              thousandsGroupStyle="thousand"
            />
          </div>
        </div>
        <div className='grid-x small-2'>
          <div className='grid-x small-10 receipts__payments__content-input-price'>
            <NumericFormat
              type='text'
              prefix={"$ "}
              placeholder='$ 0'
              value={sumPrices(received,"expense")}
              thousandSeparator=","
              className='input-price'
              thousandsGroupStyle="thousand"
            />
          </div>
        </div>
      </div>
      <div>
        <div className='receipts__summary__line receipts__summary__line--mod'></div>
        <div className='grid-x align-middle small-6'>
          <button
            onClick={() => addPayrolls()}
          >
            <img
              src={Assets.SharedIcons.icon_income}
              alt='icon_income'
            />
            <span className='receipts__button-income'>Agregar Recaudo</span>
          </button>
        </div>
      </div>
    </div>
  )
}

const mapStateToPropsActions = {
  setItemCashClosingWholesaleReducer,
  setStateCashClosingWholesaleReducer
};

export default connect(null, mapStateToPropsActions)(ReceiptsComponent);