export const dataExpenses = [
    {
        price: "",
        invoiceNumber: "",
        disabled: true,
        category: {
            label: "",
            value: "",
        },
        subCategory: {
            label: "",
            value: "",
        },
        disabledSubCategory: true,
        listOfExpenseSubCategory: []
    },
    {
        price: "",
        invoiceNumber: "",
        disabled: true,
        category: {
            label: "",
            value: "",
        },
        subCategory: {
            label: "",
            value: "",
        },
        disabledSubCategory: true,
        listOfExpenseSubCategory: []
    },
    {
        price: "",
        invoiceNumber: "",
        disabled: true,
        category: {
            label: "",
            value: "",
        },
        subCategory: {
            label: "",
            value: "",
        },
        disabledSubCategory: true,
        listOfExpenseSubCategory: []
    },
    {
        price: "",
        invoiceNumber: "",
        disabled: true,
        category: {
            label: "",
            value: "",
        },
        subCategory: {
            label: "",
            value: "",
        },
        disabledSubCategory: true,
        listOfExpenseSubCategory: []
    },
    {
        price: "",
        invoiceNumber: "",
        disabled: true,
        category: {
            label: "",
            value: "",
        },
        subCategory: {
            label: "",
            value: "",
        },
        disabledSubCategory: true,
        listOfExpenseSubCategory: []
    },
    {
        price: "",
        invoiceNumber: "",
        disabled: true,
        category: {
            label: "",
            value: "",
        },
        subCategory: {
            label: "",
            value: "",
        },
        disabledSubCategory: true,
        listOfExpenseSubCategory: []
    },
    {
        price: "",
        invoiceNumber: "",
        disabled: true,
        category: {
            label: "",
            value: "",
        },
        subCategory: {
            label: "",
            value: "",
        },
        disabledSubCategory: true,
        listOfExpenseSubCategory: []
    }
]