// Assets
import { Assets } from '../../assets';

//Component - shared
import ErrorAlertComponent from '../../shared/components/alerts/error-alert/error-alert.component';

// Libraries
import React, { useEffect, useState } from 'react'
import { Checkbox} from 'antd';
import { useNavigate } from 'react-router-dom';

// Services
import { getFindAllWholesalerService } from '../../services/cash-closing-wholesale.services';

// Styles
import './cash-closing-wholesale.page.scss'

const CashClosingWholesalePage = () => {

    const history = useNavigate();

    const INITIAL_STATE = {
        checkedItems: [],
        isHoverName: false,
        listOfHeadquarters: []
    }

    const [state, setState] = useState(INITIAL_STATE)

    const {
        isHoverName, 
        checkedItems, 
        wholesaler,
    } = state;

    useEffect(() => {
        findAllWholesaler();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []); 


    const findAllWholesaler = async() => {
        try {
          const wholesaler = await getFindAllWholesalerService()
          setState({
            ...state,
            wholesaler: wholesaler ? wholesaler : [],
          })
        } catch (error) {
            ErrorAlertComponent()
        }
    }

    const handleCheckboxChange = (index) => {

        const prevItem = checkedItems.includes(index) ? checkedItems.filter(itemIndex => itemIndex !== index) : [...checkedItems, index];
    
        setState((prevState) => ({
          ...prevState,
          checkedItems: prevItem
        }))
    };
    
    const isItemChecked = (index) => checkedItems.includes(index);

    const viewCashClosing = (idHeadquarter, headquarterName) => {
        history(`/reportCashClosingWholesaler/${idHeadquarter}`, { state: { name: headquarterName, id: idHeadquarter}});
    }

    const optionFilter = (nameFilter, listOfHeadquarters) => {
        let sortedColdPointValuation = [...listOfHeadquarters];
        const isSorted = isSortedAscendingByAttribute(listOfHeadquarters, nameFilter);
        const sortOrder = isSorted ? 1 : -1;
        sortedColdPointValuation.sort((a, b) => (sortOrder * (b.name.localeCompare(a.name))))
        setState((prevState) => ({
            ...prevState,
            wholesaler: sortedColdPointValuation,
        }));
    };

    const isSortedAscendingByAttribute = (list, attributeName) => {
        return list?.every((item, index) => index === 0 || item[attributeName] >= list[index - 1][attributeName]);
    };

    return (
        <div className='grid-x align-center cash-closing-wholesales__container'>
            <div className='grid-x align-middle cash-closing-wholesales__title__content'>
                <span className='cash-closing-wholesales__title'>Cierre de caja</span>
            </div>
            <div className='cash-closing-wholesales__box'>
                <div className='grid-x align-middle justify-content-between cash-closing-wholesales__box__content-titles'>
                    <div className='grid-x align-center-middle'>
                        <button
                            onClick={() => optionFilter('name', wholesaler)}
                            onMouseOver={() => setState({ ...state, isHoverName: true })}
                            onMouseOut={() => setState({ ...state, isHoverName: false })}
                            className='grid-x align-center-middle cash-closing-wholesales__box__button'
                        >
                            <img
                                alt={isHoverName ? "icon_blue_arrow_down" : "icon_blue_arrow"}
                                src={isHoverName ? Assets.SharedIcons.icon_blue_arrow_down : Assets.SharedIcons.icon_blue_arrow}
                                className={`cash-closing-wholesales__box__button__icon${isSortedAscendingByAttribute(wholesaler, 'name') ? '__active' : ''}`}
                            />
                            <span className={`cash-closing-wholesales__box__title${isHoverName ? '__active' : ''}`}>
                                Nombre de la sede
                            </span>
                        </button>
                    </div>
                    <span className='cash-closing-wholesales__box__title'>
                        Acciones
                    </span>
                </div>
                <div className='grid-x cash-closing-wholesales__box__content-information'>
                    {wholesaler?.map((headquarter, index) => (
                        <div key={index} className={`grid-x align-middle justify-content-between cash-closing-wholesales__box__info${isItemChecked(headquarter) ? '__selected' : ''}`}>
                            <div className='grid-x align-middle small-7'>
                                <div className='cash-closing-wholesales__box__info__checkbox'>
                                    <Checkbox
                                        checked={isItemChecked(headquarter)}
                                        onChange={() => handleCheckboxChange(headquarter)}
                                    />
                                </div>
                                <div>
                                    <span className='cash-closing-wholesales__box__info__text'>
                                        {headquarter?.name}
                                    </span>
                                </div>
                            </div>
                            <div className='grid-x align-middle justify-content-end small-5'>
                                <button
                                    className='cash-closing-wholesales__box__info__button'
                                    onClick={() => viewCashClosing(headquarter.id, headquarter.name)}
                                >
                                    <span>Cierre de caja</span>
                                    <img
                                        alt="icon_arrow"
                                        src={Assets.SharedIcons.icon_arrow}
                                        className='cash-closing-wholesales__box__info__button__icon'
                                    />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    )

}

export default CashClosingWholesalePage