// Actions
import { setStateAuthorizedManagementReducer } from "../../../storage/reducers/authorized-management/authorized-management.actions";

// Assets
import { Assets } from "../../../assets";

// Components - shared
import ErrorAlertComponent from "../../../shared/components/alerts/error-alert/error-alert.component";
import InformationAlertComponent from "../../../shared/components/alerts/information-alert/information-alert.component";
import SuccessAlertComponent from "../../../shared/components/alerts/success-alert/success-alert.component";

// Components
import PreviewAuthorizedComponent from "../components/preview-authorized/preview-authorized.component";

// Libraries
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Input, Select } from "antd";
import { NumericFormat } from "react-number-format";
import { useFormik } from "formik";
import { connect } from "react-redux";

// Services
import { getIndicativeNumbersService } from "../../../services/shared.services";
import { createAuthorizedManagementService, getAuthorizedManagementByIdService, updateAuthorizedManagementService } from "../../../services/authorized-management.services";

// Styles
import "./authorized-create-edit.page.scss"

// Utils
import { authorizedSchema } from "../../../utils/authorized-management.utils";

const AuthorizedCreateEditPage = (props) => {

  const {
    // Actions
    setStateAuthorizedManagementReducer,
    // Variables
    userAuthorizedSelected
  } = props;

  const navigate = useNavigate();
  const { state: routeState } = useLocation();
  const INITIAL_STATE = {
    listIndicativeNumbers: [],
  }
  const [state, setState] = useState(INITIAL_STATE)
  const { listIndicativeNumbers } = state
  const { values, errors, setFieldValue, handleBlur, touched, setValues, setTouched } = useFormik({
    initialValues: {
      name: "",
      documentNumber: "",
      telephone: "",
      permittedBalance: 0,
      indicativeNumber: undefined
    },
    initialErrors: {
      name: "",
      documentNumber: "",
      telephone: "",
      permittedBalance: "",
    },
    validationSchema: authorizedSchema,
    onSubmit: () => {},
  });

  useEffect(() => {
    personalInformation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const personalInformation = () => {
    setTouched({
      ...touched,
      name: userAuthorizedSelected?.name ? true : false,
      documentNumber: userAuthorizedSelected?.numberDocument ? true : false,
      telephone: userAuthorizedSelected?.numberPhone ? true : false,
      permittedBalance: userAuthorizedSelected?.authorizedCash ? true : false,
    })

    setValues({
      ...values,
      name: userAuthorizedSelected?.name,
      documentNumber: userAuthorizedSelected?.numberDocument,
      telephone: userAuthorizedSelected?.numberPhone,
      permittedBalance: userAuthorizedSelected?.authorizedCash,
      indicativeNumber: userAuthorizedSelected?.IndicativeNumberCharge ? 
        { 
          label: userAuthorizedSelected?.IndicativeNumberCharge.indicative,
          value: userAuthorizedSelected?.IndicativeNumberCharge.id
        }
      : undefined
    })
    getIndicativeNumbers()
  }

  const getIndicativeNumbers = async() => {
    try {
      const listIndicativeNumbers = await getIndicativeNumbersService()
      setState({
        ...state,
        listIndicativeNumbers: listIndicativeNumbers
      })
      if(!userAuthorizedSelected?.IndicativeNumberCharge ){
        const indicativeDefault = listIndicativeNumbers?.find((item) => item?.label === "+57")
        setFieldValue("indicativeNumber", indicativeDefault || listIndicativeNumbers[0])
      } 
    } catch (error) {
      ErrorAlertComponent()
    }
  }

  const onChangeField = (target,data) => {
    let value = data && data.target ? data.target.value || '' : data;

    if (target === "name" ) {
      value = value?.trimStart()
    }
    
    if (target === "permittedBalance") {
      value = value.floatValue || 0
      setFieldValue(target, value)
    }else{
      setFieldValue(target, value)
    }
  }

  const handleCreateAndEdit = () => {
    if(userAuthorizedSelected){
      const dataOriginal = {
        name: userAuthorizedSelected?.name,
        numberDocument: userAuthorizedSelected?.numberDocument,
        numberPhone: userAuthorizedSelected?.numberPhone,
        authorizedCash: userAuthorizedSelected?.authorizedCash,
        idIndicativeNumber: userAuthorizedSelected?.idIndicativeNumber
      }
      const dataValues = {
        name: values.name,
        numberDocument: values.documentNumber,
        numberPhone: values.telephone,
        authorizedCash: values.permittedBalance,
        idIndicativeNumber: values.indicativeNumber?.value
      }
      let updateData = findChanges(dataOriginal, dataValues)
      if(Object.keys(updateData).length > 0){
        updateAuthorized(userAuthorizedSelected?.id,updateData)
      }else{
        InformationAlertComponent()
      }
    }else{
      createAuthorized()
    }
  }



  const findChanges = (object1, object2) =>{
    return Object.keys(object2).reduce((changes, key) => {
      if (object1.hasOwnProperty(key) && object1[key] !== object2[key]) {
        changes[key] = object2[key];
      }
      return changes;
    }, {});
  }

  const createAuthorized = async() => {
    try {
      const idAuthorized = await createAuthorizedManagementService({
        name: values.name,
        numberDocument: values.documentNumber,
        numberPhone: values.telephone,
        isAuthorizeFurtherLoans: userAuthorizedSelected?.isAuthorizeFurtherLoans ? true : false,
        authorizedCash: values.permittedBalance,
        idIndicativeNumber: values.indicativeNumber?.value
      })
      const getAuthorizedManagement = await getAuthorizedManagementByIdService(idAuthorized)
      setStateAuthorizedManagementReducer("userAuthorizedSelected", getAuthorizedManagement)
      SuccessAlertComponent()
    } catch (error) {
      ErrorAlertComponent()
    }
  }

  const updateAuthorized = async(id,updateData) => {
    try {
      await updateAuthorizedManagementService(id,updateData)
      const getAuthorizedManagement = await getAuthorizedManagementByIdService(userAuthorizedSelected?.id)
      setStateAuthorizedManagementReducer("userAuthorizedSelected", getAuthorizedManagement)
      SuccessAlertComponent()
    } catch (error) {
      ErrorAlertComponent()
    }
  }

  return (
    <div className="authorized-create-edit__content">
      <div className="grid-x align-middle">
        <Link
          replace={true}
          to="/authorizedManagement"
          className="banks-detail__title"
        >
          Fiados
        </Link>
        <img
          alt="icon_arrow"
          src={Assets.SharedIcons.icon_arrow}
          className="banks-detail__icon-arrow"
        />
        {routeState?.type === 2 && (
          <>
            <button
              onClick={() => navigate(-1)}
              className="banks-detail__title"
            >
              {userAuthorizedSelected?.name}
            </button>
            <img
            alt="icon_arrow"
            src={Assets.SharedIcons.icon_arrow}
            className="banks-detail__icon-arrow"
            />
          </>
        )}
        <span className="banks-detail__subtitle">{routeState?.type === 2 ? "Editar Autorizado" : "Crear Autorizado"}</span>
      </div>
      <div className="grid-x authorized-create-edit__box">
        <div className="small-4">
          <PreviewAuthorizedComponent
            type={routeState.type}
          />
        </div>
        <div className="small-8">
          <div className="authorized-create-edit__data-personal">
            <label className="authorized-create-edit__title">Datos personales</label>
            <div className='authorized-create-edit__data-personal__content'>
              <span className='authorized-create-edit__data-personal__label'>
                Nombres y apellidos del autorizado <span style={{ color: '#F85F61' }}>*</span>
              </span>
              <Input
                value={values.name}
                placeholder='Escribe el nombre del fiador'
                onBlur={(value) => handleBlur('name')(value)}
                onChange={(value) => onChangeField("name", value)}
                className='authorized-create-edit__data-personal__input'
                status={errors.name && touched.name ? 'error' : ''}
              />
              <span className="authorized-create-edit__data-personal__error">{touched.name ? errors.name : null}</span>
            </div>
            <div className="grid-x small-12 justify-content-between">
              <div className='authorized-create-edit__data-personal__content authorized-create-edit__data-personal__content--mod'>
                <span className='authorized-create-edit__data-personal__label'>
                  Documento de identidad <span style={{ color: '#F85F61' }}>*</span>
                </span>
                <Input
                  value={values.documentNumber}
                  placeholder='Número del documento'
                  onBlur={(value) => handleBlur('documentNumber')(value)}
                  onChange={(value) => onChangeField("documentNumber", value)}
                  className='authorized-create-edit__data-personal__input'
                  status={errors.documentNumber && touched.documentNumber ? 'error' : ''}
                />
                <span className="authorized-create-edit__data-personal__error">{touched.documentNumber ? errors.documentNumber : null}</span>
              </div>
              <div className='authorized-create-edit__data-personal__content authorized-create-edit__data-personal__content--mod'>
                <span className='authorized-create-edit__data-personal__label'>
                  Número de contacto <span style={{ color: '#F85F61' }}>*</span>
                </span>
                <div className="grid-x justify-content-between">
                  <Select
                    options={listIndicativeNumbers}
                    popupMatchSelectWidth={false}
                    placeholder='Selecciona un banco'
                    onBlur={(value) => handleBlur('indicativeNumber')(value)}
                    onChange={(e, event) => onChangeField("indicativeNumber", event)}
                    value={values?.indicativeNumber ? values?.indicativeNumber : undefined}
                    className='authorized-create-edit__select'
                    status={errors.indicativeNumber && touched.indicativeNumber ? 'error' : ''}
                    popupClassName='authorized-create-edit__select__popup'
                    suffixIcon={
                        <div className='authorized-create-edit__select__icon'>
                            <img
                                alt="icon_blue_arrow"
                                src={Assets.SharedIcons.icon_blue_arrow}
                            />
                        </div>
                    }
                  />
                  <Input
                    value={values.telephone}
                    placeholder='Número de contacto'
                    onBlur={(value) => handleBlur('telephone')(value)}
                    onChange={(value) => onChangeField('telephone', value)}
                    className='authorized-create-edit__data-personal__input authorized-create-edit__data-personal__input--mod'
                    status={errors.telephone && touched.telephone ? 'error' : ''}
                  />
                </div>
                <span className="authorized-create-edit__data-personal__error">{touched.telephone ? errors.telephone : null}</span>
              </div>
            </div>
            <div className='authorized-create-edit__data-personal__content authorized-create-edit__data-personal__content--mod'>
              <span className='authorized-create-edit__data-personal__label'>
                Saldo permitido <span style={{ color: '#F85F61' }}>*</span>
              </span>
              <NumericFormat
                defaultValue={0}
                type='text'
                prefix={"$ "}
                placeholder='$ 0'
                isAllowed={(valueField) => {
                  const { floatValue } = valueField;
                  return floatValue === undefined && values.permittedBalance === 0 ? false : true;
                }}
                thousandSeparator=","
                value={values.permittedBalance}
                thousandsGroupStyle="thousand"
                onValueChange={(e) => onChangeField('permittedBalance',e)}
                onBlur={(value) => handleBlur('permittedBalance')(value)}
                className={errors.permittedBalance && touched.permittedBalance ? 'input-price-error' : 'input-price'}
              />
              <span className="authorized-create-edit__data-personal__error">{touched.permittedBalance ? errors.permittedBalance : null}</span>
            </div>
            <div className="grid-x small-12 justify-content-end authorized-create-edit__buttons">
              <button
                className='authorized-create-edit__cancel'
                onClick={() => navigate(-1)}
              >
                Cancelar
              </button>
              <button
                onClick={()=> handleCreateAndEdit()}
                className='authorized-create-edit__save'
                disabled={Object.keys(errors).length > 0}
              >
                Guardar Cambios
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ AuthorizedManagementReducer }) => {
  const { userAuthorizedSelected } = AuthorizedManagementReducer;
  return { userAuthorizedSelected };
};

const mapStateToPropsActions = {
  setStateAuthorizedManagementReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(AuthorizedCreateEditPage);