class UserModel {

    constructor(name, lastName, authentication, permissions) {
        this.name = name;
        this.lastName = lastName;
        this.authentication = authentication;
        this.permissions = permissions;
    }

}

export default UserModel