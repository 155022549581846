export const dataPaymentOfBondsmen = [
    {
        price: "",
        concept: "",
        invoiceNumber: "",
        nameOfCharge: {
            label: "",
            value: "",
        },
        disabled: true
    },
    {
        price: "",
        concept: "",
        invoiceNumber: "",
        nameOfCharge: {
            label: "",
            value: "",
        },
        disabled: true
    },
    {
        price: "",
        concept: "",
        invoiceNumber: "",
        nameOfCharge: {
            label: "",
            value: "",
        },
        disabled: true
    },
    {
        price: "",
        concept: "",
        invoiceNumber: "",
        nameOfCharge: {
            label: "",
            value: "",
        },
        disabled: true
    },
    {
        price: "",
        concept: "",
        invoiceNumber: "",
        nameOfCharge: {
            label: "",
            value: "",
        },
        disabled: true
    },
    {
        price: "",
        concept: "",
        invoiceNumber: "",
        nameOfCharge: {
            label: "",
            value: "",
        },
        disabled: true
    },
    {
        price: "",
        concept: "",
        invoiceNumber: "",
        nameOfCharge: {
            label: "",
            value: "",
        },
        disabled: true
    },
]