// Assets
import { Assets } from "../../../../assets";

// Components - Shared
import ErrorAlertComponent from "../../../../shared/components/alerts/error-alert/error-alert.component";
import SuccessAlertComponent from "../../../../shared/components/alerts/success-alert/success-alert.component";

// Components
import FileUploadComponent from "../file-upload/file-upload.component";
import TransferValuationComponent from "../transfer-valuation/transfer-valuation.component";

// Libraries
import React, { useEffect, useState } from "react";
import { Checkbox, Modal, Spin } from "antd";
import { NumericFormat } from "react-number-format";

// Services
import { createTransfersService, getDownloadTemplateTransferService, getExcelTransfersService } from "../../../../services/inventory.services";

// Styles
import "./transfers.component.scss";

const TransfersComponent = (props) => {

  const {
    // Functions
    onChangeTransfer = () => null,
    // Variables
    isUpdated
  } = props;

  const INITIAL_STATE = {
    excelFile: null,
    statusProgress: "normal",
    isModal: false,
    dataTransfer: [],
    checkedItems: [],
    isDiscardAll: false,
    isHeadquarter: false,
    listHeadQuartersChecked: [],
    dataTransfersTotal: {
      transfersOrganized: [],
    },
    listCheckedOld: [],
    isLoading: true
  };
  const [state, setState] = useState(INITIAL_STATE);

  const {
    excelFile,
    statusProgress,
    isModal,
    dataTransfer,
    checkedItems,
    isDiscardAll,
    isHeadquarter,
    listHeadQuartersChecked,
    dataTransfersTotal,
    listCheckedOld,
    isLoading
  } = state;

  useEffect(() => {
    getDataTransfers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isUpdated && excelFile !== null) {
      setState((prevState)=>({
        ...prevState,
        excelFile: null,
        statusProgress: "normal",
      }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated])

  const getDataTransfers = async () => {
    try {
      const responseProduct = await getExcelTransfersService();
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          dataTransfersTotal: responseProduct,
        }));
        onChangeTransfer({isTransfers: true, isUpdated: false, lastUpdate: responseProduct.lastModifiedDate});
      }, 500);;
    } catch (error) {
      setTimeout(() => {
        if (error.code !== "INVY_023") {
          ErrorAlertComponent();
        }else{
          setState((prevState) => ({
            ...prevState,
            dataTransfersTotal: {
              transfersOrganized: [],
            },
            isLoading: false,
          }));
        }
      }, 500);;
    }
  }

  const closeModal = () => {
    setState((prevState) => ({
      ...prevState,
      isModal: false,
      checkedItems: [],
      isDiscardAll: false
    }));
  };

  const validateFileProcess = async(headQuartersToAvoid,productsSpecification) => {
    try {
      let dataService = {
        excel:excelFile.originFileObj
      }

      if (headQuartersToAvoid) {
        dataService.headQuartersToAvoid = headQuartersToAvoid
      }

      if (productsSpecification) {
        dataService.productsSpecification = productsSpecification
      }

      const responseProduct = await createTransfersService(dataService);

      let newState = {}
      if (responseProduct.hasOwnProperty("unregisteredNames")) {
        const arrayTransfer = responseProduct?.unregisteredNames?.map(item => ({ name: item }));

        let arrayChecked = [
          ...listCheckedOld,
          ...checkedItems
        ];

        newState = {
          ...newState,
          dataTransfer: arrayTransfer,
          isHeadquarter: false,
          isModal: true,
          checkedItems: [],
          isDiscardAll: false,
          listCheckedOld: arrayChecked
        }
      }

      if (responseProduct.hasOwnProperty("unregisteredProducts")) {
        newState = {
          ...newState,
          dataTransfer: responseProduct.unregisteredProducts.map((item,index)=> ({...item, key:index})),
          isHeadquarter: true,
          listHeadQuartersChecked: headQuartersToAvoid,
          checkedItems: [],
          listCheckedOld: [],
          isDiscardAll: false,
          isModal: true
        }
      }

      if (responseProduct === true) {
        newState= {
          ...newState,
          isModal: false,
          checkedItems: [],
          listCheckedOld: [],
          isDiscardAll: false,
        }
        await getDataTransfers()
      }

      setState((prevState) => ({
        ...prevState,
        ...newState
      }))

      if (newState.isModal === false) {
        return true
      }

    } catch (error) {
      ErrorAlertComponent();
    }
  }

  const endAndSave = async() => {
    try {
      if (isHeadquarter) {
        let listHeadQuarters = [...listCheckedOld, ...checkedItems]
        const namesChecked = dataTransfer.map(object => {
          const objectFilter = listHeadQuarters.find(obj => obj.key === object.key);
          return `${object?.transferNumber}->${object?.product}->${objectFilter ? "null" : object?.value}`
        });
        const listChecked = namesChecked.join('&&');
        const getData = await validateFileProcess(listHeadQuartersChecked,listChecked);
        if (getData) {
          setState((prevState) => ({
            ...prevState,
            isLoading: true
          }))
          await getDataTransfers();
        }
      }else{
        let listHeadQuarters = [...listCheckedOld, ...checkedItems]
        const namesChecked = listHeadQuarters.map(object => object.name);
        const listChecked = namesChecked.join(',');
        const getData = await validateFileProcess(listChecked);
        if (getData) {
          setState((prevState) => ({
            ...prevState,
            isLoading: true
          }))
          await getDataTransfers();
        }
      }
    } catch (error) {
      ErrorAlertComponent();
    }
  }
  
  const validateDiscardAll = (isDiscardAll) => checkedItems.length === dataTransfer.length || isDiscardAll;

  const isItemChecked = (headquarter) => checkedItems.includes(headquarter);

  const handleCheckboxChange = (value, target, headquarter) => {

    if (target === 'isDiscardAll') {
      setState((prevState) => ({
        ...prevState,
        isDiscardAll: value,
        checkedItems: value ? dataTransfer : []
      }));
    };

    if (target === 'isDiscard') {
      const isChecked = checkedItems.includes(headquarter);
      setState((prevState) => ({
        ...prevState,
        isDiscardAll: false,
        checkedItems: isChecked ? checkedItems.filter(item => item !== headquarter) : [...checkedItems, headquarter]
      }));
    };
  };

  const handleChange = (e, index, name) => {
    let value = e && e.target ? e.target.value || '' : e;
    let newArray = [...dataTransfer];
    value = value.replace(/[^0-9.]/g, '')
    newArray[index][name] = value;
    setState((prevState) => ({
      ...prevState,
      dataTransfer: newArray
    }));
  };

  const downloadTemplate = async() => {
    try {
      await getDownloadTemplateTransferService();
      SuccessAlertComponent(undefined, 'Se ha descargado el archivo con éxito.')
    } catch (error) {
      ErrorAlertComponent();
    }
  };

  return (
    <>
    { isLoading ?
      (
        <div className='grid-y align-center-middle transfers__loading'>
          <Spin size='large' />
          <span className='transfers__loading__text'>Cargando...</span>
        </div>
      )
        :
      ((dataTransfersTotal?.transfersOrganized?.length === 0 || isUpdated) ?
        <div className="grid-x align-center-middle small-12 transfers__download">
          <span className="small-7 text-center transfers__download__description">
            Bienvenido al submódulo de <strong>traslados</strong>, aún no tenemos
            datos para mostrarte, ayúdanos adjuntando los archivos base
          </span>
          <div className="grid-x small-6 align-center transfers__download__box">
            <span className="small-11 text-center transfers__download__description">
              Adjunta los archivos en formato .xlsx con un peso máximo de 100 MB
              cada uno. Si no encuentras el formato de los archivos descargados
              aquí
            </span>
            <div className="grid-x small-9 align-center transfers__download__container">
              <div className="grid-x align-center small-6">
                <label className="transfers__download__text">
                  Descarga aquí la plantilla
                </label>
                <button
                  className="grid-x align-center-middle transfers__download__button"
                  onClick={() => downloadTemplate()}
                >
                  <img
                    src={Assets.SharedIcons.icon_download_white}
                    alt="icon_download_white"
                    className="transfers__download__button__icon"
                  />
                  <span className="transfers__download__button__text">
                    traslados.xlsx
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="grid-x small-10 transfers__attach align-center-middle">
            <FileUploadComponent
              nameDocument="traslados"
              isDisabledFile={false}
              excelFile={excelFile}
              statusProgress={statusProgress}
              setUpdateState={(newState)=>{ 
                let excelFileNew = newState.excelInventoryFile
                let statusProgressNew = newState.statusProgressInventory
                setState((prevState) => ({
                  ...prevState,
                  ...newState,
                  excelFile: excelFileNew,
                  statusProgress: statusProgressNew
                }))
              }}
            />
          </div>
          <div className="grid-x small-9 transfers__container align-center-middle">
            <button
              onClick={() => {
                setState((prevState) => ({ ...prevState, isLoading: true }))
                validateFileProcess()
              }}
              className="transfers__download__button-create-inventory"
              disabled={statusProgress === 'done' ? false : true}
            >
              <span className="transfers__button-add">
                Crear nuevo traslado
              </span>
            </button>
          </div>
        </div> 
        : 
        <TransferValuationComponent
          dataTransfers={dataTransfersTotal}
        />
      )
    }
      {isModal && (
        <Modal
          centered
          width={isHeadquarter ? 700 : 500}
          destroyOnClose
          closeIcon={null}
          open={isModal}
          onCancel={() => closeModal()}
          footer={
            <div className="grid-x justify-content-center transfers__modal__content-footer">
              <button
                key="cancel"
                onClick={() => closeModal()}
                className="transfers__modal__button__cancel"
              >
                <span>Cancelar</span>
              </button>
              <button
                key="accept"
                onClick={() => endAndSave()}
                className="transfers__modal__button__confirm"
              >
                <span>Finalizar y guardar</span>
              </button>
            </div>
          }
        >
          <div className="grid-x align-center-middle transfers__modal__content">
            <div className="grid-x align-center-middle transfers__modal__content-title">
              <img alt="icon_warning" src={Assets.SharedIcons.icon_warning} />
              <span className="transfers__modal__title">
                {isHeadquarter ? "Ajustar costos" : "Sedes no identificadas"}
              </span>
            </div>
            <span className="transfers__modal__text">
              {isHeadquarter ? 
                "A continuación te compartimos el listado de los productos a los cuales el costo por unidad es igual a $0." 
                : 
                "A continuación te compartimos el listado de las sedes que no han sido creadas desde el panel administrativo."
              }
            </span>
            <div className={`grid-x transfers__modal__content-titles ${isHeadquarter ? "align-center-middle":"justify-content-between align-middle" }`}>
              { isHeadquarter &&
                <span className="small-2 transfers__modal__name-site">
                  Number
                </span>
              }
              <span className={`transfers__modal__name-site ${isHeadquarter ? "small-3" : "small-4" }`}>
                {isHeadquarter ? "Nombre del producto" : "Nombre de la sede"}
              </span>
              { isHeadquarter &&
                <>
                  <span className="grid-x small-2 align-center-middle transfers__modal__name-site">
                    Cantidad
                  </span>
                  <span className="small-2 transfers__modal__name-site">
                    Costo UNIDAD
                  </span>
                </>
              }
              <div className="grid-x small-3 align-center-middle justify-content-end transfers__modal__discard__content">
                <span className="transfers__modal__discard">
                  Descartar todo
                </span>
                <div className="transfers__modal__discard__checkbox">
                  <Checkbox
                    checked={validateDiscardAll(isDiscardAll)}
                    onChange={(e) => handleCheckboxChange(e?.target?.checked, 'isDiscardAll')}
                  />
                </div>
              </div>
            </div>
            <div className="transfers__modal__info__content">
              {dataTransfer?.map((product, index) => {
                return (
                  <div
                    key={index}
                    className={
                      `grid-x align-center-middle transfers__modal__info__column 
                        ${ index === dataTransfer?.length - 1 ?  
                          "transfers__modal__info__column--mod" 
                          : 
                          ""
                        }
                        ${ isHeadquarter ? 
                          "align-center-middle" 
                          : 
                          "justify-content-between align-middle"
                        }
                      `
                    }
                  >
                    { isHeadquarter &&
                      <span className="small-2 transfers__modal__name-site">
                        {product?.transferNumber}
                      </span>
                    }
                    <div className={`transfers__modal__info__site__content ${ isHeadquarter ? "small-3" : "small-8" }`}>
                      <img
                        alt="icon_inventory_control"
                        src={Assets.SharedIcons.icon_inventory_control}
                      />
                      <span className="transfers__modal__info__site__text">
                        {product?.name || product?.product}
                      </span>
                    </div>
                    { isHeadquarter &&
                      <>
                        <span className="grid-x small-2 align-center-middle transfers__modal__name-site">
                          {product.amount}
                        </span>
                        <div className="grid-x small-2 transfers__payment__content-input-price">
                          <NumericFormat
                            type="text"
                            prefix={"$ "}
                            placeholder="$ 0"
                            thousandSeparator=","
                            className="input-price"
                            thousandsGroupStyle="thousand"
                            value={product?.value}
                            onChange={(e) => handleChange(e,index,'value')}
                          />
                          <div className="transfers__payment__content-icon-price">
                            <img
                              className="transfers__payment__icon"
                              alt="icon_edit"
                              src={Assets.SharedIcons.icon_edit}
                            />
                          </div>
                        </div>
                      </>
                    }
                    <div className="grid-x small-3 align-center-middle justify-content-end transfers__modal__info__discard__content">
                      <span className="transfers__modal__info__discard__text">
                        Descartar
                      </span>
                      <div className="transfers__modal__info__discard__checkbox">
                        <Checkbox
                          checked={isItemChecked(product)}
                          onChange={(e) => handleCheckboxChange(e?.target?.checked, 'isDiscard', product)}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default TransfersComponent;
