// Actions
import { setStateHeadquartersReducer } from '../../../../../storage/reducers/headquarters/headquarters.actions';

// Assets
import { Assets } from '../../../../../assets';

// Libraries
import dayjs from "dayjs";
import { DatePicker, Input } from 'antd';
import { NumericFormat } from 'react-number-format';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

// Styles
import './credits-or-we-owe-inventory.component.scss';

const CreditsOrWeOweInventoryComponent = (props) => {

  const { 
    // Actions
    setStateHeadquartersReducer,
    // Variables
    provisionsData,
    treasuryPortfoliosData,
    weMustData,
    changesGeneralSummary,
    generalSummary,
    totalCredits
  } = props;

  const INITIAL_STATE = {
    scrollEnd: false,
    listOfWeOwe: [],
    isCollapsedPortfolios: false,
    isCollapsedOwesTo: false,
    isCollapsedProvisions: false,
    isCollapsedCredits: false
  };

  const [state, setState] = useState(INITIAL_STATE);

  const { scrollEnd, listOfWeOwe, isCollapsedPortfolios, isCollapsedOwesTo,
    isCollapsedProvisions, isCollapsedCredits
  } = state;

  const formRef = useRef(null);

  useEffect(() => {
    if (formRef.current && scrollEnd) {
      const miDiv = formRef.current;
      miDiv.scrollTop = miDiv.scrollHeight;
    }
  }, [scrollEnd, listOfWeOwe.length]);


  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      listOfWeOwe: weMustData.data
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[generalSummary])

  const addWeOwe = () => {
    const objectInputDisabled = listOfWeOwe.find(object => object.hasOwnProperty("disabled") && object.disabled === true);

    if (!objectInputDisabled) {
      const newArray = [
        ...listOfWeOwe,
        {
          description: '',
          value: '',
          disabled: false
        }
      ];
      setState((prevState) => ({
        ...prevState,
        scrollEnd: true,
        listOfWeOwe: newArray
      }))
    } else {
      const newArray = listOfWeOwe.map(object => (object === objectInputDisabled ? { ...object, disabled: false } : object));
      setState((prevState) => ({
        ...prevState,
        listOfWeOwe: newArray
      }))
    }
  };

  const handleChange = (e, index, name) => {
    let value = e && e.target ? e.target.value || '' : e;
    let changesReducer = changesGeneralSummary ? [...changesGeneralSummary] : []
    let newChanges = {}
    const listOfWeOweCopy = JSON.parse(JSON.stringify(listOfWeOwe));

    if (name === "value") {
      value = value.replace(/[^0-9.,]/g, '').replace(/(\..*)\./g, '$1').replace(/,/g, '')
      const parts = value.split('.');
      if (parts.length === 2) {
        const decimalPart = parts[1];
        if (decimalPart.length > 2) {
          parts[1] = decimalPart.slice(0, 2);
        }
      }
      value = parts.join('.');
    };

    listOfWeOweCopy[index][name] = value;

    const existingObjectIndex = changesReducer.findIndex(x => x.index === index);
    if (existingObjectIndex !== -1) {
      changesReducer[existingObjectIndex] = {
        ...changesReducer[existingObjectIndex],
        [name]: value
      };
    } else {
      newChanges = {
        ...listOfWeOweCopy[index],
        idConcept: weMustData?.id,
        index: index,
        [name]: value
      };
      changesReducer.push(newChanges);
    }

    setStateHeadquartersReducer("changesGeneralSummary", changesReducer)

    setState({
      ...state,
      listOfWeOwe: listOfWeOweCopy
    })
  };

  const onChanges = (data, field, target, item) => {
    let value = data && data.target ? data.target.value || '' : data;
    value = value.trimStart()
    
    let changesReducer = changesGeneralSummary ? [...changesGeneralSummary] : []
    let newChanges = {}

    if (target === "treasuryPortfoliosData") {
      const existingObjectIndex = changesReducer.findIndex(x => x.idConcept === treasuryPortfoliosData?.id);

      if (field === "value") {
        value = parseFloat(value.replace(/[^0-9.]/g, '')) || 0
      }

      if (existingObjectIndex !== -1) {
        changesReducer[existingObjectIndex] = {
          ...changesReducer[existingObjectIndex],
          [field]: value
        };
      } else {
        newChanges = {
          idConcept: treasuryPortfoliosData?.id,
          [field]: value
        };
        changesReducer.push(newChanges);
      }
    }

    if (target === "provisionsData") {
      const existingObjectIndex = changesReducer.findIndex(changes => changes.id === item?.id);
      value = parseFloat(value?.replace(/[^0-9.]/g, '')) || 0
      if (existingObjectIndex !== -1) {
        changesReducer[existingObjectIndex] = {
          ...changesReducer[existingObjectIndex],
          [field]: value
        };
      } else {
        newChanges = {
          ...item,
          [field]: value
        };
        changesReducer.push(newChanges);
      }
    }

    setStateHeadquartersReducer("changesGeneralSummary", changesReducer)
  }

  const totalAmount = totalCredits?.providerList?.reduce((acc, Credits) => {
    return acc + (Credits.credit - Credits.payment);
  }, 0);
  
  return (
    <div className='credits-or-we-owe-inventory__container'>
      <div className='credits-or-we-owe-inventory__table'>
        <div className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__title__content'>
          <span className='credits-or-we-owe-inventory__table__title'>Carteras con tesorería</span>
          <button
            onClick={() => setState({ ...state, isCollapsedPortfolios: !isCollapsedPortfolios })}
          >
            <img
              alt='icon_blue_arrow_up'
              className={`credits-or-we-owe-inventory__table__icon ${isCollapsedPortfolios ? 'rotate' : ''}`}
              src={Assets.SharedIcons.icon_blue_arrow_up}
            />
          </button>
        </div>
        <div className='credits-or-we-owe-inventory__table__box'>
          <div className={`credits-or-we-owe-inventory__table__content-info-scroll ${isCollapsedPortfolios ? 'collapsed' : ''}`}>
            <div className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__subtitle__content'>
              <span className='credits-or-we-owe-inventory__table__subtitle'>Concepto</span>
              <span className='credits-or-we-owe-inventory__table__subtitle'>Valor</span>
            </div>
            <div className='credits-or-we-owe-inventory__table__content-info-scroll'>
              {treasuryPortfoliosData?.data?.map((portfoliosWithTreasury, index) => {
                return (
                  <div key={index} className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__content-label'>
                    <Input
                      disabled
                      prefix={<></>}
                      value={portfoliosWithTreasury?.description}
                      className='credits-or-we-owe-inventory__table__input'
                    />
                    <div className='credits-or-we-owe-inventory__table__input-price-disabled'>
                      <NumericFormat
                        disabled
                        type='text'
                        prefix={"$ "}
                        placeholder='$ 0'
                        thousandSeparator=","
                        className='input-price'
                        thousandsGroupStyle="thousand"
                        value={portfoliosWithTreasury?.value}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__content-label'>
            <Input
              value={changesGeneralSummary?.find(item => item?.idConcept === treasuryPortfoliosData?.id)?.description || ""}
              placeholder='Descripción'
              className='credits-or-we-owe-inventory__table__input'
              prefix={<img src={Assets.SharedIcons.icon_add} alt="icon_add" />}
              onChange={(e) => onChanges(e,"description","treasuryPortfoliosData")}
            />
            <div className='grid-x credits-or-we-owe-inventory__table__input-price-enabled'>
              <NumericFormat
                value={changesGeneralSummary?.find(item => item?.idConcept === treasuryPortfoliosData?.id)?.value || ""}
                type='text'
                prefix={"$ "}
                placeholder='$ 0'
                thousandSeparator=","
                className='input-price'
                thousandsGroupStyle="thousand"
                onChange={(e) => onChanges(e,"value","treasuryPortfoliosData")}
              />
              <div className='credits-or-we-owe-inventory__table__input-price-enabled__icon'>
                <img
                  alt="icon_add"
                  src={Assets.SharedIcons.icon_add}
                />
              </div>
            </div>
          </div>
          <div className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__total__content'>
            <div className='grid-x align-middle justify-content-end credits-or-we-owe-inventory__table__total__text__content'>
              <span className='credits-or-we-owe-inventory__table__total__text'>Total</span>
            </div>
            <div className='credits-or-we-owe-inventory__table__total__content-value'>
              <NumericFormat
                disabled
                type='text'
                prefix={"$ "}
                placeholder='$ 0'
                thousandSeparator=","
                className='input-price'
                thousandsGroupStyle="thousand"
                value={treasuryPortfoliosData?.total || 0}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='credits-or-we-owe-inventory__table'>
        <div className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__title__content'>
          <span className='credits-or-we-owe-inventory__table__title'>Debemos a</span>
          <button
            onClick={() => setState({ ...state, isCollapsedOwesTo: !isCollapsedOwesTo })}
          >
            <img
              alt='icon_blue_arrow_up'
              className={`credits-or-we-owe-inventory__table__icon ${isCollapsedOwesTo ? 'rotate' : ''}`}
              src={Assets.SharedIcons.icon_blue_arrow_up}
            />
          </button>
        </div>
        <div className='credits-or-we-owe-inventory__table__box'>
          <div className={`credits-or-we-owe-inventory__table__content-info-scroll--mod ${isCollapsedOwesTo ? 'collapsed' : ''}`}>
            <div className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__subtitle__content'>
              <span className='credits-or-we-owe-inventory__table__subtitle'>Concepto</span>
              <span className='credits-or-we-owe-inventory__table__subtitle'>Valor</span>
            </div>
            <div ref={formRef} className='credits-or-we-owe-inventory__table__content-info-scroll--mod'>
              {listOfWeOwe?.map((weOwe, index) => {
                return (
                  <div key={index} className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__content-label'>
                    <Input
                      value={weOwe?.description}
                      disabled={weOwe.disabled}
                      placeholder='Descripción'
                      className='credits-or-we-owe-inventory__table__input__concept'
                      onChange={(e) => handleChange(e, index, "description")}
                      prefix={
                        <img
                          alt={weOwe.disabled ? 'icon_add' : 'icon_edit'}
                          src={weOwe.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                        />
                      }
                    />
                    <div className='grid-x credits-or-we-owe-inventory__table__input__concept-input-price'>
                      <NumericFormat
                        type='text'
                        prefix={"$ "}
                        placeholder='$ 0'
                        value={weOwe?.value}
                        thousandSeparator=","
                        className='input-price'
                        disabled={weOwe.disabled}
                        thousandsGroupStyle="thousand"
                        onChange={(e) => handleChange(e, index, 'value')}
                      />
                      <div className='credits-or-we-owe-inventory__table__input-price-enabled__icon'>
                        <img
                          alt={weOwe.disabled ? "icon_add" : "icon_edit"}
                          src={weOwe.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__total__content--mod'>
            <div className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__total__text__content'>
              <button
                className='grid-x align-middle'
                onClick={() => addWeOwe()}
              >
                <img
                  alt='icon_income_blue'
                  src={Assets.SharedIcons.icon_income_blue}
                  className='credits-or-we-owe-inventory__table__total__icon'
                />
                <span className='credits-or-we-owe-inventory__table__total__text--mod'>Agregar concepto</span>
              </button>
              <span className='credits-or-we-owe-inventory__table__total__text'>Total</span>
            </div>
            <div className='credits-or-we-owe-inventory__table__total__content-value'>
              <NumericFormat
                disabled
                type='text'
                prefix={"$ "}
                placeholder='$ 0'
                thousandSeparator=","
                className='input-price'
                thousandsGroupStyle="thousand"
                value={weMustData?.total || 0}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='credits-or-we-owe-inventory__table'>
        <div className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__title__content'>
          <span className='credits-or-we-owe-inventory__table__title'>Provisiones</span>
          <button onClick={() => setState({ ...state, isCollapsedProvisions: !isCollapsedProvisions })}>
            <img
              alt='icon_blue_arrow_up'
              className={`credits-or-we-owe-inventory__table__icon ${isCollapsedProvisions ? 'rotate' : ''}`}
              src={Assets.SharedIcons.icon_blue_arrow_up}
            />
          </button>
        </div>
        <div className='credits-or-we-owe-inventory__table__box'>
          <div className={`credits-or-we-owe-inventory__table__content-info-scroll--mod ${isCollapsedProvisions ? 'collapsed' : ''}`}>
            <div className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__subtitle__content'>
              <span className='credits-or-we-owe-inventory__table__subtitle'>Concepto</span>
              <span className='credits-or-we-owe-inventory__table__subtitle'>Valor</span>
            </div>
            <div className='credits-or-we-owe-inventory__table__content-info-scroll--mod'>
              {provisionsData?.data?.map((provision, index) => {
                return (
                  <div key={index} className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__content-label'>
                    <Input
                      disabled
                      prefix={<></>}
                      value={provision?.description}
                      className='credits-or-we-owe-inventory__table__input'
                    />
                    <div className='grid-x credits-or-we-owe-inventory__table__input-price-enabled'>
                      <NumericFormat
                        type='text'
                        prefix={"$ "}
                        placeholder='$ 0'
                        thousandSeparator=","
                        className='input-price'
                        thousandsGroupStyle="thousand"
                        value={provision?.value || 0}
                        onChange={(e) => onChanges(e, 'value', "provisionsData", provision)}
                      />
                      <div className='credits-or-we-owe-inventory__table__input-price-enabled__icon'>
                        <img
                          alt="icon_add"
                          src={Assets.SharedIcons.icon_add}
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__total__content'>
            <div className='grid-x align-middle justify-content-end credits-or-we-owe-inventory__table__total__text__content'>
              <span className='credits-or-we-owe-inventory__table__total__text'>Total</span>
            </div>
            <div className='credits-or-we-owe-inventory__table__total__content-value'>
              <NumericFormat
                disabled
                type='text'
                prefix={"$ "}
                placeholder='$ 0'
                thousandSeparator=","
                className='input-price'
                thousandsGroupStyle="thousand"
                value={provisionsData?.total || 0}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='credits-or-we-owe-inventory__table'>
        <div className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__title__content'>
          <span className='credits-or-we-owe-inventory__table__title'>Créditos</span>
          <button
            onClick={() => setState({ ...state, isCollapsedCredits: !isCollapsedCredits })}
          >
          <img
            alt='icon_blue_arrow_up'
            className={`credits-or-we-owe-inventory__table__icon ${isCollapsedCredits ? 'rotate' : ''}`}
            src={Assets.SharedIcons.icon_blue_arrow_up}
          />
          </button>
        </div>
        <div className='credits-or-we-owe-inventory__table__box'>
          <div className={`credits-or-we-owe-inventory__table__container-primary ${isCollapsedCredits ? 'collapsed' : ''}`}>
            <div className="grid-x credits-or-we-owe-inventory__table__subtitle__content">
              <div className='grid-x align-middle credits-or-we-owe-inventory__table__container-subtitle__first'>
                <span className='credits-or-we-owe-inventory__table__subtitle'>Fecha</span>
              </div>
              <div className='grid-x align-middle credits-or-we-owe-inventory__table__container-subtitle__second'>
                <span className='credits-or-we-owe-inventory__table__subtitle'>Nombre del autorizado</span>
              </div>
              <div className='grid-x align-middle credits-or-we-owe-inventory__table__container-subtitle__third'>
                <span className='credits-or-we-owe-inventory__table__subtitle'>N° de factura</span>
              </div>
              <div className='grid-x align-middle justify-content-end credits-or-we-owe-inventory__table__container-subtitle__first'>
                <span className='credits-or-we-owe-inventory__table__subtitle'>Valor</span>
              </div>
            </div>
            <div className='credits-or-we-owe-inventory__table__content-info-scroll--mod'>
              {totalCredits?.providerList?.map((Credits, index) => {
                return (
                  <div key={index} className='grid-x align-middle credits-or-we-owe-inventory__table__content-label'>
                    <div className='credits-or-we-owe-inventory__table__picker__content'>
                      <DatePicker
                        disabled
                        suffixIcon={null}
                        format="YYYY/MM/DD"
                        placeholder="AAAA/MM/DD"
                        className='credits-or-we-owe-inventory__table__picker'
                        value={Credits.date ? dayjs(Credits.date, "YYYY-MM-DD") : null}
                      />
                    </div>
                    <div className='credits-or-we-owe-inventory__table__input-credits'>
                      <Input
                        disabled
                        prefix={<></>}
                        placeholder='Descripción'
                        value={Credits?.name}
                        className='credits-or-we-owe-inventory__table__input-credits__input'
                      />
                    </div>
                    <div className='credits-or-we-owe-inventory__table__input-credits'>
                      <Input
                        disabled
                        prefix={<></>}
                        placeholder='0000'
                        className='credits-or-we-owe-inventory__table__input-credits__input'
                      />
                    </div>
                    <div className='credits-or-we-owe-inventory__table__input-price-disabled--mod'>
                      <NumericFormat
                        disabled
                        type='text'
                        prefix={"$ "}
                        placeholder='$ 0'
                        thousandSeparator=","
                        className='input-price'
                        thousandsGroupStyle="thousand"
                        value={Credits.credit - Credits?.payment}
                        onChange={(e) => onChanges(e, 'value', "provisionsData", Credits)}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__total__content'>
            <div className='grid-x align-middle justify-content-end credits-or-we-owe-inventory__table__total__text__content'>
              <span className='credits-or-we-owe-inventory__table__total__text'>Total</span>
            </div>
            <div className='credits-or-we-owe-inventory__table__total__content-value'>
              <NumericFormat
                disabled
                type='text'
                prefix={"$ "}
                placeholder='$ 0'
                thousandSeparator=","
                className='input-price'
                value={totalAmount}
                thousandsGroupStyle="thousand"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ HeadquartersReducer }) => {

  const { generalSummary, changesGeneralSummary, totalCredits } = HeadquartersReducer;

  const dataTables = generalSummary?.dataTables;
  const orderTypes = generalSummary?.orderTypes;

  let treasuryPortfoliosData = {
    data: [],
    total: 0
  }

  if (generalSummary?.portfoliosList) {
    treasuryPortfoliosData.data = [generalSummary?.portfoliosList]
  }

  let weMustData = {
    total: 0,
    data: [
      { description: '', value: "", disabled: true },
      { description: '', value: "", disabled: true },
      { description: '', value: "", disabled: true },
      { description: '', value: "", disabled: true }
    ]
  }

  let provisionsData = {
    data: [],
    total: 0
  }

  if (orderTypes && dataTables &&  orderTypes?.length) {
    const idFilterTreasuryPortfolios = orderTypes[2]
    const idFilterWeMust = orderTypes[3]
    const idFilterProvisions = orderTypes[4]

    treasuryPortfoliosData.id = idFilterTreasuryPortfolios
    provisionsData.id = idFilterProvisions
    weMustData.id = idFilterWeMust

    const treasuryPortfolios = dataTables[idFilterTreasuryPortfolios]
    const weMust = dataTables[idFilterWeMust]
    const provisions = dataTables[idFilterProvisions]

    if(treasuryPortfolios){
      treasuryPortfoliosData = {
        ...treasuryPortfoliosData,
        ...treasuryPortfolios,
        data: [
          ...treasuryPortfoliosData?.data,
          ...treasuryPortfolios?.data
        ]
      }
    }

    if(weMust){
      weMustData = {
        ...weMustData,
        ...weMust
      }
    }

    if(provisions){
      provisionsData = {
        ...provisionsData,
        ...provisions
      }
    }

  }

  if (treasuryPortfoliosData?.data?.length > 0) {
    let total = 0
    treasuryPortfoliosData.data.forEach((item) => { total += item.value})
    treasuryPortfoliosData.total = total
  }

  return {
    provisionsData,
    treasuryPortfoliosData,
    weMustData,
    changesGeneralSummary,
    generalSummary,
    totalCredits
  };
};

const mapStateToPropsActions = {
  setStateHeadquartersReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(CreditsOrWeOweInventoryComponent);