//Assets
import { Assets } from '../../../assets'

//Libraries
import React from 'react'
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

//Styles
import "./week-selector.component.scss"

const WeekSelectorComponent = (props) => {

    const { 
        handleDate = ()=> null,
        previousDate = ()=> null,
        nextDate = ()=> null,
        previousWeek = ()=> null,
        nextWeek = ()=> null,
        selectDay = ()=> null,
        daySelected = dayjs(),
        dateSelected = dayjs(),
        typeDate = 'mes',
        disabledDate = dayjs()
    } = props

    const showDate = Array.from({ length: 7 }, (_, index) =>
        dateSelected.startOf('week').add(index, 'day')
    );

  return (
    <div className='grid-x week-selector__container'>
        <div className='grid-y justify-content-end week-selector__container-date'>
            <span className='week-selector__text'>Seleccionar {typeDate}:</span>
            <div className='week-selector__date align-middle grid-x justify-content-between'>
                <button
                    onClick={() => previousDate()}
                >
                    <img
                        className='week-selector__arrow'
                        src={Assets.SharedIcons.icon_arrow}
                        alt='icon_arrow'
                    />
                </button>
                <DatePicker
                    onChange={(date) => handleDate(date)}
                    value={dateSelected}
                    defaultValue={disabledDate}
                    picker={typeDate === 'mes' ? 'month' : 'year' }
                    suffixIcon={null}
                    className='week-selector__month grid-x align-center-middle'
                    allowClear={false}
                    bordered={false}
                    format={(value) => {
                        const formattedDate = typeDate === 'mes' ? 'MMMM,' : ''
                        return `${dayjs(value)?.format(`${formattedDate} YYYY`)}`;
                    }}
                    inputReadOnly={true}
                    popupClassName='week-selector__month__popup'
                    disabledDate={(current)=> current.isAfter(disabledDate)}
                />
                <button
                    onClick={() => nextDate()}
                    disabled={dayjs(dateSelected).isSame(dayjs(), typeDate === 'mes' ? 'month' : 'year')}
                >
                    <img
                        src={Assets.SharedIcons.icon_arrow}
                        alt='icon_arrow'
                    />
                </button>
            </div>
        </div>
        <div className='grid-x week-selector__week align-center'>
            <div className='week-selector__week__select grid-x'>
                <button
                    onClick={() => previousWeek()}
                >
                    <img
                        className='week-selector__arrow'
                        src={Assets.SharedIcons.icon_arrow}
                        alt='icon_arrow'
                    />
                </button>
                <DatePicker 
                    onChange={(date)=> handleDate(date)}
                    value={dateSelected}
                    defaultValue={dayjs()}
                    picker="week"
                    suffixIcon={null}
                    className='week-selector__month grid-x align-center-middle'
                    allowClear={false}
                    bordered={false}
                    format={(value)=>{
                        return `${dayjs(value)
                        ?.startOf("week")
                        ?.format("dddd D,MMMM")} - ${dayjs(value)
                        ?.endOf("week")
                        ?.format("dddd D,MMMM")}`;
                    }}
                    inputReadOnly={true}
                    popupClassName='week-selector__month__popup'
                    disabledDate={(current)=> current.isAfter(disabledDate)}
                />
                <button
                    onClick={() => nextWeek()}
                    disabled={(dayjs(dateSelected).add(1, 'week').isAfter(disabledDate, 'week'))}
                >
                    <img
                        src={Assets.SharedIcons.icon_arrow}
                        alt='icon_arrow'
                    />
                </button>
            </div>
            <div className='grid-x small-12 justify-content-around'>
            {
                showDate.map((item, index) => {
                    return (
                        <button
                            className={daySelected.isSame(item, 'day') ? 'week-selector__button__selected' : 'week-selector__button'}
                            key={index}
                            onClick={() => selectDay(item)}
                            disabled={dayjs(item).isAfter(disabledDate, 'day')}
                        >
                            {item?.format('dddd D')}
                        </button>
                    )
                })
            }
            </div>
        </div>
    </div>
  )
}

export default WeekSelectorComponent