// Actions
import { setStateAuthorizedManagementReducer } from "../../../storage/reducers/authorized-management/authorized-management.actions";

// Assets
import { Assets } from "../../../assets";

// Components - shared
import ErrorAlertComponent from "../../../shared/components/alerts/error-alert/error-alert.component";

// Components
import PreviewAuthorizedComponent from "../components/preview-authorized/preview-authorized.component";

// Libraries
import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

//Services
import { getAuthorizedManagementByIdService } from "../../../services/authorized-management.services";

// Styles
import "./authorization-history.page.scss"

const AuthorizationHistoryPage = (props) => {
    const { state: routeState } = useLocation();
    const history = useNavigate();
    const  {
      // Actions
      setStateAuthorizedManagementReducer,
      // Variables
      userAuthorizedSelected
    } = props;

    const handleEdith = () => {
      history(`/authorizedManagement/createEdit`,{ state: { type: 2  } });
    }

    useEffect(() => {
      !userAuthorizedSelected && getDataAuthorizedManagement()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const getDataAuthorizedManagement = async() => {
      try {
        const getAuthorizedManagement = await getAuthorizedManagementByIdService(routeState?.idAuthorized)
        setStateAuthorizedManagementReducer("userAuthorizedSelected", getAuthorizedManagement)
      } catch (error) {
        ErrorAlertComponent()
      }
    }
    
  return (
    <div className="authorization-history__container">
      <div className="grid-x align-middle">
        <Link
          replace={true}
          to="/authorizedManagement"
          className="authorization-history__title"
        >
          Fiados
        </Link>
        <img
          alt="icon_arrow"
          src={Assets.SharedIcons.icon_arrow}
          className="authorization-history__icon-arrow"
        />
        <span className="authorization-history__subtitle">{userAuthorizedSelected?.name}</span>
      </div>
      <div className="grid-x authorization-history__box">
        <div className="small-4">
          <PreviewAuthorizedComponent handleEdith={() => handleEdith()}/>
        </div>
        <div className="small-8">
          <div className="authorization-history__loans">
            <div className="grid-y">
              <span className="authorization-history__loans__title">Historial de prestamos</span>
              <span className="authorization-history__loans__text">Histórico de prestamos y abonos del usuario</span>
            </div>
            <div className="grid-x authorization-history__loans__table">
              <label className="small-3 authorization-history__loans__title authorization-history__loans__title--subtitle">Número de identidad</label>
              <label className="small-3 authorization-history__loans__title authorization-history__loans__title--subtitle">Valor de préstamo</label>
              <label className="small-3 authorization-history__loans__title authorization-history__loans__title--subtitle">Pagado a la fecha</label>
              <label className="small-3 authorization-history__loans__title authorization-history__loans__title--subtitle">Deuda a la fecha</label>
            </div>
            <div className="authorization-history__registration__container">
              { userAuthorizedSelected?.lendingHistory?.map((item,index)=>{
                const debtDate = (item?.totalPriceCashExpenseTrust - item?.totalPriceCashReceiptPaymentOfBondsmen)
                const isLastChild = index === userAuthorizedSelected.lendingHistory.length - 1;
                return (
                  <div key={index} className={`grid-x authorization-history__registration align-middle ${isLastChild ? "authorization-history__registration--mod" : ""}`}>
                    <div className="grid-x small-3">
                      <img src={Assets.SharedIcons.icon_document} alt="icon_document" className="authorization-history__total__icon authorization-history__total__icon--mod"/>
                      <label className="authorization-history__loans__text">{item?.concept}</label>
                    </div>
                    <label 
                      className="small-3 authorization-history__loans__title authorization-history__loans__title--subtitle authorization-history__loans__title--red"
                    >
                      ${(item?.totalPriceCashExpenseTrust)?.toLocaleString()}
                    </label>
                    <label 
                      className="small-3 authorization-history__loans__title authorization-history__loans__title--subtitle authorization-history__loans__title--green"
                    >
                      ${(item?.totalPriceCashReceiptPaymentOfBondsmen)?.toLocaleString()}
                    </label>
                    <label 
                      className="small-3 authorization-history__loans__title authorization-history__loans__title--subtitle"
                    >
                      ${debtDate?.toLocaleString()}
                    </label>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="grid-x justify-content-between authorization-history__total__content">
            <div className="grid-x justify-content-between align-middle authorization-history__total">
              <label className="authorization-history__total__text">Saldo permitido</label>
              <div className="grid-x align-middle authorization-history__total__box justify-content-end">
                <span className="authorization-history__total__text">${userAuthorizedSelected?.authorizedCash?.toLocaleString()}</span>
              </div>
            </div>
            <div className="grid-x justify-content-between align-middle authorization-history__total">
              <label className="authorization-history__total__text">Deuda actual</label>
              <div className="grid-x justify-content-between authorization-history__total__box align-middle authorization-history__total__box--mod">
                <img
                  alt="icon_egress"
                  src={Assets.SharedIcons.icon_egress}
                  className="authorization-history__total__icon"
                />
                <span className="authorization-history__total__text authorization-history__total__text--red">${(routeState?.currentDebtAuthorized)?.toLocaleString()}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToPropsActions = {
  setStateAuthorizedManagementReducer
};

const mapStateToProps = ({ AuthorizedManagementReducer }) => {
  const { userAuthorizedSelected } = AuthorizedManagementReducer;
  return { userAuthorizedSelected };
};

export default connect(mapStateToProps, mapStateToPropsActions)(AuthorizationHistoryPage);