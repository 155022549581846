//Actions
import { setItemCashClosingWholesaleReducer, setStateCashClosingWholesaleReducer } from '../../../../storage/reducers/cash-closing-wholesale/cash-closing-wholesale.actions';

//Assets
import { Assets } from '../../../../assets'

//Components - Shared
import ErrorAlertComponent from '../../../../shared/components/alerts/error-alert/error-alert.component';

//Libraries
import { NumericFormat } from 'react-number-format';
import React, { useEffect, useRef, useState } from 'react';
import { Input, Select } from 'antd'
import { connect } from 'react-redux';

//Services
import { deleteLiquidationService } from '../../../../services/cash-closing-wholesale.services';

//Styles
import './liquidation.component.scss'

//Utils
import { spreadsheets } from '../../../../utils/cash-closing-wholesale.utils';

const LiquidationComponent = (props) => {

  const { 
    //Actions
    setItemCashClosingWholesaleReducer,
    setStateCashClosingWholesaleReducer,
    //Variables
    listTable, driversList 
  } = props
  
  const INITIAL_STATE = {
    scrollEnd: false,
    liquidation: spreadsheets
  };

  const [state, setState] = useState(INITIAL_STATE)

  const { liquidation, scrollEnd } = state

  const formRef = useRef(null);

  useEffect(() => {
    if (formRef.current && scrollEnd) {
      const miDiv = formRef.current;
      miDiv.scrollTop = miDiv.scrollHeight;
    }
  }, [scrollEnd, liquidation?.length]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      liquidation: listTable ? listTable : spreadsheets
    }))
  }, [listTable])

  const onChanges = (e, index, name) => {
    let value = e.target ? e.target.value || '' : e;
    let liquidationModified = liquidation
    if (name !== "codeLiquidation" && name !== "driverLiquidationTemplate") {
      value = parseInt((value || "0")?.replace(/[^0-9]/g, ''),10)
    }
    
    liquidationModified[index][name] = value;

    setState((prevState)=>({
      ...prevState,
      liquidation: liquidationModified,
    }))

    setItemCashClosingWholesaleReducer("liquidationList",index, liquidationModified[index]);
  }

  const deleteExpenses = async (index) => {
    try {
      let liquidationModified = JSON.parse(JSON.stringify(liquidation));

      const initialItem = {
        banknote: 0,
        codeLiquidation: 0,
        codeTemplate: "",
        coin: 0,
        credit: 0,
        creditNote: 0,
        driverLiquidationTemplate: { label: '', value: '' },
        expense: 0,
        idTypeLiquidation: "",
        personLiquidationTemplate: { label: '', value: '' },
        priceTemplate: 0,
        transfer: 0,
        disabled: true
      };

      if (liquidationModified[index].id) {
        await deleteLiquidationService({ liquidationTemplate: [liquidationModified[index]?.id] })
      };

      liquidationModified[index] = initialItem;
      const removedItem = liquidationModified.splice(index, 1)[0];
      liquidationModified.push(removedItem);

      setState((prevState) => ({
        ...prevState,
        liquidation: liquidationModified
      }));

      setStateCashClosingWholesaleReducer({ liquidationList: liquidationModified, isDeletingRecords: true });

    } catch (error) {
      ErrorAlertComponent()
    }
  };

  const sumPrices = (list,name) => {
    return (list || []).reduce((accumulator, product) => {
      const price = !isNaN(Number(product[name])) ? Number(product[name]) : Number(product[name]?.replace(/[^0-9]/g, ''));
      return accumulator + (isNaN(price) ? 0 : price);
    }, 0);
  };

  return (
    <div>
      <div className='grid-x'>
        <div className='grid-x small-12 justify-content-between align-items-end'>
          <span className='small-2 liquidation__subtitle'> Nombre del Conductor </span>
          <span className='small-1 liquidation__subtitle'> Nro. Planilla </span>
          <span className='small-1 liquidation__subtitle'> Nro. Liquidación </span>
          <span className='small-1 text-end liquidation__subtitle'> Valor de Venta </span>
          <span className='small-1 text-end liquidation__subtitle'> Billetes </span>
          <span className='small-1 text-end liquidation__subtitle'> Monedas </span>
          <span className='small-1 text-end liquidation__subtitle'> Créditos </span>
          <span className='small-1 text-end liquidation__subtitle'> Consignación </span>
          <span className='small-2 text-center liquidation__subtitle'> Sobrante </span>
        </div>
      </div>
      <div className='liquidation__summary__content'>
        {liquidation?.map((item, index) => {
          item.salesValue = item?.banknote + item?.coin  + item?.credit + item?.transfer + item?.expense
          let isDisabledItem = item.id && !item.disabled ? false : true

          return (
            <div key={index} className='grid-x liquidation__payments__container'>
              <div className='grid-x small-12 justify-content-between'>
                <div className='small-2'>
                  <Select
                    onChange={(e, event) => onChanges(event, index, "driverLiquidationTemplate")}
                    placeholder='Selección'
                    optionLabelProp='label'
                    popupMatchSelectWidth={false}
                    options={driversList || []}
                    disabled={isDisabledItem}
                    className='liquidation__payments__select'
                    popupClassName='liquidation__payments__select__popup'
                    value={item?.driverLiquidationTemplate?.value ? item?.driverLiquidationTemplate : undefined}
                    suffixIcon={
                      <div className='liquidation__payments__select__icon'>
                        <img
                          alt={isDisabledItem ? "icon_disabled_arrow" : "icon_blue_arrow"}
                          src={isDisabledItem ? Assets.SharedIcons.icon_disabled_arrow : Assets.SharedIcons.icon_blue_arrow}
                        />
                      </div>
                    }
                  />
                </div>
                <div className='small-1'>
                  <Input
                    disabled
                    value={ !isDisabledItem ? item?.codeTemplate : ""}
                    placeholder='N° Planilla'
                    className='liquidation__payments__input liquidation__payments__input--mod'
                    prefix={<></>}
                  />
                </div>
                <div className='small-1'>
                  <Input
                    onChange={(e) => onChanges(e, index, "codeLiquidation")}
                    disabled={isDisabledItem}
                    value={item?.codeLiquidation}
                    placeholder='Descripción'
                    className='liquidation__payments__input'
                    prefix={<img src={isDisabledItem ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit} alt="icon_add" />}
                  />
                </div>
                <div className='grid-x small-1'>
                  <div className='grid-x small-12 liquidation__payments__content-liquidation liquidation__payments__content-liquidation--mod'>
                    <NumericFormat
                      type='text'
                      prefix={"$ "}
                      placeholder='$ 0'
                      value={item?.salesValue}
                      thousandSeparator=","
                      className='input-price'
                      disabled
                      thousandsGroupStyle="thousand"
                    />
                  </div>
                </div>
                <div className='grid-x small-1'>
                  <div className='grid-x small-12 liquidation__payments__content-liquidation'>
                    <NumericFormat
                      onChange={(e) => onChanges(e, index, "banknote")}
                      type='text'
                      prefix={"$ "}
                      placeholder='$ 0'
                      value={item?.banknote || ""}
                      thousandSeparator=","
                      className='input-price'
                      disabled={isDisabledItem}
                      thousandsGroupStyle="thousand"
                    />
                    <div className='liquidation__payments__content-icon-price'>
                      <img
                        className='liquidation__payments__icon'
                        alt={isDisabledItem ? "icon_add" : "icon_edit"}
                        src={isDisabledItem ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                      />
                    </div>
                  </div>
                </div>
                <div className='grid-x small-1'>
                  <div className='grid-x small-12 liquidation__payments__content-liquidation'>
                    <NumericFormat
                      onChange={(e) => onChanges(e, index, "coin")}
                      type='text'
                      prefix={"$ "}
                      placeholder='$ 0'
                      value={item?.coin || ""}
                      thousandSeparator=","
                      className='input-price'
                      disabled={isDisabledItem}
                      thousandsGroupStyle="thousand"
                    />
                    <div className='liquidation__payments__content-icon-price'>
                      <img
                        className='liquidation__payments__icon'
                        alt={isDisabledItem ? "icon_add" : "icon_edit"}
                        src={isDisabledItem ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                      />
                    </div>
                  </div>
                </div>
                <div className='grid-x small-1'>
                  <div className='grid-x small-12 liquidation__payments__content-liquidation liquidation__payments__content-liquidation--mod'>
                    <NumericFormat
                      type='text'
                      prefix={"$ "}
                      placeholder='$ 0'
                      value={item?.credit}
                      thousandSeparator=","
                      className='input-price'
                      disabled
                      thousandsGroupStyle="thousand"
                    />
                  </div>
                </div>
                <div className='grid-x small-1'>
                  <div className='grid-x small-12 liquidation__payments__content-liquidation liquidation__payments__content-liquidation--mod'>
                    <NumericFormat
                      type='text'
                      prefix={"$ "}
                      placeholder='$ 0'
                      value={item?.transfer}
                      thousandSeparator=","
                      className='input-price'
                      disabled
                      thousandsGroupStyle="thousand"
                    />
                  </div>
                </div>
                <div className='grid-x small-2'>
                  <div className='grid-x small-10 liquidation__payments__content-liquidation liquidation__payments__content-liquidation--mod'>
                    <NumericFormat
                      type='text'
                      prefix={"$ "}
                      placeholder='$ 0'
                      value={item?.expense}
                      thousandSeparator=","
                      className='input-price'
                      disabled
                      thousandsGroupStyle="thousand"
                    />
                  </div>
                  <div className='grid-x small-2 justify-content-end'>
                    <button
                      className='liquidation__button-income__button-delete'
                      disabled={isDisabledItem}
                      onClick={() => deleteExpenses(index)}
                    >
                      <img
                        className='liquidation__button-income__button-delete__icon'
                        alt={isDisabledItem ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                        src={isDisabledItem ? Assets.SharedIcons.icon_deleted_disabled : Assets.SharedIcons.icon_deleted_active}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className='grid-x small-12 justify-content-between'>
        <div className='liquidation__summary__line liquidation__summary__line--mod'></div>
        <div className='grid-x small-4'>
          <span className='grid-x align-middle small-11 justify-content-end liquidation__subtitle'>
            Totalizadores
          </span>
        </div>
        <div className='grid-x small-1'>
          <div className='grid-x small-12 liquidation__payments__content-input-price'>
            <NumericFormat
              type='text'
              prefix={"$ "}
              placeholder='$ 0'
              value={sumPrices(liquidation,"salesValue")}
              thousandSeparator=","
              className='input-price'
              thousandsGroupStyle="thousand"
            />
          </div>
        </div>
        <div className='grid-x small-1'>
          <div className='grid-x small-12 liquidation__payments__content-input-price'>
            <NumericFormat
              type='text'
              prefix={"$ "}
              placeholder='$ 0'
              value={sumPrices(liquidation,"banknote")}
              thousandSeparator=","
              className='input-price'
              thousandsGroupStyle="thousand"
            />
          </div>
        </div>
        <div className='grid-x small-1'>
          <div className='grid-x small-12 liquidation__payments__content-input-price'>
            <NumericFormat
              type='text'
              prefix={"$ "}
              placeholder='$ 0'
              value={sumPrices(liquidation,"coin")}
              thousandSeparator=","
              className='input-price'
              thousandsGroupStyle="thousand"
            />
          </div>
        </div>
        <div className='grid-x small-1'>
          <div className='grid-x small-12 liquidation__payments__content-input-price'>
            <NumericFormat
              type='text'
              prefix={"$ "}
              placeholder='$ 0'
              value={sumPrices(liquidation,"credit")}
              thousandSeparator=","
              className='input-price'
              thousandsGroupStyle="thousand"
            />
          </div>
        </div>
        <div className='grid-x small-1'>
          <div className='grid-x small-12 liquidation__payments__content-input-price'>
            <NumericFormat
              type='text'
              prefix={"$ "}
              placeholder='$ 0'
              value={sumPrices(liquidation,"transfer")}
              thousandSeparator=","
              className='input-price'
              thousandsGroupStyle="thousand"
            />
          </div>
        </div>
        <div className='grid-x small-2'>
          <div className='grid-x small-10 liquidation__payments__content-input-price'>
            <NumericFormat
              type='text'
              prefix={"$ "}
              placeholder='$ 0'
              value={sumPrices(liquidation,"expense")}
              thousandSeparator=","
              className='input-price'
              thousandsGroupStyle="thousand"
            />
          </div>
        </div>
      </div>
      <div className='liquidation__summary__line liquidation__summary__line--mod'></div>
    </div>
  )
}

const mapStateToPropsActions = {
  setItemCashClosingWholesaleReducer,
  setStateCashClosingWholesaleReducer
};

export default connect(null, mapStateToPropsActions)(LiquidationComponent);
