// Actions
import { setStateGeneralAdjustmentReducer } from '../../../../../storage/reducers/headquarters/headquarters.actions';

// Assets
import { Assets } from '../../../../../assets';

// Libraries
import { Input } from 'antd';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format';

// Styles
import "./retention.component.scss";

// Utils
import { formattedNumberInteger } from '../../../../../utils/shared.utils';

const RetentionComponent = (props) => {

  const {
    // Actions
    setStateGeneralAdjustmentReducer,
    // Variables
    generalAdjustment
  } = props;

  const INITIAL_STATE = {
    infoRetention: {
      total: 0,
      listOfItems: [],
      title: 'Retención',
    }
  };

  const [state, setState] = useState(INITIAL_STATE);

  const { infoRetention } = state;

  useEffect(() => {
    setState({
      ...state,
      infoRetention: {
        total: generalAdjustment?.organizeTaxesByType[1]?.total,
        title: generalAdjustment?.organizeTaxesByType[1]?.title,
        listOfItems: generalAdjustment?.organizeTaxesByType[1]?.data,
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalAdjustment?.organizeTaxesByType]);

  const onChangeInput = (data, index, name) => {
    let value = data && data.target ? data.target.value || '' : data;

    if (/^\d*\.?\d*$/.test(value)) {

      const copyRetention = JSON.parse(JSON.stringify(infoRetention?.listOfItems));
      copyRetention[index][name] = value;
      setState({
        ...state,
        infoRetention: {
          ...infoRetention,
          listOfItems: copyRetention
        }
      });
      setStateGeneralAdjustmentReducer('changeGeneralAdjustment', 'retention', copyRetention);

    }
  };

  return (
    <div className="retention__content-tables__table">
      <div className="grid-x align-middle justify-content-between retention__content-tables__content-values">
        <div className="grid-x align-middle">
          <img alt="icon_information" src={Assets.SharedIcons.icon_information} />
          <span className="retention__content-tables__title">
            {infoRetention?.title}
          </span>
        </div>
        <div className="grid-x align-middle">
          <span className="retention__content-tables__title">
            Total:
          </span>
          <div className="grid-x retention__content-tables__values--mod">
            <div className="retention__content-tables__values__content-icon">
              <img
                alt="icon_egress"
                src={Assets.SharedIcons.icon_egress}
                className="retention__content-tables__values__content-icon__icon"
              />
            </div>
            <NumericFormat
              disabled
              type="text"
              prefix={"$ "}
              placeholder="$ 0"
              thousandSeparator=","
              className="input-price"
              value={infoRetention?.total}
              thousandsGroupStyle="thousand"
            />
          </div>
        </div>
      </div>
      <div className="grid-x small-12 retention__content-tables__content-info">
        <div className="grid-x align-content-end retention__content-tables__content-info__content-title">
          <span className="small-2 retention__content-tables__content-info__title">
            Mes
          </span>
          <span className="small-3 retention__content-tables__content-info__title">
            Concepto
          </span>
          <span className="small-3 retention__content-tables__content-info__title">
            Ventas del mes
          </span>
          <span className="small-1 retention__content-tables__content-info__title">
            %
          </span>
          <span className="grid-x justify-content-end small-3 retention__content-tables__content-info__title">
            Valor
          </span>
        </div>
        <div className="retention__content-tables__content-info__content-scroll">
          {infoRetention?.listOfItems.map((item, index) => {
            return (
              <div
                key={index}
                className="grid-x align-middle retention__content-tables__content-info__information-row"
              >
                <div className='small-2 retention__content-tables__content-input'>
                  <Input
                    disabled
                    prefix={null}
                    placeholder="Mes"
                    value={item?.month}
                  />
                </div>
                <div className='small-3 retention__content-tables__content-input'>
                  <Input
                    disabled
                    prefix={null}
                    value={item?.concept}
                    placeholder="Descripción"
                  />
                </div>
                <div className='small-3 retention__content-tables__content-input'>
                  <Input
                    disabled
                    prefix={null}
                    value={formattedNumberInteger(item?.total)}
                    placeholder="Tipo de pago"
                  />
                </div>
                <div className={`small-1 retention__content-tables__content-input${!item?.edit ? "" : "__disabled"}`}>
                  <Input
                    prefix={null}
                    placeholder="%"
                    disabled={!item?.edit}
                    value={item?.taxPorcentage}
                    onChange={(value) => onChangeInput(value, index, "taxPorcentage")}
                  />
                </div>
                <div className='grid-x small-3 justify-content-end retention__content-tables__content-input'>
                  <NumericFormat
                    disabled
                    type="text"
                    prefix={"$ "}
                    placeholder="$ 0"
                    value={item?.tax}
                    thousandSeparator=","
                    className="input-price"
                    thousandsGroupStyle="thousand"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ HeadquartersReducer }) => {
  const { generalAdjustment } = HeadquartersReducer;

  return {
    generalAdjustment
  };
};

const mapStateToPropsActions = {
  setStateGeneralAdjustmentReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(RetentionComponent);