import * as headquartersTypes from './headquarters.types';

export const setStateHeadquartersReducer = (prop, value) => (dispatch) => {
	dispatch({ type: headquartersTypes.SET_STATE_HEADQUARTERS, payload: { prop, value } });
};

export const setStateHeadquartersGenerallyReducer = (value) => (dispatch) => {
	dispatch({ type: headquartersTypes.SET_STATE_GENERALLY, payload: { value } });
};

export const setStateGeneralAdjustmentReducer = (prop, key, value) => (dispatch) => {
	dispatch({ type: headquartersTypes.SET_STATE_GENERAL_ADJUSTMENT, payload: { prop, key, value } });
};