// Assets
import { Assets } from '../../../../assets'

// Libraries
import React, { useState } from 'react'

// Styles
import "./transfer-valuation.component.scss"

const TransferValuationComponent = (props) => {

    const { dataTransfers } = props

    const INITIAL_STATE = {
        isOrder: false,
        listProducts: dataTransfers?.transfersOrganized || [],
    }

    const [state, setState] = useState(INITIAL_STATE)
    const { isOrder, listProducts } = state

    const formatterNumber = (number) => {
        if (number % 1 === 0) {
            return Number(number)
        }else{
            return Number(number.toFixed(2))
        }
    }

    const orderListProducts = () => {
        let newOrder = [...listProducts];
        if (isOrder) {
            newOrder = listProducts.sort((a, b) => a.name.localeCompare(b.name))
        } else {
            newOrder = listProducts.sort((a, b) => b.name.localeCompare(a.name))
        }
        setState((prevState) => ({
            ...prevState,
            isOrder: !isOrder,
            listProducts: newOrder
        }));
    }

  return (
    <div className='grid-x'>
        <div>
            <div className='transfer-valuation__box transfer-valuation__totals'>
                <span className='grid-x align-center-middle transfer-valuation__totals__label'>
                {`${dataTransfers.mainHeadquarterTransfer.name} (Centro de distribución)`}
                </span>
                <div className='grid-y transfer-valuation__totals__table'>
                    <div className='grid-x small-6'>
                        <span className='grid-x align-center-middle transfer-valuation__totals__title transfer-valuation__totals__title__right'>
                            Entradas
                        </span>
                        <span className='grid-x align-center-middle transfer-valuation__totals__title transfer-valuation__totals__title__left'>
                            Salidas
                        </span>
                    </div>
                    <div className='grid-x small-6'>
                        <span className='grid-x align-center-middle transfer-valuation__totals__info transfer-valuation__totals__info__right'>
                            ${formatterNumber(dataTransfers?.mainHeadquarterTransfer?.arrival)?.toLocaleString()}
                        </span>
                        <span className='grid-x align-center-middle transfer-valuation__totals__info transfer-valuation__totals__info__left'>
                            ${formatterNumber(dataTransfers?.mainHeadquarterTransfer?.exit)?.toLocaleString()}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div className='transfer-valuation__box transfer-valuation__totals transfer-valuation__list'>
            <div className='transfer-valuation__list__title__content'>
                <img
                    src={Assets.SharedIcons.transfers_selected}
                    alt="transfers_selected"
                />
                <span className='transfer-valuation__list__title'>Valorización de Traslados</span>
            </div>
            <div className='grid-x transfer-valuation__list__content'>
                <div className='grid-x small-6'>
                    <button
                        className='grid-x align-middle'
                        onClick={() => orderListProducts()}
                    >
                        <img
                            className={`${isOrder ? "transfer-valuation__list__filter":""}`}
                            alt="icon_blue_arrow"
                            src={Assets.SharedIcons.icon_blue_arrow}
                        />
                        <span className='transfer-valuation__list__label transfer-valuation__list__label--filter'>
                            Nombre de la sede
                        </span>
                    </button>
                </div>
                <span className='small-3 transfer-valuation__list__label'>Entradas</span>
                <span className='small-3 transfer-valuation__list__label'>Salidas</span>
            </div>
            <div className='transfer-valuation__list__container'>
                { listProducts?.map((item,index)=>{
                    return(
                        <div key={index} className='grid-x  transfer-valuation__list__item'>
                            <span className='small-6 transfer-valuation__list__label transfer-valuation__list__label--name'>{item.name}</span>
                            <span className='small-3 transfer-valuation__list__label'>{formatterNumber(item?.arrival)?.toLocaleString()}</span>
                            <span className='small-3 transfer-valuation__list__label'>{formatterNumber(item?.exit)?.toLocaleString()}</span>
                        </div>
                    )
                })}
            </div>
            <div className='grid-x transfer-valuation__results__content'>
                <span className='small-4 transfer-valuation__results__label'>Totales generales</span>
                <div className='grid-x small-4 justify-content-end'>
                    <span className='grid-x small-10 align-center-middle transfer-valuation__results__number'>
                        ${formatterNumber(dataTransfers?.totalTransfers?.arrival)?.toLocaleString()}
                    </span>
                </div>
                <div className='grid-x small-4 justify-content-end'>
                    <span className='grid-x small-10 align-center-middle transfer-valuation__results__number'>
                        ${formatterNumber(dataTransfers?.totalTransfers?.exit)?.toLocaleString()}
                    </span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TransferValuationComponent