// Assets
import { Assets } from '../../../assets';

// Components - shared
import ErrorAlertComponent from '../../../shared/components/alerts/error-alert/error-alert.component';

// Libraries
import React, { useEffect, useState } from 'react'
import { Checkbox, DatePicker, Input } from 'antd';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

// Services
import { getAllHeadquartersService } from '../../../services/cash-closing-inventory.services';

// Styles
import './cash-closing-inventory.page.scss';

// Utils
import { formattedNumber } from '../../../utils/shared.utils';


const CashClosingInventoryPage = () => {

  const history = useNavigate();

  const INITIAL_STATE = {
    selectedMonth: dayjs(),
    isHoverSort: false,
    isHoverName: false,
    isHoverExpenses: false,
    isHoverLiquor: false,
    isHoverKitchen: false,
    isHoverExecution: false,
    isHoverCompliance: false,
    checkedItems: [] ,
    headquartersList: [],
    headquartersListCopy: [],
    isFilterName: false,
    isFilterExpenses: false,
    isFilterLiquor: false,
    isFilterKitchen: false,
    isFilterExecution: false,
    isFilterCompliance: false,

  }

  const [state, setState] = useState(INITIAL_STATE)
  
  const {
    selectedMonth,
    isHoverName,
    isHoverExpenses,
    isHoverLiquor,
    isHoverKitchen,
    isHoverExecution,
    isHoverCompliance,
    checkedItems,
    headquartersList,
    headquartersListCopy,
    isFilterName,
    isFilterExpenses,
    isFilterLiquor,
    isFilterKitchen,
    isFilterExecution,
    isFilterCompliance
  } = state;

  useEffect(() => {
    getAllHeadquarters();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth])

  const getAllHeadquarters = async() => {
    try {
      const getHeadquarterList = await getAllHeadquartersService(selectedMonth.startOf('month').format('YYYY-MM-DD'))
      setState({
        ...state,
        headquartersList: getHeadquarterList,
        headquartersListCopy: getHeadquarterList
      })
    } catch (error) {
      ErrorAlertComponent()
    }
  }

  const goToMonth = (month) => {
    const newDate = dayjs(selectedMonth).add(month, 'month');
    const currentDate = dayjs();

    if (newDate.isBefore(currentDate, 'month') || newDate.isSame(currentDate, 'month')) {
      setState((prevState) => ({
        ...prevState,
        selectedMonth: newDate
      }))
    }
  };

  const handleCheckboxChange = (index) => {

    const prevItem = checkedItems.includes(index) ? checkedItems.filter(itemIndex => itemIndex !== index) : [...checkedItems, index];

    setState((prevState) => ({
      ...prevState,
      checkedItems: prevItem
    }))
  };

  const isItemChecked = (index) => checkedItems.includes(index);

  const setStateHover = (value) => {
    setState({
      ...state,
      isHoverName: value === 'name' ? true : false,
      isHoverExpenses: value === 'expenses' ? true : false,
      isHoverLiquor: value === 'liquor' ? true : false,
      isHoverKitchen: value === 'kitchen' ? true : false,
      isHoverExecution: value === 'execution' ? true : false,
      isHoverCompliance: value === 'compliance' ? true : false,
    })
  }

  const viewCashClosing = async (idHeadquarter, headquarterName) => {
    const currentDate = dayjs().format('YYYY-MM-DD');
    const selectedOption = dayjs(selectedMonth).format('YYYY-MM-DD');
    const selectedDateInventory = selectedOption === currentDate ? currentDate : dayjs(selectedMonth).endOf('month').format('YYYY-MM-DD')
    history(`/cashClosingInventory/${idHeadquarter}`, { state: { selectedDateInventory, headquarter: { name: headquarterName, id: idHeadquarter} } });
  }

  const onChangeFilter = (data) => {
    let value = data && data.target ? data.target.value || '' : data;
    const filteredList = value.trim() !== ''
      ? headquartersListCopy.filter(headquarter => headquarter.name.toLowerCase().includes(value.toLowerCase()))
      : headquartersListCopy;

    setState({
      ...state,
      isFilterName: false,
      isFilterExpenses: false,
      isFilterLiquor: false,
      isFilterKitchen: false,
      isFilterExecution: false,
      isFilterCompliance: false,
      headquartersList: filteredList
    });
  };

  const optionFilter = (param, optionName) => {
    let sortedHeadQuarters = [...headquartersList];
    switch (param) {
      case 1:
        if (!isFilterName) {
          sortedHeadQuarters.sort((a, b) => b.name.localeCompare(a.name));
        } else {
          sortedHeadQuarters.sort((a, b) => a.name.localeCompare(b.name));
        };
        break;
      case 2:
        if (!isFilterExpenses) {
          sortedHeadQuarters.sort((a, b) => parseInt(b.cashOutflow) - parseInt(a.cashOutflow));
        } else {
          sortedHeadQuarters.sort((a, b) => parseInt(a.cashOutflow) - parseInt(b.cashOutflow));
        };
        break;
      case 3:
        if (!isFilterLiquor) {
          sortedHeadQuarters.sort((a, b) => parseInt(b.liquorSales) - parseInt(a.liquorSales));
        } else {
          sortedHeadQuarters.sort((a, b) => parseInt(a.liquorSales) - parseInt(b.liquorSales));
        };
        break;
      case 4:
        if (!isFilterKitchen) {
          sortedHeadQuarters.sort((a, b) => parseInt(b.kitchenSales) - parseInt(a.kitchenSales));
        } else {
          sortedHeadQuarters.sort((a, b) => parseInt(a.kitchenSales) - parseInt(b.kitchenSales));
        };
        break;
      case 5:
        if (!isFilterExecution) {
          sortedHeadQuarters.sort((a, b) => parseInt(b.percentageExecution) - parseInt(a.percentageExecution));
        } else {
          sortedHeadQuarters.sort((a, b) => parseInt(a.percentageExecution) - parseInt(b.percentageExecution));
        };
        break;
      case 6:
        if (!isFilterCompliance) {
          sortedHeadQuarters.sort((a, b) => parseInt(b.percentageCompliance) - parseInt(a.percentageCompliance));
        } else {
          sortedHeadQuarters.sort((a, b) => parseInt(a.percentageCompliance) - parseInt(b.percentageCompliance));
        };
        break;
      default:

        break;
    };
    setState((prevState) => ({
      ...prevState,
      isFilterName: (optionName === 'name' && !isFilterName) ? true : false,
      isFilterExpenses: (optionName === 'expenses' && !isFilterExpenses) ? true : false,
      isFilterLiquor: (optionName === 'liquor' && !isFilterLiquor) ? true : false,
      isFilterKitchen: (optionName === 'kitchen' && !isFilterKitchen) ? true : false,
      isFilterExecution: (optionName === 'execution' && !isFilterExecution) ? true : false,
      isFilterCompliance: (optionName === 'compliance' && !isFilterCompliance) ? true : false,
      headquartersList: sortedHeadQuarters,
    }));
  };

  return (
    <div className='cash-closing-inventory__container'>
      <div className='grid-x align-content-end cash-closing-inventory__content-search'>
        <div className='grid-x align-middle cash-closing-inventory__container-search'>
          <span className='cash-closing-inventory__title'>Cierre de caja</span>
          <Input
            placeholder='Buscar sede'
            onChange={(value) => onChangeFilter(value)}
            className='cash-closing-inventory__content-search__input'
            prefix={<img src={Assets.SharedIcons.icon_search_magnifier} alt="icon_search_magnifier" />}
          />
        </div>
        <div className='grid-x align-right cash-closing-inventory__content-date-picker'>
          <div className='grid-x align-middle justify-content-between cash-closing-inventory__content-date-picker__date'>
            <button onClick={() => goToMonth(-1)}>
              <img
                alt='icon_arrow'
                src={Assets.SharedIcons.icon_arrow}
                className='cash-closing-inventory__content-date-picker__arrow'
              />
            </button>
            <DatePicker
              picker='month'
              bordered={false}
              suffixIcon={null}
              allowClear={false}
              inputReadOnly={true}
              value={selectedMonth}
              defaultValue={dayjs()}
              disabledDate={(current) => current.isAfter(dayjs(), 'month')}
              onChange={(date) => setState((prevState) => ({...prevState, selectedMonth: date}))}
              className='cash-closing-inventory__content-date-picker__month'
              format={(value) => {
                return `${dayjs(value)?.format('MMMM, YYYY')}`;
              }}
            />
            <button
              onClick={() => goToMonth(1)}
              className='cash-closing-inventory__content-date-picker__button-next'
              disabled={(dayjs(selectedMonth).add(1, 'month').isAfter(dayjs(), 'month'))}
            >
              <img
                alt={(dayjs(selectedMonth).add(1, 'month').isAfter(dayjs(), 'month')) ? 'icon_arrow_picker_disabled' : 'icon_arrow'}
                src={(dayjs(selectedMonth).add(1, 'month').isAfter(dayjs(), 'month')) ? Assets.SharedIcons.icon_arrow_picker_disabled : Assets.SharedIcons.icon_arrow}
              />
            </button>
          </div>
        </div>
      </div>
      <div className='grid-x cash-closing-inventory__container-headquarters'>
        <div className='grid-x small-12 cash-closing-inventory__content-result'>
          <div className='grid-x align-middle cash-closing-inventory__content-result__container-title'>
            <div className='grid-x justify-content-end cash-closing-inventory__content-result__title__content--mod'>
              <button
                onClick={() => optionFilter(1, 'name')}
                onMouseOver={() => setStateHover('name')}
                onMouseOut={() => setState({ ...state, isHoverName: false })}
                className='grid-x align-middle cash-closing-inventory__content-search__select-headquarter__content-selected'
              >
                <img
                  alt={isHoverName ? "icon_blue_arrow_down" : "icon_blue_arrow"}
                  src={isHoverName ? Assets.SharedIcons.icon_blue_arrow_down : Assets.SharedIcons.icon_blue_arrow}
                  className={`cash-closing-inventory__content-search__select-headquarter__icon${isFilterName ? '__active' : ''}`}
                />
                <span className={`cash-closing-inventory__content-search__select-headquarter__text${isHoverName ? '__hover' : ''}`}>
                  Nombre de la sede
                </span>
              </button>
            </div>
            <div className='grid-x cash-closing-inventory__content-result__title__content'>
              <button
                onClick={() => optionFilter(2, 'expenses')}
                onMouseOver={() => setStateHover('expenses')}
                onMouseOut={() => setState({ ...state, isHoverExpenses: false })}
                className='grid-x align-middle cash-closing-inventory__content-search__select-headquarter__content-selected'
              >
                <img
                  alt={isHoverExpenses ? "icon_blue_arrow_down" : "icon_blue_arrow"}
                  src={isHoverExpenses ? Assets.SharedIcons.icon_blue_arrow_down : Assets.SharedIcons.icon_blue_arrow}
                  className={`cash-closing-inventory__content-search__select-headquarter__icon${isFilterExpenses ? '__active' : ''}`}
                />
                <span className={`cash-closing-inventory__content-search__select-headquarter__text${isHoverExpenses ? '__hover' : ''}`}>
                  Total de Gastos
                </span>
              </button>
            </div>
            <div className='grid-x cash-closing-inventory__content-result__title__content'>
              <button
                onClick={() => optionFilter(3, 'liquor')}
                onMouseOver={() => setStateHover('liquor')}
                onMouseOut={() => setState({ ...state, isHoverLiquor: false })}
                className='grid-x align-middle cash-closing-inventory__content-search__select-headquarter__content-selected'
              >
                <img
                  alt={isHoverLiquor ? "icon_blue_arrow_down" : "icon_blue_arrow"}
                  src={isHoverLiquor ? Assets.SharedIcons.icon_blue_arrow_down : Assets.SharedIcons.icon_blue_arrow}
                  className={`cash-closing-inventory__content-search__select-headquarter__icon${isFilterLiquor ? '__active' : ''}`}
                />
                <span className={`cash-closing-inventory__content-search__select-headquarter__text${isHoverLiquor ? '__hover' : ''}`}>
                  Ventas Licorera
                </span>
              </button>
            </div>
            <div className='grid-x cash-closing-inventory__content-result__title__content'>
              <button
                onClick={() => optionFilter(4, 'kitchen')}
                onMouseOver={() => setStateHover('kitchen')}
                onMouseOut={() => setState({ ...state, isHoverKitchen: false })}
                className='grid-x align-middle cash-closing-inventory__content-search__select-headquarter__content-selected'
              >
                <img
                  alt={isHoverKitchen ? "icon_blue_arrow_down" : "icon_blue_arrow"}
                  src={isHoverKitchen ? Assets.SharedIcons.icon_blue_arrow_down : Assets.SharedIcons.icon_blue_arrow}
                  className={`cash-closing-inventory__content-search__select-headquarter__icon${isFilterKitchen ? '__active' : ''}`}
                />
                <span className={`cash-closing-inventory__content-search__select-headquarter__text${isHoverKitchen ? '__hover' : ''}`}>
                  Ventas Cocina
                </span>
              </button>
            </div>
            <div className='grid-x align-center cash-closing-inventory__content-result__title__content'>
              <button
                onClick={() => optionFilter(5, 'execution')}
                onMouseOver={() => setStateHover('execution')}
                onMouseOut={() => setState({ ...state, isHoverExecution: false })}
                className='grid-x align-middle justify-content-center cash-closing-inventory__content-search__select-headquarter__content-selected'
              >
                <img
                  alt={isHoverExecution ? "icon_blue_arrow_down" : "icon_blue_arrow"}
                  src={isHoverExecution ? Assets.SharedIcons.icon_blue_arrow_down : Assets.SharedIcons.icon_blue_arrow}
                  className={`cash-closing-inventory__content-search__select-headquarter__icon${isFilterExecution ? '__active' : ''}`}
                />
                <span className={`cash-closing-inventory__content-search__select-headquarter__text${isHoverExecution ? '__hover' : ''}`}>
                  % de Ejecución
                </span>
              </button>
            </div>
            <div className='grid-x align-center cash-closing-inventory__content-result__title__content'>
              <button
                onClick={() => optionFilter(6, 'compliance')}
                onMouseOver={() => setStateHover('compliance')}
                onMouseOut={() => setState({ ...state, isHoverCompliance: false })}
                className='grid-x align-middle justify-content-center cash-closing-inventory__content-search__select-headquarter__content-selected'
              >
                <img
                  alt={isHoverCompliance ? "icon_blue_arrow_down" : "icon_blue_arrow"}
                  src={isHoverCompliance ? Assets.SharedIcons.icon_blue_arrow_down : Assets.SharedIcons.icon_blue_arrow}
                  className={`cash-closing-inventory__content-search__select-headquarter__icon${isFilterCompliance ? '__active' : ''}`}
                />
                <span className={`cash-closing-inventory__content-search__select-headquarter__text${isHoverCompliance ? '__hover' : ''}`}>
                  % de Cumplimiento
                </span>
              </button>
            </div>
            <div className='grid-x cash-closing-inventory__content-result__title__content--mod-second'>
              <span className='cash-closing-inventory__content-result__title'>Acciones</span>
            </div>
          </div>
          <div className='cash-closing-inventory__content-result__content-info'>
            {headquartersList.map((headquarters, index) => (
              <div key={index} className={`grid-x align-middle cash-closing-inventory__content-result__content-info__information-row${isItemChecked(index) ? '__selected' : ''}`}>
                <div className='grid-x cash-closing-inventory__content-result__content-info__content-fragment--mod'>
                  <div className='grid-x align-middle justify-content-center cash-closing-inventory__content-result__content-info__content-checkbox'>
                    <div className='cash-closing-inventory__content-result__content-info__checkbox'>
                      <Checkbox checked={isItemChecked(index)} onChange={() => handleCheckboxChange(index)}/>
                    </div>
                  </div>
                  <div className='grid-x align-middle cash-closing-inventory__content-result__content-info__content-location'>
                    <span className='cash-closing-inventory__content-result__content-info__description'>
                      {headquarters?.name}
                    </span>
                  </div>
                </div>
                <div className='grid-x cash-closing-inventory__content-result__content-info__content-fragment'>
                  <span className='cash-closing-inventory__content-result__content-info__description'>
                    {formattedNumber(headquarters?.cashOutflow)}
                  </span>
                </div>
                <div className='grid-x cash-closing-inventory__content-result__content-info__content-fragment'>
                  <span className='cash-closing-inventory__content-result__content-info__description'>
                    {formattedNumber(headquarters?.liquorSales)}
                  </span>
                </div>
                <div className='grid-x cash-closing-inventory__content-result__content-info__content-fragment'>
                  <span className='cash-closing-inventory__content-result__content-info__description'>
                    {formattedNumber(headquarters?.kitchenSales)}
                  </span>
                </div>
                <div className='grid-x align-center cash-closing-inventory__content-result__content-info__content-fragment'>
                  <Input
                    title={Math.ceil(headquarters?.percentageExecution) || 0}
                    className="cash-closing-inventory__input-percentage__green"
                    disabled
                    value={Math.ceil(headquarters?.percentageExecution) || 0}
                    suffix="%"
                  />
                </div>
                <div className='grid-x align-center cash-closing-inventory__content-result__content-info__content-fragment'>
                  <Input
                    title={Math.ceil(headquarters?.percentageCompliance) || 0}
                    className="cash-closing-inventory__input-percentage"
                    disabled
                    value={Math.ceil(headquarters?.percentageCompliance) || 0}
                    suffix="%"
                  />
                </div>
                <div className='grid-x cash-closing-inventory__content-result__content-info__content-fragment--mod-second'>
                  <button
                    className='grid-x align-middle cash-closing-inventory__content-result__content-info__button'
                    onClick={() => viewCashClosing(headquarters?.id, headquarters?.name)}
                  >
                    <span>Cierre de caja</span>
                    <img src={Assets.SharedIcons.icon_arrow} alt="icon_arrow" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CashClosingInventoryPage