//Constants
import CashClosingEndPoints from "./constants/cash-closing.end-points"

////Infrastructures
import Http from "./infrastructure/http.infrastructure"

//Services
import { getTokenService } from "./authentication.services"

export async function getNameHeadquartersService() {
    const token = getTokenService()
    try {
        const { data: responseGetNameHeadquarters, error } = await Http.get(CashClosingEndPoints.GET_NAME_HEADQUARTERS, null, token)
        if (responseGetNameHeadquarters && responseGetNameHeadquarters.status) {
            return responseGetNameHeadquarters.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getCashClosingService(date) {
    const token = getTokenService()
    try {
        const { data: responseGetCashClosing, error } = await Http.get(CashClosingEndPoints.GET_CASH_CLOSING, { date }, token)
        if (responseGetCashClosing && responseGetCashClosing.status) {
            return responseGetCashClosing.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createCashExpenseService(expense, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingEndPoints.CREATE_CASH_EXPENSE.replace(":id", id)
        const { data: responseCashExpense, error } = await Http.post(urlWithParams, { expense }, token)
        if (responseCashExpense && responseCashExpense.status) {
            return responseCashExpense.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateCashExpenseService(cashExpenses) {
    const token = getTokenService();
    try {
        const { data: responseCashExpense, error } = await Http.patch(CashClosingEndPoints.UPDATE_CASH_EXPENSE, { cashExpenses }, token)
        if (responseCashExpense && responseCashExpense.status) {
            return responseCashExpense.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createCashReceiptMoneyTransfersService(moneyTransfer, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingEndPoints.CREATE_CASH_TRANSFERS.replace(":id", id)
        const { data: responseCashReceiptMoneyTransfers, error } = await Http.post(urlWithParams, { moneyTransfer }, token)
        if (responseCashReceiptMoneyTransfers && responseCashReceiptMoneyTransfers.status) {
            return responseCashReceiptMoneyTransfers.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateCashReceiptMoneyTransfersService(moneyTransfers) {
    const token = getTokenService();
    try {
        const { data: responseCashReceiptMoneyTransfers, error } = await Http.patch(CashClosingEndPoints.UPDATE_CASH_TRANSFERS, { moneyTransfers }, token)
        if (responseCashReceiptMoneyTransfers && responseCashReceiptMoneyTransfers.status) {
            return responseCashReceiptMoneyTransfers.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createCashExpenseProviderService(expenseProvider, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingEndPoints.CREATE_CASH_EXPENSE_PROVIDER.replace(":id", id)
        const { data: responseCashExpenseProvider, error } = await Http.post(urlWithParams, { expenseProvider }, token)
        if (responseCashExpenseProvider && responseCashExpenseProvider.status) {
            return responseCashExpenseProvider.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateCashExpenseProviderService(cashExpensesProvider) {
    const token = getTokenService();
    try {
        const { data: responseCashReceiptMoneyTransfers, error } = await Http.patch(CashClosingEndPoints.UPDATE_CASH_EXPENSE_PROVIDER, { cashExpensesProvider }, token)
        if (responseCashReceiptMoneyTransfers && responseCashReceiptMoneyTransfers.status) {
            return responseCashReceiptMoneyTransfers.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createCashReceiptPaymentOfBondsmenService(paymentOfBondsmen, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingEndPoints.CREATE_CASH_RECEIPT_PAYMENT_OF_BONDSMEN.replace(":id", id)
        const { data: responseCashReceiptPaymentOfBondsmen, error } = await Http.post(urlWithParams, { paymentOfBondsmen }, token)
        if (responseCashReceiptPaymentOfBondsmen && responseCashReceiptPaymentOfBondsmen.status) {
            return responseCashReceiptPaymentOfBondsmen.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateCashReceiptPaymentOfBondsmenService(paymentOfBondsmen) {
    const token = getTokenService();
    try {
        const { data: responseCashReceiptPaymentOfBondsmen, error } = await Http.patch(CashClosingEndPoints.UPDATE_CASH_RECEIPT_PAYMENT_OF_BONDSMEN, { paymentOfBondsmen }, token)
        if (responseCashReceiptPaymentOfBondsmen && responseCashReceiptPaymentOfBondsmen.status) {
            return responseCashReceiptPaymentOfBondsmen.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createCashExpenseTrustService(expenseTrust, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingEndPoints.CREATE_CASH_EXPENSE_TRUST.replace(":id", id)
        const { data: responseCashExpenseTrust, error } = await Http.post(urlWithParams, { expenseTrust }, token)
        if (responseCashExpenseTrust && responseCashExpenseTrust.status) {
            return responseCashExpenseTrust.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateCashExpenseTrustService(expensesTrust) {
    const token = getTokenService();
    try {
        const { data: responseCashExpenseTrust, error } = await Http.patch(CashClosingEndPoints.UPDATE_CASH_EXPENSE_TRUST, { expensesTrust }, token)
        if (responseCashExpenseTrust && responseCashExpenseTrust.status) {
            return responseCashExpenseTrust.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateCashReceiptBottleControlService(cashReceiptBottleControl) {
    const token = getTokenService();
    try {
        const { data: responseCashReceiptBottleControl, error } = await Http.patch(CashClosingEndPoints.UPDATE_CASH_RECEIPT_BOTTLE_CONTROL, { cashReceiptBottleControl }, token)
        if (responseCashReceiptBottleControl && responseCashReceiptBottleControl.status) {
            return responseCashReceiptBottleControl.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createCashReceiptCardReadersService(cardReaders, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingEndPoints.CREATE_RECEIPT_CARD_READERS.replace(":id", id)
        const { data: responseCashReceiptCardReaders, error } = await Http.post(urlWithParams, { cardReaders }, token)
        if (responseCashReceiptCardReaders && responseCashReceiptCardReaders.status) {
            return responseCashReceiptCardReaders.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateCashReceiptCardReadersService(cashReceiptCardReader) {
    const token = getTokenService();
    try {
        const { data: responseCashReceiptCardReaders, error } = await Http.patch(CashClosingEndPoints.UPDATE_RECEIPT_CARD_READERS, { cashReceiptCardReader }, token)
        if (responseCashReceiptCardReaders && responseCashReceiptCardReaders.status) {
            return responseCashReceiptCardReaders.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateCashRegistersObservationService(observation, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingEndPoints.UPDATE_CASH_REGISTERS_OBSERVATION.replace(":id", id)
        const { data: responseCashRegistersObservation, error } = await Http.patch(urlWithParams, { observation }, token)
        if (responseCashRegistersObservation && responseCashRegistersObservation.status) {
            return responseCashRegistersObservation.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateCashRegistersInitialBalanceService(initialBalance, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingEndPoints.UPDATE_CASH_REGISTERS_INITIAL_BALANCE.replace(":id", id)
        const { data: responseCashRegistersInitialBalance, error } = await Http.patch(urlWithParams, { initialBalance }, token)
        if (responseCashRegistersInitialBalance && responseCashRegistersInitialBalance.status) {
            return responseCashRegistersInitialBalance.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateCashRegistersSalesCloudService(salesTNSCloud, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingEndPoints.UPDATE_CASH_REGISTERS_SALES_TNS.replace(":id", id)
        const { data: responseCashRegistersSalesCloud, error } = await Http.patch(urlWithParams, { salesTNSCloud }, token)
        if (responseCashRegistersSalesCloud && responseCashRegistersSalesCloud.status) {
            return responseCashRegistersSalesCloud.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateCashRegistersSalesPointColdService(salesPF, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingEndPoints.UPDATE_CASH_REGISTERS_SALES_PF.replace(":id", id)
        const { data: responseCashRegistersSalesPointCold, error } = await Http.patch(urlWithParams, { salesPF }, token)
        if (responseCashRegistersSalesPointCold && responseCashRegistersSalesPointCold.status) {
            return responseCashRegistersSalesPointCold.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateCashRegistersKitchenService(kitchen, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingEndPoints.UPDATE_CASH_REGISTERS_KITCHEN.replace(":id", id)
        const { data: responseCashRegistersKitchen, error } = await Http.patch(urlWithParams, { kitchen }, token)
        if (responseCashRegistersKitchen && responseCashRegistersKitchen.status) {
            return responseCashRegistersKitchen.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateCashRegistersRealCashService(realCash, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingEndPoints.UPDATE_CASH_REGISTERS_REAL_CASH.replace(":id", id)
        const { data: responseCashRegistersRealCash, error } = await Http.patch(urlWithParams, { realCash }, token)
        if (responseCashRegistersRealCash && responseCashRegistersRealCash.status) {
            return responseCashRegistersRealCash.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function saveClosingCashRegisterService(id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingEndPoints.SAVE_CLOSING_CASH_REGISTER.replace(":id", id)
        const { data: responseClosingCashRegister, error } = await Http.patch(urlWithParams, null, token)
        if (responseClosingCashRegister && responseClosingCashRegister.status) {
            return responseClosingCashRegister.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function publishClosingCashRegisterService(id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingEndPoints.PUBLISH_CLOSING_CASH_REGISTER.replace(":id", id)
        const { data: responseClosingCashRegister, error } = await Http.patch(urlWithParams, null, token)
        if (responseClosingCashRegister && responseClosingCashRegister.status) {
            return responseClosingCashRegister.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createCashExpenseReturnsService(returns, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingEndPoints.CREATE_CASH_RETURN.replace(":id", id)
        const { data: responseCashExpenseReturns, error } = await Http.post(urlWithParams, { returns }, token)
        if (responseCashExpenseReturns && responseCashExpenseReturns.status) {
            return responseCashExpenseReturns.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateCashExpenseReturnsService(returns) {
    const token = getTokenService();
    try {
        const { data: responseCashExpenseReturns, error } = await Http.patch(CashClosingEndPoints.UPDATE_CASH_RETURN, { returns }, token)
        if (responseCashExpenseReturns && responseCashExpenseReturns.status) {
            return responseCashExpenseReturns.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createInternalConsumptionService(domesticConsumption, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingEndPoints.CREATE_INTERNAL_CONSUMPTION.replace(":id", id)
        const { data: responseInternalConsumption, error } = await Http.post(urlWithParams, { domesticConsumption }, token)
        if (responseInternalConsumption && responseInternalConsumption.status) {
            return responseInternalConsumption.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateInternalConsumptionService(domesticConsumption) {
    const token = getTokenService();
    try {
        const { data: responseInternalConsumption, error } = await Http.patch(CashClosingEndPoints.UPDATE_INTERNAL_CONSUMPTION, { domesticConsumption }, token)
        if (responseInternalConsumption && responseInternalConsumption.status) {
            return responseInternalConsumption.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function deletedRecordDataphonesTableService(body) {
    const token = getTokenService();
    try {
        const { data: responseDeletedRecordDataphonesTable, error } = await Http.delete(CashClosingEndPoints.DELETED_RECORD_DATAPONES_TABLE, body, token)
        if (responseDeletedRecordDataphonesTable && responseDeletedRecordDataphonesTable.status) {
            return responseDeletedRecordDataphonesTable.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function deletedRecordRefundsTableService(body) {
    const token = getTokenService();
    try {
        const { data: responseDeletedRecordRefundsTable, error } = await Http.delete(CashClosingEndPoints.DELETED_RECORD_REFUNDS_TABLE, body, token)
        if (responseDeletedRecordRefundsTable && responseDeletedRecordRefundsTable.status) {
            return responseDeletedRecordRefundsTable.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function deletedRecordTransfersTableService(body) {
    const token = getTokenService();
    try {
        const { data: responseDeletedRecordTransfersTable, error } = await Http.delete(CashClosingEndPoints.DELETED_RECORD_TRANSFERS_TABLE, body, token)
        if (responseDeletedRecordTransfersTable && responseDeletedRecordTransfersTable.status) {
            return responseDeletedRecordTransfersTable.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function deletedRecordExpensesTableService(body) {
    const token = getTokenService();
    try {
        const { data: responseDeletedRecordExpensesTable, error } = await Http.delete(CashClosingEndPoints.DELETED_RECORD_EXPENSES_TABLE, body, token)
        if (responseDeletedRecordExpensesTable && responseDeletedRecordExpensesTable.status) {
            return responseDeletedRecordExpensesTable.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function deletedRecordPaymentOfBondsmenTableService(body) {
    const token = getTokenService();
    try {
        const { data: responseDeletedRecordPaymentOfBondsmenTable, error } = await Http.delete(CashClosingEndPoints.DELETED_RECORD_PAYMENT_OF_BONDSMEN_TABLE, body, token)
        if (responseDeletedRecordPaymentOfBondsmenTable && responseDeletedRecordPaymentOfBondsmenTable.status) {
            return responseDeletedRecordPaymentOfBondsmenTable.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function deletedRecordLoansTableService(body) {
    const token = getTokenService();
    try {
        const { data: responseDeletedRecordLoansTable, error } = await Http.delete(CashClosingEndPoints.DELETED_RECORD_LOANS_TABLE, body, token)
        if (responseDeletedRecordLoansTable && responseDeletedRecordLoansTable.status) {
            return responseDeletedRecordLoansTable.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function deletedRecordProvidersTableService(body) {
    const token = getTokenService();
    try {
        const { data: responseDeletedRecordProvidersTable, error } = await Http.delete(CashClosingEndPoints.DELETED_RECORD_PROVIDERS_TABLE, body, token)
        if (responseDeletedRecordProvidersTable && responseDeletedRecordProvidersTable.status) {
            return responseDeletedRecordProvidersTable.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function deletedRecordInternalConsumptionTableService(body) {
    const token = getTokenService();
    try {
        const { data: responseDeletedRecordInternalConsumptionTable, error } = await Http.delete(CashClosingEndPoints.DELETED_RECORD_INTERNAL_CONSUMPTION_TABLE, body, token)
        if (responseDeletedRecordInternalConsumptionTable && responseDeletedRecordInternalConsumptionTable.status) {
            return responseDeletedRecordInternalConsumptionTable.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};