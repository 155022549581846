// Actions
import { setMultipleStatesWholesaleFinalBalanceReducer } from '../../storage/reducers/wholesale-final-balance/wholesale-final-balance.actions';

// Libraries
import dayjs from 'dayjs';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

// Components
import HeaderControlComponent from './components/header-control/header-control.component';
import TablesOfRecordsComponent from './components/tables-of-records/tables-of-records.component';
import ErrorAlertComponent from '../../shared/components/alerts/error-alert/error-alert.component';
import GeneralSummaryContainerComponent from './components/general-summary-container/general-summary-container.component';

// Services
import { getAllListOfTabsService, getGeneralSummaryService, getInfoTabByIdService } from '../../services/final-reconciliation.services';

// Styles
import './final-reconciliation.page.scss';

const FinalReconciliationPage = (props) => {

    const { state: locationState } = useLocation();

    const {
        // Actions
        setMultipleStatesWholesaleFinalBalanceReducer,
        indexTabSelected,
        // Variables
        isLoading,
        isGeneralSummary,
        isGetAllTabs,
        dateSelected
    } = props;

    const INITIAL_STATE = {
        isTabs: false
    }
    const [state, setState] = useState(INITIAL_STATE);

    const { isTabs } = state;

    useEffect(() => {

        if (!isTabs && isGetAllTabs) {
            setState({ ...state, isTabs: true })
            getAllListOfTabs(true);
        }else{
            getAllListOfTabs(isTabs);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateSelected, isGetAllTabs]);
    
    const getAllListOfTabs = async (isTabsState) => {
        try {
            const responseTabs = await getAllListOfTabsService(locationState?.idHeadquarter);
            const allResponseTables = [];

            for (const tab of responseTabs) {
                if (tab.schema) {
                    const tabResponseTables = [];
                    for (const schemaObj of tab.schema) {
                        if (schemaObj?.positiveFlow || schemaObj?.negativeFlow) {
                            const responseTable = await getInfoTabByIdService({ positiveFlowId: schemaObj?.positiveFlow, negativeFlowId: schemaObj?.negativeFlow });
                            tabResponseTables.push(responseTable);
                        }
                    }
                    allResponseTables.push(tabResponseTables);
                };
            };

            const respGeneralSummary = await getGeneralSummaryService(locationState?.idHeadquarter, dayjs(dateSelected).format('YYYY-MM-DD'));

            const newObject = { name: 'Resumen General', icon: 'icon_summary' };
            responseTabs.unshift(newObject);
            allResponseTables.unshift(respGeneralSummary);

            setMultipleStatesWholesaleFinalBalanceReducer({
                indexTabSelected: isTabsState ? indexTabSelected : 0,
                tabList: responseTabs,
                isGeneralSummary: isTabsState && indexTabSelected !== 0 ? false : true,
                listsTableInformation: allResponseTables,
                listsTableInformationCopy: allResponseTables ? JSON.parse(JSON.stringify(allResponseTables)) : allResponseTables,
                isGetAllTabs: false,
                isLoading: false
            });

        } catch (error) {
            ErrorAlertComponent();
            setMultipleStatesWholesaleFinalBalanceReducer({ isLoading: false });
        }
    };

    return (
        <div className='final-reconciliation__container'>
            {isLoading
                ? 
                    (<div className='grid-y align-center-middle final-reconciliation__content-loading-view'>
                        <Spin size='large' />
                        <span className='final-reconciliation__content-loading-view__text'>Cargando...</span>
                    </div>)
                :
                    (<>
                        <HeaderControlComponent />
                        <div className='grid-x final-reconciliation__content-tables'>
                            {isGeneralSummary
                                ? (<GeneralSummaryContainerComponent idHeadquarter={locationState?.idHeadquarter} />)
                                : (<TablesOfRecordsComponent />)
                            }
                        </div>
                    </>)
            }
        </div>
    )
};

const mapStateToProps = ({ WholesaleFinalBalanceReducer }) => {
    const {
        isLoading,
        dateSelected,
        isGetAllTabs,
        isGeneralSummary,
        indexTabSelected
    } = WholesaleFinalBalanceReducer;
    return {
        isLoading,
        dateSelected,
        isGetAllTabs,
        isGeneralSummary,
        indexTabSelected
    };
};

const mapStateToPropsActions = {
    setMultipleStatesWholesaleFinalBalanceReducer,
};

export default connect(mapStateToProps, mapStateToPropsActions)(FinalReconciliationPage);