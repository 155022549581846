// Assets
import { Assets } from '../../../../assets';

// Libraries
import { notification } from 'antd';

// Styles
import '../alert.component.scss';

const SuccessAlertComponent = (
  message='Proceso exitoso!',
  description='Cambios guardados satisfactoriamente.'
) => {
  notification.success({
    message,
    description,
    icon: <img src={Assets.SharedIcons.icon_alert_success} alt="icon_alert_success" />,
    closeIcon: <img src={Assets.SharedIcons.icon_deleted_active} alt="icon_deleted_active" />,
    className:'notification-alert__success'
});
}

export default SuccessAlertComponent