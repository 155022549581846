//Assets
import { Assets } from '../../../assets/index'

// Actions
import {
  setUserLoginAuthenticationReducer,
  setErrorLoginAuthenticationReducer,
  setProcessValidateSessionAuthenticationReducer
} from "../../../storage/reducers/authentication/authentication.actions";

// Components - shared
import ErrorAlertComponent from '../../../shared/components/alerts/error-alert/error-alert.component';

// Libraries
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Checkbox, Input } from 'antd';

// Models
import CredentialModel from "../../../models/credential/model/credential.model";

// Services
import {
  loginService,
  setTokenService,
  setUserPermissionsService,
  setLoggedUserService
} from "../../../services/authentication.services";

// Styles
import "./login.page.scss";

// Utils
import { validateLogin } from "../../../utils/validate-recovery-password.utils";

const LoginPage = (props) => {

  const {
    setUserLoginAuthenticationReducer,
    setErrorLoginAuthenticationReducer,
    setProcessValidateSessionAuthenticationReducer
  } = props;

  const navigate = useNavigate();

  const [isErrorUser, setErrorUser] = useState(false);

  const { handleSubmit, handleChange, setErrors, values, errors } = useFormik({
    initialValues: {
      user: "",
      password: "",
    },
    initialErrors: {
      user: "",
      password: "",
    },
    validate: validateLogin,
    onSubmit: () => {
      loginService(new CredentialModel(values.user, values.password))
        .then((res) => {
          const { authentication, permissions, name } = res
          const user = `${name}`;
          const [sortedPermissions] = permissions.slice().sort((a, b) => a.type - b.type);
          if (sortedPermissions) {
            const functionality = sortedPermissions.moduleFunctionality;
            setTokenService(authentication.token);
            setUserLoginAuthenticationReducer(res);
            setUserPermissionsService(permissions)
            setLoggedUserService(user)
            const [permission] = functionality;
            navigate(`/${permission.route}`);
          } else {
            ErrorAlertComponent(
              'El usuario no tiene permisos asignados.',
              'Por favor, contacta con el soporte para generar los permisos necesarios para poder ingresar a la aplicación'
            );
          }
        })
        .catch((err) => {
          setErrorUser(true);
          setErrorLoginAuthenticationReducer(err);
          setErrors({ user: 'Usuario no encontrado, verifica e inténtalo nuevamente' });
          ErrorAlertComponent(undefined, 'Usuario no encontrado, verifica e inténtalo nuevamente');
        });
    },
  });

  useEffect(() => {
    setProcessValidateSessionAuthenticationReducer(false, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="grid-x">
      <div className="cell medium-6 login-page flex-container align-center-middle">
        <img
          src={Assets.SharedIcons.icon_logo_text}
          alt="icon_logo_text"
        />
      </div>
      <div className=" cell medium-6 login-page__main-container">
        <div className="grid-padding-x login-page__main-container__welcome-text">
          Bienvenido al portal de Surtilicores, aquí podrás reportar de una manera más efectiva tu labor.
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid-y justify-content-between login-page__container">
            <div className='grid-y align-center-middle'>
              <span className="login-page__title">
                Iniciar sesión
              </span>
              <span className="login-page__text">
                Ingresa tu nombre de usuario y contraseña
              </span>
            </div>
            <div className='login-page__input__container'>
              <Input
                value={values.user}
                className="login-page__input"
                placeholder="Nombre de usuario"
                onChange={(value) => {
                  handleChange("user")(value)
                  isErrorUser && setErrorUser(false);
                }}
                prefix={
                  <div className='login-page__input__content-icon'>
                    <img
                      alt="icon_user"
                      src={Assets.AuthenticationIcons.icon_user}
                    />
                  </div>
                }
                suffix={
                  (isErrorUser) &&
                  (<button
                    style={{ marginRight: '10px' }}
                    onClick={() => {
                      setErrorUser(false)
                      handleChange("user")('');
                    }}
                  >
                    <img
                      alt="icon_clear"
                      src={Assets.AuthenticationIcons.icon_clear}
                    />
                  </button>)
                }
              />
              <span className="login-page__input__error">{errors.user}</span>
            </div>
            <div className='login-page__input__container'>
              <Input.Password
                value={values.password}
                placeholder="Contraseña"
                className="login-page__input"
                onChange={(value) => handleChange("password")(value)}
                prefix={
                  <div className='login-page__input__content-icon'>
                    <img
                      alt="icon_user"
                      src={Assets.AuthenticationIcons.icon_password}
                    />
                  </div>
                }
              />
              <span className="login-page__input__error">{errors.password}</span>
            </div>
            <div className='grid-x align-center-middle justify-content-between login-page__content-options-password'>
              <div className='login-page__checkbox'>
                <Checkbox>
                  Recordar contraseña
                </Checkbox>
              </div>
              <Link
                to="/validateUser"
                className='login-page__link'
              >
                ¿Olvidaste tu contraseña?
              </Link>
            </div>
            <button
              type="submit"
              className="button"
              style={{ background: '#1499F2' }}
              disabled={Object.keys(errors).length !== 0}
            >
              Iniciar sesión
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToPropsActions = {
  setUserLoginAuthenticationReducer,
  setErrorLoginAuthenticationReducer,
  setProcessValidateSessionAuthenticationReducer
};

export default connect(null, mapStateToPropsActions)(LoginPage);
