const FinalReconciliationEndPoints =  {
    GET_ALL_LIST_OF_TABS : `/api/finalBalanceWholesaler/getBalanceTypeByWholesaler/:wholesalerId`,
    GET_INFO_TAB_BY_ID : `/api/finalBalanceWholesaler/getBalanceByWholesaler`,
    GET_REGISTER_MONTHLY_CLOSING : `/api/finalBalanceWholesaler/getFinalBalance/:wholesalerId/:date`,
    CREATE_REGISTER_MONTHLY_CLOSING : `/api/finalBalanceWholesaler/setMovements/:wholesalerId`,
    GET_GENERAL_SUMMARY : `/api/finalBalanceWholesaler/getGeneralBalance/:id/:date`,
    CREATE_GENERAL_SUMMARY : `/api/finalBalanceWholesaler/setMovements/:id`,
    UPDATE_GENERAL_SUMMARY : `/api/finalBalanceWholesaler/updateMovements`,
    CREATE_RECORDS_IN_THE_TABLES : `/api/finalBalanceWholesaler/setBalanceByWholesaler/:wholesalerId`,
    UPDATE_RECORDS_IN_THE_TABLES : `/api/finalBalanceWholesaler/updateBalances`,
    DELETED_RECORDS_IN_THE_TABLES : `/api/finalBalanceWholesaler/deleteBalance/:balanceId/:isExpense`,
}

export default FinalReconciliationEndPoints;