// Actions
import { setMultipleStatesWholesaleFinalBalanceReducer } from '../../../../storage/reducers/wholesale-final-balance/wholesale-final-balance.actions';

// Assets
import { Assets } from '../../../../assets';

// Components
import WeekSelectorComponent from '../../../components/week-selector/week-selector.component';
import MonthlyClosingWholesalerComponent from '../monthly-closing-wholesaler/monthly-closing-wholesaler.component';

// Libraries
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// Styles
import './header-control.component.scss';

const HeaderControlComponent = (props) => {

  const { state: locationState } = useLocation();

  const {
    // Actions
    setMultipleStatesWholesaleFinalBalanceReducer,
    // Variables
    tabList,
    daySelected,
    dateSelected,
    isGeneralSummary,
    indexTabSelected,
  } = props;

  const INITIAL_STATE = {
    hoverIndex: null,
    isHoverSaveProcess: false,
  };

  const [state, setState] = useState(INITIAL_STATE);
  const [openModal, setOpenModal] = useState(false);

  const {
    hoverIndex,
    isHoverSaveProcess
  } = state;
  
  const goToMonth = (amount) => {
    const newDate = dayjs(dateSelected).add(amount, 'month');
    const currentDate = dayjs();

    if (newDate.isBefore(currentDate, 'month') || newDate.isSame(currentDate, 'month')) {
      setMultipleStatesWholesaleFinalBalanceReducer({ dateSelected: newDate, isGetAllTabs: true })
    };
  };

  const disabledDate = (current) => {
    return current && current.isAfter(dayjs(), 'month');
  };

  const handleDate = (date) => {
    setMultipleStatesWholesaleFinalBalanceReducer({ dateSelected: date })
  };

  const previousMonth = () => {
    const monthNew = dateSelected.subtract(1, 'month')
    setMultipleStatesWholesaleFinalBalanceReducer({ dateSelected: monthNew })
  };

  const nextMonth = () => {
    const monthNew = dateSelected.add(1, 'month')
    setMultipleStatesWholesaleFinalBalanceReducer({ dateSelected: monthNew })
  };

  const previousWeek = () => {
    const week = dateSelected.subtract(1, 'week')
    setMultipleStatesWholesaleFinalBalanceReducer({ dateSelected: week })
  };

  const nextWeek = () => {
    const week = dateSelected.add(1, 'week')
    setMultipleStatesWholesaleFinalBalanceReducer({ dateSelected: week })
  };

  const selectDay = (day) => {
    setMultipleStatesWholesaleFinalBalanceReducer({ dateSelected: day, daySelected: day })
  };

  const validatePropsHoverTab = (index, iconName) => {
    let src, typeText;

    if (hoverIndex === index) {
      src = iconName+'_active';
      typeText = '__hover';
    } else if (indexTabSelected === index) {
      src = iconName;
      typeText = '__active';
    } else {
      src = iconName+'_inactive';
      typeText = '__inactive';
    }

    return { src, typeText };
  };

  const toggleModalClosing = () => {
    setOpenModal(prevState => !prevState);
  };

  const handleSave = () => {
    setMultipleStatesWholesaleFinalBalanceReducer({ isSave: true })
  }

  return (
    <div className='header-control__container'>
      <div className='grid-x justify-content-between header-control__link-and-calendar'>
        <div className='grid-x align-middle'>
          <Link
            to="/finalBalanceWholesaler"
            className="header-control__link-and-calendar__link"
          >
            Sedes
            <img
              alt='icon_arrow'
              src={Assets.SharedIcons.icon_arrow}
              className='header-control__link-and-calendar__icon'
            />
          </Link>
          <span className='header-control__link-and-calendar__name'>
            {locationState?.nameHeadquarter || ''}
          </span>
        </div>
        <div className='grid-x align-middle'>
          {!isGeneralSummary &&
            (<>
              <span className='header-control__link-and-calendar__name-calendar'>Seleccionar mes:</span>
              <div className='grid-x align-middle justify-content-between header-control__link-and-calendar__calendar'>
                <button onClick={() => goToMonth(-1)}>
                  <img
                    alt='icon_arrow'
                    src={Assets.SharedIcons.icon_arrow}
                    className='header-control__link-and-calendar__arrow'
                  />
                </button>
                <DatePicker
                  picker='month'
                  suffixIcon={null}
                  allowClear={false}
                  inputReadOnly={true}
                  variant='borderless'
                  value={dateSelected}
                  defaultValue={dayjs()}
                  disabledDate={disabledDate}
                  className='header-control__link-and-calendar__month'
                  format={(value) => { return `${dayjs(value)?.format('MMMM, YYYY')}` }}
                  onChange={(date) => setMultipleStatesWholesaleFinalBalanceReducer({ dateSelected: date })}
                />
                <button
                  onClick={() => goToMonth(1)}
                  className='header-control__link-and-calendar__button-next'
                  disabled={(dayjs(dateSelected).add(1, 'month').isAfter(dayjs(), 'month'))}
                >
                  <img
                    alt={(dayjs(dateSelected).add(1, 'month').isAfter(dayjs(), 'month')) ? 'icon_arrow_picker_disabled' : 'icon_arrow'}
                    src={(dayjs(dateSelected).add(1, 'month').isAfter(dayjs(), 'month')) ? Assets.SharedIcons.icon_arrow_picker_disabled : Assets.SharedIcons.icon_arrow}
                  />
                </button>
              </div>
            </>)
          }
        </div>
      </div>
      <div className='grid-x header-control__tabs-and-buttons'>
        <div className='grid-x header-control__tabs-and-buttons__content-tabs'>
          <div className='header-control__tabs-and-buttons__content-tabs__container'>
            {tabList.map((tab, index) => {
              return (
                <button
                  key={index}
                  onMouseOver={() => setState({ ...state, hoverIndex: index })}
                  onMouseOut={() => setState({ ...state, hoverIndex: null })}
                  className={`header-control__tabs-and-buttons__content-tabs__tab-button${indexTabSelected === index ? "__active" : ""}`}
                  onClick={() => {
                    if (indexTabSelected === index) return;
                    setMultipleStatesWholesaleFinalBalanceReducer({
                      itemTabSelected: tab,
                      indexTabSelected: index,
                      isGeneralSummary: index === 0,
                      isNewAttribute: (tab.name === 'AJE COLOMBIA') || (tab.name === 'FACTORIN BBVA') ? true : false
                    })
                  }}
                >
                  <img
                    alt={tab.icon}
                    src={Assets.SharedIcons[validatePropsHoverTab(index, tab.icon).src]}
                    className='header-control__tabs-and-buttons__content-tabs__tab-button__icon'
                  />
                  <span className={`header-control__tabs-and-buttons__content-tabs__tab-button__text${validatePropsHoverTab(index, tab.icon).typeText}`}>
                    {tab.name}
                  </span>
                </button>
              );
            })}
          </div>
        </div>
        <div className='grid-x align-middle justify-content-between header-control__tabs-and-buttons__content-buttons'>
          <button
            onClick={() => handleSave()}
            className='header-control__tabs-and-buttons__content-buttons__button__save'
            onMouseOver={() => setState({ ...state, isHoverSaveProcess: true })}
            onMouseOut={() => setState({ ...state, isHoverSaveProcess: false })}
          >
            <img
              alt={false
                ? 'icon_save_disabled'
                : isHoverSaveProcess
                  ? 'icon_save_hover'
                  : 'icon_save'
              }
              src={
                false
                  ? Assets.SharedIcons.icon_save_disabled
                  : isHoverSaveProcess
                    ? Assets.SharedIcons.icon_save_hover
                    : Assets.SharedIcons.icon_save
              }
            />
            <span>Guardar y actualizar</span>
          </button>
          <button className='header-control__tabs-and-buttons__content-buttons__button__publish'>
            <span>Finalizar y publicar</span>
            <img
              alt={false ? 'icon_publish_disabled' : 'icon_publish'}
              src={false ? Assets.SharedIcons.icon_publish_disabled : Assets.SharedIcons.icon_publish}
            />
          </button>
        </div>
      </div>
      {isGeneralSummary &&
        (<div className='grid-x header-control__week-and-button'>
          <div className='header-control__week-and-button__week'>
            <WeekSelectorComponent
              daySelected={daySelected}
              dateSelected={dateSelected}
              nextWeek={() => nextWeek()}
              nextDate={() => nextMonth()}
              previousWeek={() => previousWeek()}
              previousDate={() => previousMonth()}
              selectDay={(date) => selectDay(date)}
              handleDate={(date) => handleDate(date)}
            />
          </div>
          <div className='grid-x align-middle justify-content-end header-control__week-and-button__button-content'>
            <button 
              className='grid-x align-center-middle header-control__week-and-button__button-content__button'
              onClick={toggleModalClosing}
              >
              <span>Ver Cierre Mensual</span>
              <img
                alt='icon_arrow_picker_disabled'
                src={Assets.SharedIcons.icon_arrow_picker_disabled}
              />
            </button>
          </div>
        </div>)
      }
      {openModal &&
        <MonthlyClosingWholesalerComponent
          open={openModal}
          onClose={toggleModalClosing}
          date={dateSelected}
          idWholesaler={locationState?.idHeadquarter}
        />
      }
    </div>
  )
};

const mapStateToProps = ({ WholesaleFinalBalanceReducer }) => {
  const {
    tabList,
    daySelected,
    dateSelected,
    isGeneralSummary,
    indexTabSelected,
  } = WholesaleFinalBalanceReducer;

  return {
    tabList,
    daySelected,
    dateSelected,
    isGeneralSummary,
    indexTabSelected
  };
};

const mapStateToPropsActions = {
  setMultipleStatesWholesaleFinalBalanceReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(HeaderControlComponent);