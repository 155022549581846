// Actions
import { setMultipleStatesWholesaleFinalBalanceReducer } from '../../../../storage/reducers/wholesale-final-balance/wholesale-final-balance.actions';

// Assets
import { Assets } from '../../../../assets';

// Components - Shared
import ErrorAlertComponent from '../../../../shared/components/alerts/error-alert/error-alert.component';

// Libraries
import { Checkbox, Input } from 'antd';
import { NumericFormat } from 'react-number-format';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';

// Styles
import './credits-we-owe-final-reconciliation.component.scss';

// Utils
import { dataOriginGeneralSummary } from '../../../../utils/final-reconciliation.utils';

const CreditsWeOweFinalReconciliationComponent = (props) => {

  const {
    // Actions
    setMultipleStatesWholesaleFinalBalanceReducer,
    // Variables
    listsTableInformation,
    dateSelected
   } = props;

  const INITIAL_STATE = {
    scrollEnd: false,
    creditsWeOweInfo: {
      monthly: [],
      constants: [],
      variables: [],
    }
  };

  const [state, setState] = useState(INITIAL_STATE);

  const {
    scrollEnd,
    creditsWeOweInfo
  } = state;

  const formRef = useRef(null);

  useEffect(() => {
    if (formRef.current && scrollEnd) {
      const miDiv = formRef.current;
      miDiv.scrollTop = miDiv.scrollHeight;
    }
  }, [scrollEnd, creditsWeOweInfo?.variables?.length]);

  useEffect(() => {
    let dataDefault = {
      monthly: listsTableInformation[0]?.against?.monthly || [],
      constants: listsTableInformation[0]?.against?.constants || [],
      variables: listsTableInformation[0]?.against?.variables || dataOriginGeneralSummary,
    }


    if (dataDefault.variables.length < dataOriginGeneralSummary?.length) {
      const resp = dataDefault.variables?.concat(dataOriginGeneralSummary.slice(0, (dataOriginGeneralSummary?.length - dataDefault?.variables?.length)))
      dataDefault.variables = resp
    }

    setState((prevState)=>({
      ...prevState,
      creditsWeOweInfo: dataDefault
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listsTableInformation[0]?.against])

  const sumPrices = (list,name) => {
    return (list || []).reduce((accumulator, product) => {
      const price = !isNaN(Number(product[name])) ? Number(product[name]) : Number(product[name]?.replace(/[^0-9]/g, ''));
      return accumulator + (isNaN(price) ? 0 : price);
    }, 0);
  };

  const onChanges = (e, index, name, table) => {
    let value = e.target ? e.target.value || '' : e;
    let dataModified = creditsWeOweInfo
    
    if (name === "total") {
      value = parseInt((value || "0")?.replace(/[^0-9]/g, ''),10)
    }
    
    dataModified[table][index][name] = value;

    setState((prevState)=>({
      ...prevState,
      creditsWeOweInfo: dataModified,
    }))

    let dataReducer =[
      ...listsTableInformation
    ]

    dataReducer[0].against = dataModified

    setMultipleStatesWholesaleFinalBalanceReducer({
      listsTableInformation: dataReducer
    });
  }

  const addVariables = () => {
    const dataState = {...creditsWeOweInfo}
    const objectDisabled = creditsWeOweInfo?.variables?.find(object => object.hasOwnProperty("disabled") && object.disabled === true);
    if (!objectDisabled) {
      const newArray = [
        ...creditsWeOweInfo.variables,
        {
          ...dataOriginGeneralSummary[0],
          disabled: false
        } 
      ]
      
      setState({
        ...state,
        scrollEnd: true,
        creditsWeOweInfo: {...dataState, variables: newArray}
      })
    } else {
      const newArray = creditsWeOweInfo?.variables?.map(object => object === objectDisabled ? { ...object, disabled: false } : object);
      setState({ ...state, creditsWeOweInfo: {...dataState, variables: newArray} })
    };
  };

  const deleteData = async(index) => {
    try {
      const dataState = {...creditsWeOweInfo}
      let dataModified = creditsWeOweInfo?.variables

      dataModified.splice(index, 1)

      if (dataModified.length <= 6) {
        dataModified = [
          ...dataModified,
          {
            ...dataOriginGeneralSummary[0],
            disabled: true
          }
        ]
      }

      setState((prevState)=>({
        ...prevState,
        creditsWeOweInfo: {...dataState, variables:dataModified}
      }))

      let dataReducer =[
        ...listsTableInformation
      ]
  
      dataReducer[0].against = {...creditsWeOweInfo, variables: dataModified}

      setMultipleStatesWholesaleFinalBalanceReducer({
        listsTableInformation: dataReducer
      })

    } catch (error) {
      ErrorAlertComponent()
    }
  }

  const totalTables = () => {
    return (sumPrices(creditsWeOweInfo?.constants,"total") + sumPrices(creditsWeOweInfo?.variables,"total") + sumPrices(creditsWeOweInfo?.monthly,"total")) || 0
  }

  return (
    <div className='grid-x small-6 align-right align-content-start'>
      <div className='grid-x align-center-middle credits-we-owe-final-reconciliation__title__content'>
        <span className='credits-we-owe-final-reconciliation__title'>CRÉDITOS O DEBEMOS</span>
      </div>
      <div className='credits-we-owe-final-reconciliation__box'>
        <div className='grid-x justify-content-between'>
          <span className='credits-we-owe-final-reconciliation__box__title'>Concepto</span>
          <span className='credits-we-owe-final-reconciliation__box__title'>Valor</span>
        </div>
        <div className='credits-we-owe-final-reconciliation__box__list-totalizers'>
          {creditsWeOweInfo?.monthly?.map((creditsWeOweTotalizer, index) => {
            return (
              <div key={index} className='grid-x align-middle justify-content-between credits-we-owe-final-reconciliation__box__content-label'>
                <Input
                  disabled
                  prefix={<></>}
                  placeholder='Concepto'
                  value={creditsWeOweTotalizer?.name}
                  className='credits-we-owe-final-reconciliation__box__input-concept'
                />
                <div className='credits-we-owe-final-reconciliation__box__input-price'>
                  <NumericFormat
                    disabled
                    type='text'
                    prefix={"$ "}
                    placeholder='$ 0'
                    thousandSeparator=","
                    className='input-price'
                    thousandsGroupStyle="thousand"
                    value={creditsWeOweTotalizer?.total}
                  />
                </div>
              </div>
            )
          })}
        </div>
        <div className='credits-we-owe-final-reconciliation__box__list-constants'>
          {creditsWeOweInfo?.constants?.map((creditsWeOweConstant, index) => {
            return (
              <div key={index} className='grid-x align-middle justify-content-between credits-we-owe-final-reconciliation__box__content-label'>
                <Input
                  disabled
                  prefix={<></>}
                  placeholder='Concepto'
                  value={creditsWeOweConstant?.name}
                  className='credits-we-owe-final-reconciliation__box__input-concept'
                />
                <div className='credits-we-owe-final-reconciliation__box__input-price'>
                  <NumericFormat
                    type='text'
                    prefix={"$ "}
                    placeholder='$ 0'
                    thousandSeparator=","
                    className='input-price'
                    thousandsGroupStyle="thousand"
                    value={creditsWeOweConstant?.total}
                    disabled
                  />
                </div>
              </div>
            )
          })}
        </div>
        <div ref={formRef} className='credits-we-owe-final-reconciliation__box__aggregates-list'>
          {creditsWeOweInfo?.variables?.map((creditsWeOweOptional, index) => {
            return (
              <div key={index} className='grid-x align-middle justify-content-between credits-we-owe-final-reconciliation__box__content-label'>
                <Input
                  placeholder='Concepto'
                  value={creditsWeOweOptional?.name}
                  disabled={creditsWeOweOptional?.disabled}
                  className={`credits-we-owe-final-reconciliation__box__input-concept${creditsWeOweOptional?.disabled ? '__disabled' : ''}`}
                  prefix={
                    <img
                      alt={creditsWeOweOptional?.disabled ? 'icon_add' : 'icon_edit'}
                      src={creditsWeOweOptional?.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                    />
                  }
                  onChange={(e) => onChanges(e, index, 'name', 'variables')}
                  readOnly={!creditsWeOweOptional.allowEdit}
                />
                <div className='grid-x justify-content-between credits-we-owe-final-reconciliation__box__content-input-optional'>
                  <div className='credits-we-owe-final-reconciliation__box__input-price__optional'>
                    <NumericFormat
                      type='text'
                      prefix={"$ "}
                      placeholder='$ 0'
                      thousandSeparator=","
                      className='input-price'
                      thousandsGroupStyle="thousand"
                      value={creditsWeOweOptional?.total}
                      disabled={creditsWeOweOptional?.disabled}
                      onChange={(e) => onChanges(e, index, 'total', 'variables')}
                      readOnly={!creditsWeOweOptional.allowEdit}
                    />
                    <div className='credits-we-owe-final-reconciliation__box__icon'>
                      <img
                        alt={creditsWeOweOptional?.disabled ? "icon_add" : "icon_edit"}
                        src={creditsWeOweOptional?.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                      />
                    </div>
                  </div>
                  <div className='grid-x justify-content-end credits-we-owe-final-reconciliation__box__button-deleted__container'>
                    {creditsWeOweOptional.id ? 
                        <Checkbox
                          disabled={!creditsWeOweOptional?.allowEdit}
                          onChange={() => onChanges(!creditsWeOweOptional?.checked, index, 'checked', 'variables')}
                          className='credits-we-owe-final-reconciliation__checkbox'
                        />
                      :
                        <button
                          onClick={() => deleteData(index)}
                          disabled={creditsWeOweOptional?.disabled}
                          className='credits-we-owe-final-reconciliation__box__button-deleted'
                        >
                          <img
                            className='credits-we-owe-final-reconciliation__box__button-deleted__icon'
                            alt={creditsWeOweOptional?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                            src={creditsWeOweOptional?.disabled ? Assets.SharedIcons.icon_deleted_disabled : Assets.SharedIcons.icon_deleted_active}
                          />
                        </button>
                    }
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className='grid-x align-middle justify-content-between credits-we-owe-final-reconciliation__box__content-label'>
          <div className='grid-x justify-content-end credits-we-owe-final-reconciliation__box__title-total__content'>
            <span className='credits-we-owe-final-reconciliation__box__title-total'>
              Total
            </span>
          </div>
          <div className='credits-we-owe-final-reconciliation__box__input-price'>
            <NumericFormat
              disabled
              type='text'
              prefix={"$ "}
              placeholder='$ 0'
              thousandSeparator=","
              className='input-price'
              thousandsGroupStyle="thousand"
              value={totalTables()}
            />
          </div>
        </div>
        { dayjs().isSame(dayjs(dateSelected), 'day') &&
          <button
            onClick={() => addVariables()}
            className='grid-x align-middle credits-we-owe-final-reconciliation__box__button-add'
          >
            <img
              alt='icon_income'
              src={Assets.SharedIcons.icon_income}
            />
            <span className='credits-we-owe-final-reconciliation__box__button-add__text'>
              Agregar debemos
            </span>
          </button>
        }
      </div>
    </div>
  )

}

const mapStateToProps = ({ WholesaleFinalBalanceReducer }) => {
  const { listsTableInformation, dateSelected } = WholesaleFinalBalanceReducer;
  return { listsTableInformation, dateSelected };
};

const mapStateToPropsActions = {
  setMultipleStatesWholesaleFinalBalanceReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(CreditsWeOweFinalReconciliationComponent);