import { getTokenService } from "./authentication.services"
import AuthorizedManagementEndPoints from "./constants/authorized-management.end-points"
import Http from "./infrastructure/http.infrastructure"


export async function getAllAuthorizedManagementService(filterValue = "") {
    try {
        const token = getTokenService()
        const {data: responseGetAllAuthorizedManagement, error} = await Http.get(AuthorizedManagementEndPoints.GET_ALL_AUTHORIZED_MANAGEMENT,{filterValue},token)
        if (responseGetAllAuthorizedManagement && responseGetAllAuthorizedManagement.status) {
            let responseGetAll = responseGetAllAuthorizedManagement.data.map((authorizedManagement) => ({
                ...authorizedManagement,
                id: authorizedManagement.id,
                name: authorizedManagement.name,
                document: authorizedManagement.numberDocument,
                allowableAvailable: authorizedManagement.authorizedCash,
                currentDebt: authorizedManagement.totalPriceCashExpenseTrusts - authorizedManagement.totalPriceCashReceiptPaymentOfBondsmen,
                difference: (authorizedManagement.totalPriceCashExpenseTrusts - authorizedManagement.totalPriceCashReceiptPaymentOfBondsmen) - authorizedManagement.totalPriceCashExpenseTrusts,
                contact: authorizedManagement.numberPhone,
            }))
            return responseGetAll
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getAuthorizedManagementByIdService(id) {
    try {
        const token = getTokenService()
        const urlWithParams = AuthorizedManagementEndPoints.GET_AUTHORIZED_BY_ID.replace(":id", id)
        const {data: responseGetAuthorizedManagement, error} = await Http.get(urlWithParams,null,token)
        if (responseGetAuthorizedManagement && responseGetAuthorizedManagement.status) {
            return responseGetAuthorizedManagement.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createAuthorizedManagementService(data) {
    try {
        const token = getTokenService()
        const {data: responseCreateAuthorizedManagement, error} = await Http.post( AuthorizedManagementEndPoints.CREATE_AUTHORIZED_MANAGEMENT,data, token)
        if (responseCreateAuthorizedManagement && responseCreateAuthorizedManagement.status) {
            return responseCreateAuthorizedManagement.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateAuthorizedManagementService(id,data) {
    try {
        const token = getTokenService()
        const urlWithParams = AuthorizedManagementEndPoints.UPDATE_AUTHORIZED_MANAGEMENT.replace(":id", id)
        const {data: responseCreateAuthorizedManagement, error} = await Http.patch(urlWithParams,data, token)
        if (responseCreateAuthorizedManagement && responseCreateAuthorizedManagement.status) {
            return responseCreateAuthorizedManagement.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}