export const dataPayment = [
    {
        date: "",
        conceptDescription: "",
        PaymentMethod:  undefined,
        price: 0,
    },
    {
        date: "",
        conceptDescription: "",
        PaymentMethod: undefined,
        price: 0,
    },
    {
        date: "",
        conceptDescription: "",
        PaymentMethod: undefined,
        price: 0,
    },
    {
        date: "",
        conceptDescription: "",
        PaymentMethod: undefined,
        price: 0,
    },
    {
        date: "",
        conceptDescription: "",
        PaymentMethod: undefined,
        price: 0,
    },
    {
        date: "",
        conceptDescription: "",
        PaymentMethod: undefined,
        price: 0,
    },
    {
        date: "",
        conceptDescription: "",
        PaymentMethod: undefined,
        price: 0,
    },
    {
        date: "",
        conceptDescription: "",
        PaymentMethod: undefined,
        price: 0,
    },
    {
        date: "",
        conceptDescription: "",
        PaymentMethod: undefined,
        price: 0,
    },
    {
        date: "",
        conceptDescription: "",
        PaymentMethod: undefined,
        price: 0,
    }
]