//Constants
import PortfolioEndPoints from "./constants/portfolio.end-points"

//Infrastructures
import Http from "./infrastructure/http.infrastructure"

//Services
import { getTokenService } from "../services/authentication.services"

export async function getHistoricalPortfoliosService(date, filterValue) {
    try {
        const token = getTokenService();
        let filter = filterValue ? {value: filterValue} : null
        const urlWithParams = PortfolioEndPoints.GET_HISTORICAL_PORTFOLIOS.replace(":date", date)
        const { data: responseGetHistoricalPortfolio, error } = await Http.get(urlWithParams, filter , token)
        if (responseGetHistoricalPortfolio && responseGetHistoricalPortfolio.status) {
            return responseGetHistoricalPortfolio.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getAllPortfolioService() {

    try {
        const {data: responseGetAllPortfolio, error} = await Http.get(PortfolioEndPoints.GET_ALL_PORTFOLIO)
        if (responseGetAllPortfolio && responseGetAllPortfolio.status) {

            const responseAllPortfolio = responseGetAllPortfolio.data.map(({id, name}) => {
                
                return {
                    id,
                    name
                }
            })

            return responseAllPortfolio
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getSummaryByHeadquarterService(body) {
    const token = getTokenService()
    try {
        const { data: responseGetSummaryByHeadquarter, error } = await Http.get(PortfolioEndPoints.GET_SUMMARY_BY_HEADQUARTER, body, token)
        if (responseGetSummaryByHeadquarter && responseGetSummaryByHeadquarter.status) {
            const responseGetSummary = responseGetSummaryByHeadquarter.data
            let response = {}
            response.cashReceipt = responseGetSummary.cashReceived.map((data) => ({
                ...data,
                date: data.dailyTransactionTransaction.date,
                conceptDescription: data.concept,
                PaymentMethod:{ name: data.paymentMethodDailyTransactionTransaction.name },
                price: data.price
            }));
            response.cashExpense = responseGetSummary.cashOutflow.map((data) => ({
                ...data,
                date: data.dailyTransactionTransaction.date,
                conceptDescription: data.concept,
                PaymentMethod:{ name: data.paymentMethodDailyTransactionTransaction.name },
                price: data.price
            }));
            response.totals ={
                totalOtherProducts: responseGetSummary.amountMonthCashOutflow,
                subTotal: responseGetSummary.amountMonthCashReceived,
                cashOutflow: responseGetSummary.historicalPortfolios.cashOutflow,
                cashReceived: responseGetSummary.historicalPortfolios.cashReceived,
                total: responseGetSummary.historicalPortfolios.cashOutflow - responseGetSummary.historicalPortfolios.cashReceived,
            }
            return response;
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};


export async function getAllPortfolioWalletService(date, filterValue) {

    try {
        const token = getTokenService();
        let filter = filterValue ? {value: filterValue} : null
        const urlWithParams = PortfolioEndPoints.GET_ALL_PORTFOLIO_WALLET.replace(":date", date)
        const { data: responseGetHistoricalPortfolio, error } = await Http.get(urlWithParams, filter , token)
        if (responseGetHistoricalPortfolio && responseGetHistoricalPortfolio.status) {
            return responseGetHistoricalPortfolio.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getSummaryByHeadquarterWalletService(body) {
    const token = getTokenService()
    try {
        const { data: responseGetSummaryByHeadquarter, error } = await Http.get(PortfolioEndPoints.GET_SUMMARY_BY_HEADQUARTER_WALLET, body, token)
        if (responseGetSummaryByHeadquarter && responseGetSummaryByHeadquarter.status) {
            const responseGetSummary = responseGetSummaryByHeadquarter.data
            let response = {}
            response.cashReceipt = responseGetSummary.cashReceived.map((data) => ({
                ...data,
                date: data.dailyTransactionTransaction.date,
                conceptDescription: data.concept,
                PaymentMethod:{ name: data.paymentMethodDailyTransactionTransaction.name },
                price: data.price
            }));
            response.cashExpense = responseGetSummary.cashOutflow.map((data) => ({
                ...data,
                date: data.dailyTransactionTransaction.date,
                conceptDescription: data.concept,
                PaymentMethod:{ name: data.paymentMethodDailyTransactionTransaction.name },
                price: data.price
            }));
            response.totals ={
                totalOtherProducts: responseGetSummary.amountMonthCashOutflow,
                subTotal: responseGetSummary.amountMonthCashReceived,
                cashOutflow: responseGetSummary.historicalPortfolios.cashOutflow,
                cashReceived: responseGetSummary.historicalPortfolios.cashReceived,
                total: responseGetSummary.historicalPortfolios.cashOutflow - responseGetSummary.historicalPortfolios.cashReceived,
            }
            return response;
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};