// Components
import ErrorAlertComponent from '../../../shared/components/alerts/error-alert/error-alert.component';
import SuccessAlertComponent from '../../../shared/components/alerts/success-alert/success-alert.component';
import InformationAlertComponent from '../../../shared/components/alerts/information-alert/information-alert.component';

// Assets
import { Assets } from '../../../assets';

// Libraries
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Checkbox, Input, Spin, Tree, Upload } from 'antd';

// Services
import { getAllPermissionsService } from '../../../services/shared.services';
import {
    createUserService,
    updateUserService,
    getUserByIdService,
    createUserPermissionService,
    getUserPermissionByIdService,
    updateUserPermissionService,
} from '../../../services/administration-panel.services';

// Styles
import './edit-or-add-user.page.scss';

// Utils
import { addUserSchema, editUserSchema } from '../../../utils/edit-or-add-user.utils';

const EditOrAddUserPage = () => {

    let { state: paramsRouters } = useLocation();

    const INITIAL_STATE = {
        checkedItems: [],
        permissionsList: [],
        checkedItemsUpdate: [],
        isLoading: false,
        createdUserId : '',
        profilePhoto: null,
        isCreateUser: false,
        isUserPermissions: false,
        isAddPermissionsUser: false,
        isHoverButtonPermissions: false,
        isHoverButtonNameUser: false,
        userInfo: {
            state: false,
            name: '',
            email: '',
            password: '',
            nickName: '',
            position: '',
            phoneNumber: '',
            confirmPassword: '',
            identificationNumber: '',
        }
    };

    const [state, setState] = useState(INITIAL_STATE);

    const {
        userInfo,
        isLoading,
        profilePhoto,
        isCreateUser,
        checkedItems,
        createdUserId,
        permissionsList,
        isUserPermissions,
        checkedItemsUpdate,
        isAddPermissionsUser,
        isHoverButtonPermissions,
        isHoverButtonNameUser
    } = state;

    const getInitialErrors = (isAddUser) => {
        const commonValues = {
            name: '',
            email: '',
            nickName: '',
            password: '',
            position: '',
            phoneNumber: '',
            confirmPassword: '',
            identificationNumber: '',
        };
    
        if (!isAddUser) {
            delete commonValues.password;
            delete commonValues.confirmPassword;
        };
    
        return commonValues;
    };

    const { values, errors, setFieldValue, handleBlur, touched, setValues, setTouched } = useFormik({
        initialValues: {
            name: '',
            email: '',
            nickName: '',
            password: '',
            position: '',
            state: false,
            phoneNumber: '',
            confirmPassword: '',
            identificationNumber: '',
        },
        initialErrors: getInitialErrors(paramsRouters?.isAddUser),
        validationSchema: paramsRouters?.isAddUser ? addUserSchema : editUserSchema,
        onSubmit: () => { },
    });


    useEffect(() => {
        if (!paramsRouters?.isAddUser) {
            getPersonalInformation()
        };
        getPermissionsInformation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paramsRouters]);

    
    const getPersonalInformation = async () => {
        try {
            const { user } = await getUserByIdService(paramsRouters?.userId);
            
            setTouched({
                ...touched,
                name: user?.name ? true : false,
                email: user?.email ? true : false,
                position: user?.position ? true : false,
                nickName: user?.nickName ? true : false,
                phoneNumber: user?.phoneNumber ? true : false,
                identificationNumber: user?.identificationNumber ? true : false,
            })
            setValues({
                ...values,
                name: user?.name || '',
                email: user?.email || '',
                state: Boolean(!user?.state),
                nickName: user?.nickName || '',
                position: user?.position || '',
                phoneNumber: user?.phoneNumber || '',
                identificationNumber: user?.identificationNumber || '',
            });
            setState((prevState) => ({
                ...prevState,
                userInfo: {
                    name: user?.name || '',
                    email: user?.email || '',
                    state: Boolean(!user?.state),
                    nickName: user?.nickName || '',
                    position: user?.position || '',
                    phoneNumber: user?.phoneNumber || '',
                    identificationNumber: user?.identificationNumber || '',
                }
            }));
        } catch (error) {
            ErrorAlertComponent();
        }
    };

    const getPermissionsInformation = async () => {
        try {
            const permissionsList = await getAllPermissionsService();
            setState((prevState) => ({ ...prevState, permissionsList }))
        } catch (error) {
            ErrorAlertComponent();
        }
    };

    const UserItems = [
        { value: userInfo?.identificationNumber, title: 'Documento de identidad', iconSrc: Assets.SharedIcons.icon_document, iconAlt: 'icon_document' },
        { value: userInfo?.phoneNumber, title: 'Número de contacto', iconSrc: Assets.SharedIcons.icon_phone, iconAlt: 'icon_phone' },
        { value: userInfo?.email, title: 'Correo electrónico', iconSrc: Assets.SharedIcons.icon_email_solid, iconAlt: 'icon_email_solid' },
        { value: userInfo?.position, title: 'Cargo u ocupación', iconSrc: Assets.SharedIcons.icon_payroll, iconAlt: 'icon_payroll' },
    ];

    const onChangeField = (target, data) => {
        let value = data && data.target ? data.target.value || '' : data;
        setFieldValue(target, value)
    };

    const handleFileChange = ({ fileList }) => {};

    const customRequest = ({ file, onSuccess, onError }) => {};

    const validateTypeProp = (isHover) => {
        let alt, src, typeText;
        if (paramsRouters?.isAddUser && Object.values(userInfo).every(value => !value)) {
            typeText = '__disabled';
            alt = 'icon_key_disabled';
            src = Assets.SharedIcons.icon_key_disabled;
        } else if (isHover) {
            typeText = '__hover';
            alt = 'icon_key_active';
            src = Assets.SharedIcons.icon_key_active;
        } else {
            typeText = '';
            alt = 'icon_key';
            src = Assets.SharedIcons.icon_key;
        }
        return { alt, src, typeText };
    };

    const createUserData = async () => {
        try {
            const createdUserId = await createUserService({
                name: values.name,
                email: values.email,
                state: !values.state,
                password: values.password,
                nickName: values.nickName,
                position: values.position,
                phoneNumber: values.phoneNumber,
                identificationNumber: values.identificationNumber,
            });
            setState((prevState) => ({
                ...prevState,
                createdUserId,
                isCreateUser: true,
                userInfo: {
                    ...userInfo,
                    name: values.name,
                    email: values.email,
                    state: !values.state,
                    position: values.position,
                    phoneNumber: values.phoneNumber,
                    identificationNumber: values.identificationNumber,
                }
            }));
            SuccessAlertComponent(undefined, 'Usuario creado exitosamente!');
        } catch (error) {
            ErrorAlertComponent()
        }
    };

    const updateUserData = async () => {
        try {
            const updateUser = Object.keys(values).reduce((updatedUser, key) => {
                if (key !== "confirmPassword" && values[key] !== userInfo?.[key] && values[key] !== "") {
                    updatedUser[key] = key === "state" ? !values[key] : values[key];
                }
                return updatedUser;
            }, {});
            if (Object.keys(updateUser).length === 0) {
                InformationAlertComponent('Ops!', 'No hay cambios que actualizar');
                return;
            }
            await updateUserService({ idUser: paramsRouters?.userId, ...updateUser });
            getPersonalInformation();
            SuccessAlertComponent(undefined, 'Usuario actualizado exitosamente!');
        } catch (error) {
            ErrorAlertComponent();
        }
    };

    const validateDisabledPermission = () => {
        let disabled = true;

        if (!paramsRouters?.isAddUser) {
            disabled = false;
        } else if (paramsRouters?.isAddUser && isCreateUser) {
            disabled = false;
        }

        return disabled;
    };

    const getPermissionsList = async () => {
        try {
            let checkedItems = [];
            setState((prevState) => ({ ...prevState, isLoading: true }));
            const userPermissionsList = await getUserPermissionByIdService(paramsRouters?.isAddUser ? createdUserId : paramsRouters?.userId);
            userPermissionsList.map((userPermission, index) => (
                checkedItems.push({ id: userPermission.id })
            ));
            setTimeout(() => {
                setState((prevState) => ({
                    ...prevState,
                    checkedItems,
                    isLoading: false,
                    isAddPermissionsUser: true,
                    checkedItemsUpdate: checkedItems,
                    isUserPermissions: checkedItems.length > 0,
                }));
            }, 500);
        } catch (error) {
            ErrorAlertComponent();
        }
    };

    const handleCheckboxChange = (itemPermission) => {
        const isChecked = checkedItems.some(checkedItem => checkedItem.id === itemPermission.id);
        const updatedCheckedItems = isChecked
            ? checkedItems.filter(checkedItem => !(checkedItem.id === itemPermission.id))
            : [...checkedItems, itemPermission];

        setState((prevState) => ({ ...prevState, checkedItems: updatedCheckedItems }));
    };

    const isItemChecked = (itemPermission) => {
        return checkedItems.some(checkedItem => checkedItem.id === itemPermission.id);
    };

    const handleCheckTreeChange = (checkedKeysValue, info) => {
        if (info?.node?.children) {
            const updatedChildren = info.node.children.map(child => ({ id: child.key }));
            let updatedCheckedItems = [...checkedItems];

            updatedChildren.forEach(updatedChild => {
                const existingIndex = checkedItems.findIndex(item => item.id === updatedChild.id);

                if (existingIndex === -1) {
                    updatedCheckedItems.push(updatedChild);
                } else {
                    updatedCheckedItems = updatedCheckedItems.filter(item => !(item.id === updatedChild.id));
                };
                setState((prevState) => ({ ...prevState, checkedItems: updatedCheckedItems }));
            });
        } else {
            const { key } = info.node;
            const newNode = { id: key };
            const existingIndex = checkedItems.findIndex(item => item.id === key);

            const newCheckedItems = [...checkedItems];

            if (existingIndex === -1) {
                newCheckedItems.push(newNode);
            } else {
                newCheckedItems.splice(existingIndex, 1);
            }

            setState((prevState) => ({ ...prevState, checkedItems: newCheckedItems}))
        }
    };

    const checkedKeysTree = (functionality) => {
        const items = functionality.map(funcItem => {
            const matchingItem = checkedItems.find(checkedItem => checkedItem.id === funcItem.id);
            return matchingItem ? matchingItem.id : null;
        });

        return items.filter(item => item !== null);
    };

    const createPermissionsUser = async () => {
        const functionality = checkedItems.map(item => (item.id))
        try {
            await createUserPermissionService({ idUser: paramsRouters?.userId || createdUserId, functionality });
            SuccessAlertComponent(undefined, functionality.length > 1 ? 'Permisos creados exitosamente!' : 'Permiso creado exitosamente!');
            getPermissionsList();
        } catch (error) {
            ErrorAlertComponent();
        }
    };

    const updatePermissionsUser = async () => {
        try {
            const permissionsDeleted = checkedItemsUpdate.filter(itemUpdate => !checkedItems.some(item => item.id === itemUpdate.id)).map(item => item.id);
            const permissionsAdd = checkedItems.filter(itemUpdate => !checkedItemsUpdate.some(item => item.id === itemUpdate.id)).map(item => item.id);
            const functionality = [...permissionsDeleted, ...permissionsAdd];
            await updateUserPermissionService({ idUser:  paramsRouters?.userId || createdUserId, functionality });
            SuccessAlertComponent(undefined, 'Permisos actualizados exitosamente!');
            getPermissionsList();
        } catch (error) {
            ErrorAlertComponent();
        }
    };

    const disabledUpdateUser = (isError) => {
        let disabled = true;
        const { password, confirmPassword } = values;
        
        if (
            (!isError && password && confirmPassword === '') ||
            (!isError && password === '' && confirmPassword) ||
            (!isError && password && confirmPassword && password !== confirmPassword) ||
            isError
        ) {
            disabled = true;
        } else {
            disabled = false;
        }
    
        return disabled;
    };

    return (
        <div className='edit-or-add-user__container'>
            <div className='grid-x align-middle'>
                <Link
                    to="/adminPanel"
                    className="grid-x edit-or-add-user__link"
                >
                    Usuarios
                </Link>
                <img
                    alt='icon_arrow'
                    src={Assets.SharedIcons.icon_arrow}
                    className='edit-or-add-user__icon-arrow'
                />
                <button
                    className="grid-x align-middle"
                    disabled={!isAddPermissionsUser}
                    onMouseOver={() => setState({ ...state, isHoverButtonNameUser: true })}
                    onMouseOut={() => setState({ ...state, isHoverButtonNameUser: false })}
                    onClick={() => setState((prevState) => ({ ...prevState, isAddPermissionsUser: false }))}
                >
                    <span className={`edit-or-add-user__name${(isHoverButtonNameUser && isAddPermissionsUser) ? '__hover' : ''}`}>
                        {userInfo?.name || 'Nuevo usuario'}
                    </span>
                </button>
                {isAddPermissionsUser &&
                    (<>
                        <img
                            alt="icon_arrow"
                            src={Assets.SharedIcons.icon_arrow}
                            className='edit-or-add-user__icon-arrow'
                        />
                        <span className='edit-or-add-user__name'>
                            Editar Permisos
                        </span>
                    </>)
                }
            </div>
            <div className='grid-x edit-or-add-user__container-table'>
                <div className='grid-x align-content-start edit-or-add-user__first-table__content'>
                    <div className='edit-or-add-user__first-table'>
                        <div className='grid-x align-middle edit-or-add-user__first-table__container-profile'>
                            {paramsRouters?.isAddUser
                                ?
                                    (<div className='grid-x align-center-middle edit-or-add-user__first-table__icon-text'>
                                        <span className='edit-or-add-user__first-table__icon-text__text'>NU</span>
                                    </div>)
                                :
                                    (<img
                                        alt='photo_profile'
                                        className='edit-or-add-user__first-table__image'
                                        src={'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'}
                                    />)
                            }
                            <div className='grid-y'>
                                <span className='edit-or-add-user__first-table__name'>{userInfo?.name || 'Nombre del usuario'}</span>
                                <span className='edit-or-add-user__first-table__occupation'>{userInfo?.position || 'Cargo u ocupación'}</span>
                            </div>
                        </div>
                        <div className='edit-or-add-user__first-table__info__container'>
                            {UserItems.map((user, index) => (
                                <div key={index} className='grid-x edit-or-add-user__first-table__info'>
                                    <img
                                        src={user?.iconSrc}
                                        alt={user?.iconAlt}
                                        className='edit-or-add-user__first-table__info__icon'
                                    />
                                    <div className='grid-y'>
                                        <span className='edit-or-add-user__first-table__info__title'>
                                            {user?.title}
                                        </span>
                                        <span className={`edit-or-add-user__first-table__info__description${Object.values(userInfo).every(value => !value) ? '__disabled' : ''}`}>
                                            {user?.value || 'Sin asignar'}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='grid-x align-middle edit-or-add-user__first-table__check__container'>
                            <div className='edit-or-add-user__first-table__check'>
                                <Checkbox
                                    checked={values?.state}
                                    onChange={(e) => onChangeField('state', e.target.checked)}
                                />
                            </div>
                            <span className='edit-or-add-user__first-table__check__title'>Bloquear acceso</span>
                        </div>
                        <div className='edit-or-add-user__first-table__edit-permission__container'>
                            <button
                                onClick={() => getPermissionsList()}
                                disabled={validateDisabledPermission()}
                                className='edit-or-add-user__first-table__edit-permission__button'
                                onMouseOver={() => setState({ ...state, isHoverButtonPermissions: true })}
                                onMouseOut={() => setState({ ...state, isHoverButtonPermissions: false })}
                            >
                                <img
                                    alt={validateTypeProp(isHoverButtonPermissions).alt}
                                    src={validateTypeProp(isHoverButtonPermissions).src}
                                />
                                <span className={`edit-or-add-user__first-table__edit-permission__title${validateTypeProp(isHoverButtonPermissions).typeText}`}>
                                    Editar permisos
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className='grid-x align-content-start edit-or-add-user__second-table__content'>
                    <div className='edit-or-add-user__second-table'>
                        {isLoading
                            ? (<Spin size='large' className='edit-or-add-user__second-table__spinner' />)
                            : !isAddPermissionsUser
                                ?
                                    (<>
                                        <span className='edit-or-add-user__second-table__title'>Datos personales</span>
                                        <div className='grid-x justify-content-between edit-or-add-user__second-table__form-container'>
                                            <div className='grid-x justify-content-between edit-or-add-user__second-table__content-box'>
                                                <div className={`grid-x align-middle edit-or-add-user__second-table__container-photo${profilePhoto ? '__uploaded' : '__empty'}`}>
                                                    {profilePhoto
                                                        ?
                                                        (<>
                                                            <img
                                                                alt='photo_profile'
                                                                className='edit-or-add-user__second-table__photo-profile'
                                                                src={'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'}
                                                            />
                                                            <div className='grid-y edit-or-add-user__second-table__container-buttons'>
                                                                <Upload
                                                                    maxCount={1}
                                                                    onChange={handleFileChange}
                                                                    customRequest={customRequest}
                                                                    accept='image/jpg, image/jpeg, image/png'
                                                                    className='edit-or-add-user__second-table__button'
                                                                >
                                                                    <div className='grid-x align-center-middle edit-or-add-user__second-table__button__content'>
                                                                        <img
                                                                            alt='icon_edit'
                                                                            src={Assets.SharedIcons.icon_edit}
                                                                        />
                                                                        <span className='edit-or-add-user__second-table__text-button'>
                                                                            Cambiar foto
                                                                        </span>
                                                                    </div>
                                                                </Upload>
                                                                <button className='edit-or-add-user__second-table__button'>
                                                                    <img
                                                                        alt='icon_trash_bin'
                                                                        src={Assets.SharedIcons.icon_trash_bin}
                                                                    />
                                                                    <span className='edit-or-add-user__second-table__text-delete'>
                                                                        Eliminar foto
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </>)
                                                        :
                                                        (<div className='grid-x align-middle edit-or-add-user__second-table__container-photo__empty__container'>
                                                            <Upload
                                                                maxCount={1}
                                                                onChange={handleFileChange}
                                                                customRequest={customRequest}
                                                                accept='image/jpg, image/jpeg, image/png'
                                                                className='edit-or-add-user__second-table__container-photo__empty__upload'
                                                            >
                                                                <div className='grid-x align-middle justify-content-evenly edit-or-add-user__second-table__container-photo__empty__upload__content'>
                                                                    <div className='grid-x align-center-middle edit-or-add-user__second-table__container-photo__empty__icon-text'>
                                                                        <span className='edit-or-add-user__second-table__container-photo__empty__icon-text__text'>NU</span>
                                                                    </div>
                                                                    <div className='grid-y align-center-middle edit-or-add-user__second-table__container-photo__empty__content-text'>
                                                                        <span className='text-center edit-or-add-user__second-table__container-photo__empty__title'>
                                                                            Agregar foto
                                                                        </span>
                                                                        <span className='edit-or-add-user__second-table__container-photo__empty__description'>
                                                                            Preferiblemente una fotografía cuadrada de 1080x1080 px, formato .png, .jpg, no mayor a 1 MB.
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </Upload>
                                                        </div>)
                                                    }
                                                </div>
                                                <div className='grid-y justify-content-end edit-or-add-user__second-table__container-inputs-names'>
                                                    <div className='edit-or-add-user__second-table__content-input__aux'>
                                                        <div className='grid-x edit-or-add-user__second-table__content-input__label__content'>
                                                            <span className='edit-or-add-user__second-table__content-input__label'>
                                                                Nombres y apellidos completo
                                                                <span className='edit-or-add-user__second-table__content-input__label__required'>*</span>
                                                            </span>
                                                        </div>
                                                        <Input
                                                            value={values.name}
                                                            onBlur={(value) => handleBlur('name')(value)}
                                                            placeholder='Escribe aquí el nombre y el apellido'
                                                            onChange={(value) => onChangeField("name", value)}
                                                            status={errors.name && touched.name ? 'error' : ''}
                                                            className={`edit-or-add-user__second-table__content-input${values.name ? '__input' : '__empty'}`}
                                                        />
                                                        <div className='grid-x justify-content-end'>
                                                            <span className='edit-or-add-user__second-table__content-input__text-error'>
                                                                {touched.name ? errors.name : null}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='edit-or-add-user__second-table__content-input__aux'>
                                                        <div className='grid-x edit-or-add-user__second-table__content-input__label__content'>
                                                            <span className='edit-or-add-user__second-table__content-input__label'>
                                                                Nombre de usuario
                                                                <span className='edit-or-add-user__second-table__content-input__label__required'>*</span>
                                                            </span>
                                                        </div>
                                                        <Input
                                                            value={values.nickName}
                                                            placeholder='Escribe aquí el nombre de usuario'
                                                            onBlur={(value) => handleBlur('nickName')(value)}
                                                            onChange={(value) => onChangeField("nickName", value)}
                                                            status={errors.nickName && touched.nickName ? 'error' : ''}
                                                            className={`edit-or-add-user__second-table__content-input${values.nickName ? '__input' : '__empty'}`}
                                                        />
                                                        <div className='grid-x justify-content-end'>
                                                            <span className='edit-or-add-user__second-table__content-input__text-error'>
                                                                {touched.nickName ? errors.nickName : null}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='edit-or-add-user__second-table__content-input'>
                                                <div className='grid-x edit-or-add-user__second-table__content-input__label__content'>
                                                    <span className='edit-or-add-user__second-table__content-input__label'>
                                                        Documento de identidad
                                                        <span className='edit-or-add-user__second-table__content-input__label__required'>*</span>
                                                    </span>
                                                </div>
                                                <Input
                                                value={values.identificationNumber}
                                                    placeholder='Número del documento'
                                                    onBlur={(value) => handleBlur('identificationNumber')(value)}
                                                    onChange={(value) => onChangeField("identificationNumber", value)}
                                                    status={errors.identificationNumber && touched.identificationNumber ? 'error' : ''}
                                                    className={`edit-or-add-user__second-table__content-input${values.identificationNumber ? '__input' : '__empty'}`}
                                                />
                                                <div className='grid-x justify-content-end'>
                                                    <span className='edit-or-add-user__second-table__content-input__text-error'>
                                                        {touched.identificationNumber ? errors.identificationNumber : null}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='edit-or-add-user__second-table__content-input'>
                                                <div className='grid-x edit-or-add-user__second-table__content-input__label__content'>
                                                    <span className='edit-or-add-user__second-table__content-input__label'>
                                                        Número de contacto
                                                        <span className='edit-or-add-user__second-table__content-input__label__required'>*</span>
                                                    </span>
                                                </div>
                                                <Input
                                                    value={values.phoneNumber}
                                                    placeholder='Número de contacto'
                                                    onBlur={(value) => handleBlur('phoneNumber')(value)}
                                                    onChange={(value) => onChangeField("phoneNumber", value)}
                                                    status={errors.phoneNumber && touched.phoneNumber ? 'error' : ''}
                                                    className={`edit-or-add-user__second-table__content-input${values.phoneNumber ? '__input' : '__empty'}`}
                                                />
                                                <div className='grid-x justify-content-end'>
                                                    <span className='edit-or-add-user__second-table__content-input__text-error'>
                                                        {touched.phoneNumber ? errors.phoneNumber : null}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='edit-or-add-user__second-table__content-input'>
                                                <div className='grid-x edit-or-add-user__second-table__content-input__label__content'>
                                                    <span className='edit-or-add-user__second-table__content-input__label'>
                                                        Correo electrónico
                                                        <span className='edit-or-add-user__second-table__content-input__label__required'>*</span>
                                                    </span>
                                                </div>
                                                <Input
                                                    value={values.email}
                                                    placeholder='Escribe el correo electrónico'
                                                    onBlur={(value) => handleBlur('email')(value)}
                                                    onChange={(value) => onChangeField("email", value)}
                                                    status={errors.email && touched.email ? 'error' : ''}
                                                    className={`edit-or-add-user__second-table__content-input${values.email ? '__input' : '__empty'}`}
                                                />
                                                <div className='grid-x justify-content-end'>
                                                    <span className='edit-or-add-user__second-table__content-input__text-error'>
                                                        {touched.email ? errors.email : null}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='edit-or-add-user__second-table__content-input'>
                                                <div className='grid-x edit-or-add-user__second-table__content-input__label__content'>
                                                    <span className='edit-or-add-user__second-table__content-input__label'>
                                                        Cargo u ocupación
                                                        <span className='edit-or-add-user__second-table__content-input__label__required'>*</span>
                                                    </span>
                                                </div>
                                                <Input
                                                    value={values.position}
                                                    placeholder='Escribe el cargo u ocupación'
                                                    onBlur={(value) => handleBlur('position')(value)}
                                                    onChange={(value) => onChangeField("position", value)}
                                                    status={errors.position && touched.position ? 'error' : ''}
                                                    className={`edit-or-add-user__second-table__content-input${values.position ? '__input' : '__empty'}`}
                                                />
                                                <div className='grid-x justify-content-end'>
                                                    <span className='edit-or-add-user__second-table__content-input__text-error'>
                                                        {touched.position ? errors.position : null}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <span className='edit-or-add-user__second-table__title'>Actualizar contraseña</span>
                                        <div className='grid-x edit-or-add-user__second-table__content-update-password'>
                                            <div className='edit-or-add-user__second-table__content-input'>
                                                <div className='grid-x edit-or-add-user__second-table__content-input__label__content'>
                                                    <span className='edit-or-add-user__second-table__content-input__label'>
                                                        Nueva contraseña
                                                        {paramsRouters?.isAddUser &&
                                                            (<span className='edit-or-add-user__second-table__content-input__label__required'>*</span>)
                                                        }
                                                    </span>
                                                </div>
                                                <Input.Password
                                                    value={values.password}
                                                    placeholder='Escribe aquí la contraseña'
                                                    onBlur={(value) => handleBlur('password')(value)}
                                                    onChange={(value) => onChangeField("password", value)}
                                                    status={paramsRouters?.isAddUser ? errors.password && touched.password ? 'error' : '' : ''}
                                                    className='edit-or-add-user__second-table__content-update-password__input-password'
                                                    prefix={
                                                        <div className='edit-or-add-user__second-table__content-update-password__input-password__icon'>
                                                            <img alt="icon_user" src={Assets.AuthenticationIcons.icon_password} />
                                                        </div>
                                                    }
                                                />
                                                <div className='grid-x justify-content-end'>
                                                    <span className='edit-or-add-user__second-table__content-input__text-error'>
                                                        {paramsRouters?.isAddUser
                                                            ? touched.password
                                                                ? errors.password
                                                                : null
                                                            : (values.confirmPassword && values.password === '')
                                                                ? 'requerido'
                                                                : null
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='edit-or-add-user__second-table__content-input'>
                                                <div className='grid-x edit-or-add-user__second-table__content-input__label__content'>
                                                    <span className='edit-or-add-user__second-table__content-input__label'>
                                                        Confirmar contraseña
                                                        {paramsRouters?.isAddUser &&
                                                            (<span className='edit-or-add-user__second-table__content-input__label__required'>*</span>)
                                                        }
                                                    </span>
                                                </div>
                                                <Input.Password
                                                    value={values.confirmPassword}
                                                    placeholder='Escribe aquí la contraseña'
                                                    onBlur={(value) => handleBlur('confirmPassword')(value)}
                                                    onChange={(value) => onChangeField('confirmPassword', value)}
                                                    className='edit-or-add-user__second-table__content-update-password__input-password'
                                                    status={paramsRouters?.isAddUser ? errors.confirmPassword && touched.confirmPassword ? 'error' : '' : ''}
                                                    prefix={
                                                        <div className='edit-or-add-user__second-table__content-update-password__input-password__icon'>
                                                            <img alt="icon_user" src={Assets.AuthenticationIcons.icon_password} />
                                                        </div>
                                                    }
                                                />
                                                <div className='grid-x justify-content-end'>
                                                    <span className='edit-or-add-user__second-table__content-input__text-error'>
                                                    {paramsRouters?.isAddUser
                                                        ? touched.confirmPassword
                                                            ? errors.confirmPassword
                                                            : null
                                                        : (values.password || values.confirmPassword) && (values.password !== values.confirmPassword)
                                                            ? 'Las contraseñas no coinciden'
                                                            : null
                                                    }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='edit-or-add-user__second-table__content-button-save'>
                                            <button
                                                className='edit-or-add-user__second-table__content-button-save__button'
                                                onClick={() => paramsRouters?.isAddUser ? createUserData() : updateUserData()}
                                                disabled={paramsRouters?.isAddUser ? Object.keys(errors).length !== 0 : disabledUpdateUser(Object.keys(errors).length !== 0)}
                                            >
                                                <span>Guardar cambios</span>
                                            </button>
                                        </div>
                                    </>)
                                :
                                    (<div className='grid-y justify-content-between edit-or-add-user__permissions__content'>
                                        <div className='grid-y edit-or-add-user__permissions__title-container'>
                                            <span className='edit-or-add-user__permissions__title'>Permisos en el sistema</span>
                                            <span className='edit-or-add-user__permissions__description'>A continuación configura los permisos y accesos para este usuario. Estos permisos le brindarán acceso a funciones específicas dentro de la plataforma.</span>
                                        </div>
                                        <div className='grid-x edit-or-add-user__permissions__container-list-checks'>
                                            {permissionsList.map((item, index) => {
                                                return (
                                                    <div key={index} className='grid-x small-6'>
                                                        {item.functionality.length === 1
                                                            ?
                                                                (<div className='edit-or-add-user__permissions__check'>
                                                                    <Checkbox
                                                                        checked={isItemChecked({ id: item.functionality[0].id })}
                                                                        onChange={() => handleCheckboxChange({ id: item.functionality[0].id })}
                                                                    />
                                                                    <span className='edit-or-add-user__permissions__check__text'>
                                                                        {item.functionality[0].label}
                                                                    </span>
                                                                </div>)
                                                            :
                                                                (item.functionality.length > 0 && (
                                                                    <Tree
                                                                        showLine
                                                                        checkable
                                                                        defaultExpandAll
                                                                        selectable={false}
                                                                        switcherIcon={null}
                                                                        onCheck={handleCheckTreeChange}
                                                                        checkedKeys={checkedKeysTree(item.functionality)}
                                                                        treeData={[{
                                                                            key: item?.id,
                                                                            title: item?.label,
                                                                            children: item.functionality.map(funcItem => ({
                                                                                key: funcItem?.id,
                                                                                title: funcItem?.label,
                                                                            })),
                                                                        }]}
                                                                        className='edit-or-add-user__permissions__container-tree'
                                                                    />
                                                                ))
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className='edit-or-add-user__permissions__content-button-save'>
                                            <button
                                                className='edit-or-add-user__permissions__content-button-save__button'
                                                onClick={() => isUserPermissions ? updatePermissionsUser() : createPermissionsUser()}
                                            >
                                                <span>Guardar cambios</span>
                                            </button>
                                        </div>
                                    </div>)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditOrAddUserPage;