//Assets
import { Assets } from '../../../../assets';

//Libraries
import dayjs from 'dayjs';
import { DatePicker, Spin } from 'antd';
import React, { useEffect, useState } from 'react';

//Services
import { getDailyCashClosingReportsService } from '../../../../services/dashboard.services';

//Styles
import './cash-closing-dashboard.component.scss';

//Utils
import { formattedNumber } from '../../../../utils/shared.utils';

const CashClosingDashboardComponent = () => {

  useEffect(() => {
    getDailyCashClosingReports(dayjs(daySelected)?.format('YYYY-MM-DD'));
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [daySelected, setDaySelected] = useState(dayjs());
  const [dailyCashClosingReports, setDailyCashClosingReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getDailyCashClosingReports = async (date) => {
    setIsLoading(true); 
    try {
      const resp = await getDailyCashClosingReportsService(date);
      setTimeout(() => {
        setDailyCashClosingReports(resp);
        setIsLoading(false);
      }, 500);
    } catch (error) {

    }
  };

  const goToDate = (amount) => {
    const newDate = dayjs(daySelected).add(amount, 'day');
    const currentDate = dayjs();
    if (newDate.isBefore(currentDate, 'day') || newDate.isSame(currentDate, 'day')) {
      setDaySelected(newDate);
      getDailyCashClosingReports(dayjs(newDate)?.format('YYYY-MM-DD'));
    }
    setIsLoading(true);
  };

  const disabledDate = (current) => {
    return current && current.isAfter(dayjs(), 'day');
  };

  return (
    <div className='grid-y cash-closing-dashboard__container'>
      <span className='cash-closing-dashboard__title'>
        Cierre de caja
      </span>
      <span className='cash-closing-dashboard__description'>
        Informe diario de las sedes
      </span>

      <div className='grid-x align-middle justify-content-between cash-closing-dashboard__date'>
        <button
          onClick={() => goToDate(-1)}
        >
          <img
            alt='icon_arrow'
            src={Assets.SharedIcons.icon_arrow}
            className='cash-closing-dashboard__arrow'
          />
        </button>
        <DatePicker
          picker="date"
          bordered={false}
          suffixIcon={null}
          allowClear={false}
          value={daySelected}
          inputReadOnly={true}
          defaultValue={dayjs()}
          onChange={(date) => {
            setDaySelected(date);
            getDailyCashClosingReports(dayjs(date)?.format('YYYY-MM-DD'));
          }}
          className='cash-closing-dashboard__month'
          popupClassName='cash-closing-dashboard__month__popup'
          format={(value) => {
            return `${dayjs(value)?.format('dddd D, MMMM')}`;
          }}
          disabledDate={disabledDate}
        />
        <button
          onClick={() => goToDate(1)}
          className='cash-closing-dashboard__button-next'
          disabled={(dayjs(daySelected).add(1, 'day').isAfter(dayjs(), 'day'))}
        >
          <img
            alt='icon_arrow'
            src={Assets.SharedIcons.icon_arrow}
          />
        </button>
      </div>
      <div className='cash-closing-dashboard__headquarters'>
        {isLoading ? (
          <div className='grid-y align-center-middle text-center cash-closing-dashboard__headquarters__without-content'>
            <Spin size="large">
              <div className="content" />
            </Spin>
          </div>
        ) : (
          <>
            {dailyCashClosingReports.length > 0 ? (
                dailyCashClosingReports.map((item, index) => {
                  return (
                    <div key={index} className='grid-y cash-closing-dashboard__headquarters__box'>
                      <div className='grid-x cash-closing-dashboard__headquarters__content-title'>
                        <div style={{ width: '40px' }}>
                          <div className='grid-x justify-content-center cash-closing-dashboard__headquarters__icon-cup'>
                            <img
                              alt="icon_liquor"
                              src={Assets.SharedIcons.icon_liquor}
                            />
                          </div>
                        </div>
                        <div className='grid-x align-middle'>
                          <span className='cash-closing-dashboard__headquarters__title'>
                            Sede <strong>{item?.headQuarterUserChargeHeadQuarter?.name}</strong>
                          </span>
                        </div>
                      </div>
                      <div className='grid-y justify-content-between cash-closing-dashboard__headquarters__content-sales'>
                        <div className='grid-x align-middle justify-content-between cash-closing-dashboard__headquarters__content-info'>
                          <span className='cash-closing-dashboard__headquarters__title'>Ventas Licorera</span>
                          <div className='grid-x align-middle justify-content-center cash-closing-dashboard__headquarters__box-value'>
                            <img
                              alt="icon_add"
                              src={Assets.SharedIcons.icon_income_graph}
                            />
                            <span className='cash-closing-dashboard__headquarters__value'>
                              {formattedNumber(item?.closingCashRegisterCashRegister?.salesTNSCloud  + item?.closingCashRegisterCashRegister?.salesPF)}
                            </span>
                          </div>
                        </div>
                        <div className='grid-x align-middle justify-content-between cash-closing-dashboard__headquarters__content-info'>
                          <span className='cash-closing-dashboard__headquarters__title'>Ventas de Cocina</span>
                          <div className='grid-x align-middle justify-content-center cash-closing-dashboard__headquarters__box-value'>
                            <img
                              alt="icon_add"
                              src={Assets.SharedIcons.icon_income_graph}
                            />
                            <span className='cash-closing-dashboard__headquarters__value'>{formattedNumber(item?.closingCashRegisterCashRegister?.salesPF)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
            ) : (
              <div className='grid-y align-center-middle text-center cash-closing-dashboard__headquarters__without-content'>
                <img
                  alt='icon_arrow'
                  src={Assets.SharedIcons.icon_search}
                />
                <div className='cash-closing-dashboard__headquarters__without-content__title'>
                    Aún no existen informes publicados en este día
                </div>
                <div className='cash-closing-dashboard__headquarters__without-content__first-paragraph'>
                    Es posible que los encargados de las sedes se encuentren preparándolos. 
                </div>
                <div className='cash-closing-dashboard__headquarters__without-content__second-paragraph'>
                    Vuelve en breve y actualiza la plataforma para observar los cambios.
                </div>
            </div>
            )}
          </>
        )}
      </div>
    </div>
  )
};

export default CashClosingDashboardComponent;