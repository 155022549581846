import * as authorizedManagementTypes from './authorized-management.types';

const INITIAL_STATE = {
	userAuthorizedSelected: undefined,
};

/**
 * AuthorizedManagementReducer  - This reducer stores the information related to the authorized module, so we will find the information of an authorized for its sub modules.
 *
 * @param {[object]} userAuthorizedSelected Selected authorized user.
 *
 */

const AuthorizedManagementReducer = (state = INITIAL_STATE, action) => {

	switch (action.type) {
		case authorizedManagementTypes.SET_STATE_AUTHORIZED_MANAGEMENT:
			return {
				...state,
				[action.payload.prop]: action.payload.value
			};

		default:
			return state;
	}

};

export default AuthorizedManagementReducer;