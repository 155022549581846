// Libraries
import {
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import React, { useEffect, useState } from 'react'

// Routes
import InnerContent from "./inner-content.router";
import PublicRoutes from "./public-routes.router";
import ProtectedRoutes from "./protected-routes.router";

// Views - Pages
import LoginPage from "./../pages/authentication/login/login.page";
import ValidateUserPage from "../pages/authentication/validate-user/validate-user.page"
import ValidateCodePage from "../pages/authentication/validate-code/validate-code.page"
import NewPasswordPage from "../pages/authentication/new-password/new-password.page";
import DeniedPage from "../pages/authentication/denied/denied.page";
import NotFoundPage from "../pages/authentication/not-found/not-found.page";
import DailyMovementsPage from "../pages/daily-movements/daily-movements.page";
import PortfolioPage from "../pages/portfolio/portfolio/portfolio.page";
import PortfolioDetailPage from "../pages/portfolio/portfolio-detail/portfolio-detail.page";
import CashClosingPage from "../pages/cash-closing/cash-closing.page";
import DashboardPage from "../pages/ dashboard/dashboard.page";
import BanksPage from "../pages/banks/banks/banks.page";
import BanksDetailPage from "../pages/banks/banks-detail/banks-detail.page";
import ReportsPage from "../pages/reports/reports.page";
import CashClosingInventoryPage from "../pages/cash-closing-inventory/cash-closing-inventory/cash-closing-inventory.page";
import AuthorizedManagementPage from "../pages/authorized-management/authorized-management/authorized-management.page";
import FinalBalanceTreasuryPage from "../pages/final-balance/final-balance.page";
import ViewAndEditSummaryPage from "../pages/headquarters/view-and-edit-summary/view-and-edit-summary.page";
import HeadquartersPage from "../pages/headquarters/headquarters/headquarters.page";
import AuthorizationHistoryPage from "../pages/authorized-management/authorization-history/authorization-history.page";
import AuthorizedCreateEditPage from "../pages/authorized-management/authorized-create-edit/authorized-create-edit.page";
import AdminPanelPage from "../pages/admin-panel/admin-panel/admin-panel.page";
import HeadquarterCreateEditPage from "../pages/admin-panel/headquarter-create-edit/headquarter-create-edit.page";
import EditOrAddUserPage from "../pages/admin-panel/edit-or-add-user/edit-or-add-user.page";
import CashClosingWholesalePage from "../pages/cash-closing-wholesale/cash-closing-wholesale.page";
import DetailCashClosingPage from "../pages/detail-cash-closing/detail-cash-closing.page";
import FinalAdjustmentPage from "../pages/final-adjustment/final-adjustment.page";
import FinalReconciliationPage from "../pages/final-reconciliation/final-reconciliation.page";

//Services
import { getUserPermissionsService } from "../services/authentication.services";

const AppRouter = () => {
    const INITIAL_STATE = {
        route: ''
    }
    const [state, setState] = useState(INITIAL_STATE);
    const { route } = state;
    useEffect(() => {
        const permissionsAssociateByUser = getUserPermissionsService()
        if (permissionsAssociateByUser) {
            const [sortedPermissions] = permissionsAssociateByUser.slice().sort((a, b) => a.type - b.type);
            const functionality = sortedPermissions.moduleFunctionality;
            if (functionality) {
                const [permission] = functionality;
                setState((prevState) => ({
                    ...prevState,
                    route: permission.route,
                }))
            }
        }
    }, [])
    return (
        <Routes>
            {/** Protected Routes */}
            {/** Wrap all Route under ProtectedRoutes element */}
            <Route path="/" element={<ProtectedRoutes />}>
                <Route path="/" element={<InnerContent />}>
                    <Route path="/*" element={<Navigate replace to={`/${route}`} />} />
                    <Route path="/dailyTransactionTreasury" element={<DailyMovementsPage />} />
                    <Route path="/wallet" element={<PortfolioPage />} />
                    <Route path="/wallet/:id" element={<PortfolioDetailPage />} />
                    <Route path="/banksTreasury" element={<BanksPage />} />
                    <Route path="/addBank" element={<BanksDetailPage />} />
                    <Route path="/banksTreasury/:id" element={<BanksDetailPage />} />
                    <Route path="/adminPanel" element={<AdminPanelPage />} />
                    <Route path="/adminPanel/:tab?" element={<AdminPanelPage />} />
                    <Route path="/adminPanel/userDetails/:id" element={<EditOrAddUserPage />} />
                    <Route path="/adminPanel/addUser" element={<EditOrAddUserPage />} />
                    <Route path="/reportCashClosingHeadquarter" element={<CashClosingPage />} />
                    <Route path="/dashboard" element={<DashboardPage />} />
                    <Route path="/inventory" element={<ReportsPage />} />
                    <Route path="/cashClosingInventory" element={<CashClosingInventoryPage />} />
                    <Route path="/cashClosingInventory/:id" element={<CashClosingPage />} />
                    <Route path="/authorizedManagement" element={<AuthorizedManagementPage />} />
                    <Route path="/authorizedManagement/:id" element={<AuthorizationHistoryPage />} />
                    <Route path="/authorizedManagement/createEdit" element={<AuthorizedCreateEditPage />} />
                    <Route path="/viewAndEditSummary" element={<HeadquartersPage />} />
                    <Route path="/viewAndEditSummary/:id" element={<ViewAndEditSummaryPage />} />
                    <Route path="/finalBalanceTreasury" element={<FinalBalanceTreasuryPage />} />
                    <Route path="/headquarterBusiness/createEdit" element={<HeadquarterCreateEditPage />} />
                    <Route path="/headquarterBusiness/:id" element={<HeadquarterCreateEditPage />} />
                    <Route path="/reportCashClosingWholesaler" element={<CashClosingWholesalePage />} />
                    <Route path="/reportCashClosingWholesaler/:id" element={<DetailCashClosingPage />} />
                    <Route path="/finalBalanceWholesaler" element={<FinalAdjustmentPage />} />
                    <Route path="/finalBalanceWholesaler/:id" element={<FinalReconciliationPage />} />


                    {/* Examples routes on app-root.router */}
                    {/* <Route path="tabs" element={<Tabs props={{userName: "Lions Team"}} />}> */}
                    {/* <Route path="/tabs" element={<Navigate replace to="tab1" />} /> */}
                    {/* <Route path="tab1" element={<Tab1 />} /> */}
                    {/* <Route path="tab3" element={<Tab3 />} /> */}
                </Route>
            </Route>

            {/** Public Routes */}
            {/** Wrap all Route under PublicRoutes element */}
            <Route path="/" element={<PublicRoutes />}>
                <Route path="/" element={<Navigate replace to="login" />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/validateUser" element={<ValidateUserPage />} />
                <Route path="/validateCode" element={<ValidateCodePage />} />
                <Route path="/newPassword" element={<NewPasswordPage />} />
            </Route>

            {/** Permission denied route */}π
            <Route path="/denied" element={<DeniedPage />} />

            {/** Not found page */}
            <Route path="/NotFoundPage" element={<NotFoundPage />} />
            <Route path="*" element={<Navigate replace to="/NotFoundPage" />} />

        </Routes>
    )
}

export default AppRouter