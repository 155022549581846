
export const dataPackages = { 
    reference:"",  
    price: "", 
    amount: null, 
    total: "", 
    disabled: true 
}

export const dataInventory = { 
    code:"",  
    nameProduct: "", 
    amount: null, 
    liquid: "", 
    package: "", 
    total: "", 
    disabled: true 
}