// Assets
import { Assets } from '../../../../../assets';

// Components
import ErrorAlertComponent from '../../../../../shared/components/alerts/error-alert/error-alert.component';

// Components - shared
import PaginationComponent from '../../../../../shared/components/pagination/pagination.component';

// Libraries
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { Checkbox, Dropdown, Image, Input } from 'antd';

// Services
import { getAllUsersService } from '../../../../../services/administration-panel.services';

// Styles
import './users-permissions.component.scss';

const UsersPermissionsComponent = () => {

  let history = useNavigate();

  const [filterKey, setFilterKey ] = useState();

  const INITIAL_STATE = {
    page: 1,
    amount: 0,
    totalPages: 0,
    listOfUsers: [],
    checkedItems: [],
    filterSearch: '',
    isHoverSelected: false
  }
  const [state, setState] = useState(INITIAL_STATE);

  const {
    page,
    totalPages,
    listOfUsers,
    checkedItems,
    filterSearch,
    isHoverSelected
  } = state;

  useEffect(() => {
    getListUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filterSearch]);

  const getListUser = async () => {
    try {
      const { amount, users } = await getAllUsersService(filterSearch, null, page);
      setState((prevState) => ({
        ...prevState,
        listOfUsers: users,
        totalPages: amount,
      }));
    } catch (error) {
      ErrorAlertComponent();
    }
  };

  const handleCheckboxChange = (idBank) => {
    const checkedItem = checkedItems.includes(idBank) ? checkedItems.filter(itemIndex => itemIndex !== idBank) : [...checkedItems, idBank];

    setState((prevState) => ({
      ...prevState,
      checkedItems: checkedItem
    }))
  };

  const isItemChecked = (idBank) => checkedItems.includes(idBank);

  const items = [
    {
      key: '0',
      label:
        <div className='users-permissions__content-search__select-user__checkbox'>
          <Checkbox
            style={{ width: '100%' }}
            checked={filterKey === "0"}
            onChange={() => handleFilter("0")}
          >
            Ascendente A-Z
          </Checkbox>
        </div>
    },
    {
      key: '1',
      label:
        <div className='users-permissions__content-search__select-user__checkbox'>
          <Checkbox
            style={{ width: '100%' }}
            checked={filterKey === "1"}
            onChange={() => handleFilter("1")}
          >
            Descendente Z-A
          </Checkbox>
        </div>
    },
    {
      key: '2',
      label:
        <div className='users-permissions__content-search__select-user__checkbox'>
          <Checkbox
            style={{ width: '100%' }}
            checked={filterKey === "2"}
            onChange={() => handleFilter("2")}
          >
            Mayor a menor
          </Checkbox>
        </div>
    },
    {
      key: '3',
      label:
        <div className='users-permissions__content-search__select-user__checkbox'>
          <Checkbox
            style={{ width: '100%' }}
            checked={filterKey === "3"}
            onChange={() => handleFilter("3")}
          >
            Menor a mayor
          </Checkbox>
        </div>
    },
  ];

  const handleFilter = (key) => {
    let sortedUsers = [...listOfUsers];
    switch (key) {
      case '0':
        sortedUsers.sort((a, b) => a.nickName.localeCompare(b.nickName));
        break;
      case '1':
        sortedUsers.sort((a, b) => b.nickName.localeCompare(a.nickName));
        break;
      case '2':
        sortedUsers.sort((a, b) => b.identificationNumber - a.identificationNumber);
        break;
      case '3':
        sortedUsers.sort((a, b) => a.identificationNumber - b.identificationNumber);
        break;
      default:
        break;
    };
    setFilterKey(key);
    setState({ ...state, listOfUsers: sortedUsers });
  };

  return (
    <div className='users-permissions__container'>
      <div className='grid-x align-content-end justify-content-between users-permissions__content-search'>
        <Input
          value={filterSearch}
          className='users-permissions__content-search__input'
          placeholder='Buscar usuario por nombre o número de documento'
          onChange={(e) => setState({ ...state, filterSearch: e.target.value })}
          prefix={<img src={Assets.SharedIcons.icon_search_magnifier} alt="icon_search_magnifier" />}
        />
        <div className='grid-x'>
          <div className='users-permissions__content-search__select-user'>
            <Dropdown
              menu={{ items }}
              trigger={['click']}
              className='grid-x small-12'
            >
              <div
                onMouseOver={() => setState({...state,isHoverSelected:true})}
                onMouseOut={() => setState({...state,isHoverSelected:false})}
                className='grid-x align-middle justify-content-center users-permissions__content-search__select-user__content-selected'
              >
                <img
                  alt={isHoverSelected ? "icon_filter_hover" : "icon_filter"}
                  src={isHoverSelected ? Assets.SharedIcons.icon_filter_hover : Assets.SharedIcons.icon_filter}
                />
                <span className={`users-permissions__content-search__select-user__text${isHoverSelected ? '__hover' : ''}`}>
                  Ordenar por
                </span>
              </div>
            </Dropdown>
          </div>
          <button
            className='users-permissions__content-search__button'
            onClick={() => history(`/adminPanel/addUser`, { state: { isAddUser: true } })}
          >
            <img src={Assets.SharedIcons.icon_add_white} alt="icon_search" />
            <span className='users-permissions__content-search__button__text'>Agregar nuevo usuario</span>
          </button>
        </div>
      </div>
      <div className='users-permissions__table'>
        <div className='grid-x align-middle users-permissions__table__container-title'>
          <div className='grid-x align-center users-permissions__table__width-column__first'>
            <span className='users-permissions__table__title--mod'>Foto</span>
          </div>
          <span className='users-permissions__table__title users-permissions__table__width-column__second'>Nombre del usuario</span>
          <span className='users-permissions__table__title users-permissions__table__width-column__third'>Número de identidad</span>
          <span className='users-permissions__table__title users-permissions__table__width-column__second'>Nro. de contacto</span>
          <span className='users-permissions__table__title users-permissions__table__width-column__fourth'>Correo electrónico</span>
          <span className='users-permissions__table__title users-permissions__table__width-column__second'>Cargo u ocupación</span>
          <span className='users-permissions__table__title users-permissions__table__width-column__fifth'>Acciones</span>
        </div>
        <div className='users-permissions__table__container'>
          {listOfUsers.map((user, index) => (
            <div key={index} className={`grid-x align-middle users-permissions__table__information-row${isItemChecked(user.id) ? '__selected' : ''}`}>
              <div className='grid-x users-permissions__table__width-column__first'>
                <div className='grid-x align-middle justify-content-center users-permissions__table__information-row__width-row__first'>
                  <div className='users-permissions__table__checkbox'>
                    <Checkbox
                      checked={isItemChecked(user.id)}
                      onChange={() => handleCheckboxChange(user.id)}
                    />
                  </div>
                </div>
                <div className='grid-x align-middle users-permissions__table__information-row__width-row__second'>
                  <span className='users-permissions__table__description'>
                    <Image
                      width={'50%'}
                      className='users-permissions__table__profile-image'
                      src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                    />
                  </span>
                </div>
              </div>
              <div className='grid-x users-permissions__table__width-column__second'>
                <span className='users-permissions__table__description'>
                  {user.name}
                </span>
              </div>
              <div className='grid-x users-permissions__table__width-column__third'>
                <span className='users-permissions__table__description'>
                  {user.identificationNumber}
                </span>
              </div>
              <div className='grid-x users-permissions__table__width-column__second'>
                <span className='users-permissions__table__description--mod'>
                  {user.phoneNumber}
                </span>
              </div>
              <div className='grid-x users-permissions__table__width-column__fourth'>
                <span className='users-permissions__table__description'>
                  {user.email}
                </span>
              </div>
              <div className='grid-x users-permissions__table__width-column__second'>
                <span className='users-permissions__table__description'>
                  {user.position}
                </span>
              </div>
              <div className='grid-x users-permissions__table__width-column__fifth'>
                <button
                  className='grid-x align-middle users-permissions__table__button'
                  onClick={() => history(`/adminPanel/userDetails/${user.id}`, { state: { userId: user.id, isAddUser: false } })}
                >
                  <span>Ver detalles</span>
                  <img className='users-permissions__table__icon-arrow' src={Assets.SharedIcons.icon_arrow} alt="icon_arrow" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='grid-x align-middle justify-content-between users-permissions__content-pagination'>
        <span className='users-permissions__content-pagination__text'>
          Páginas: <span className='users-permissions__content-pagination__text--mod'>{page}</span> de <span className='users-permissions__content-pagination__text--mod'>{Math.ceil(totalPages / 10)}</span>
        </span>
        <PaginationComponent
          page={page}
          pageItem={10}
          totalItems={totalPages}
          onPage={(page) => {
            setState({ ...state, page })
          }}
        />
      </div>
    </div>
  )
}

export default UsersPermissionsComponent;