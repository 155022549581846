import * as adminHeadquarterTypes from './admin-headquarter.types';

const INITIAL_STATE = {
	adminHeadquarterSelected: undefined,
};

/**
 * AdminHeadquarterReducer  - This reducer stores the information related to the module headquarter or businesses of the administrator panel, so we will find the information of a headquarter.
 *
 * @param {[object]} adminHeadquarterSelected Selected headquarter.
 *
 */

const AdminHeadquarterReducer = (state = INITIAL_STATE, action) => {

	switch (action.type) {
		case adminHeadquarterTypes.SET_STATE_ADMIN_HEADQUARTER:
			return {
				...state,
				[action.payload.prop]: action.payload.value
			};

		default:
			return state;
	}

};

export default AdminHeadquarterReducer;