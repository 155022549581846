import SharedEndPoints from "./constants/shared.end-points"
import Http from "./infrastructure/http.infrastructure"


export async function getChargesService() {
    try {
        const { data: responseGetCharge, error } = await Http.get(SharedEndPoints.GET_ALL_CHARGE, null)
        if (responseGetCharge && responseGetCharge.status) {
            const responseCharge = responseGetCharge.data.map((charge) => ({
                value: charge.id,
                label: charge.name
            })
            );
            return responseCharge
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getProvidersService() {
    try {
        const { data: responseGetProviders, error } = await Http.get(SharedEndPoints.GET_PROVIDERS, null)
        if (responseGetProviders && responseGetProviders.status) {
            const responseProviders = responseGetProviders.data.map((provider) => ({
                value: provider.id,
                label: provider.name.toLowerCase().replace(/\b\w/g, c => c.toUpperCase())
            })
            );
            return responseProviders
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getPaymentMethodService() {
    try {
        const { data: responseGetPaymentMethod, error } = await Http.get(SharedEndPoints.GET_ALL_PAYMENT_METHOD_PROVIDERS, null)
        if (responseGetPaymentMethod && responseGetPaymentMethod.status) {
            const responsePaymentMethod = responseGetPaymentMethod.data.map((paymentMethod) => ({
                value: paymentMethod.id,
                label: paymentMethod.name.toLowerCase().replace(/\b\w/g, c => c.toUpperCase())
            })
            );
            return responsePaymentMethod
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getAllInternalConsumptionCategoriesService() {
    try {
        const { data: responseGetAllInternalConsumptionCategories, error } = await Http.get(SharedEndPoints.GET_ALL_INTERNAL_CONSUMPTION_CATEGORY, null)
        if (responseGetAllInternalConsumptionCategories && responseGetAllInternalConsumptionCategories.status) {
            const responseInternalConsumptionCategories = responseGetAllInternalConsumptionCategories.data.map((category) => ({
                value: category.id,
                label: category.name.toLowerCase().replace(/\b\w/g, c => c.toUpperCase())
            })
            );
            return responseInternalConsumptionCategories
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getAllInternalConsumptionProductsService(idCategory) {
    try {
        const { data: responseGetAllInternalConsumptionProducts, error } = await Http.get(SharedEndPoints.GET_ALL_INTERNAL_CONSUMPTION_PRODUCT, idCategory)
        if (responseGetAllInternalConsumptionProducts && responseGetAllInternalConsumptionProducts.status) {
            const responseInternalConsumptionProducts = responseGetAllInternalConsumptionProducts.data.map((product) => ({
                value: product.id,
                label: product.name.toLowerCase().replace(/\b\w/g, c => c.toUpperCase())
            })
            );
            return responseInternalConsumptionProducts
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getAllHeadQuartersService() {
    try {
        const { data: responseGetAllHeadQuarters, error } = await Http.get(SharedEndPoints.GET_ALL_HEAD_QUARTERS)
        if (responseGetAllHeadQuarters && responseGetAllHeadQuarters.status) {
            const response = responseGetAllHeadQuarters.data.map((headQuarter) => ({
                ...headQuarter,
                value: headQuarter.id,
                label: headQuarter.name
            })
            );
            return response;
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getAllExpenseCategoryService() {
    try {
        const { data: responseGetAllCategories, error } = await Http.get(SharedEndPoints.GET_ALL_EXPENSE_CATEGORY)
        if (responseGetAllCategories && responseGetAllCategories.status) {
            const response = responseGetAllCategories.data.map((category) => ({
                ...category,
                value: category.id,
                label: category.name.toLowerCase().replace(/\b\w/g, c => c.toUpperCase())
            })
            );
            return response;
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getExpenseSubCategoryService(idExpenseCategory) {
    try {
        const { data: responseGetExpenseSubCategory, error } = await Http.get(SharedEndPoints.GET_EXPENSE_SUB_CATEGORY, idExpenseCategory)
        if (responseGetExpenseSubCategory && responseGetExpenseSubCategory.status) {
            const response = responseGetExpenseSubCategory.data.map((category) => ({
                ...category,
                value: category.id,
                label: category.name.toLowerCase().replace(/\b\w/g, c => c.toUpperCase())
            })
            );
            return response
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getPaymentMethodDailyTransactionService(body) {
    try {
        const { data: responseGetPaymentMethodDailyTransaction, error } = await Http.get(SharedEndPoints.GET_PAYMENT_METHOD_DAILY_TRANSACTION, body)
        if (responseGetPaymentMethodDailyTransaction && responseGetPaymentMethodDailyTransaction.status) {
            const response = responseGetPaymentMethodDailyTransaction.data.map((paymentMethod) => ({
                idHeadQuarter: paymentMethod.idHeadQuarter,
                value: paymentMethod.id,
                label: paymentMethod.name
            })
            );
            return response
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getIndicativeNumbersService() {
    try {
        const { data: responseGetIndicativeNumbers, error } = await Http.get(SharedEndPoints.GET_All_INDICATIVE_NUMBERS)
        if (responseGetIndicativeNumbers && responseGetIndicativeNumbers.status) {
            const response = responseGetIndicativeNumbers.data.map((indicativeNumber) => ({
                value: indicativeNumber.id,
                label: indicativeNumber.indicative
            }));
            return response
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getAllPermissionsService() {
    try {
        const { data: responseGetAllPermissions, error } = await Http.get(SharedEndPoints.GET_All_PERMISSIONS)
        if (responseGetAllPermissions && responseGetAllPermissions.status) {
            return responseGetAllPermissions.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getUserAvailableHeadquarterService() {
    try {
        const { data: responseGetUserAvailable, error } = await Http.get(SharedEndPoints.GET_All_USERS_AVAILABLE_HEADQUARTER, null)
        if (responseGetUserAvailable && responseGetUserAvailable.status) {
            const responseUserAvailable = responseGetUserAvailable.data.map((userAvailable) => ({
                value: userAvailable.id,
                label: userAvailable.name
            })
            );
            return responseUserAvailable
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getConceptNoReportService(isCredit,filter) {
    try {
        const urlWithParams = SharedEndPoints.GET_CONCEPT.replace(":filterValue", filter).replace(":isCredit", isCredit)
        const { data: responseGetConcept, error } = await Http.get(urlWithParams)
        if (responseGetConcept && responseGetConcept.status) {
            const resp = responseGetConcept.data.map((concept) => ({
                value: concept
            }))
            return resp
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};