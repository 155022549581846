// Assets
import { Assets } from '../../../assets';

// Components - Shared
import ErrorAlertComponent from '../../../shared/components/alerts/error-alert/error-alert.component';
import NoInformationResultsComponent from '../../../shared/components/no-information-results/no-information-results.component';

// Libraries
import React, { useEffect, useState } from 'react'
import { Checkbox, DatePicker, Input, Spin } from 'antd';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

// Services
import { getAllHeadquartersService } from '../../../services/headquarters.services';

// Styles
import "./headquarters.page.scss";

// Utils
import { formattedNumber } from '../../../utils/shared.utils';


const HeadquartersPage = () => {

  const history = useNavigate();

  const INITIAL_STATE = {
    isLoading: true,
    selectedMonth: dayjs(),
    isHoverName: false,
    isHoverExpenses: false,
    isHoverSales: false,
    isHoverPortfolio: false,
    isHoverExecution: false,
    isHoverCompliance: false,
    checkedItems: [],
    headquartersList: [],
    headquartersListCopy: []
  }

  const [state, setState] = useState(INITIAL_STATE)
  
  const {
    isLoading,
    selectedMonth,
    isHoverName,
    isHoverExpenses,
    isHoverSales,
    isHoverPortfolio,
    isHoverExecution,
    isHoverCompliance,
    checkedItems,
    headquartersList,
    headquartersListCopy
  } = state;

  useEffect(() => {
    getAllHeadquarters();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth])

  const goToMonth = (month) => {
    const newDate = dayjs(selectedMonth).add(month, 'month');
    const currentDate = dayjs();

    if (newDate.isBefore(currentDate, 'month') || newDate.isSame(currentDate, 'month')) {
      setState((prevState) => ({
        ...prevState,
        selectedMonth: newDate
      }))
    }
  };

  const handleCheckboxChange = (index) => {

    const prevItem = checkedItems.includes(index) ? checkedItems.filter(itemIndex => itemIndex !== index) : [...checkedItems, index];

    setState((prevState) => ({
      ...prevState,
      checkedItems: prevItem
    }))
  };

  const isItemChecked = (index) => checkedItems.includes(index);

  const setStateHover = (value) => {
    setState({
      ...state,
      isHoverName: value === 'name' ? true : false,
      isHoverExpenses: value === 'expenses' ? true : false,
      isHoverSales: value === 'liquor' ? true : false,
      isHoverPortfolio: value === 'kitchen' ? true : false,
      isHoverExecution: value === 'execution' ? true : false,
      isHoverCompliance: value === 'compliance' ? true : false,
    })
  }

  const viewAndEditSummary = async (headquarter) => {
    try {
      history(`/viewAndEditSummary/${headquarter?.id}`, { state: { selectedMonth: dayjs(selectedMonth).format('YYYY-MM-DD'), headquarter: headquarter } });
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  }

  const getAllHeadquarters = async () => {
    try {
      setState((prevState) => ({...prevState, isLoading: true}))
      const getHeadquarters = await getAllHeadquartersService(dayjs(selectedMonth).startOf('month').format('YYYY-MM-DD'))
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        headquartersList: getHeadquarters,
        headquartersListCopy: getHeadquarters
      }));
    } catch (error) {
      ErrorAlertComponent();
      setState((prevState) => ({...prevState, isLoading: false}))
    }
  }

  const isSortedAscendingByAttribute = (list, attributeName) => {
    return list.every((item, index) => index === 0 || item[attributeName] >= list[index - 1][attributeName]);
  }

  const optionFilter = (nameFilter, headquarters) => {
    let sortedHeadQuarters = [...headquarters];
    const isSorted = isSortedAscendingByAttribute(headquarters, nameFilter);
    const sortOrder = isSorted ? 1 : -1;
    if(nameFilter === 'name'){
      sortedHeadQuarters.sort((a, b) => (sortOrder * (b.name.localeCompare(a.name)) ));
    }else{
      sortedHeadQuarters.sort((a, b) => (nameFilter ? sortOrder * (b[nameFilter] - a[nameFilter]) : 0));
    }
    
    setState((prevState) => ({
      ...prevState,
      headquartersList: sortedHeadQuarters,
    }));
  };

  
  const filterHeadquarterName = (data) => {
    let value = data && data.target ? data.target.value || '' : data;
    const filteredList = value.trim() !== ''
      ? headquartersListCopy.filter(headquarter => headquarter.name.toLowerCase().includes(value.toLowerCase()))
      : headquartersListCopy;
    
    setState({
      ...state,
      headquartersList: filteredList
    });
    optionFilter('name', filteredList)
  };

  return (
    <div className='headquarters__container'>
      <div className='grid-x align-content-end headquarters__content-search'>
        <div className='grid-x align-middle headquarters__container-search'>
          <span className='headquarters__title'>Cuadre de Sedes</span>
          <Input
            className='headquarters__content-search__input'
            onChange={(value) => filterHeadquarterName(value)}
            placeholder='Buscar sede'
            prefix={<img src={Assets.SharedIcons.icon_search_magnifier} alt="icon_search_magnifier" />}
          />
        </div>
        <div className='grid-x align-right headquarters__content-date-picker'>
          <div className='grid-x align-middle justify-content-between headquarters__content-date-picker__date'>
            <button onClick={() => goToMonth(-1)}>
              <img
                alt='icon_arrow'
                src={Assets.SharedIcons.icon_arrow}
                className='headquarters__content-date-picker__arrow'
              />
            </button>
            <DatePicker
              picker='month'
              bordered={false}
              suffixIcon={null}
              allowClear={false}
              inputReadOnly={true}
              value={selectedMonth}
              defaultValue={dayjs()}
              disabledDate={(current) => current.isAfter(dayjs(), 'month')}
              onChange={(date) => setState((prevState) => ({...prevState, selectedMonth: date}))}
              className='headquarters__content-date-picker__month'
              format={(value) => {
                return `${dayjs(value)?.format('MMMM, YYYY')}`;
              }}
            />
            <button
              onClick={() => goToMonth(1)}
              className='headquarters__content-date-picker__button-next'
              disabled={(dayjs(selectedMonth).add(1, 'month').isAfter(dayjs(), 'month'))}
            >
              <img
                alt={(dayjs(selectedMonth).add(1, 'month').isAfter(dayjs(), 'month')) ? 'icon_arrow_picker_disabled' : 'icon_arrow'}
                src={(dayjs(selectedMonth).add(1, 'month').isAfter(dayjs(), 'month')) ? Assets.SharedIcons.icon_arrow_picker_disabled : Assets.SharedIcons.icon_arrow}
              />
            </button>
          </div>
        </div>
      </div>
      <div className='grid-x headquarters__container-headquarters'>
        <div className='grid-x small-12 headquarters__content-result'>
          <div className='grid-x align-middle headquarters__content-result__container-title'>
            <div className='grid-x justify-content-end headquarters__content-result__title__content--mod'>
              <button
                onClick={() => optionFilter('name', headquartersList)}
                onMouseOver={() => setStateHover('name')}
                onMouseOut={() => setState({ ...state, isHoverName: false })}
                className='grid-x align-middle headquarters__content-search__select-headquarter__content-selected'
              >
                <img
                  alt={isHoverName ? "icon_filter_hover" : "icon_filter"}
                  src={isHoverName ? Assets.SharedIcons.icon_blue_arrow_down : Assets.SharedIcons.icon_blue_arrow}
                  className={`grid-x align-middle headquarters__content-search__select-headquarter__filter-icon${isSortedAscendingByAttribute(headquartersList, 'name') ? '__active' : ''}`}
                />
                <span className={`headquarters__content-search__select-headquarter__text${isHoverName ? '__hover' : ''}`}>
                  Nombre de la sede
                </span>
              </button>
            </div>
            <div className='grid-x headquarters__content-result__title__content'>
              <button
                onClick={() => optionFilter('totalExpense', headquartersList)}
                onMouseOver={() => setStateHover('expenses')}
                onMouseOut={() => setState({ ...state, isHoverExpenses: false })}
                className='grid-x align-middle headquarters__content-search__select-headquarter__content-selected'
              >
                <img
                  alt={isHoverExpenses ? "icon_filter_hover" : "icon_filter"}
                  src={isHoverExpenses ? Assets.SharedIcons.icon_blue_arrow_down : Assets.SharedIcons.icon_blue_arrow}
                  className={`grid-x align-middle headquarters__content-search__select-headquarter__filter-icon${isSortedAscendingByAttribute(headquartersList, 'totalExpense') ? '__active' : ''}`}
                />
                <span className={`headquarters__content-search__select-headquarter__text${isHoverExpenses ? '__hover' : ''}`}>
                  Gastos del mes
                </span>
              </button>
            </div>
            <div className='grid-x headquarters__content-result__title__content'>
              <button
                onClick={() => optionFilter('cashReceivedReportCashClosing', headquartersList)}
                onMouseOver={() => setStateHover('liquor')}
                onMouseOut={() => setState({ ...state, isHoverSales: false })}
                className='grid-x align-middle headquarters__content-search__select-headquarter__content-selected'
              >
                <img
                  alt={isHoverSales ? "icon_filter_hover" : "icon_filter"}
                  src={isHoverSales ? Assets.SharedIcons.icon_blue_arrow_down : Assets.SharedIcons.icon_blue_arrow}
                  className={`grid-x align-middle headquarters__content-search__select-headquarter__filter-icon${isSortedAscendingByAttribute(headquartersList, 'cashReceivedReportCashClosing') ? '__active' : ''}`}
                />
                <span className={`headquarters__content-search__select-headquarter__text${isHoverSales ? '__hover' : ''}`}>
                  Ventas del mes
                </span>
              </button>
            </div>
            <div className='grid-x headquarters__content-result__title__content'>
              <button
                onClick={() => optionFilter('treasuryPortfolio', headquartersList)}
                onMouseOver={() => setStateHover('kitchen')}
                onMouseOut={() => setState({ ...state, isHoverPortfolio: false })}
                className='grid-x align-middle headquarters__content-search__select-headquarter__content-selected'
              >
                <img
                  alt={isHoverPortfolio ? "icon_filter_hover" : "icon_filter"}
                  src={isHoverPortfolio ? Assets.SharedIcons.icon_blue_arrow_down : Assets.SharedIcons.icon_blue_arrow}
                  className={`grid-x align-middle headquarters__content-search__select-headquarter__filter-icon${isSortedAscendingByAttribute(headquartersList, 'treasuryPortfolio') ? '__active' : ''}`}
                />
                <span className={`headquarters__content-search__select-headquarter__text${isHoverPortfolio ? '__hover' : ''}`}>
                  Cartera con tesorería
                </span>
              </button>
            </div>
            <div className='grid-x align-center headquarters__content-result__title__content'>
              <button
                onClick={() => optionFilter('percentageExpenditure', headquartersList)}
                onMouseOver={() => setStateHover('execution')}
                onMouseOut={() => setState({ ...state, isHoverExecution: false })}
                className='grid-x align-middle justify-content-center headquarters__content-search__select-headquarter__content-selected'
              >
                <img
                  alt={isHoverExecution ? "icon_filter_hover" : "icon_filter"}
                  src={isHoverExecution ? Assets.SharedIcons.icon_blue_arrow_down : Assets.SharedIcons.icon_blue_arrow}
                  className={`grid-x align-middle headquarters__content-search__select-headquarter__filter-icon${isSortedAscendingByAttribute(headquartersList, 'percentageExpenditure') ? '__active' : ''}`}
                />
                <span className={`headquarters__content-search__select-headquarter__text${isHoverExecution ? '__hover' : ''}`}>
                  % de Ejecución
                </span>
              </button>
            </div>
            <div className='grid-x align-center headquarters__content-result__title__content'>
              <button
                onClick={() => optionFilter('percentageSales', headquartersList)}
                onMouseOver={() => setStateHover('compliance')}
                onMouseOut={() => setState({ ...state, isHoverCompliance: false })}
                className='grid-x align-middle justify-content-center headquarters__content-search__select-headquarter__content-selected'
              >
                <img
                  alt={isHoverCompliance ? "icon_filter_hover" : "icon_filter"}
                  src={isHoverCompliance ? Assets.SharedIcons.icon_blue_arrow_down : Assets.SharedIcons.icon_blue_arrow}
                  className={`grid-x align-middle headquarters__content-search__select-headquarter__filter-icon${isSortedAscendingByAttribute(headquartersList, 'percentageSales') ? '__active' : ''}`}
                />
                <span className={`headquarters__content-search__select-headquarter__text${isHoverCompliance ? '__hover' : ''}`}>
                  % de Cumplimiento
                </span>
              </button>
            </div>
            <div className='grid-x headquarters__content-result__title__content--mod-second'>
              <span className='headquarters__content-result__title'>Acciones</span>
            </div>
          </div>
          <div className='headquarters__content-result__content-info'>
            {isLoading
              ? (<Spin size='large' className='headquarters__spinner' />)
              : headquartersList?.length
                ? (headquartersList?.map((headquarters, index) => {
                    return (
                      <div key={index} className={`grid-x align-middle headquarters__content-result__content-info__information-row${isItemChecked(index) ? '__selected' : ''}`}>
                        <div className='grid-x headquarters__content-result__content-info__content-fragment--mod'>
                          <div className='grid-x align-middle justify-content-center headquarters__content-result__content-info__content-checkbox'>
                            <div className='headquarters__content-result__content-info__checkbox'>
                              <Checkbox checked={isItemChecked(index)} onChange={() => handleCheckboxChange(index)} />
                            </div>
                          </div>
                          <div className='grid-x align-middle headquarters__content-result__content-info__content-location'>
                            <span className='headquarters__content-result__content-info__description'>
                              {headquarters?.name}
                            </span>
                          </div>
                        </div>
                        <div className='grid-x headquarters__content-result__content-info__content-fragment'>
                          <span className='headquarters__content-result__content-info__description'>
                            {formattedNumber(headquarters?.totalExpense)}
                          </span>
                        </div>
                        <div className='grid-x headquarters__content-result__content-info__content-fragment'>
                          <span className='headquarters__content-result__content-info__description'>
                            {formattedNumber(headquarters?.cashReceivedReportCashClosing)}
                          </span>
                        </div>
                        <div className='grid-x headquarters__content-result__content-info__content-fragment'>
                          <span className='headquarters__content-result__content-info__description'>
                            {formattedNumber(headquarters?.treasuryPortfolio)}
                          </span>
                        </div>
                        <div className='grid-x align-center headquarters__content-result__content-info__content-fragment'>
                          <Input
                            title={Math.ceil(headquarters?.percentageExpenditure) || 0}
                            className="headquarters__input-percentage__green"
                            disabled
                            value={Math.ceil(headquarters?.percentageExpenditure) || 0}
                            suffix="%"
                          />
                        </div>
                        <div className='grid-x align-center headquarters__content-result__content-info__content-fragment'>
                          <Input
                            title={Math.ceil(headquarters?.percentageSales) || 0}
                            className="headquarters__input-percentage"
                            disabled
                            value={Math.ceil(headquarters?.percentageSales) || 0}
                            suffix="%"
                          />
                        </div>
                        <div className='grid-x headquarters__content-result__content-info__content-fragment--mod-second'>
                          <button
                            className='grid-x align-middle headquarters__content-result__content-info__button'
                            onClick={() => viewAndEditSummary(headquarters)}
                          >
                            <span>Ver y editar resumen</span>
                            <img src={Assets.SharedIcons.icon_arrow} alt="icon_arrow" />
                          </button>
                        </div>
                      </div>
                    )
                  }))
                : (<NoInformationResultsComponent />)
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeadquartersPage