// Assets
import { Assets } from '../../../../assets';

// Libraries
import { notification } from 'antd';

// Styles
import '../alert.component.scss';

const ErrorAlertComponent = (
  message='Se ha producido un error!',
  description='Intenta nuevamente más tarde o contacta al soporte técnico.'
) => {
  notification.error({
    message,
    description,
    icon: <img src={Assets.SharedIcons.icon_alert_error} alt="icon_alert_error" />,
    closeIcon: <img src={Assets.SharedIcons.icon_deleted_active} alt="icon_deleted_active" />,
    className:'notification-alert__error'
  });
}

export default ErrorAlertComponent