//Assets
import { Assets } from '../../../../assets';

//Components - shared
import ErrorAlertComponent from '../../../../shared/components/alerts/error-alert/error-alert.component';

//Libraries
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DatePicker, Select } from 'antd';
import { 
  Bar, BarChart, CartesianGrid, Rectangle, 
  ReferenceLine, ResponsiveContainer, XAxis, 
  YAxis, Tooltip 
} from 'recharts';

//Services
import { getControlPanelReportsService } from '../../../../services/dashboard.services';

//Styles
import './general-summary-by-headquarters.component.scss';

const GeneralSummaryByHeadquartersComponent = () => {

  const INITIAL_STATE = {
    referenceLine: 80,
    pagination: 1,
    dataControlPanel: [],
    keyFilter : 0
  };
  
  const [monthDate, setMonthDate] = useState(dayjs());
  const [state, setState] = useState(INITIAL_STATE);
  const { 
    referenceLine, 
    pagination, 
    dataControlPanel,
    keyFilter
  } = state;

  const options = [
    {
      value: 0,
      label: "Gastos",
    },
    {
      value: 1,
      label: "Ventas",
    },
    {
      value: 2,
      label: "Utilidad",
    },
  ];

  useEffect(() => {
    getDataControlPanel()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthDate])

  const getDataControlPanel = async () => {
    try {
      const responseDataControlPanel = await getControlPanelReportsService(dayjs(monthDate)?.startOf('month').format('YYYY-MM-DD'))
      setState({
        ...state,
        dataControlPanel: responseDataControlPanel,
      });
    } catch {
      ErrorAlertComponent()
    }
  }

  const colorsGraph = (percentage) => {
    const colors = [
      {
        active: "#0E7D80",
        inactive: "#CFF1E6",
      },
      {
        active: "#F85F61",
        inactive: "#FFC3D0",
      },
    ];
    if (percentage < referenceLine) {
      return keyFilter ? colors[1] : colors[0];
    } else {
      return keyFilter ? colors[0] : colors[1];
    }
  };

  const paginationData = (array, number) => {
    const index = (number - 1) * 3;
    const array2 = array.slice(index, index + 3);
    return array2
  };

  const filterDataControlPanel = (value) => {
    let filteredData = [];

    switch (value) {
      case 0:
        filteredData = dataControlPanel.map(item => ({
          id: item.id,
          name: item.name,
          percentage: item.percentageExpenditure,
        }));
        break;
  
      case 1:
        filteredData = dataControlPanel.map(item => ({
          id: item.id,
          name: item.name,
          percentage: item.percentageSales,
          percentageDeviation: item.salesDeviation,
        }));
        break;
  
      case 2:
        filteredData = dataControlPanel.map(item => ({
          id: item.id,
          name: item.name,
          percentage: item.percentageProfit,
          percentageDeviation: item.profitDeviation,
        }));
        break;
      default:
        break;
    }
    return filteredData
  };

  return (
    <div className="grid-x align-center-middle general-summary-by-headquarters__container">
      <div className="general-summary-by-headquarters__content-date">
        <div className="grid-x align-middle">
          <span className="general-summary-by-headquarters__text">
            Seleccionar mes:
          </span>
          <div className="grid-x align-middle general-summary-by-headquarters__date">
            <button onClick={() => setMonthDate(monthDate.add(-1, "month"))}>
              <img
                alt="icon_arrow"
                className="general-summary-by-headquarters__arrow"
                src={Assets.SharedIcons.icon_arrow}
              />
            </button>
            <DatePicker
              value={monthDate}
              picker="month"
              bordered={false}
              suffixIcon={null}
              allowClear={false}
              inputReadOnly={true}
              defaultValue={dayjs()}
              onChange={(date) => setMonthDate(date)}
              className="general-summary-by-headquarters__month"
              popupClassName="general-summary-by-headquarters__month__popup"
              format={(value) => {
                return `${dayjs(value)?.format("MMMM, YYYY")}`;
              }}
              disabledDate={(current) => current.isAfter(dayjs(), "month")}
            />
            <button
              onClick={() => setMonthDate(monthDate.add(1, "month"))}
              className="general-summary-by-headquarters__button-next"
              disabled={dayjs(monthDate)
                .add(1, "month")
                .isAfter(dayjs(), "month")}
            >
              <img alt="icon_arrow" src={Assets.SharedIcons.icon_arrow} />
            </button>
          </div>
        </div>
      </div>
      <div className="grid-y general-summary-by-headquarters__table__content">
        <div className="grid-x justify-content-between">
          <div>
            <span className="general-summary-by-headquarters__percentage__label">
              Porcentaje de cumplimiento a la fecha
            </span>
            <div>
              <Select
                onChange={(value) => setState({...state, keyFilter: value})}
                options={options}
                defaultValue={options[0]}
                bordered={false}
                className="general-summary-by-headquarters__select"
                suffixIcon={
                  <img
                    alt="icon_blue_arrow"
                    src={Assets.SharedIcons.icon_blue_arrow_down}
                  />
                }
              />
            </div>
          </div>
          <div className="grid-x">
            <div className="grid-x">
              <div className="general-summary-by-headquarters__percentage__circle"></div>
              <span className="general-summary-by-headquarters__percentage__label general-summary-by-headquarters__percentage__label--mod">
                Porcentaje de Incumplimiento
              </span>
            </div>
            <div className="grid-x">
              <div className="general-summary-by-headquarters__percentage__circle general-summary-by-headquarters__percentage__circle--green"></div>
              <span className="general-summary-by-headquarters__percentage__label general-summary-by-headquarters__percentage__label--mod">
                Porcentaje de Cumplimiento
              </span>
            </div>
            <div className="grid-x">
              <div className="general-summary-by-headquarters__percentage__circle general-summary-by-headquarters__percentage__circle--blue"></div>
              <span className="general-summary-by-headquarters__percentage__label general-summary-by-headquarters__percentage__label--mod">
                Porcentaje Proyección
              </span>
            </div>
          </div>
        </div>
        <ResponsiveContainer width="100%" height="80%">
          <BarChart
            width={"100%"}
            height={300}
            data={paginationData(filterDataControlPanel(keyFilter), pagination)}
            margin={{ top: 10, right: 10 }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="name" />
            <YAxis interval={0} domain={[0, 100]} height={10} unit={"%"} />
            <Tooltip
              isAnimationActive={false}
              content={(props) => {
                const { active, payload } = props;
                if (active && payload && payload.length) {
                  return (
                    <div className="grid-x align-center-middle general-summary-by-headquarters__tooltip__content">
                      <p
                        className="general-summary-by-headquarters__tooltip"
                        style={{
                          color: colorsGraph(Math.ceil(payload[0]?.payload?.percentage))
                            ?.active,
                        }}
                      >
                        {Math.ceil(payload[0]?.payload?.percentage) || 0}%
                      </p>
                      <p className="general-summary-by-headquarters__tooltip__description">
                        Porcentaje cumplimiento
                      </p>
                      { payload[0]?.payload?.percentageDeviation ?
                        <>
                          <p
                            className="general-summary-by-headquarters__tooltip"
                            style={{
                              color: colorsGraph(payload[0]?.payload?.percentage)
                                ?.active,
                            }}
                          >
                            {Math.ceil(payload[0]?.payload?.percentageDeviation) || 0}%
                          </p>
                          <p className="general-summary-by-headquarters__tooltip__description">
                            Porcentaje desviación
                          </p>
                        </>
                        : null
                      }
                    </div>
                  );
                }

                return null;
              }}
            />
            <Bar
              radius={[5, 5, 0, 0]}
              dataKey="percentage"
              barSize={32}
              animationBegin={0}
              animationDuration={0}
              shape={(props) => {
                const { x, y, width, height, value } = props;
                return (
                  <Rectangle
                    radius={[5, 5, 0, 0]}
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    fill={colorsGraph(value)?.inactive}
                  />
                );
              }}
              activeBar={(props) => {
                return (
                  <Rectangle
                    {...props}
                    fill={colorsGraph(props?.value)?.active}
                  />
                );
              }}
            />
            <ReferenceLine y={referenceLine} stroke="#1499F2" />
          </BarChart>
        </ResponsiveContainer>
        <div className="grid-x justify-content-between align-middle general-summary-by-headquarters__graph">
          <button
            className="general-summary-by-headquarters__button-text__content"
            disabled={pagination === 1}
            onClick={() => setState({ ...state, pagination: pagination - 1 })}
          >
            <img
              src={Assets.SharedIcons.icon_arrow}
              alt="icon_arrow"
              className="general-summary-by-headquarters__button-text__icon general-summary-by-headquarters__button-text__icon--mod"
            />
            <span className="general-summary-by-headquarters__button-text">
              Ver a listado anterior
            </span>
          </button>
          <button
            className="general-summary-by-headquarters__button-text__content"
            disabled={paginationData(filterDataControlPanel(keyFilter), pagination + 1).length === 0}
            onClick={() => setState({ ...state, pagination: pagination + 1 })}
          >
            <span className="general-summary-by-headquarters__button-text">
              Ver listado siguiente
            </span>
            <img
              src={Assets.SharedIcons.icon_arrow}
              alt="icon_arrow"
              className="general-summary-by-headquarters__button-text__icon"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default GeneralSummaryByHeadquartersComponent;