//Assets
import { Assets } from '../../../../assets';

//Components - Shared
import InformationAlertComponent from '../../../../shared/components/alerts/information-alert/information-alert.component';
import ErrorAlertComponent from '../../../../shared/components/alerts/error-alert/error-alert.component';
import SuccessAlertComponent from '../../../../shared/components/alerts/success-alert/success-alert.component';

//Libraries
import React, { useEffect, useState } from 'react'
import { Drawer, Collapse, Input, Checkbox } from 'antd';
import { NumericFormat } from "react-number-format";
import PropTypes from 'prop-types'; 
import dayjs from 'dayjs';

//Services
import { createRegisterMonthlyClosingService, getRegisterMontlyClosingService, updateGeneralSummaryService } from '../../../../services/final-reconciliation.services';

//Styles
import "./monthly-closing-wholesaler.component.scss"

//Utils
import { formattedNumber } from '../../../../utils/shared.utils';

const objectMonthlyWholesaler = {
    total: 0,
    name: '',
    id: null,
    disabled: true,
    allowEdit: true
}

const MonthlyClosingWholesalerComponent = ({ 
    open, 
    onClose, 
    date, 
    idWholesaler 
}) => {

    const INITIAL_STATE = {
        currentDate: dayjs(),
        informative: Array(3).fill().map(() => ({...objectMonthlyWholesaler})),
        expenses: Array(7).fill().map(() => ({...objectMonthlyWholesaler})),
        utility: Array(7).fill().map(() => ({...objectMonthlyWholesaler})),
        informativeCopy: [],
        expensesCopy: [],
        utilityCopy: [],
        totalExpenses: 0,
        totalUtility: 0,
        totalMonthlyClosing: 0,
    }

    const [visible, setVisible] = useState(open);
    const [state, setState] = useState(INITIAL_STATE);
    const { 
        currentDate,
        informative,
        expenses,
        utility,
        informativeCopy,
        expensesCopy,
        utilityCopy,
        totalExpenses,
        totalUtility,
        totalMonthlyClosing,
    } = state;

    useEffect (() => {
        getRegisterMontlyClosing(dayjs(date).format('YYYY-MM-DD'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const closeModalClosing = () => {
        setVisible(false); 
        setTimeout(() => {
            onClose();
        }, 200); 
    };

    const getRegisterMontlyClosing = async (dateFormat) => {
        try {
            const response = await getRegisterMontlyClosingService(dateFormat, idWholesaler);
    
            if (response) {
                const finalBalance = response?.finalBalance;
    
                let dataInformative = finalBalance?.informative?.data;
                let dataExpenses = finalBalance?.bills?.data;
                let dataUtility = finalBalance?.utility?.data;
    
                if (finalBalance?.informative?.data?.length < informative?.length) {
                    dataInformative = [...finalBalance?.informative?.data, ...informative.slice(finalBalance?.informative?.data?.length)];
                }
    
                let dataInformativeCopy = finalBalance?.informative?.data && JSON.parse(JSON.stringify(finalBalance?.informative?.data));
    
                if (finalBalance?.bills?.data?.length < expenses?.length) {
                    dataExpenses = [...finalBalance?.bills?.data, ...expenses.slice(finalBalance?.bills?.data?.length)];
                }
    
                let dataExpensesCopy = finalBalance?.bills?.data && [...finalBalance?.bills?.data];
    
                let dataUtilityCopy = finalBalance?.utility?.data && [...finalBalance?.utility?.data];
    
                if (finalBalance?.utility?.data?.length < utility?.length) {
                    dataUtility = [...finalBalance?.utility?.data, ...utility.slice(finalBalance?.utility?.data?.length)];
                    dataUtilityCopy = [...finalBalance?.utility?.data];
                }
    
                setState((prevState) => ({
                    ...prevState,
                    informative: dataInformative,
                    informativeCopy: dataInformativeCopy,
                    expenses: dataExpenses,
                    expensesCopy: dataExpensesCopy,
                    utility: dataUtility,
                    utilityCopy: dataUtilityCopy,
                    totalExpenses: finalBalance?.bills?.total,
                    totalUtility: finalBalance?.utility.total,
                    totalMonthlyClosing: finalBalance?.closing,
                }));
            }
        } catch (error) {
            ErrorAlertComponent();
        }
    };

  const onChanges = (e, index, name, tableName) => {
    let value = e && e.target ? e.target.value || '' : e;
    if (name === "name") {
      value = value.trimStart();
    }
    
    let modifiedData = [...state[tableName]];
    
    switch (tableName) {
      case "informative":
        modifiedData = [...state.informative];
        break;
      case "utility":
        modifiedData = [...state.utility];
        break;
      case "expenses":
        modifiedData = [...state.expenses];
        break;
      default:
        break;
    }
    
    modifiedData[index][name] = value;
    
    setState(prevState => ({
      ...prevState,
      [tableName]: modifiedData,
    }));
  };
  
    const addMonthlyClosing = (array, key) => {
        const objectDisabled = array.find(item => item.hasOwnProperty("disabled") && item.disabled === true);
    
        if (!objectDisabled) {
            const newArray = [...array, {...objectMonthlyWholesaler, disabled: false}];
            setState((prevState) => ({
                ...prevState,
                scrollEnd: true,
                [key]: newArray
            }));
        } else {
            const newArray = array.map(item => item === objectDisabled ? { ...item, disabled: false } : item);
            setState((prevState) => ({ ...prevState, [key]: newArray }));
        }
    };

    const deleteItem = (array, index, key, initialAmount) => {
        let updatedArray = array.slice();
        updatedArray.splice(index, 1);
        if (updatedArray.length <= initialAmount) {
            updatedArray = [
                ...updatedArray,
                {
                    total: 0,
                    name: '',
                    id: null,
                    disabled: true
                }
            ]
        }
        setState((prevState)=>({
            ...prevState,
            [key]: updatedArray
        }));
    };

    const filterAndMapData = (data, isClosing, state) => {
        return data
            .filter(item => !item.disabled)
            .map(item => {
                let structure = {
                    concept: item?.name, 
                    price: Number(item?.total) ? item?.total : parseInt((item?.total || "0")?.replace(/[^0-9]/g, ''),10),
                    initialDate: dayjs(currentDate).format('YYYY-MM-DD'),
                    finalDate: null,
                    state,
                    isClosing,
                    checked: item?.checked
                };
                if(item?.id){ 
                    structure.id = item.id; 
                }
                return structure;
            });
    };

    const filterAndMapDataUpdated = (data) => {
        
        return data
        .filter(item => !item.disabled)
        .map(item => {
                let dataMovement = {
                    concept: item?.name
                }
                if(item?.checked){ 
                    dataMovement.finalDate = dayjs().format("YYYY-MM-DD")
                }
                let structure = {
                    id: item?.id,
                    movementData: dataMovement || null,
                    price: Number(item?.total) ? item?.total : parseInt((item?.total || "0")?.replace(/[^0-9]/g, ''),10),
                    updateByMonth: true
                };
                if(item?.id){ 
                    structure.id = item.id; 
                }
                return structure;
            });
    };
    
    const checkDataComplete = (data) => {
        return data.every(item => {
            return item?.concept?.trim() !== '' && item?.price > 0;
        });
    };

    const saveRegister = async () => {

        try {

            let servicesAllPromise = []

            //Check Data Informative
            const informativeFilter = filterAndMapData(informative, 1, null);
            const informativeFilterCopy= informative.filter(item => item.id)

            const createInformativeList = informativeFilter.filter(item => !item.id);
            let completeInformation = checkDataComplete(informativeFilter);

            const updateInformativeList = compareArraysOfObjects(informativeCopy, informativeFilterCopy)
            const informativeModifiedMap = filterAndMapDataUpdated(updateInformativeList)

            //Check Data Utility
            const utilityFilter = filterAndMapData(utility, 1, 1);
            const createUtilityList = utilityFilter.filter(item => !item.id);

            //Check Data Utility Modified
            const utilityModified = utility?.filter(item => item.id)

            const updateUtilityList = compareArraysOfObjects(utilityCopy.map(item => ({...item, checked: false})), utilityModified)

            const utilityModifiedMap = filterAndMapDataUpdated(updateUtilityList);

            if (completeInformation) {
                completeInformation = checkDataComplete(utilityFilter);
            }

            //Check Data Expenses
            const expensesFilter = filterAndMapData(expenses, 1, 0);
            const expensesFilterCopy = expenses.filter(item => item.id)

            const createExpensesList = expensesFilter.filter(item => !item.id);

            const updateExpensesList = compareArraysOfObjects(expensesCopy.map(item => ({...item, checked: false})), expensesFilterCopy)
            const expensesModifiedMap = filterAndMapDataUpdated(updateExpensesList)

            if (completeInformation) {
                completeInformation = checkDataComplete(expensesFilter);
            }

            if (!completeInformation) {
                InformationAlertComponent(undefined, "Faltan campos por completar");
                return;
            }

            createInformativeList?.length > 0 && servicesAllPromise.push(createRegisterMonthlyClosingService(
                createInformativeList,
                idWholesaler
            ))
            createUtilityList?.length > 0 && servicesAllPromise.push(createRegisterMonthlyClosingService(
                createUtilityList,
                idWholesaler
            ))
            createExpensesList?.length > 0 && servicesAllPromise.push(createRegisterMonthlyClosingService(
                createExpensesList,
                idWholesaler
            ))

            informativeModifiedMap?.length > 0 && servicesAllPromise.push(updateGeneralSummaryService(informativeModifiedMap))
            utilityModifiedMap?.length > 0 && servicesAllPromise.push(updateGeneralSummaryService(utilityModifiedMap))
            expensesModifiedMap?.length > 0 && servicesAllPromise.push(updateGeneralSummaryService(expensesModifiedMap))

            if (servicesAllPromise.length > 0) {
                const responseServices = await Promise.allSettled(servicesAllPromise)
                let errorServices = responseServices.find(item => item.status === "rejected")
                if (errorServices) {
                    ErrorAlertComponent()
                } else {
                    setState(INITIAL_STATE)
                    SuccessAlertComponent()
                    await getRegisterMontlyClosing(dayjs(date).format('YYYY-MM-DD'))
                }
            } else {
                InformationAlertComponent()
            }
        } catch (error) {
            ErrorAlertComponent()
        }
    };

    const compareArraysOfObjects = (array1, array2) => {
        const compareObjects = (object1, object2) => {
            for (let property in object1) {
                if (object1[property] !== object2[property]) {
                    return false;
                }
            }
            return true;
        };

        const differentInArray2 = array2.filter(object2 =>
            !array1.some(object1 => compareObjects(object1, object2))
        );

        return differentInArray2;
    };
    
    const dataCashReceived = [
        {
            key: '1',
            label: 
                <>
                    <img
                        src={Assets.SharedIcons.icon_income}
                        alt="icon_income"
                        height={"23px"}
                        width={"23px"}
                        className='monthly-closing-wholesaler__icon-income' /> Utilidad
                </>,
            children:
                <div>
                    <div className='grid-x align-center-middle justify-content-between monthly-closing-wholesaler__content-cash-wholesaler'>
                        <div className='monthly-closing-wholesaler__text-wholesaler'>Concepto</div>
                        <div className='monthly-closing-wholesaler__cash-wholesaler'>Valor</div>
                    </div>
                    <div className='monthly-closing-wholesaler__content-info'>
                        {utility.map((item, index) => {
                            return (
                                <div key={index} className='grid-x monthly-closing-wholesaler__table'>
                                    <div className='small-6'>
                                        <Input
                                            onChange={(e) => onChanges(e, index, "name", 'utility')}
                                            disabled={item?.disabled}
                                            value={item?.name}
                                            placeholder='Descripción'
                                            className='monthly-closing-wholesaler__input'
                                            prefix={!item?.allowEdit ? <></> :<img src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit} alt="icon_add" />}
                                            readOnly={!item?.allowEdit}
                                        />
                                    </div>
                                    <div className='small-5'>
                                        <div className='grid-x monthly-closing-wholesaler__table__content-inputs'>
                                            <NumericFormat
                                                type='text'
                                                prefix={"$ "}
                                                placeholder='$ 0'
                                                value={item?.total}
                                                thousandSeparator=","
                                                className='input-price'
                                                disabled={item?.disabled}
                                                thousandsGroupStyle="thousand"
                                                onChange={(e) => onChanges(e, index, "total", 'utility')}
                                                readOnly={!item?.allowEdit}
                                            />
                                            {item.allowEdit &&
                                                <div className='monthly-closing-wholesaler__table__content-icon-price'>
                                                    <img
                                                        alt="icon_add"
                                                        src={item?.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {item?.id ? (
                                        <div className='grid-x small-1 justify-content-end monthly-closing-wholesaler__checkbox'>
                                            <Checkbox
                                                onChange={() => onChanges(!item.checked, index, "checked", 'utility')}
                                                disabled={!item?.allowEdit}
                                            />
                                        </div>
                                    ) : (
                                        <div className='grid-x small-1 justify-content-end align-middle'>
                                            <button
                                                className='monthly-closing-wholesaler__button-delete'
                                                disabled={item?.disabled}
                                                onClick={() => deleteItem(utility, index, 'utility', 6)}
                                            >
                                                <img
                                                    className='monthly-closing-wholesaler__button-delete__icon'
                                                    alt={item?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                                                    src={item?.disabled ? Assets.SharedIcons.icon_deleted_disabled : Assets.SharedIcons.icon_deleted_active}
                                                />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </div>
            ,
        },
    ];

    const dataCashExpenses = [
        {
            key: '1',
            label: 
                <>
                    <img
                        src={Assets.SharedIcons.icon_egress}
                        alt="icon_egress"
                        height={"23px"}
                        width={"23px"}
                        className='monthly-closing-wholesaler__icon_egress'
                    /> Gastos
                </>,
            children:
                <div>
                    <div className='grid-x align-center-middle justify-content-between monthly-closing-wholesaler__content-cash-wholesaler'>
                        <div className='monthly-closing-wholesaler__text-wholesaler'>Concepto</div>
                        <div className='monthly-closing-wholesaler__cash-wholesaler'>Valor</div>
                    </div>
                    <div className='monthly-closing-wholesaler__content-info'>
                        {expenses.map((item, index) => {
                            return (
                                <div key={index} className='grid-x monthly-closing-wholesaler__table'>
                                    <div className='small-6'>
                                        <Input
                                            onChange={(e) => onChanges(e, index, "name", 'expenses')}
                                            disabled={item?.disabled}
                                            value={item?.name}
                                            placeholder='Descripción'
                                            className='monthly-closing-wholesaler__input'
                                            prefix={!item?.allowEdit ? <></> :<img src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit} alt="icon_add" />}
                                            readOnly={!item?.allowEdit}
                                        />
                                    </div>
                                    <div className='small-5'>
                                        <div className='grid-x monthly-closing-wholesaler__table__content-inputs'>
                                            <NumericFormat
                                                type='text'
                                                prefix={"$ "}
                                                placeholder='$ 0'
                                                value={item?.total}
                                                thousandSeparator=","
                                                className='input-price'
                                                disabled={item?.disabled}
                                                thousandsGroupStyle="thousand"
                                                onChange={(e) => onChanges(e, index, "total", 'expenses')}
                                                readOnly={!item?.allowEdit}
                                            />
                                            {item.allowEdit &&
                                                <div className='monthly-closing-wholesaler__table__content-icon-price'>
                                                    <img
                                                        alt="icon_add"
                                                        src={item?.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {item?.id ? (
                                        <div className='grid-x small-1 justify-content-end monthly-closing-wholesaler__checkbox'>
                                            <Checkbox
                                                onChange={() => onChanges(!item.checked, index, "checked", 'expenses')}
                                                disabled={!item?.allowEdit}
                                            />
                                        </div>
                                    ) : (
                                        <div className='grid-x small-1 justify-content-end align-middle'>
                                            <button
                                                className='monthly-closing-wholesaler__button-delete'
                                                disabled={item?.disabled}
                                                onClick={() => deleteItem(expenses, index, 'expenses', 6)}
                                            >
                                                <img
                                                    className='monthly-closing-wholesaler__button-delete__icon'
                                                    alt={item?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                                                    src={item?.disabled ? Assets.SharedIcons.icon_deleted_disabled : Assets.SharedIcons.icon_deleted_active}
                                                />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </div>
            ,
        },
    ];

    const expandIcon = ({ isActive }) => (
        isActive ? 
            <img
                alt="icon_disabled_arrow"
                src={Assets.SharedIcons.icon_blue_arrow_up}
            /> : 
            <img
                alt="icon_disabled_arrow"
                src={Assets.SharedIcons.icon_blue_arrow_down}
            /> 
    );

    const closeIcon = (
        <img
            src={Assets.SharedIcons.icon_close_blue}
            alt="close_icon"
        />
    );

    return (
        <div className='monthly-closing-wholesaler text-center'>
            <Drawer
                title={
                    <div className='monthly-closing-wholesaler__title text-center'>Cierre mensual</div>
                }
                placement="right"
                onClose={closeModalClosing}
                open={visible}
                className='monthly-closing-wholesaler'
                width={400}
                closeIcon={closeIcon}
                rootStyle={{ 
                    backdropFilter: 'blur(10px)', 
                    width: `calc(100vw - 200px)`,
                    left: '200px',
                    height: `calc(100vh - 60px)`,
                    top: '60px',
                    zIndex: 1
                }}
            >
                <div className='monthly-closing-wholesaler__content-final-cash text-center'>
                    <div className='grid-x monthly-closing-wholesaler__container-totalizer'>
                        <div className='grid-x small-6 monthly-closing-wholesaler__container-cash-received'>
                            <img
                                src={Assets.SharedIcons.icon_income}
                                alt="icon_income"
                                height={"23px"}
                                width={"23px"}
                                className='monthly-closing-wholesaler__icon-income'
                            />
                            <div className='monthly-closing-wholesaler__content-cash-received text-left'>
                                <div className='monthly-closing-wholesaler__cash-received '>Cuadre</div>
                                <div className='monthly-closing-wholesaler__currency-received'>
                                    {formattedNumber(totalUtility)}
                                </div>
                            </div>
                        </div>
                        <div className='grid-x small-6 align-center monthly-closing-wholesaler__container-cash-expenses'>
                            <img
                                src={Assets.SharedIcons.icon_egress}
                                alt="icon_egress"
                                height={"23px"}
                                width={"23px"}
                                className='monthly-closing-wholesaler__icon_egress'
                            />
                            <div className='monthly-closing-wholesaler__content-cash-expenses text-left'>
                                <div className='monthly-closing-wholesaler__cash-expenses'>Deudas</div>
                                <div className='monthly-closing-wholesaler__currency-expenses'>
                                    {formattedNumber(totalExpenses)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='monthly-closing-wholesaler__text'>Cierre de mes</div>
                    <div className='monthly-closing-wholesaler__title'>
                        {formattedNumber(totalMonthlyClosing)}
                    </div>
                </div>
                <div className='monthly-closing-wholesaler__subtitle text-center'>
                    Informativo
                </div>
                <div className='monthly-closing-wholesaler__content-info'>
                    {informative.map((item, index) => {
                        return (
                            <div key={index} className='grid-x monthly-closing-wholesaler__table'>
                                <div className='small-6'>
                                    <Input
                                        onChange={(e) => onChanges(e, index, "name", 'informative')}
                                        disabled={item?.disabled}
                                        value={item?.name}
                                        placeholder='Descripción'
                                        className='monthly-closing-wholesaler__input'
                                        prefix={<img src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit} alt="icon_add" />}
                                    />
                                </div>
                                <div className='small-5'>
                                    <div className='grid-x monthly-closing-wholesaler__table__content-inputs'>
                                        <NumericFormat
                                            type='text'
                                            prefix={"$ "}
                                            placeholder='$ 0'
                                            value={item?.total}
                                            thousandSeparator=","
                                            className='input-price'
                                            disabled={item?.disabled}
                                            thousandsGroupStyle="thousand"
                                            onChange={(e) => onChanges(e, index, "total", 'informative')}
                                        />
                                        <div className='monthly-closing-wholesaler__table__content-icon-price'>
                                            <img
                                                alt="icon_add"
                                                src={item?.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {!item?.id &&
                                    <div className='grid-x small-1 justify-content-end align-middle'>
                                        <button
                                            className='monthly-closing-wholesaler__button-delete'
                                            disabled={item?.disabled}
                                            onClick={() => deleteItem(informative, index, 'informative', 2)}
                                        >
                                            <img
                                                className='monthly-closing-wholesaler__button-delete__icon'
                                                alt={item?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                                                src={item?.disabled ? Assets.SharedIcons.icon_deleted_disabled : Assets.SharedIcons.icon_deleted_active}
                                            />
                                        </button>
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>
                <div className='grid-x justify-content-between monthly-closing-wholesaler__container-total'>
                        { dayjs().isSame(dayjs(date), 'day') &&
                        <button
                            onClick={() => addMonthlyClosing(informative, 'informative')}
                            className='grid-x align-middle monthly-closing-wholesaler__container-total__type--informative'
                        >
                            <img
                                alt="icon_income_blue"
                                src={Assets.SharedIcons.icon_income_blue}
                            />
                            <span className='monthly-closing-wholesaler__type__name'>
                                Agregar
                            </span>
                        </button>
                        }
                    </div>
                <div className='monthly-closing-wholesaler__container-collapse'>
                    <Collapse 
                        items={dataCashReceived}
                        defaultActiveKey={['1']}
                        expandIconPosition='end'
                        expandIcon={expandIcon}
                    />
                    <div className='grid-x justify-content-between monthly-closing-wholesaler__container-total'>
                        { dayjs().isSame(dayjs(date), 'day') &&
                        <button
                            onClick={() => addMonthlyClosing(utility, 'utility')}
                            className='grid-x align-middle monthly-closing-wholesaler__container-total__type'
                        >
                            <img
                                alt='icon_income'
                                src={Assets.SharedIcons.icon_income}
                            />
                            <span className='monthly-closing-wholesaler__type__name'>
                                Agregar
                            </span>
                        </button>
                        }
                        <div className='grid-x'>
                            <div className='grid-x align-center-middle monthly-closing-wholesaler__cash-wholesaler'>Total:</div>
                            <div className='monthly-closing-wholesaler__total-cash-received justify-content-between'>
                                <div className='monthly-closing-wholesaler__content-icon-price'>
                                    =
                                </div>
                                <div className='grid-x align-center-middle justify-content-end monthly-closing-wholesaler__input-price'>
                                    {formattedNumber(totalUtility)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='monthly-closing-wholesaler__container-collapse'>
                    <Collapse 
                        items={dataCashExpenses}
                        defaultActiveKey={['1']}
                        expandIconPosition='end'
                        expandIcon={expandIcon}
                    />
                    <div className='grid-x justify-content-between monthly-closing-wholesaler__container-total'>
                        { dayjs().isSame(dayjs(date), 'day') &&
                        <button
                            onClick={() => addMonthlyClosing(expenses, 'expenses')}
                            className='grid-x align-middle monthly-closing-wholesaler__container-total__type--mod'
                        >
                            <img
                                alt='icon_egress'
                                src={Assets.SharedIcons.icon_egress}
                            />
                            <span className='monthly-closing-wholesaler__type__name'>
                                Agregar
                            </span>
                        </button>
                        }
                        <div className='grid-x'>
                            <div className='grid-x align-center-middle monthly-closing-wholesaler__cash-wholesaler'>Total:</div>
                            <div className='monthly-closing-wholesaler__total-cash-received justify-content-between'>
                                <div className='monthly-closing-wholesaler__content-icon-price'>
                                    =
                                </div>
                                <div className='grid-x align-center-middle justify-content-end monthly-closing-wholesaler__input-price'>
                                    {formattedNumber(totalExpenses)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='grid-x align-middle justify-content-center monthly-closing-wholesaler__container-button'>
                    <button
                        onClick={() => saveRegister()}
                        className='monthly-closing-wholesaler__container-button__save'
                    >
                        <img
                            alt='icon_save_disabled'
                            src={Assets.SharedIcons.icon_save_disabled}
                        />
                        <span>Guardar y actualizar</span>
                    </button>
                </div>
            </Drawer>
        </div>
    )
}

MonthlyClosingWholesalerComponent.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    date: PropTypes.instanceOf(dayjs).isRequired, 
    idWholesaler: PropTypes.string.isRequired
};

export default MonthlyClosingWholesalerComponent