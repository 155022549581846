//Actions
import { setStateAuthenticationReducer } from '../../../storage/reducers/authentication/authentication.actions';

//Assets
import { Assets } from '../../../assets/index';

// Components - shared
import ErrorAlertComponent from '../../../shared/components/alerts/error-alert/error-alert.component';

// Libraries
import { Input } from 'antd';
import { useFormik } from "formik";
import { connect } from 'react-redux';
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Services
import { validateUserService } from '../../../services/authentication.services';

// Styles
import "./validate-user.page.scss";

// Utils
import { validateRecoveryPassword } from '../../../utils/validate-recovery-password.utils';

const ValidateUserPage = (props) => {

  const { setStateAuthenticationReducer } = props;

  let history = useNavigate();

  const [isErrorUser, setErrorUser] = useState(false);

  const { handleSubmit, handleChange, setErrors, values, errors } = useFormik({
    initialValues: {
      user: ""
    },
    initialErrors: {
      user: ""
    },
    validate: validateRecoveryPassword,
    onSubmit: () => processValidateNameUser()
  });

  const processValidateNameUser = async () => {
    try {
      const resp = await validateUserService(values.user);
      setStateAuthenticationReducer('idValidateUser', resp);
      setStateAuthenticationReducer('parameterUser', values.user);
      history("/validateCode", { replace: true });
    } catch (error) {
      setErrorUser(true);
      setErrors({ user: 'Usuario no encontrado, verifica e inténtalo nuevamente' });
      ErrorAlertComponent(undefined, 'Usuario no encontrado, verifica e inténtalo nuevamente');
    }
  };

  return (
    <div className='grid-x'>
      <div className='cell medium-6 flex-container align-center-middle validate-user'>
        <img
          alt="icon_logo_text"
          src={Assets.SharedIcons.icon_logo_text}
        />
      </div>
      <div className='cell medium-6 validate-user__main-container'>
        <div className='grid-padding-x validate-user__main-container__welcome-text'>
          Bienvenido al portal de Surtilicores, aquí podrás reportar de una manera más efectiva tu labor.
        </div>
        <form onSubmit={handleSubmit}>
          <div className='validate-user__container'>
            <div className='grid-y align-center-middle'>
              <span className='validate-user__title'>
                Recuperar contraseña
              </span>
              <span className='validate-user__text'>
                Escribe el nombre asociado a tu cuenta de usuario. En los próximos minutos te estaremos enviando un código de recuperación de 6 dígitos, para que puedas recuperar tu cuenta.
              </span>
            </div>
            <div className='validate-user__input__container'>
              <Input
                value={values.user}
                className="validate-user__input"
                placeholder="Nombre asociado a tu cuenta"
                onChange={(value) => {
                  handleChange('user')(value);
                  isErrorUser && setErrorUser(false);
                }}
                prefix={
                  <div className='validate-user__input__content-icon'>
                    <img
                      alt="icon_user"
                      src={Assets.AuthenticationIcons.icon_user}
                    />
                  </div>
                }
                suffix={
                  (isErrorUser) &&
                  (<button
                    style={{ marginRight: '10px' }}
                    onClick={() => {
                      setErrorUser(false)
                      handleChange("user")('');
                    }}
                  >
                    <img
                      alt="icon_clear"
                      src={Assets.AuthenticationIcons.icon_clear}
                    />
                  </button>)
                }
              />
              <span className='validate-user__input__error'>{errors.user}</span>
            </div>
            <div className='grid-x small-12 justify-content-center validate-user__content-button'>
              <button
                type='submit'
                className='button'
                style={{ background: '#1499F2' }}
                disabled={Object.keys(errors).length !== 0}
              >
                Enviar código de recuperación
              </button>
              <Link to='/login' className='validate-user__content-button__link'>
                Regresar
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
};

const mapStateToPropsActions = {
  setStateAuthenticationReducer
};

export default connect(null, mapStateToPropsActions)(ValidateUserPage);