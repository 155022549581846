//Assets
import { Assets } from '../../../../assets';

//Components - shared
import ErrorAlertComponent from '../../../../shared/components/alerts/error-alert/error-alert.component';

//Libraries
import React, { useEffect, useState } from 'react';
import { Input, Select } from 'antd';
import dayjs from 'dayjs';

//Services
import { getAllHeadQuartersService } from '../../../../services/shared.services';

//Styles
import './quotation-by-headquarter.component.scss';

//Utils
import { formattedNumber } from '../../../../utils/shared.utils';
import { 
  getTotalizerDifferentialServices, 
  getTotalizerExpensesServices, 
  getTotalizerInventoryServices, 
  getTotalizerSalesServices, 
  getTotalizerTreasuryServices, 
  getTotalizerUtilityServices
} from '../../../../services/headquarters.services';

const QuotationByHeadquarterComponent = () => {

  const INITIAL_STATE = {
    daySelected: dayjs(),
    totalUtility: 0,
    totalExpenses: 0,
    totalTreasury: 0,
    totalInventory: 0,
    totalSales: 0,
    totalDifferentialIva: 0,
    headquarterSelected: null,
    listOfHeadquarters: []
  }

  const [state, setState] = useState(INITIAL_STATE)
  const {
    daySelected,
    totalUtility,
    totalExpenses,
    totalTreasury,
    totalInventory,
    totalSales,
    totalDifferentialIva,
    listOfHeadquarters,
    headquarterSelected
  } = state;


  useEffect(()=> {
    
    getAllHeadQuarters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  useEffect(()=> {
  
     getTotalizerServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headquarterSelected])

  const getAllHeadQuarters = async () => {

    try {
      const data = await getAllHeadQuartersService()
      if (data && Array.isArray(data) && data.length > 0) {
        const listOfHeadquarters = data.map((item) => ({
          value: item.id,
          label: item.label.toLowerCase().replace(/\b\w/g, c => c.toUpperCase())
        }))
        const firstHeadquarter = listOfHeadquarters[0];

        setState((prevState) => ({
          ...prevState,
          listOfHeadquarters,
          headquarterSelected: firstHeadquarter
        }));
      }
    } catch {
      ErrorAlertComponent(null, 'Ocurrió un error al obtener las sedes. Por favor, inténtalo de nuevo más tarde.')
    }
  }

  const getTotalizerServices = async () => {

    if(headquarterSelected?.value) {
      try {
        const formattedDate = dayjs(daySelected).startOf('month').format('YYYY-MM-DD')
        const responseServices = await Promise.allSettled([
          getTotalizerUtilityServices(headquarterSelected?.value, formattedDate),
          getTotalizerExpensesServices(headquarterSelected?.value, formattedDate),
          getTotalizerTreasuryServices(headquarterSelected?.value, formattedDate),
          getTotalizerInventoryServices(headquarterSelected?.value, formattedDate),
          getTotalizerSalesServices(headquarterSelected?.value, formattedDate),
          getTotalizerDifferentialServices(headquarterSelected?.value, formattedDate)
        ])
  
        let totalUtility = {}
        let totalExpenses = {}
        let totalTreasury = {}
        let totalInventory = {}
        let totalSales = {}
        let totalDifferentialIva = {}
        let errors = false
  
        for (let index = 0; index < responseServices.length; index++) {
          const elementResponse = responseServices[index];
          if (elementResponse.status === "fulfilled") {
            switch (index) {
              case 0:
                totalUtility = elementResponse?.value
                break;
              case 1:
                totalExpenses = elementResponse?.value
                break;
              case 2:
                totalTreasury = elementResponse?.value
                break;
              case 3:
                totalInventory = elementResponse?.value
                break;
              case 4:
                totalSales = elementResponse?.value
                break;
              case 5:
                totalDifferentialIva = elementResponse?.value
                break;
              default:
                break;
            }
          } else {
            errors = true
          }
        };
  
        setState(prevState => ({
          ...prevState,
          totalUtility: totalUtility?.totalUtility,
          totalExpenses: totalExpenses?.totalExpenses,
          totalTreasury: totalTreasury?.totalTreasury,
          totalInventory: totalInventory?.totalInventory,
          totalSales: totalSales?.totalSales,
          totalDifferentialIva: totalDifferentialIva?.totalDifferentialIva
        }));
  
        if (errors) {
          ErrorAlertComponent()
        }
  
      } catch (error) {
        ErrorAlertComponent()
      }
    }
  }

  return (
    <div className='quotation-by-headquarter__container'>
      <div className='grid-x align-middle justify-content-between quotation-by-headquarter__content'>
        <div className='grid-y'>
          <div className='quotation-by-headquarter__title'>
            <span>Cuadre de sede</span>
          </div>
          <div className='quotation-by-headquarter__subtitle'>
            <span>Resumen general al <span style={{ fontWeight: 'bold' }}>{daySelected.format('DD/MM/YYYY')}</span></span>
          </div>
        </div>
        <Select
          value={headquarterSelected}
          optionLabelProp
          options={listOfHeadquarters}
          popupMatchSelectWidth={false}
          onChange={(e, event) => setState(prevState => ({ ...prevState, headquarterSelected: event }))}
          placeholder='Nombre de la sede'
          className='quotation-by-headquarter__select'
          popupClassName='quotation-by-headquarter__select__popup'
          suffixIcon={
            <div className='quotation-by-headquarter__select__icon'>
              <img
                alt="icon_blue_arrow"
                src={Assets.SharedIcons.icon_blue_arrow}
              />
            </div>
          }
        />
      </div>
      <div className='grid-x align-middle quotation-by-headquarter__content-graph-register'>
        <div className="quotation-by-headquarter__box">
          <div className='grid-x'>
            <img
              alt="icon_income_graph"
              src={Assets.SharedIcons.icon_income_graph}
            />
            <span className="quotation-by-headquarter__value">
              {formattedNumber(totalUtility - totalExpenses)}
            </span>
          </div>
          <span className="quotation-by-headquarter__text">
            Utilidad neta
          </span>
        </div>
        <div className="quotation-by-headquarter__box">
          <div className='grid-x'>
            <img
              alt="icon_income_graph"
              src={Assets.SharedIcons.icon_income_graph}
            />
            <span className="quotation-by-headquarter__value">
              {formattedNumber(totalUtility) ?? 0}
            </span>
          </div>
          <span className="quotation-by-headquarter__text">
            Utilidad bruta
          </span>
        </div>
        <div className="quotation-by-headquarter__box">
          <div className='grid-x'>
            <img
              alt="icon_income_graph"
              src={Assets.SharedIcons.icon_income_graph}
            />
            <span className="quotation-by-headquarter__value">
              {formattedNumber(totalSales) ?? 0}
            </span>
          </div>
          <span className="quotation-by-headquarter__text">
            Ventas del mes
          </span>
        </div>
        <div className="quotation-by-headquarter__box--mod">
          <div className='grid-x'>
            <img
              alt="icon_falling_graph"
              src={Assets.SharedIcons.icon_falling_graph}
            />
            <span className="quotation-by-headquarter__value--mod">
              {formattedNumber(totalDifferentialIva) ?? 0}
            </span>
          </div>
          <span className="quotation-by-headquarter__text--mod">
            Diferencia del IVA
          </span>
        </div>
        <div className="quotation-by-headquarter__box--mod">
          <div className='grid-x'>
            <img
              alt="icon_falling_graph"
              src={Assets.SharedIcons.icon_falling_graph}
            />
            <span className="quotation-by-headquarter__value--mod">
              {formattedNumber(totalExpenses) ?? 0}
            </span>
          </div>
          <span className="quotation-by-headquarter__text--mod">
            Gastos del mes
          </span>
        </div>
        <div className="quotation-by-headquarter__box">
          <div className='grid-x'>
            <img
              alt="icon_income_graph"
              src={Assets.SharedIcons.icon_income_graph}
            />
            <span className="quotation-by-headquarter__value">
              {formattedNumber(totalTreasury) ?? 0}
            </span>
          </div>
          <span className="quotation-by-headquarter__text">
            Cartera con tesorería
          </span>
        </div>
        <div className="quotation-by-headquarter__box">
          <div className='grid-x'>
            <img
              alt="icon_income_graph"
              src={Assets.SharedIcons.icon_income_graph}
            />
            <span className="quotation-by-headquarter__value">
              {formattedNumber(totalInventory) ?? 0}
            </span>
          </div>
          <span className="quotation-by-headquarter__text">
            Valor de inventario
          </span>
        </div>
      </div>
      <div className='quotation-by-headquarter__content-text-area'>
        <span className='quotation-by-headquarter__content-text-area__title'>
          Observaciones
        </span>
        <Input.TextArea
          onChange={null}
          className='quotation-by-headquarter__content-text-area__input'
          placeholder="Aquí puedes dejarnos las observaciones sobre los datos anteriormente mencionados."
        />
      </div>
    </div>
  )
};

export default QuotationByHeadquarterComponent;