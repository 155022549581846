const CashClosingWholesaleEndPoints =  {
    GET_FIND_ALL_WHOLESALER: `/api/reportCashClosingWholesaler/wholesaler`,
    GET_ALL_EXPENSES_WHOLESALE: `/api/reportCashClosingWholesaler/expense/:id/:date`,
    GET_FIND_ALL_FINANCIAL_MOVEMENT: `/api/reportCashClosingWholesaler/financialMovement/:id/:date`,
    CREATE_EXPENSES_WHOLESALE: `/api/reportCashClosingWholesaler/expense/:id/:date`,
    UPDATE_EXPENSES_WHOLESALE: `/api/reportCashClosingWholesaler/expense`,
    DELETE_EXPENSES_WHOLESALE: `/api/reportCashClosingWholesaler/expense`,
    CREATE_FINANCIAL_MOVEMENT: `/api/reportCashClosingWholesaler/financialMovement/:id/:date`,
    UPDATE_FINANCIAL_MOVEMENT: `/api/reportCashClosingWholesaler/financialMovement`,
    DELETE_FINANCIAL_MOVEMENT: `/api/reportCashClosingWholesaler/financialMovement`,
    GET_ALL_LIQUIDATION_WHOLESALE: `/api/reportCashClosingWholesaler/liquidationTemplate/:id/:date`,
    CREATE_LIQUIDATION_WHOLESALE: `/api/reportCashClosingWholesaler/liquidationTemplate/:id/:date/:liquidationId`,
    UPDATE_LIQUIDATION_WHOLESALE: `/api/reportCashClosingWholesaler/liquidationTemplate`,
    UPDATE_LIQUIDATION: `/api/reportCashClosingWholesaler/liquidation`,
    DELETE_LIQUIDATION: `/api/reportCashClosingWholesaler/liquidation`,
    GET_ALL_INVENTORY: `/api/reportCashClosingWholesaler/inventoryWholesaler/:id/:date/:type`,
    UPDATE_INVENTORY: `/api/reportCashClosingWholesaler/inventoryWholesaler/:id/:date`,
    UPDATE_TOTALS: `/api/reportCashClosingWholesaler/closingWholesaler/:id/:date`,
}

export default CashClosingWholesaleEndPoints