

import BuildErrorInternalAppModel from "../../../../models/aggregates/build-error-internal-app/build-error-internal-app.model"
import AlertsErrorCodesConst from "./alerts-error-codes.const"

const AlertErrorsConst = {
    paramsRequiredBuildAlerts: new BuildErrorInternalAppModel(AlertsErrorCodesConst.ALT_APP_001, "errors.alertsApp.paramsRequiredBuildAlerts"),
    paramsRequiredBuildToasts: new BuildErrorInternalAppModel(AlertsErrorCodesConst.TST_APP_001, "errors.alertsApp.paramsRequiredBuildToasts"),
}

export default AlertErrorsConst