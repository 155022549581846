// Actions
import { setItemCashClosingWholesaleReducer } from '../../../../storage/reducers/cash-closing-wholesale/cash-closing-wholesale.actions';

//Assets
import { Assets } from '../../../../assets';

//Components - Shared
import ErrorAlertComponent from '../../../../shared/components/alerts/error-alert/error-alert.component';
import SuccessAlertComponent from '../../../../shared/components/alerts/success-alert/success-alert.component';

//Libraries
import { Select, Input } from 'antd';
import { NumericFormat } from "react-number-format";
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';

//Services
import { deleteExpensesWholesalerService } from '../../../../services/cash-closing-wholesale.services';

//Styles
import './expenses.component.scss'

const ExpensesComponent = (props) => {
  
  const { 
    table,
    keyTable,
    optionsExpenses,
    setItemCashClosingWholesaleReducer,
  } = props

  const INITIAL_STATE = {
    scrollEnd: false,
    expenses: {}
  };

  const [state, setState] = useState(INITIAL_STATE)

  const { expenses, scrollEnd } = state

  const formRef = useRef(null);

  useEffect(() => {
    if (formRef.current && scrollEnd) {
      const miDiv = formRef.current;
      miDiv.scrollTop = miDiv.scrollHeight;
    }
  }, [scrollEnd, expenses.length]);

  useEffect(() => {
    setState((prevState)=>({
      ...prevState,
      expenses: table
    }))
  }, [table])


  const addExpenses = () => {

    let expenseModified = expenses
    const objectDisabled = expenseModified?.expenseWholesaler.find(object => object.hasOwnProperty("disabled") && object.disabled === true);

    if (!objectDisabled) {
        const newArray = [
          ...expenseModified.expenseWholesaler,
          {
            typeExpense: {label:"",value:""}, 
            price: "", 
            concept: "", 
            disabled: false, 
            value: "" 
          }
        ]
        expenseModified.expenseWholesaler = newArray
        setState((prevState)=>({
          ...prevState,
          expenses: expenses,
          scrollEnd: true
        }))
        setItemCashClosingWholesaleReducer("listExpenses",keyTable, expenseModified);
    } else {
        const newArray = expenseModified?.expenseWholesaler.map(object => object === objectDisabled ? { ...object, disabled: false } : object);
        expenseModified.expenseWholesaler = newArray
        setState((prevState)=>({
          ...prevState,
          expenses: expenseModified,
        }))
        setItemCashClosingWholesaleReducer("listExpenses", keyTable, expenseModified);
    };
  };

  const onChanges = (e, index, name) => {
    let value = e && e.target ? e.target.value || '' : e;
    let expenseModified = expenses
    if (name !== "typeExpense") {
      value = value.trimStart()
    }
    expenseModified.expenseWholesaler[index][name] = value;
    setState((prevState)=>({
      ...prevState,
      expenses: expenseModified,
    }))
    setItemCashClosingWholesaleReducer("listExpenses",keyTable, expenseModified);
  }

  const deleteExpenses = async(index) => {
    try {
      let expenseModified = expenses

      if (expenseModified.expenseWholesaler[index].id) {
        await deleteExpensesWholesalerService({expense:[expenseModified?.expenseWholesaler[index]?.id]})
        SuccessAlertComponent()
      }

      expenseModified.expenseWholesaler.splice(index, 1)

      if (expenseModified.expenseWholesaler.length <= 6) {
        expenseModified.expenseWholesaler = [
          ...expenseModified.expenseWholesaler,
          {
            typeExpense: {label:"",value:""}, 
            price: "", 
            concept: "", 
            disabled: true, 
            value: "" 
          }
        ]
      }

      setState((prevState)=>({
        ...prevState,
        expenses: expenseModified
      }))

      setItemCashClosingWholesaleReducer("listExpenses",keyTable, expenseModified);
    } catch (error) {
      ErrorAlertComponent()
    }
  }

  const sumPrices = (list) => {
    return (list || []).reduce((accumulator, product) => {
      const price = !isNaN(Number(product?.price)) >= 0 ? Number(product?.price) : Number(product?.price?.replace(/[^0-9]/g, ''));
      return accumulator + (isNaN(price) ? 0 : price);
    }, 0);
  };
  
  return (
    <div className='expenses__container'>
      <div className='grid-y'>
        <div className='grid-x expenses__expenses__content-titles__primary'>
          <div className='grid-x align-middle small-6'>
            <img src={Assets.SharedIcons.icon_information} alt="icon_information" />
            <span 
              className='expenses__expenses__titles expenses__expenses__titles__space'
            >
              {expenses?.name}
            </span>
          </div>
          <div className='grid-x align-middle small-6 justify-content-end'>
            <span className='expenses__expenses__titles'>Total:</span>
            <div className='grid-x align-middle expenses__summary__details'>
              <div className='expenses__expenses__table__content-icon-price'>
                <img
                  alt="icon_egress"
                  src={Assets.SharedIcons.icon_egress}
                  className='expenses__summary__price__icon expenses__summary__price__icon__rotate'
                />
              </div>
              <NumericFormat
                defaultValue={0}
                value={sumPrices(expenses?.expenseWholesaler)}
                type='text'
                prefix={"$ "}
                disabled
                placeholder='$ 0'
                thousandSeparator=","
                className='input-price'
                thousandsGroupStyle="thousand"
              />
            </div>
          </div>
        </div>
        <div className='grid-x expenses__expenses__content-titles__second'>
          {keyTable === 0 &&<span className="small-2 expenses__expenses__titles">Nº Liquidación</span>}
          {keyTable === 0 && <span className="small-3 expenses__expenses__titles">Categoría</span>}
          <span className={`${keyTable === 0 ? "small-3" : "small-6"} expenses__expenses__titles`}>Concepto</span>
          <span className={`${keyTable === 0 ? "small-3" : "small-5"} justify-content-end expenses__expenses__titles`}>Valor</span>
        </div>
      </div>

      <div className='expenses__expenses__info'>
        {expenses?.expenseWholesaler?.map((item, index) => {
          return (
            <div key={index} className='grid-x expenses__expenses__table'>
            { keyTable === 0 &&
              <>
                <div className="small-2">
                    <div className='grid-x expenses__expenses__table__content-inputs'>
                      <NumericFormat
                        type='text'
                        prefix={""}
                        placeholder='0'
                        value={item?.value}
                        thousandSeparator=""
                        className='input-price'
                        disabled={item?.disabled}
                        thousandsGroupStyle="thousand"
                        onChange={(e)=> onChanges(e, index, "value")}
                      />
                      <div className='expenses__expenses__table__content-icon-price'>
                        <img
                          alt="icon_add"
                          src={item?.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                        />
                      </div>
                    </div>
                </div>
                <div className='small-3'>
                  { item.id ?
                    <Input
                      readOnly
                      disabled={item?.disabled}
                      value={optionsExpenses?.find(option => option?.value === item?.typeExpense?.value).label}
                      className='expenses__input'
                      prefix={<></>}
                    /> 
                    :
                    <Select
                      onChange={(event,e)=> onChanges(e, index, "typeExpense")}
                      options={optionsExpenses || []}
                      placeholder='Categoría'
                      disabled={item?.disabled}
                      popupMatchSelectWidth={false}
                      className='expenses__expenses__select'
                      popupClassName='expenses__expenses__select__popup'
                      value={item?.typeExpense?.value ? optionsExpenses?.find(option => option?.value === item?.typeExpense?.value) : undefined}
                      suffixIcon={
                        <div className='expenses__expenses__select__icon'>
                          <img
                            alt={item.disabled ? "icon_disabled_arrow" : "icon_blue_arrow"}
                            src={item.disabled ? Assets.SharedIcons.icon_disabled_arrow : Assets.SharedIcons.icon_blue_arrow}
                          />
                        </div>
                      }
                    />
                  }
                </div>
              </>
            }
              <div className={`${keyTable === 0 ?`small-3`:`small-6`}`}>
                <Input
                  onChange={(e)=> onChanges(e, index, "concept")}
                  disabled={item?.disabled}
                  value={item?.concept}
                  placeholder='Descripción'
                  className='expenses__input'
                  prefix={<img src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit} alt="icon_add" />}
                />
              </div>
              <div className={`grid-x ${keyTable === 0 ?`small-3`:`small-5`}`}>
                <div className='grid-x expenses__expenses__table__content-inputs'>
                  <NumericFormat
                    type='text'
                    prefix={"$ "}
                    placeholder='$ 0'
                    value={item?.price}
                    thousandSeparator=","
                    className='input-price'
                    disabled={item?.disabled}
                    thousandsGroupStyle="thousand"
                    onChange={(e)=> onChanges(e, index, "price")}
                  />
                  <div className='expenses__expenses__table__content-icon-price'>
                    <img
                      alt="icon_add"
                      src={item?.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                    />
                  </div>
                </div>
              </div>
              <div className='grid-x small-1 justify-content-end'>
                <button
                  className='expenses__expenses__button-delete'
                  disabled={item?.disabled}
                  onClick={() => deleteExpenses(index)}
                >
                  <img
                    className='expenses__expenses__button-delete__icon'
                    alt={item?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                    src={item?.disabled ? Assets.SharedIcons.icon_deleted_disabled : Assets.SharedIcons.icon_deleted_active}
                  />
                </button>
              </div>
            </div>
          )
        })}
      </div>
      <div className='grid-x expenses__expenses__button'>
        <div className='expenses__expenses__line'></div>
        <button
          onClick={() => addExpenses()}
          className='grid-x align-middle expenses__expenses__type expenses__expenses__type--mod'
        >
          <img
            src={Assets.SharedIcons.icon_egress}
            alt='icon_egress'
            className='expenses__summary__price__icon__rotate'
          />
          <span className='expenses__expenses__type__name'>Agregar gasto</span>
        </button>
      </div>
    </div>
  )
}

const mapStateToPropsActions = {
  setItemCashClosingWholesaleReducer
};

export default connect(null, mapStateToPropsActions)(ExpensesComponent);