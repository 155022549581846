// Actions
import { setStateAuthorizedManagementReducer } from '../../../../storage/reducers/authorized-management/authorized-management.actions'

//Assets
import { Assets } from '../../../../assets'

// Components - shared
import ErrorAlertComponent from '../../../../shared/components/alerts/error-alert/error-alert.component'

// Libraries
import React from 'react'
import { Checkbox } from 'antd'
import { connect } from 'react-redux'

// Services
import { updateAuthorizedManagementService } from '../../../../services/authorized-management.services'

// Styles
import "./preview-authorized.component.scss"

const PreviewAuthorizedComponent = (props) => {

  const {
    // Actions
    setStateAuthorizedManagementReducer,
    // Functions
    handleEdith = () => {},
    // Variables
    type = 0,
    userAuthorizedSelected
  } = props;

  const handleLoanGranting = async() => {
    try {
      let isAuthorizeFurtherLoansChecked = userAuthorizedSelected?.isAuthorizeFurtherLoans ? 1 : 0
      await updateAuthorizedManagementService(userAuthorizedSelected.id,{isAuthorizeFurtherLoans : !isAuthorizeFurtherLoansChecked})
      setStateAuthorizedManagementReducer("userAuthorizedSelected",{...userAuthorizedSelected, isAuthorizeFurtherLoans: !isAuthorizeFurtherLoansChecked})
    } catch (error) {
      ErrorAlertComponent()
    }
  }

  return (
    <div className='preview-authorized__content'>
      <label className='preview-authorized__title'>{userAuthorizedSelected?.name || "Nombre del autorizado"}</label>
      <div className='preview-authorized__line'></div>
      <div className='grid-x preview-authorized__icon__content'>
        <img src={Assets.SharedIcons.icon_document} alt='icon_document' className='preview-authorized__icon' />
        <div>
          <label className='preview-authorized__text'>Documento de identidad</label>
          <label className='preview-authorized__title preview-authorized__title--subtitle'>{ userAuthorizedSelected?.numberDocument || "Sin asignar"}</label>
        </div>
      </div>
      <div className='grid-x'>
        <img src={Assets.SharedIcons.icon_phone} alt='icon_phone' className='preview-authorized__icon' />
        <div>
          <label className='preview-authorized__text'>Número de contacto</label>
          <label className='preview-authorized__title preview-authorized__title--subtitle'>{ userAuthorizedSelected?.numberPhone || "Sin asignar"}</label>
        </div>
      </div>
      <div className='preview-authorized__line'></div>
      <div className='grid-x align-middle'>
        <div className='preview-authorized__checkbox'>
          <Checkbox 
            checked={userAuthorizedSelected?.isAuthorizeFurtherLoans} 
            disabled={!userAuthorizedSelected}
            onChange={() => handleLoanGranting()}
          >
            <span className={`preview-authorized__text preview-authorized__text--check ${!userAuthorizedSelected ? "preview-authorized__text__disabled" : ""}`}>
              No conceder más prestamos
            </span>
          </Checkbox>
        </div>
      </div>
      { type === 0 &&
        <>
          <div className='preview-authorized__line'></div>
          <div className='grid-x'>
            <button className='preview-authorized__edit' onClick={()=> handleEdith()}>
              <img src={Assets.SharedIcons.icon_edit} alt='icon_edit' className='preview-authorized__edit__icon'/>
              <span className='preview-authorized__edit__text'>Editar perfil</span>
            </button>
          </div>
        </>
      }
    </div>
  )
}

const mapStateToProps = ({ AuthorizedManagementReducer }) => {
  const { userAuthorizedSelected } = AuthorizedManagementReducer;
  return { userAuthorizedSelected };
};

const mapStateToPropsActions = {
  setStateAuthorizedManagementReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(PreviewAuthorizedComponent);