export const dataTransfers = [
    {
        price: "",
        disabled: true,
        invoiceNumber: "",
    },
    {
        price: "",
        disabled: true,
        invoiceNumber: "",
    },
    {
        price: "",
        disabled: true,
        invoiceNumber: "",
    },
    {
        price: "",
        disabled: true,
        invoiceNumber: "",
    },
    {
        price: "",
        disabled: true,
        invoiceNumber: "",
    },
    {
        price: "",
        disabled: true,
        invoiceNumber: "",
    },
    {
        price: "",
        disabled: true,
        invoiceNumber: "",
    },
]