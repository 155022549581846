//Constants
import FinalBalanceHeadquartersEndPoints from "./constants/final-balance-headquarters.end-points";

//Infrastructures
import Http from "./infrastructure/http.infrastructure";

//Services
import { getTokenService } from "./authentication.services";

export async function getFinalBalanceHeadquartersService(date) {
    try {
        const token = getTokenService()
        const urlWithParams = FinalBalanceHeadquartersEndPoints.GET_FINAL_BALANCE_HEADQUARTERS.replace(":date", date)
        const { data: responseGetFinalBalanceHeadquarters, error } = await Http.get(urlWithParams, null, token)
        if (responseGetFinalBalanceHeadquarters && responseGetFinalBalanceHeadquarters.status) {
            return responseGetFinalBalanceHeadquarters.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateFinalBalanceHeadquartersService(body) {
    try {
        const token = getTokenService()
        const { data: responseUpdateFinalBalanceHeadquarters, error } = await Http.put(FinalBalanceHeadquartersEndPoints.UPDATE_FINAL_BALANCE_HEADQUARTERS, body, token)
        if (responseUpdateFinalBalanceHeadquarters && responseUpdateFinalBalanceHeadquarters.status) {
            return responseUpdateFinalBalanceHeadquarters.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};