// Actions
import { setStateHeadquartersGenerallyReducer, setStateHeadquartersReducer } from "../../../storage/reducers/headquarters/headquarters.actions"; 

// Assets
import { Assets } from '../../../assets';

// Components - Shared
import ErrorAlertComponent from '../../../shared/components/alerts/error-alert/error-alert.component';
import SuccessAlertComponent from "../../../shared/components/alerts/success-alert/success-alert.component";
import InformationAlertComponent from "../../../shared/components/alerts/information-alert/information-alert.component";

// Components
import GeneralSummaryComponent from './components/general-summary/general-summary.component';
import GeneralAdjustmentComponent from './components/general-adjustment/general-adjustment.component';
import ProjectionsComponent from './components/projections/projections.component';

// Libraries
import dayjs from 'dayjs';
import { DatePicker, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { connect } from 'react-redux';

// Services
import { 
    createAndUpdateChangesService,
    getChargesService,
    getConceptsService,
    getConsumptionProvisionsService,
    getHeadquartersExpensesService,
    getIvaProvisionsService,
    getOrderTypesService,
    getProjectionsService,
    getTexasService,
    getTotalByHeadquartersService,
    updateProjectionsService,
    updateTexasService,
    updateConsumptionProvisionsService,
    updateIvaProvisionsService,
    getPortfoliosListServices,
    getTotalizerUtilityServices,
    getTotalizerExpensesServices,
    getTotalizerTreasuryServices,
    getTotalizerInventoryServices,
    getTotalizerSalesServices,
    getTotalizerDifferentialServices,
    getTotalizerCreditsService
} from '../../../services/headquarters.services';

// Styles
import './view-and-edit-summary.page.scss';

const ViewAndEditSummaryPage = (props) => {
    const {
        // Actions
        setStateHeadquartersReducer,
        setStateHeadquartersGenerallyReducer,
        // Variables
        changesGeneralSummary,
        projections,
        projectionsOriginal,
        changeGeneralAdjustment
    } = props;

    let { state: { selectedMonth, headquarter } } = useLocation();

    const INITIAL_STATE = {
        hoverIndex: null,
        optionSelected: 0,
        isHoverSaveProcess: false,
        selectedMonthSummary: dayjs(selectedMonth),
        isAlertModal: {
            open: false,
            index: null
        }
    };

    const [state, setState] = useState(INITIAL_STATE);

    const {
        hoverIndex,
        optionSelected,
        isHoverSaveProcess,
        isAlertModal,
        selectedMonthSummary
    } = state;

    useEffect(() => {
        getDataContainers()

        return () => {
            setStateHeadquartersReducer("changesGeneralSummary", [])
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMonthSummary])

    const getDataContainers = async () => {
        try {
            const responseServices = await Promise.allSettled([
                getOrderTypesService(),
                getTotalByHeadquartersService(headquarter?.id, dayjs(selectedMonthSummary).startOf('month').format('YYYY-MM-DD')),
                getHeadquartersExpensesService(headquarter?.id, dayjs(selectedMonthSummary).startOf('month').format('YYYY-MM-DD')),
                getConceptsService(headquarter?.id, dayjs(selectedMonthSummary).startOf('month').format('YYYY-MM-DD')),
                getChargesService(headquarter?.id, dayjs(selectedMonthSummary).startOf('month').format('YYYY-MM-DD')),
                getTexasService(headquarter?.id, dayjs(selectedMonthSummary).startOf('month').format('YYYY-MM-DD')),
                getConsumptionProvisionsService(headquarter?.id, dayjs(selectedMonthSummary).startOf('month').format('YYYY-MM-DD')),
                getIvaProvisionsService(headquarter?.id, dayjs(selectedMonthSummary).startOf('month').format('YYYY-MM-DD')),
                getProjectionsService(headquarter?.id, dayjs(selectedMonthSummary).startOf('month').format('YYYY-MM-DD')),
                getPortfoliosListServices(headquarter?.id, dayjs(selectedMonthSummary).startOf('month').format('YYYY-MM-DD')),
                getTotalizerUtilityServices(headquarter?.id, dayjs(selectedMonthSummary).startOf('month').format('YYYY-MM-DD')),
                getTotalizerExpensesServices(headquarter?.id, dayjs(selectedMonthSummary).startOf('month').format('YYYY-MM-DD')),
                getTotalizerTreasuryServices(headquarter?.id, dayjs(selectedMonthSummary).startOf('month').format('YYYY-MM-DD')),
                getTotalizerInventoryServices(headquarter?.id, dayjs(selectedMonthSummary).startOf('month').format('YYYY-MM-DD')),
                getTotalizerSalesServices(headquarter?.id, dayjs(selectedMonthSummary).startOf('month').format('YYYY-MM-DD')),
                getTotalizerDifferentialServices(headquarter?.id, dayjs(selectedMonthSummary).startOf('month').format('YYYY-MM-DD')),
                getTotalizerCreditsService(headquarter?.id, dayjs(selectedMonthSummary).startOf('month').format('YYYY-MM-DD'))

            ])
            
            let orderTypes = []
            let inventory = {}
            let generalAdjustmentInfo = []
            let dataTables = {}
            let charges = {}
            let organizeTaxesByType = []
            let consumptionProvisions = {}
            let ivaProvisions = {}
            let projections = {}
            let portfoliosList = {}
            let totalUtility = {}
            let totalExpenses = {}
            let totalTreasury = {}
            let totalInventory = {}
            let totalSales = {}
            let totalDifferentialIva = {}
            let totalCredits = {}
            let errors = false

            for (let index = 0; index < responseServices.length; index++) {
                const elementResponse = responseServices[index];
                if (elementResponse.status === "fulfilled") {
                    switch (index) {
                        case 0:
                            orderTypes = elementResponse?.value
                            break;
                        case 1:
                            inventory ={
                                initialBalance : elementResponse?.value?.initialBalance,
                                totalByHeadquarters : [
                                    { description: 'Inventario general', value: elementResponse?.value?.totalInItems?.general },
                                    { description: 'Inventario general (Averias)', value: elementResponse?.value?.totalInItems?.averias },
                                    { description: 'Envases', value: elementResponse?.value?.totalInItems?.container },
                                ],
                                total: 0
                            }
                            break;
                        case 2:
                            generalAdjustmentInfo = Object.entries(elementResponse?.value).map(([title, details]) => ({
                                title,
                                data: details.data,
                                total: details.total
                            }));
                            break;

                        case 3:
                            dataTables = elementResponse?.value
                            break;

                        case 4:
                            charges = elementResponse?.value
                            break;
                        case 5:
                            organizeTaxesByType = Object.entries(elementResponse?.value).map(([title, details]) => ({
                                title,
                                data: details.data,
                                total: details.total
                            }));
                            break;
                        case 6:
                            consumptionProvisions = elementResponse?.value
                            break;
                        case 7:
                            ivaProvisions = elementResponse?.value
                            break;
                        case 8:
                            projections = elementResponse?.value
                            break;
                        case 9:
                            portfoliosList = {
                                description: "Total a la fecha",
                                value: elementResponse?.value?.totalTreasury
                            }
                            break;
                        case 10:
                            totalUtility = elementResponse?.value
                            break;
                        case 11:
                            totalExpenses = elementResponse?.value
                            break;
                        case 12:
                            totalTreasury = elementResponse?.value
                            break;
                        case 13:
                            totalInventory = elementResponse?.value
                            break;
                        case 14:
                            totalSales = elementResponse?.value
                            break;
                        case 15:
                            totalDifferentialIva = elementResponse?.value
                            break;
                        case 16:
                            totalCredits = {
                                ...elementResponse?.value,
                                providerList: elementResponse?.value?.providerList.map(item => ({
                                    ...item,
                                    date: dayjs(item.date).add(1, 'month').startOf('month').format('YYYY-MM-DD')
                                }))
                            };
                            break;
                        default:
                            break;
                    }
                } else {
                    errors = true
                }
            };

            if(errors){
                ErrorAlertComponent()
            }

            if (dataTables && orderTypes?.length) {
                const idFilter = orderTypes[0];
                const dataFilter = dataTables[idFilter]?.data;
                const initialValueInventory = inventory.totalByHeadquarters[0]?.value || 0;
            
                let totalToAdd = 0;
                let totalByHeadquartersToAdd = [];
            
                if (dataFilter?.length) {
                    totalToAdd = dataTables[idFilter]?.total || 0;
                    totalByHeadquartersToAdd = dataFilter;
                }
            
                inventory = {
                    ...inventory,
                    id: idFilter,
                    total: initialValueInventory + totalToAdd,
                    totalByHeadquarters: [...inventory.totalByHeadquarters, ...totalByHeadquartersToAdd]
                };
            }
            

            setStateHeadquartersGenerallyReducer({
                generalSummary:{
                    inventory,
                    orderTypes,
                    dataTables,
                    charges,
                    portfoliosList,
                    totalUtility,
                    totalExpenses,
                    totalTreasury,
                    totalInventory,
                    totalSales,
                    totalDifferentialIva
                },
                generalAdjustment:{
                    generalAdjustmentInfo,
                    organizeTaxesByType,
                    consumptionProvisions,
                    ivaProvisions
                },
                projections: projections,
                projectionsOriginal: projections,
                totalCredits: totalCredits
            })

        } catch (error) {
            ErrorAlertComponent()
        }
    }

    const goToMonth = (amount) => {
        const newDate = dayjs(selectedMonthSummary).add(amount, 'month');
        const currentDate = dayjs();

        if (newDate.isBefore(currentDate, 'month') || newDate.isSame(currentDate, 'month')) {
            setState({
                ...state,
                selectedMonthSummary: newDate
            });
        }
    };

    const disabledDate = (current) => {
        return current && current.isAfter(dayjs(), 'month');
    };

    const tabs = [
        {
            label: 'Resumen General',
            iconAlt: { active: 'icon_summary', inactive: 'icon_summary_inactive', hover: 'icon_summary_active' },
            iconSrc: { active: Assets.SharedIcons.icon_summary, inactive: Assets.SharedIcons.icon_summary_inactive, hover: Assets.SharedIcons.icon_summary_active },
        },
        {
            label: 'Cuadre general',
            iconAlt: { active: 'icon_headquarters', inactive: 'icon_headquarters_inactive', hover: 'icon_headquarters_active' },
            iconSrc: { active: Assets.SharedIcons.icon_headquarters, inactive: Assets.SharedIcons.icon_headquarters_inactive, hover: Assets.SharedIcons.icon_headquarters_active },
        },
        {
            label: 'Proyecciones',
            iconAlt: { active: 'icon_projections_selected', inactive: 'icon_projections_inactive', hover: 'icon_projections_active' },
            iconSrc: { active: Assets.SharedIcons.icon_projections_selected, inactive: Assets.SharedIcons.icon_projections_inactive, hover: Assets.SharedIcons.icon_projections_active },
        }
    ];

    const validatePropsHoverTab = (index, typeIcon, typeAlt) => {
        let alt, src, typeText;
        
        if (hoverIndex === index) {
            alt = typeAlt.hover;
            src = typeIcon.hover;
            typeText = '__hover';
        } else if (optionSelected === index) {
            alt = typeAlt.active;
            src = typeIcon.active;
            typeText = '__active';
        } else {
            alt = typeAlt.inactive;
            src = typeIcon.inactive;
            typeText = '__inactive';
        }

        return { alt, src, typeText };
    };

    const handleSave = async() => {
        try {
            if(optionSelected === 0){
                if(changesGeneralSummary?.length){
                    const allHaveValidProperties = changesGeneralSummary.every(object => {
                      return object.hasOwnProperty("description") &&
                             object.hasOwnProperty("value") &&
                             object.concept !== "" &&
                             object.value !== "";
                    });
                    if(allHaveValidProperties){
                        await createAndUpdateChangesService(headquarter?.id, dayjs(selectedMonthSummary).startOf('month').format('YYYY-MM-DD'), changesGeneralSummary)
                        await getDataContainers()
                        setStateHeadquartersReducer("changesGeneralSummary", [])
                        SuccessAlertComponent()
                    }else{
                        InformationAlertComponent(undefined, 'Por favor, rellene todos los campos.')
                    }
                }else{
                    InformationAlertComponent()
                }
            }

            if (optionSelected === 1) {
                await handleSaveGeneralAdjustment(false)
            }

            if(optionSelected === 2){
                const changes = findChanges(projectionsOriginal,projections)
                if(Object.keys(changes).length > 0){
                    await updateProjectionsService({...changes, idHeadQuarter: headquarter?.id, date: dayjs(selectedMonthSummary).startOf('month').format('YYYY-MM-DD')})
                    await getDataContainers()
                    SuccessAlertComponent()
                }else{
                    InformationAlertComponent()
                }
            }
        } catch (error) {
            ErrorAlertComponent()
        }
    }

    const validateData = (index) => {
        if (optionSelected === 0) {
            if (changesGeneralSummary?.length) {
                setState({ ...state, isAlertModal: { open: true, index: index } })
            } else {
                setState({ ...state, optionSelected: index })
            }
        }
        if (optionSelected === 1) {
            const { industryAndCommerce, retention, provisionForConsumption, ivaProvisions } = changeGeneralAdjustment;
            if (industryAndCommerce?.length || retention?.length || provisionForConsumption?.length || ivaProvisions?.length) {
                setState({ ...state, isAlertModal: { open: true, index: index } })
            } else {
                setState({ ...state, optionSelected: index })
            }
        }
        if(optionSelected === 2){
            const changes = findChanges(projectionsOriginal,projections)
            if(Object.keys(changes).length > 0){
                setState({ ...state, isAlertModal: { open: true, index: index } })
            }else{
                setState({ ...state, optionSelected: index })
            }
        }
    };

    const handleNext = async (key) => {
        switch (key) {
            case 0:
                setState({ ...state, isAlertModal: { open: false, index: null } })
                break;
            case 1:
                if (optionSelected === 0) {
                    setState({ ...state, isAlertModal: { open: false, index: null }, optionSelected: isAlertModal?.index })
                    setStateHeadquartersReducer("changesGeneralSummary", [])
                }

                if (optionSelected === 1) {
                    setStateHeadquartersReducer("changeGeneralAdjustment", { industryAndCommerce: undefined, retention: undefined, provisionForConsumption: undefined, ivaProvisions: undefined });
                }

                if (optionSelected === 2) {
                    setState({ ...state, isAlertModal: { open: false, index: null }, optionSelected: isAlertModal?.index })
                    setStateHeadquartersReducer("projections", projectionsOriginal)
                }
                break;
            case 2:
                if (optionSelected === 0) {
                    await SaveSummaryGenerally()
                }

                if (optionSelected === 1) {
                    await handleSaveGeneralAdjustment(true);
                }

                if (optionSelected === 2) {
                    await SaveProjections()
                }
                break;
            default:
                break;
        }
    }

    const SaveSummaryGenerally = async() => {
        const allHaveValidProperties = changesGeneralSummary.every(object => {
            return object.hasOwnProperty("description") &&
                   object.hasOwnProperty("value") &&
                   object.concept !== "" &&
                   object.value !== "";
        });

        if(allHaveValidProperties){
            await createAndUpdateChangesService(headquarter?.id, dayjs(selectedMonthSummary).startOf('month').format('YYYY-MM-DD'), changesGeneralSummary)
            await getDataContainers()
            setStateHeadquartersReducer("changesGeneralSummary", [])
            SuccessAlertComponent()
            setState({ ...state, isAlertModal: {open: false, index: null}, optionSelected: isAlertModal?.index})
        }else{
            InformationAlertComponent(undefined, 'Por favor, rellene todos los campos.')
        }
    }

    const SaveProjections = async() => {
        try {
            const changes = findChanges(projectionsOriginal,projections)
            if(Object.keys(changes).length > 0){
                await updateProjectionsService({...changes, idHeadQuarter: headquarter?.id, date: dayjs(selectedMonthSummary).startOf('month').format('YYYY-MM-DD')})
                await getDataContainers()
                SuccessAlertComponent()
                setState({ ...state, isAlertModal: {open: false, index: null}, optionSelected: isAlertModal?.index})
            }
        } catch (error) {
            ErrorAlertComponent()
        }
    }

    const handleSaveGeneralAdjustment = async (isModal) => {
        try {
            const { industryAndCommerce, retention, provisionForConsumption, ivaProvisions } = changeGeneralAdjustment;
            const updateTaxes = async (taxes, taxType) => {
                if (taxes?.length) {
                    const taxesInfo = taxes.map((dataItem) => ({
                        type: dataItem.type,
                        taxPorcentage: dataItem.taxPorcentage,
                    }));
                    await updateTexasService(taxesInfo, headquarter?.id, dayjs(selectedMonthSummary).startOf('month').format('YYYY-MM-DD'));
                }
            };
            const updateProvisions = async (provisions, updateService) => {
                if (provisions?.length) {
                    const provisionData = provisions.map((item) => ({
                        id: item.id,
                        date: item.date,
                        value: item.value,
                        specificDate: item.date,
                    }));
                    await updateService(provisionData, headquarter?.id);
                }
            };
            await Promise.all([
                updateTaxes(industryAndCommerce, updateTexasService),
                updateTaxes(retention, updateTexasService),
                updateProvisions(provisionForConsumption, updateConsumptionProvisionsService),
                updateProvisions(ivaProvisions, updateIvaProvisionsService),
            ]);

            SuccessAlertComponent(undefined, 'Se ha guardado los cambios correctamente.');
            setStateHeadquartersReducer("changeGeneralAdjustment", { industryAndCommerce: undefined, retention: undefined, provisionForConsumption: undefined, ivaProvisions: undefined });
            isModal && setState({ ...state, isAlertModal: {open: false, index: null}, optionSelected: isAlertModal?.index});
            await getDataContainers();

        } catch (error) {
            ErrorAlertComponent();
        }
    };

    const findChanges = (object1, object2) =>{
        return Object.keys(object2).reduce((changes, key) => {
          if (object1.hasOwnProperty(key) && object1[key] !== object2[key]) {
            changes[key] = object2[key];
            (!changes.idTransaction) && (changes.idTransaction = object1.idTransaction)
          }
          return changes;
        }, {});
      }

    return (
        <>
            <div className='view-and-edit-summary__container'>
                <div className='grid-x align-middle justify-content-between view-and-edit-summary__content-header'>
                    <div className='grid-x align-middle'>
                        <Link
                            to="/viewAndEditSummary"
                            className="grid-x view-and-edit-summary__content-header__link"
                        >
                            Sedes o Licoreras
                            <img
                                alt='icon_arrow'
                                src={Assets.SharedIcons.icon_arrow}
                                className='view-and-edit-summary__content-header__link__icon'
                            />
                        </Link>
                        <span className='view-and-edit-summary__content-header__link__name'>
                            {headquarter?.name}
                        </span>
                    </div>
                    <div className='grid-x align-middle'>
                        <span className='view-and-edit-summary__content-header__date__text'>Seleccionar mes:</span>
                        <div className='grid-x align-middle view-and-edit-summary__content-header__date__picker'>
                            <button onClick={() => goToMonth(-1)}>
                                <img
                                    alt='icon_arrow'
                                    src={Assets.SharedIcons.icon_arrow}
                                    className='view-and-edit-summary__content-header__date__picker__arrow'
                                />
                            </button>
                            <DatePicker
                                picker='month'
                                bordered={false}
                                suffixIcon={null}
                                allowClear={false}
                                inputReadOnly={true}
                                defaultValue={dayjs()}
                                disabledDate={disabledDate}
                                value={selectedMonthSummary}
                                format={(value) => {
                                    return `${dayjs(value)?.format('MMMM, YYYY')}`;
                                }}
                                onChange={(date) => setState({ ...state, selectedMonthSummary: date })}
                                className='view-and-edit-summary__content-header__date__picker__month'
                            />
                            <button
                                onClick={() => goToMonth(1)}
                                disabled={(dayjs(selectedMonthSummary).add(1, 'month').isAfter(dayjs(), 'month'))}
                                className='view-and-edit-summary__content-header__date__picker__button-next'
                            >
                                <img
                                    alt={(dayjs(selectedMonthSummary).add(1, 'month').isAfter(dayjs(), 'month')) ? 'icon_arrow_picker_disabled' : 'icon_arrow'}
                                    src={(dayjs(selectedMonthSummary).add(1, 'month').isAfter(dayjs(), 'month')) ? Assets.SharedIcons.icon_arrow_picker_disabled : Assets.SharedIcons.icon_arrow}
                                />
                            </button>
                        </div>
                    </div>
                </div>
                <div className='grid-x align-middle justify-content-between view-and-edit-summary__content-options'>
                    <div className='grid-x align-middle justify-content-between view-and-edit-summary__content-options__content-buttons'>
                        {tabs.map((tab, index) => {
                            return (
                                <button
                                    key={index}
                                    onMouseOver={() => setState({ ...state, hoverIndex: index })}
                                    onMouseOut={() => setState({ ...state, hoverIndex: null })}
                                    onClick={() => validateData(index)}
                                    className={`view-and-edit-summary__content-options__buttons${optionSelected === index ? '__active' : ''}`}
                                >
                                    <img
                                        alt={validatePropsHoverTab(index, tab.iconSrc, tab.iconAlt).alt}
                                        src={validatePropsHoverTab(index, tab.iconSrc, tab.iconAlt).src}
                                        className='view-and-edit-summary__content-options__icon-button'
                                    />
                                    <span className={`view-and-edit-summary__content-options__buttons__text${validatePropsHoverTab(index, tab.iconSrc, tab.iconAlt).typeText}`}>
                                        {tab.label}
                                    </span>
                                </button>
                            )
                        })}
                    </div>
                    <div className='grid-x align-middle'>
                        <button
                            className='view-and-edit-summary__content-options__button-save'
                            onMouseOver={() => setState((prevState) => ({ ...prevState, isHoverSaveProcess: true }))}
                            onMouseOut={() => setState((prevState) => ({ ...prevState, isHoverSaveProcess: false }))}
                            onClick={() => handleSave()}
                        >
                            <img
                                alt={isHoverSaveProcess ? 'icon_save_hover' : 'icon_save'}
                                src={isHoverSaveProcess ? Assets.SharedIcons.icon_save_hover : Assets.SharedIcons.icon_save}
                                className='view-and-edit-summary__content-options__icon-button'
                            />
                            <span>Guardar y actualizar</span>
                        </button>
                        <button className='view-and-edit-summary__content-options__button-publish'>
                            <img
                                alt='icon_publish'
                                src={Assets.SharedIcons.icon_publish}
                                className='view-and-edit-summary__content-options__icon-button'
                            />
                            <span>Finalizar y publicar</span>
                        </button>
                    </div>
                </div>
                <div className='view-and-edit-summary__content-info'>
                    {optionSelected === 0 && <GeneralSummaryComponent />}
                    {optionSelected === 1 && <GeneralAdjustmentComponent />}
                    {optionSelected === 2 && <ProjectionsComponent />}
                </div>
            </div>
            {isAlertModal?.open && (
                <Modal
                    centered
                    width={450}
                    destroyOnClose
                    open={isAlertModal?.open}
                    closeIcon={<img src={Assets.SharedIcons.icon_close} alt="icon_close" />}
                    onCancel={() => handleNext(0)}
                    footer={
                        <div className='grid-x justify-content-center view-and-edit-summary__modal__content-footer'>
                            <button
                                key="cancel"
                                className='view-and-edit-summary__modal__button__cancel'
                                onClick={() => handleNext(1)}
                            >
                                <span>Continuar sin Guardar</span>
                            </button>
                            <button
                                key="accept"
                                className='view-and-edit-summary__modal__button__confirm'
                                onClick={() => handleNext(2)}
                            >
                                <span>Guardar y Continuar</span>
                            </button>
                        </div>
                    }
                >
                    <div className='grid-x align-center-middle view-and-edit-summary__modal__content'>
                        <div className='grid-y small-12 align-middle'>
                            <img
                                alt="icon_report"
                                src={Assets.SharedIcons.icon_report}
                            />
                            <span className='view-and-edit-summary__modal__title text-center'>Tienes cambios sin guardar. ¿Quieres continuar sin guardar?</span>
                            <span className='view-and-edit-summary__modal__text'>Antes de continuar, por favor ten en cuenta que la acción que estás a punto de realizar es irreversible.</span>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    )
}

const mapStateToProps = ({ HeadquartersReducer }) => {
    const { 
        changesGeneralSummary, generalAdjustment,
        projections, projectionsOriginal, changeGeneralAdjustment,
    } = HeadquartersReducer;

    return {
        changesGeneralSummary,
        generalAdjustment,
        projections,
        projectionsOriginal,
        changeGeneralAdjustment,
    };
};
  
const mapStateToPropsActions = {
    setStateHeadquartersReducer,
    setStateHeadquartersGenerallyReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(ViewAndEditSummaryPage);