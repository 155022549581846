const InfrastructureErrorCodesConst = {
    /**
     * Response's
     * */
     RES_IFT_001 : "RES_IFT_001",
     RES_IFT_002 : "RES_IFT_002",
     RES_IFT_003 : "RES_IFT_003",
     /**
     * Request's
     * */
     REQ_IFT_001 : "REQ_IFT_001",
     REQ_IFT_002 : "REQ_IFT_002",
     REQ_IFT_003 : "REQ_IFT_003",
  }

  export default InfrastructureErrorCodesConst