// Actions
import { setMultipleStatesWholesaleFinalBalanceReducer, setStateWholesaleFinalBalanceReducer } from '../../../../storage/reducers/wholesale-final-balance/wholesale-final-balance.actions';

// Assets
import { Assets } from '../../../../assets';

// Components
import ErrorAlertComponent from '../../../../shared/components/alerts/error-alert/error-alert.component';
import SuccessAlertComponent from '../../../../shared/components/alerts/success-alert/success-alert.component';

// Libraries
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { DatePicker, Input } from 'antd';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

// Services
import { createRecordsInTheTablesService, deletedRecordsInTheTablesService, updateRecordsInTheTablesService } from '../../../../services/final-reconciliation.services';

// Styles
import './tables-of-records.component.scss';

const TablesOfRecordsComponent = (props) => {

  const { state: locationState } = useLocation();

  const {
    // Actions
    setStateWholesaleFinalBalanceReducer,
    setMultipleStatesWholesaleFinalBalanceReducer,

    // Variables
    isSave,
    isNewAttribute,
    itemTabSelected,
    indexTabSelected,
    listsTableInformation,
    listsTableInformationCopy
  } = props;

  const INITIAL_STATE = {
    negativeTableList: [],
    positiveTableList: [],
  };

  const [state, setState] = useState(INITIAL_STATE);

  const { negativeTableList, positiveTableList } = state;

  useEffect(() => {
    if (isSave && indexTabSelected !== 0) {
      handleSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSave, indexTabSelected]);

  useEffect(() => {

    if (indexTabSelected !== 0) {

      const extractNegativeList = [...listsTableInformation[indexTabSelected].map((item, index) => { return item.negativeFlowData })];
      const extractPositiveList = [...listsTableInformation[indexTabSelected].map((item, index) => { return item.positiveFlowData })];

      let negativeTableList = extractNegativeList.map((item, index) => {
        if (item?.tableData?.length < structureNegativeTable.tableData?.length) {
          const resp = item?.tableData?.concat(structureNegativeTable.tableData.slice(0, (structureNegativeTable.tableData.length - item?.tableData?.length)));
          item.tableData = resp;
        };
        return item;
      });

      let positiveTableList = extractPositiveList.map((item, index) => {
        if (item?.tableData?.length < structurePositiveTable.tableData?.length) {
          const resp = item?.tableData?.concat(structurePositiveTable.tableData.slice(0, (structurePositiveTable.tableData.length - item?.tableData?.length)));
          item.tableData = resp;
        };
        return item;
      });

      setState({ ...state, negativeTableList, positiveTableList });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listsTableInformation[indexTabSelected]]);

  const structureNegativeTable = {
    total: 0,
    tableName: '',
    tableData: [
      { idTypeExpenseWholesaler: '', executionDate: '', concept: '', price: '', expirationDate: '', flow: 0, disabled: true },
      { idTypeExpenseWholesaler: '', executionDate: '', concept: '', price: '', expirationDate: '', flow: 0, disabled: true },
      { idTypeExpenseWholesaler: '', executionDate: '', concept: '', price: '', expirationDate: '', flow: 0, disabled: true },
      { idTypeExpenseWholesaler: '', executionDate: '', concept: '', price: '', expirationDate: '', flow: 0, disabled: true },
      { idTypeExpenseWholesaler: '', executionDate: '', concept: '', price: '', expirationDate: '', flow: 0, disabled: true },
      { idTypeExpenseWholesaler: '', executionDate: '', concept: '', price: '', expirationDate: '', flow: 0, disabled: true },
      { idTypeExpenseWholesaler: '', executionDate: '', concept: '', price: '', expirationDate: '', flow: 0, disabled: true },
    ]
  };

  const structurePositiveTable = {
    total: 0,
    tableName: '',
    tableData: [
      { idTypeExpenseWholesaler: '', executionDate: '', concept: '', price: '', expirationDate: '', flow: 1, disabled: true },
      { idTypeExpenseWholesaler: '', executionDate: '', concept: '', price: '', expirationDate: '', flow: 1, disabled: true },
      { idTypeExpenseWholesaler: '', executionDate: '', concept: '', price: '', expirationDate: '', flow: 1, disabled: true },
      { idTypeExpenseWholesaler: '', executionDate: '', concept: '', price: '', expirationDate: '', flow: 1, disabled: true },
      { idTypeExpenseWholesaler: '', executionDate: '', concept: '', price: '', expirationDate: '', flow: 1, disabled: true },
      { idTypeExpenseWholesaler: '', executionDate: '', concept: '', price: '', expirationDate: '', flow: 1, disabled: true },
      { idTypeExpenseWholesaler: '', executionDate: '', concept: '', price: '', expirationDate: '', flow: 1, disabled: true },
    ]
  };

  const addRecordsToTables = (indexSelectedTable, typeOfSelection) => {
    let selectedTableType = typeOfSelection ? [...positiveTableList] : [...negativeTableList];
    const objectDisabled = selectedTableType[indexSelectedTable].tableData.find(object => object.hasOwnProperty("disabled") && object.disabled === true);

    if (!objectDisabled) {
      const newArray = [
        ...selectedTableType[indexSelectedTable].tableData,
        {
          price: '',
          concept: '',
          disabled: false,
          executionDate: '',
          expirationDate: '',
          flow: typeOfSelection ? 1 : 0,
          idTypeExpenseWholesaler: itemTabSelected.schema[indexSelectedTable][typeOfSelection ? 'positiveFlow' : 'negativeFlow'],
        }
      ];

      selectedTableType[indexSelectedTable].tableData = newArray;

      setState({
        ...state,
        [typeOfSelection ? 'positiveTableList' : 'negativeTableList']: selectedTableType
      });

    } else {
      const newArray = selectedTableType[indexSelectedTable].tableData.map(object => object === objectDisabled
        ?
          {
            ...object,
            disabled: false,
            idTypeExpenseWholesaler: itemTabSelected.schema[indexSelectedTable][typeOfSelection ? 'positiveFlow' : 'negativeFlow']
          }
        : object
      );

      selectedTableType[indexSelectedTable].tableData = newArray;

      setState({
        ...state,
        [typeOfSelection ? 'positiveTableList' : 'negativeTableList']: selectedTableType
      });
    };
  };

  const onChange = (value, indexSelectedTable, typeOfSelection, indexItem, target) => {
    let fieldValue = value && value.target ? value.target.value || '' : value;
    let selectedTableType = typeOfSelection ? JSON.parse(JSON.stringify(positiveTableList)) : JSON.parse(JSON.stringify(negativeTableList));

    if (target === "price") {
      fieldValue = fieldValue.replace(/[^0-9.,]/g, '').replace(/(\..*)\./g, '$1').replace(/,/g, '')
      const parts = fieldValue.split('.');
      if (parts.length === 2) {
        const decimalPart = parts[1];
        if (decimalPart.length > 2) {
          parts[1] = decimalPart.slice(0, 2);
        }
      }
      fieldValue = parts.join('.');
      fieldValue = parseInt(fieldValue) || 0;
    };

    selectedTableType[indexSelectedTable].tableData[indexItem][target] = fieldValue;

    setState({
      ...state,
      [typeOfSelection ? 'positiveTableList' : 'negativeTableList']: selectedTableType
    });

    if (typeOfSelection) {
      listsTableInformation[indexTabSelected][indexSelectedTable].positiveFlowData = selectedTableType[indexSelectedTable];
    } else {
      listsTableInformation[indexTabSelected][indexSelectedTable].negativeFlowData = selectedTableType[indexSelectedTable];
    };

    setStateWholesaleFinalBalanceReducer('listsTableInformation', listsTableInformation);
  };

  const handleSave = async () => {
    try {
      const newListsTableInformation = listsTableInformation.slice(1);
      const newListsTableInformationCopy = listsTableInformationCopy.slice(1);

      const { createRegisters, updateRegisters } = compareArraysOfObjects(newListsTableInformation, newListsTableInformationCopy);

      if (createRegisters.length > 0) {
        await createRecordsInTheTablesService(createRegisters, locationState?.idHeadquarter);
      };
      if (updateRegisters.length > 0) {
        await updateRecordsInTheTablesService(updateRegisters);
      };

      SuccessAlertComponent();

    } catch (error) {
      ErrorAlertComponent()
    } finally {
      setMultipleStatesWholesaleFinalBalanceReducer({ isSave: false, isGetAllTabs: true, isLoading: true })
    }
  };

  const compareArraysOfObjects = (listsTableInformation, listsTableInformationCopy) => {
    const createNewRecords = [];
    const updateRecords = [];

    listsTableInformation.forEach((objectTables, indexTables) => {
      objectTables.forEach((object, indexObject) => {

        const tableDataNegative = object?.negativeFlowData?.tableData || [];
        const tableDataNegativeCopy = listsTableInformationCopy[indexTables][indexObject]?.negativeFlowData?.tableData || [];

        const tableDataPositive = object.positiveFlowData.tableData || [];
        const tableDataPositiveCopy = listsTableInformationCopy[indexTables][indexObject]?.positiveFlowData?.tableData || [];

        tableDataNegative.forEach((item, index) => {
          if (JSON.stringify(item) !== JSON.stringify(tableDataNegativeCopy[index])) {
            if (item.id) {
              const changedData = getChangedData(item, tableDataNegativeCopy[index]);
              updateRecords.push({ id: item.id, isExpense: true, data: changedData });
            } else {
              createNewRecords.push(item);
            }
          }
        });

        tableDataPositive.forEach((item, index) => {
          if (JSON.stringify(item) !== JSON.stringify(tableDataPositiveCopy[index])) {
            if (item.id) {
              const changedData = getChangedData(item, tableDataPositiveCopy[index]);
              updateRecords.push({ id: item.id, isExpense: false, data: changedData });
            } else {
              createNewRecords.push(item);
            };
          };
        });
      });
    });

    const createRecordsModified = createNewRecords.map(createRecord => {
      const { flow, price, concept, executionDate, expirationDate, idTypeExpenseWholesaler } = createRecord;
      const modifiedRecord = { flow, concept, executionDate, price };

      if (expirationDate !== '') {
        modifiedRecord.expirationDate = expirationDate;
      };

      modifiedRecord.typeId = idTypeExpenseWholesaler;

      return modifiedRecord;

    }).filter(createRecord => createRecord.concept !== '' && createRecord.price !== '' && createRecord.executionDate !== '');

    return { createRegisters: createRecordsModified, updateRegisters: updateRecords };
  };

  const getChangedData = (currentItem, copiedItem) => {
    const changedData = {};
    Object.keys(currentItem).forEach(key => {
      if (currentItem[key] !== copiedItem[key]) {
        changedData[key] = currentItem[key];
      }
    });
    return changedData;
  };

  const deleteRecordsFromTables = async (item, indexItem, indexTable, typeOfSelection) => {
    try {
      let selectedTableType = typeOfSelection ? JSON.parse(JSON.stringify(positiveTableList)) : JSON.parse(JSON.stringify(negativeTableList));
      const initialItem = { idTypeExpenseWholesaler: '', executionDate: '', concept: '', price: '', expirationDate: '', flow: typeOfSelection ? 1 : 0, disabled: true };

      item.id && await deletedRecordsInTheTablesService(item.id, typeOfSelection);

      selectedTableType[indexTable].tableData[indexItem] = initialItem;
      const removedItem = selectedTableType[indexTable].tableData.splice(indexItem, 1)[0];
      selectedTableType[indexTable].tableData.push(removedItem);
      selectedTableType[indexTable].total = handleTotal(selectedTableType[indexTable].tableData);

      setState({
        ...state,
        [typeOfSelection ? 'positiveTableList' : 'negativeTableList']: selectedTableType
      });

      if (typeOfSelection) {
        listsTableInformation[indexTabSelected][indexTable].positiveFlowData.tableData = selectedTableType[indexTable].tableData;
        listsTableInformation[indexTabSelected][indexTable].positiveFlowData.total  = handleTotal(selectedTableType[indexTable].tableData);
      } else {
        listsTableInformation[indexTabSelected][indexTable].negativeFlowData.tableData = selectedTableType[indexTable].tableData;
        listsTableInformation[indexTabSelected][indexTable].negativeFlowData.total  = handleTotal(selectedTableType[indexTable].tableData);
      };

      setStateWholesaleFinalBalanceReducer('listsTableInformation', listsTableInformation);

    } catch (error) {
      ErrorAlertComponent()
    }
  };

  const handleTotal = (data) => {
    let totalPrice = 0;
    data.forEach(object => { totalPrice += (parseFloat(object.price) ? parseFloat(object.price) : 0) });
    totalPrice = parseFloat(totalPrice.toFixed(2));
    return totalPrice;
  };

  return (
    <>
      {indexTabSelected !== 0 &&
        <>
          {/*
            // TODO: Improvement is required for the process of displaying negative tables
            {negativeTableList[0]?.tableData[0]?.id && 
          */}
          <div className='grid-x small-6 align-content-start'>
            {negativeTableList.map((item, indexTable) => {
              if (item?.tableName) {
                return (
                  <div key={indexTable} className='tables-of-records__box'>
                    <div className='grid-x'>
                      <div className='grid-x align-middle small-6'>
                        <img
                          alt="icon_egress"
                          src={Assets.SharedIcons.icon_egress}
                          className={`tables-of-records__box__icon${true ? '__red' : ''}`}
                        />
                        <span className='tables-of-records__box__title'>
                          {item?.tableName || ''}
                        </span>
                      </div>
                      <div className='grid-x align-middle small-6 justify-content-end'>
                        <span className='tables-of-records__box__title'>
                          Total:
                        </span>
                        <div className='grid-x align-middle tables-of-records__box__input-total'>
                          <div className='tables-of-records__box__input-total__icon-content'>
                            <img
                              alt="icon_egress"
                              src={Assets.SharedIcons.icon_egress}
                              className={`tables-of-records__box__input-total__icon${true ? '__red' : ''}`}
                            />
                          </div>
                          <NumericFormat
                            disabled
                            type='text'
                            prefix={"$ "}
                            placeholder='$ 0'
                            thousandSeparator=","
                            className='input-price'
                            thousandsGroupStyle="thousand"
                            value={item?.total || 0}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='grid-x tables-of-records__box__content-subtitles'>
                      <div className={`grid-x align-middle ${!isNewAttribute ? "tables-of-records__box__subtitle__container": "tables-of-records__box__subtitle__content"}`}>
                        <span className='tables-of-records__box__subtitle'>Fecha</span>
                      </div>
                      <div className={`grid-x align-middle ${!isNewAttribute ? "tables-of-records__box__subtitle__container": "tables-of-records__box__subtitle__content"}`}>
                        <span className='tables-of-records__box__subtitle'>Concepto</span>
                      </div>
                      { isNewAttribute &&
                        <div className={`grid-x align-middle ${!isNewAttribute ? "tables-of-records__box__subtitle__container": "tables-of-records__box__subtitle__content"}`}>
                          <span className='tables-of-records__box__subtitle'>Fecha final</span>
                        </div>
                      }
                      <div className={`grid-x align-middle justify-content-end ${!isNewAttribute ? "tables-of-records__box__subtitle__container": "tables-of-records__box__subtitle__content"}`}>
                        <span className='tables-of-records__box__subtitle'>Valor</span>
                      </div>
                    </div>
                    <div className='tables-of-records__box__info'>
                      {item?.tableData.map((itemTable, indexItem) => {
                        return (
                          <div key={indexItem} className='grid-x tables-of-records__box__content-label'>
                            <div className={`grid-x align-middle ${!isNewAttribute ? "tables-of-records__box__container-inputs__input" : "tables-of-records__box__container-inputs__content"}`}>
                              <DatePicker
                                suffixIcon={null}
                                format="YYYY/MM/DD"
                                placeholder="AAAA/MM/DD"
                                disabled={itemTable?.disabled}
                                allowClear={{ clearIcon: null }}
                                className='tables-of-records__box__input__datePicker'
                                onChange={(e, date) => onChange(date, indexTable, false, indexItem, 'executionDate')}
                                value={itemTable?.executionDate ? dayjs(itemTable?.executionDate, 'YYYY:MM:DD') : null}
                              />
                            </div>
                            <div className={`grid-x align-middle ${!isNewAttribute ? "tables-of-records__box__container-inputs__input" : "tables-of-records__box__container-inputs__content"}`}>
                              <Input
                                placeholder='Descripción'
                                disabled={itemTable?.disabled}
                                value={itemTable?.concept || ''}
                                className='tables-of-records__box__input__concept'
                                onChange={(value) => onChange(value, indexTable, false, indexItem, 'concept')}
                                prefix={<img src={itemTable.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit} alt="icon_add" />}
                              />
                            </div>
                            { isNewAttribute &&
                              <div className={`grid-x align-middle ${!isNewAttribute ? "tables-of-records__box__container-inputs__input" : "tables-of-records__box__container-inputs__content"}`}>
                                <DatePicker
                                  suffixIcon={null}
                                  format="YYYY/MM/DD"
                                  placeholder="AAAA/MM/DD"
                                  allowClear={{ clearIcon: null }}
                                  disabled={itemTable?.disabled}
                                  className='tables-of-records__box__input__datePicker'
                                  onChange={(e, date) => onChange(date, indexTable, false, indexItem, 'expirationDate')}
                                  value={itemTable?.expirationDate ? dayjs(itemTable?.expirationDate, 'YYYY:MM:DD') : null}
                                />
                              </div>
                            }
                            <div className={`grid-x align-middle ${!isNewAttribute ? "tables-of-records__box__container-inputs__input" : "tables-of-records__box__container-inputs__content"}`}>
                              <div className='grid-x tables-of-records__box__input__price'>
                                <NumericFormat
                                  type='text'
                                  prefix={"$ "}
                                  placeholder='$ 0'
                                  thousandSeparator=","
                                  className='input-price'
                                  value={itemTable?.price || 0}
                                  disabled={itemTable.disabled}
                                  thousandsGroupStyle="thousand"
                                  onChange={(value) => onChange(value, indexTable, false, indexItem, 'price')}
                                />
                                <div className='tables-of-records__box__input__price__icon-price'>
                                  <img
                                    alt="icon_add"
                                    src={itemTable.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='grid-x align-middle justify-content-end tables-of-records__box__container-inputs__deleted'>
                              <button
                                disabled={itemTable?.disabled}
                                className='tables-of-records__box__button-delete'
                                onClick={() => deleteRecordsFromTables(itemTable, indexItem, indexTable, false)}
                              >
                                <img
                                  className='tables-of-records__box__button-delete__icon'
                                  alt={itemTable?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                                  src={itemTable?.disabled ? Assets.SharedIcons.icon_deleted_disabled : Assets.SharedIcons.icon_deleted_active}
                                />
                              </button>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <button
                      onClick={() => addRecordsToTables(indexTable, false)}
                      className='grid-x align-middle tables-of-records__box__button-add'
                    >
                      <img
                        alt='icon_egress'
                        src={Assets.SharedIcons.icon_egress}
                        className={`tables-of-records__box__button-add__icon${true ? '__red' : ''}`}
                      />
                      <span className={`tables-of-records__box__button-add__text${true ? '__red' : ''}`}>
                        Agregar
                      </span>
                    </button>
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
          <div className='grid-x small-6 align-right align-content-start'>
            {positiveTableList.map((item, indexTable) => {
              if (item?.tableName) {
                return (
                  <div key={indexTable} className='tables-of-records__box'>
                    <div className='grid-x'>
                      <div className='grid-x align-middle small-6'>
                        <img
                          alt="icon_income_blue"
                          src={Assets.SharedIcons.icon_income_blue}
                          className={`tables-of-records__box__icon${true ? '__blue' : ''}`}
                        />
                        <span className='tables-of-records__box__title'>
                          {item?.tableName || ''}
                        </span>
                      </div>
                      <div className='grid-x align-middle small-6 justify-content-end'>
                        <span className='tables-of-records__box__title'>
                          Total:
                        </span>
                        <div className='grid-x align-middle tables-of-records__box__input-total'>
                          <div className='tables-of-records__box__input-total__icon-content'>
                            <img
                              alt="icon_income_blue"
                              src={Assets.SharedIcons.icon_income_blue}
                              className={`tables-of-records__box__input-total__icon${true ? '__blue' : ''}`}
                            />
                          </div>
                          <NumericFormat
                            disabled
                            type='text'
                            prefix={"$ "}
                            placeholder='$ 0'
                            thousandSeparator=","
                            className='input-price'
                            thousandsGroupStyle="thousand"
                            value={item?.total || 0}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='grid-x tables-of-records__box__content-subtitles'>
                      <div className={`grid-x align-middle ${!isNewAttribute ? "tables-of-records__box__subtitle__container": "tables-of-records__box__subtitle__content"}`}>
                        <span className='tables-of-records__box__subtitle'>Fecha</span>
                      </div>
                      <div className={`grid-x align-middle ${!isNewAttribute ? "tables-of-records__box__subtitle__container": "tables-of-records__box__subtitle__content"}`}>
                        <span className='tables-of-records__box__subtitle'>Concepto</span>
                      </div>
                      { isNewAttribute &&
                        <div className={`grid-x align-middle ${!isNewAttribute ? "tables-of-records__box__subtitle__container": "tables-of-records__box__subtitle__content"}`}>
                          <span className='tables-of-records__box__subtitle'>Fecha final</span>
                        </div>
                      }
                      <div className={`grid-x align-middle justify-content-end ${!isNewAttribute ? "tables-of-records__box__subtitle__container": "tables-of-records__box__subtitle__content"}`}>
                        <span className='tables-of-records__box__subtitle'>Valor</span>
                      </div>
                    </div>
                    <div className='tables-of-records__box__info'>
                      {item?.tableData.map((itemTable, indexItem) => {
                        return (
                          <div key={indexItem} className='grid-x tables-of-records__box__content-label'>
                            <div className={`grid-x align-middle ${!isNewAttribute ? "tables-of-records__box__container-inputs__input" : "tables-of-records__box__container-inputs__content"}`}>
                              <DatePicker
                                suffixIcon={null}
                                format="YYYY/MM/DD"
                                placeholder="AAAA/MM/DD"
                                allowClear={{ clearIcon: null }}
                                disabled={itemTable?.disabled}
                                className='tables-of-records__box__input__datePicker'
                                onChange={(e, date) => onChange(date, indexTable, true, indexItem, 'executionDate')}
                                value={itemTable?.executionDate ? dayjs(itemTable?.executionDate, 'YYYY:MM:DD') : null}
                              />
                            </div>
                            <div className={`grid-x align-middle ${!isNewAttribute ? "tables-of-records__box__container-inputs__input" : "tables-of-records__box__container-inputs__content"}`}>
                              <Input
                                placeholder='Descripción'
                                disabled={itemTable?.disabled}
                                value={itemTable?.concept || ''}
                                className='tables-of-records__box__input__concept'
                                onChange={(value) => onChange(value, indexTable, true, indexItem, 'concept')}

                                prefix={<img src={itemTable.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit} alt="icon_add" />}
                              />
                            </div>
                            { isNewAttribute &&
                              <div className={`grid-x align-middle ${!isNewAttribute ? "tables-of-records__box__container-inputs__input" : "tables-of-records__box__container-inputs__content"}`}>
                                <DatePicker
                                  suffixIcon={null}
                                  format="YYYY/MM/DD"
                                  placeholder="AAAA/MM/DD"
                                  allowClear={{ clearIcon: null }}
                                  disabled={itemTable?.disabled}
                                  className='tables-of-records__box__input__datePicker'
                                  onChange={(e, date) => onChange(date, indexTable, true, indexItem, 'expirationDate')}
                                  value={itemTable?.expirationDate ? dayjs(itemTable?.expirationDate, 'YYYY:MM:DD') : null}
                                />
                              </div>
                            }
                            <div className={`grid-x align-middle ${!isNewAttribute ? "tables-of-records__box__container-inputs__input" : "tables-of-records__box__container-inputs__content"}`}>
                              <div className='grid-x tables-of-records__box__input__price'>
                                <NumericFormat
                                  type='text'
                                  prefix={"$ "}
                                  placeholder='$ 0'
                                  thousandSeparator=","
                                  className='input-price'
                                  disabled={itemTable.disabled}
                                  value={itemTable?.price || 0}
                                  thousandsGroupStyle="thousand"
                                  onChange={(value) => onChange(value, indexTable, true, indexItem, 'price')}

                                />
                                <div className='tables-of-records__box__input__price__icon-price'>
                                  <img
                                    alt="icon_add"
                                    src={itemTable.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='grid-x align-middle justify-content-end tables-of-records__box__container-inputs__deleted'>
                              <button
                                disabled={itemTable?.disabled}
                                className='tables-of-records__box__button-delete'
                                onClick={() => deleteRecordsFromTables(itemTable, indexItem, indexTable, true)}
                              >
                                <img
                                  className='tables-of-records__box__button-delete__icon'
                                  alt={itemTable?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                                  src={itemTable?.disabled ? Assets.SharedIcons.icon_deleted_disabled : Assets.SharedIcons.icon_deleted_active}
                                />
                              </button>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <button
                      onClick={() => addRecordsToTables(indexTable, true)}
                      className='grid-x align-middle tables-of-records__box__button-add'
                    >
                      <img
                        alt='icon_income_blue'
                        src={Assets.SharedIcons.icon_income_blue}
                        className={`tables-of-records__box__button-add__icon${true ? '__blue' : ''}`}
                      />
                      <span className={`tables-of-records__box__button-add__text${true ? '__blue' : ''}`}>
                        Agregar
                      </span>
                    </button>
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
        </>
      }
    </>
  )
};

const mapStateToProps = ({ WholesaleFinalBalanceReducer }) => {

  const {
    isSave,
    isNewAttribute,
    itemTabSelected,
    indexTabSelected,
    listsTableInformation,
    listsTableInformationCopy,
  } = WholesaleFinalBalanceReducer;

  return {
    isSave,
    isNewAttribute,
    itemTabSelected,
    indexTabSelected,
    listsTableInformation,
    listsTableInformationCopy,
  };
};


const mapStateToPropsActions = {
  setStateWholesaleFinalBalanceReducer,
  setMultipleStatesWholesaleFinalBalanceReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(TablesOfRecordsComponent);