// Components
import ValuationOfCloudComponent from '../valuation-of-cloud/valuation-of-cloud.component';
import ValuationOfColdPointComponent from '../valuation-of-cold-point/valuation-of-cold-point.component';

// Libraries
import React from 'react';

// Styles
import './list-of-tns-valuations.component.scss';

const ListOfTnsValuationsComponent = ({ cloudValuationInfo, coldPointValuationInfo }) => {
  return (
    <div className='grid-x list-of-tns-valuations__container'>
      <ValuationOfCloudComponent cloudValuationInfo={cloudValuationInfo} />
      <ValuationOfColdPointComponent coldPointValuationInfo={coldPointValuationInfo} />
    </div>
  )
}

export default ListOfTnsValuationsComponent;