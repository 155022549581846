import * as cashClosingTypes from './cash-closing.types';

const INITIAL_STATE = {
	cashClosingData: [],
	expensesForm: [],
	transfersForm: [],
	providersForm: [],
	paymentOfBondsmenForm: [],
	loansDataFrom: [],
	packagingControlFrom: [],
	dataPhonesForm: [],
	refundsForm: [],
	internalConsumptionForm: []
};


const CashClosingReducer = (state = INITIAL_STATE, action) => {

	switch (action.type) {

		case cashClosingTypes.SET_STATE_CLASH_CLOSING:

			return {
				...state,
				[action.payload.prop]: action.payload.value
			};

		default:
			return state;
	}

};

export default CashClosingReducer;