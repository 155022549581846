// Assets
import { Assets } from '../../../assets';

// Libraries
import React from 'react';

// Styles
import './no-information-results.component.scss';

const NoInformationResultsComponent = () => {

  return (
    <div className='grid-y no-information-results-component__container'>
        <span className='no-information-results-component__title'>
            Oops!
        </span>
        <img
          alt='icon_search'
          src={Assets.SharedIcons.icon_search}
        />
        <span className='no-information-results-component__text'>Aquí, al parecer, aún no hay registros</span>
    </div>
  )

}

export default NoInformationResultsComponent;