// Actions
import { setStateAuthorizedManagementReducer } from "../../../storage/reducers/authorized-management/authorized-management.actions";

// Assets
import { Assets } from "../../../assets";

// Components - shared
import PaginationComponent from "../../../shared/components/pagination/pagination.component";
import ErrorAlertComponent from "../../../shared/components/alerts/error-alert/error-alert.component";

// Libraries
import React, { useEffect, useState } from "react";
import { Checkbox, Dropdown, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// Services
import { getAllAuthorizedManagementService } from "../../../services/authorized-management.services";

// Styles
import "./authorized-management.page.scss";

const AuthorizedManagementPage = (props) => {

  const {
    // Actions
    setStateAuthorizedManagementReducer
  }= props;

  const history = useNavigate();

  const INITIAL_STATE = {
    isHoverSelected: false,
    listAuthorized: [],
    paginatorState:{
      totalPages: 1,
      page: 1
    },
    filterKey: undefined
  }

  const [state, setState] = useState(INITIAL_STATE);
  const { isHoverSelected, paginatorState, listAuthorized, filterKey } = state;

  useEffect(() => {
    getDataList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDataList = async(filterValue) => {
    try {
      const listAuthorized = await getAllAuthorizedManagementService(filterValue);
      setState({
        ...state,
        listAuthorized: listAuthorized,
        paginatorState: {
          totalPages: Math.ceil(listAuthorized.length / 10),
          page: 1
        },
        filterKey: undefined
      })
      setStateAuthorizedManagementReducer("userAuthorizedSelected", undefined)
    } catch (error) {
      ErrorAlertComponent()
    }
  }

  const items = [
    {
      key: '0',
      label:
        <div className='banks-page__content-search__select-bank__checkbox'>
          <Checkbox
            style={{ width: '100%' }}
            onChange={() => handleFilter("0")}
            checked={filterKey === "0"}
          >
            Ascendente A-Z
          </Checkbox>
        </div>
    },
    {
      key: '1',
      label:
        <div className='banks-page__content-search__select-bank__checkbox'>
          <Checkbox
            style={{ width: '100%' }}
            onChange={() => handleFilter("1")}
            checked={filterKey === "1"}
          >
            Descendente Z-A
          </Checkbox>
        </div>
    },
    {
      key: '2',
      label:
        <div className='banks-page__content-search__select-bank__checkbox'>
          <Checkbox
            style={{ width: '100%' }}
            onChange={() => handleFilter("2")}
            checked={filterKey === "2"}
          >
            Deuda del mayor a menor
          </Checkbox>
        </div>
    },
    {
      key: '3',
      label:
        <div className='banks-page__content-search__select-bank__checkbox'>
          <Checkbox
            style={{ width: '100%' }}
            onChange={() => handleFilter("3")}
            checked={filterKey === "3"}
          >
            Deuda del menor a mayor
          </Checkbox>
        </div>
    },
  ];

  const handleFilter = (key) => {
    let listAuthorizedManagement = [...listAuthorized];
    switch (key) {
      case '0':
        listAuthorizedManagement.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case '1':
        listAuthorizedManagement.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case '2':
        listAuthorizedManagement.sort((a, b) => b.currentDebt - a.currentDebt);
        break;
      case '3':
        listAuthorizedManagement.sort((a, b) => a.currentDebt - b.currentDebt);
        break;
      default:
        break;
    };
    setState({ ...state, listAuthorized: listAuthorizedManagement, filterKey: key });
  }

  const handleDetails = (authorizedSelected) => {
    history(`/authorizedManagement/${authorizedSelected.id}`, { state: { idAuthorized: authorizedSelected.id, currentDebtAuthorized: authorizedSelected.currentDebt  } });
  }

  const handleCreate = () => {
    history(`/authorizedManagement/createEdit`,{ state: { type: 1 } });
  }

  const handleSearch = async(e) => {
    const { value } = e.target;
    if (value.length > 1 || value.length === 0) {
      try {
        await getDataList(value);
      } catch (error) {
        ErrorAlertComponent()
      }
    }
  }

  const arrayFilterPage = (page,array) => {
    const startIndex = (page - 1) * 10;
    const endIndex = startIndex + 10;

    return array?.slice(startIndex, endIndex);
  }

  return (
    <div className="authorized-management__container">
      <div className="grid-x">
        <div className="grid-x small-6 align-middle">
          <span className="authorized-management__title">Autorizados</span>
          <Input
            onChange={(e) => handleSearch(e)}
            className="authorized-management__content-search__input"
            placeholder="Buscar autorizados por nombre o número de documento"
            prefix={
              <img
                src={Assets.SharedIcons.icon_search_magnifier}
                alt="icon_search_magnifier"
              />
            }
          />
        </div>
        <div className="grid-x small-6 justify-content-end">
          <div className="authorized-management__content-search__select-bank">
            <Dropdown
              menu={{ items }}
              trigger={["click"]}
              className="grid-x small-12"
            >
              <div
                onMouseOver={() =>
                  setState({ ...state, isHoverSelected: true })
                }
                onMouseOut={() =>
                  setState({ ...state, isHoverSelected: false })
                }
                className="grid-x align-middle justify-content-center authorized-management__content-search__select-bank__content-selected"
              >
                <img
                  alt={isHoverSelected ? "icon_filter_hover" : "icon_filter"}
                  src={
                    isHoverSelected
                      ? Assets.SharedIcons.icon_filter_hover
                      : Assets.SharedIcons.icon_filter
                  }
                />
                <span
                  className={`authorized-management__content-search__select-bank__text ${
                    isHoverSelected
                      ? "authorized-management__content-search__select-bank__text__hover"
                      : ""
                  }`}
                >
                  Ordenar por
                </span>
              </div>
            </Dropdown>
          </div>
          <button
            onClick={()=>handleCreate()}
            className="authorized-management__content-search__button"
          >
            <img src={Assets.SharedIcons.icon_add_white} alt="icon_search" />
            <span className="authorized-management__content-search__button__text">
              Agregar autorizado
            </span>
          </button>
        </div>
      </div>
      <div className="authorized-management__table">
        <div className="grid-x authorized-management__table__content align-middle">
          <label className="grid-x small-3 align-middle">
            <div className='authorized-management__table__checkbox'>
              <Checkbox/>
            </div>
            <label className="authorized-management__table__title">
              Nombre del autorizado
            </label>
          </label>
          <div className="grid-x small-9">
            <label className="small-2 authorized-management__table__title">Número de identidad</label>
            <label className="small-2 authorized-management__table__title">Saldo permitido</label>
            <label className="small-2 authorized-management__table__title">Deuda actual</label>
            <label className="small-2 authorized-management__table__title">Diferencia</label>
            <label className="small-2 authorized-management__table__title">Contacto</label>
            <label className="small-2 authorized-management__table__title">Acciones</label>
          </div>
        </div>
        <div className="authorized-management__table__box">
          { arrayFilterPage(paginatorState.page, listAuthorized )?.map((item, index) => {
            const classDifference = item.difference >= 0 ? "authorized-management__table__text__green" : "authorized-management__table__text__red";
            return(
              <div key={index} className="grid-x authorized-management__table__content align-middle">
                <div className="grid-x small-3 align-middle">
                  <div className='authorized-management__table__checkbox'>
                    <Checkbox/>
                  </div>
                  <label className="authorized-management__table__text">
                    {item.name}
                  </label>
                </div>
                <div className="grid-x small-9">
                  <label className="small-2 authorized-management__table__text">{item.document}</label>
                  <label className="small-2 authorized-management__table__title authorized-management__table__text__green">${(item?.allowableAvailable)?.toLocaleString()}</label>
                  <label className="small-2 authorized-management__table__title">${(item?.currentDebt)?.toLocaleString()}</label>
                  <label className={`small-2 authorized-management__table__title ${classDifference}`}>${(item?.difference)?.toLocaleString()}</label>
                  <label className="small-2 authorized-management__table__text">{item.contact}</label>
                  <div className="grid-x small-2">
                    <button 
                      className="grid-x align-middle"
                      onClick={()=> {handleDetails(item)}}
                    >
                      <span className="authorized-management__table__title authorized-management__table__text__blue">Ver detalles</span>
                      <img src={Assets.SharedIcons.icon_arrow} alt="icon_arrow" className="authorized-management__table__details"/>
                    </button>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="grid-x justify-content-between authorized-management__footer">
        <span className="grid-x">
          <label className="authorized-management__table__text">
            Páginas:
          </label>
          <label className="authorized-management__table__title">
            {paginatorState.page} de {paginatorState.totalPages}
          </label>
        </span>
        <PaginationComponent 
          pageItem={10}
          page={paginatorState.page}
          totalItems={listAuthorized?.length}
          onPage={(page)=>{
            setState({
              ...state,
              paginatorState: {
                ...state.paginatorState,
                page: page
              }
            })
          }}
        />
      </div>
    </div>
  );
};

const mapStateToPropsActions = {
  setStateAuthorizedManagementReducer
};

export default connect(null, mapStateToPropsActions)(AuthorizedManagementPage);
