//Assets
import { Assets } from "../../../../assets/index"

//Components
import { userPermissionMenuItems } from '../side-nav-items/sidenav-main.component';

//Libraries
import { Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";

// Services
import {
  clearKeysAuthenticationService,
  getUserPermissionsService,
  setModuleNameService
} from "../../../../services/authentication.services";

//Styles
import './sidenav-main.component.scss'


const SidenavMainComponent = () => {

  const navigate = useNavigate();

  const INITIAL_STATE = {
    permissionsAssociateByUser: []
  }

  const [state, setState] = useState(INITIAL_STATE)

  const { permissionsAssociateByUser } = state
  useEffect(() => {
    const permissionsAssociateByUser = getUserPermissionsService()
    const [sortedPermissions] = permissionsAssociateByUser.slice().sort((a, b) => a.type - b.type);
    const functionality = sortedPermissions.moduleFunctionality;
    if (functionality) {
      const [permission] = functionality;
      setModuleNameService(permission.label);
      navigate(`/${permission.route}`);
    }
    setState((prevState) => ({
      ...prevState,
      permissionsAssociateByUser: userPermissionMenuItems(permissionsAssociateByUser),
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const routeRedirection = ({ key }) => {
    const [functionality] = permissionsAssociateByUser.functionality.filter((value) => value.key.toString() === key)
    if (functionality) {
      setModuleNameService(functionality.label);
      navigate(functionality.route, { state : { key : functionality?.key === 6 ? functionality?.key : null } });
    }
  }

  const processLogout = () => {
    clearKeysAuthenticationService()
    navigate("/login", { replace: true })
  }

  return (
    <div className="sidenav-container">
      <p className="sidenav-container__title">Menú principal</p>
      <Menu
        className="sidenav-container__sidenav-main"
        defaultOpenKeys={["TREASURY", "INVENTORY_CONTROL", "WHOLESALER"]}
        mode="inline"
        items={permissionsAssociateByUser.items}
        onClick={(key) => routeRedirection(key)}
      />
      <button
        className="sidenav-container__menu-button"
        onClick={processLogout}
      >
        <img
          className="sidenav-container__menu-button__icon-logout" src={Assets.SharedIcons.icon_log_out} alt='icon_log_out'
        />
        <span>
          Cerrar Sesión
        </span>
      </button>
    </div>
  )
}

export default SidenavMainComponent