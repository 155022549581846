//Constants
import DashboardEndPoints from "./constants/dashboard.end-points";

////Infrastructures
import Http from "./infrastructure/http.infrastructure";

//Services
import { getTokenService } from "./authentication.services";

export async function getDailyCashClosingReportsService(date) {
    const token = getTokenService()
    try {
        const { data: responseGetDailyCashClosingReports, error } = await Http.get(DashboardEndPoints.GET_DAILY_CASH_CLOSING_REPORTS, { date }, token)
        if (responseGetDailyCashClosingReports && responseGetDailyCashClosingReports.status) {
            return responseGetDailyCashClosingReports.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getCashClosingReportsService(date) {
    try {
        const token = getTokenService()
        const urlWithParams = DashboardEndPoints.GET_DATA_CASH_CLOSING.replace(":date", date)
        const { data: responseGetCashClosingReports, error } = await Http.get(urlWithParams, null, token)
        if (responseGetCashClosingReports && responseGetCashClosingReports.status) {
            return responseGetCashClosingReports.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getControlPanelReportsService(date) {
    try {
        const token = getTokenService()
        const urlWithParams = DashboardEndPoints.GET_CONTROL_PANEL.replace(":date", date)
        const { data: responseGetControlPanelReports, error } = await Http.get(urlWithParams, null, token)
        if (responseGetControlPanelReports && responseGetControlPanelReports.status) {
            return responseGetControlPanelReports.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getTotalizerUtilityServices(id, date) {
    try {
        const token = getTokenService();
        const urlWithParams = DashboardEndPoints.GET_TOTAL_UTILITY.replace(":headQuarterId", id).replace(":date", date)
        const { data: responseGetTotalUtility, error } = await Http.get(urlWithParams, null, token)
        if (responseGetTotalUtility && responseGetTotalUtility.data) {
            return responseGetTotalUtility?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getTotalizerExpensesServices(id, date) {
    try {
        const token = getTokenService();
        const urlWithParams = DashboardEndPoints.GET_TOTAL_EXPENSES.replace(":headQuarterId", id).replace(":date", date)
        const { data: responseGetTotalExpenses, error } = await Http.get(urlWithParams, null, token)
        if (responseGetTotalExpenses && responseGetTotalExpenses.data) {
            return responseGetTotalExpenses?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getTotalizerTreasuryServices(id, date) {
    try {
        const token = getTokenService();
        const urlWithParams = DashboardEndPoints.GET_TOTAL_TREASURY.replace(":headQuarterId", id).replace(":date", date)
        const { data: responseGetTotalTreasury, error } = await Http.get(urlWithParams, null, token)
        if (responseGetTotalTreasury && responseGetTotalTreasury.data) {
            return responseGetTotalTreasury?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getTotalizerInventoryServices(id, date) {
    try {
        const token = getTokenService();
        const urlWithParams = DashboardEndPoints.GET_TOTAL_INVENTORY.replace(":headQuarterId", id).replace(":date", date)
        const { data: responseGetTotalInventory, error } = await Http.get(urlWithParams, null, token)
        if (responseGetTotalInventory && responseGetTotalInventory.data) {
            return responseGetTotalInventory?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getTotalizerSalesServices(id, date) {
    try {
        const token = getTokenService();
        const urlWithParams = DashboardEndPoints.GET_TOTAL_SALES.replace(":headQuarterId", id).replace(":date", date)
        const { data: responseGetTotalSales, error } = await Http.get(urlWithParams, null, token)
        if (responseGetTotalSales && responseGetTotalSales.data) {
            return responseGetTotalSales?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getTotalizerDifferentialServices(id, date) {
    try {
        const token = getTokenService();
        const urlWithParams = DashboardEndPoints.GET_TOTAL_DIFFERENTIAL.replace(":headQuarterId", id).replace(":date", date)
        const { data: responseGetTotalDifferential, error } = await Http.get(urlWithParams, null, token)
        if (responseGetTotalDifferential && responseGetTotalDifferential.data) {
            return responseGetTotalDifferential?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};