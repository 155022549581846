export const spreadsheets = [
    {
        banknote: 0,
        codeLiquidation: 0,
        codeTemplate: "",
        coin: 0,
        credit: 0,
        creditNote: 0,
        driverLiquidationTemplate: {label: '',value: ''},
        expense: 0,
        idTypeLiquidation: "",
        personLiquidationTemplate: {label: '', value: ''},
        priceTemplate: 0,
        transfer: 0,
        disabled: true
    },
    {
        banknote: 0,
        codeLiquidation: 0,
        codeTemplate: "",
        coin: 0,
        credit: 0,
        creditNote: 0,
        driverLiquidationTemplate: {label: '',value: ''},
        expense: 0,
        idTypeLiquidation: "",
        personLiquidationTemplate: {label: '', value: ''},
        priceTemplate: 0,
        transfer: 0,
        disabled: true
    },
    {
        banknote: 0,
        codeLiquidation: 0,
        codeTemplate: "",
        coin: 0,
        credit: 0,
        creditNote: 0,
        driverLiquidationTemplate: {label: '',value: ''},
        expense: 0,
        idTypeLiquidation: "",
        personLiquidationTemplate: {label: '', value: ''},
        priceTemplate: 0,
        transfer: 0,
        disabled: true
    },
    {
        banknote: 0,
        codeLiquidation: 0,
        codeTemplate: "",
        coin: 0,
        credit: 0,
        creditNote: 0,
        driverLiquidationTemplate: {label: '',value: ''},
        expense: 0,
        idTypeLiquidation: "",
        personLiquidationTemplate: {label: '', value: ''},
        priceTemplate: 0,
        transfer: 0,
        disabled: true
    },
    {
        banknote: 0,
        codeLiquidation: 0,
        codeTemplate: "",
        coin: 0,
        credit: 0,
        creditNote: 0,
        driverLiquidationTemplate: {label: '',value: ''},
        expense: 0,
        idTypeLiquidation: "",
        personLiquidationTemplate: {label: '', value: ''},
        priceTemplate: 0,
        transfer: 0,
        disabled: true
    },
    {
        banknote: 0,
        codeLiquidation: 0,
        codeTemplate: "",
        coin: 0,
        credit: 0,
        creditNote: 0,
        driverLiquidationTemplate: {label: '',value: ''},
        expense: 0,
        idTypeLiquidation: "",
        personLiquidationTemplate: {label: '', value: ''},
        priceTemplate: 0,
        transfer: 0,
        disabled: true
    },
    {
        banknote: 0,
        codeLiquidation: 0,
        codeTemplate: "",
        coin: 0,
        credit: 0,
        creditNote: 0,
        driverLiquidationTemplate: {label: '',value: ''},
        expense: 0,
        idTypeLiquidation: "",
        personLiquidationTemplate: {label: '', value: ''},
        priceTemplate: 0,
        transfer: 0,
        disabled: true
    }
]