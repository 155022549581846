import { combineReducers } from 'redux';

import AuthenticationReducer from '../reducers/authentication/authentication.reducer';
import CashClosingReducer from '../reducers/cash-closing/cash-closing.reducer';
import AuthorizedManagementReducer from '../reducers/authorized-management/authorized-management.reducer';
import HeadquartersReducer from '../reducers/headquarters/headquarters.reducer';
import AdminHeadquarterReducer from '../reducers/admin-headquarter/admin-headquarter.reducer';
import CashClosingWholesaleReducer from '../reducers/cash-closing-wholesale/cash-closing-wholesale.reducer';
import WholesaleFinalBalanceReducer from '../reducers/wholesale-final-balance/wholesale-final-balance.reducer';

const rootReducer = combineReducers({
	AuthenticationReducer,
	CashClosingReducer,
	AuthorizedManagementReducer,
	HeadquartersReducer,
	AdminHeadquarterReducer,
	CashClosingWholesaleReducer,
	WholesaleFinalBalanceReducer
});

export default rootReducer;
