// Libraries
import React from 'react'
import { connect } from "react-redux";
import PropTypes from 'prop-types'; 

// Styles
import "./preview-headquarter.component.scss"

const PreviewHeadquarterComponent = (props) => {

  const {
    adminHeadquarterSelected
  } = props;

  return (
    <div className='preview-headquarter__content'>
      <label className='preview-headquarter__title'>{adminHeadquarterSelected?.name || "Nombre de la sede"}</label>
      <div className='preview-headquarter__line'></div>
      <div className='grid-x preview-headquarter__container'>
        <div>
          <label className='preview-headquarter__text'>Dirección del establecimiento, sede o negocio</label>
          <label className={`preview-headquarter__title preview-headquarter__title${adminHeadquarterSelected?.address ? '' : '--subtitle'}`}>
            { adminHeadquarterSelected?.address || "Sin asignar"}
          </label>
        </div>
      </div>
      <div className='grid-x preview-headquarter__container'>
        <div>
          <label className='preview-headquarter__text'>Número de cuenta bancaria</label>
          {adminHeadquarterSelected?.accounts ?
            adminHeadquarterSelected?.accounts.map((accounts, index) => (
              <span key={accounts?.id} className='preview-headquarter__title preview-headquarter__title'>
                {accounts?.name}
                {index < adminHeadquarterSelected?.accounts.length - 1 && ', '}
              </span>
            )) :
            <span className='preview-headquarter__title preview-headquarter__title--subtitle'>{"Sin asignar"}</span>
          }
        </div>
      </div>
      <div className='grid-x preview-headquarter__container'>
        <div>
          <label className='preview-headquarter__text'>Bodega</label>
          {adminHeadquarterSelected?.store ? 
            adminHeadquarterSelected?.store.map((store, index) => (
              <span key={store?.id} className='preview-headquarter__title preview-headquarter__title'>
                  { store?.code }
                  {index < adminHeadquarterSelected?.store.length - 1 && ', '}
              </span>
            )) : 
              <span className='preview-headquarter__title preview-headquarter__title--subtitle'>{"Sin asignar"}</span>
          }
        </div>
      </div>
      <div className='grid-x preview-headquarter__container'>
        <div>
          <label className='preview-headquarter__text'>Responsable de sede</label>
          <label className={`preview-headquarter__title preview-headquarter__title${adminHeadquarterSelected?.userChargeHeadQuarter?.name ? '' : '--subtitle'}`}>
            { adminHeadquarterSelected?.userChargeHeadQuarter?.name || "Sin asignar"}
          </label>
        </div>
      </div>
    </div>
  )
}

PreviewHeadquarterComponent.propTypes = {
  adminHeadquarterSelected: PropTypes.object,
};

const mapStateToProps = ({ AdminHeadquarterReducer }) => {
  const { adminHeadquarterSelected } = AdminHeadquarterReducer;
  return { adminHeadquarterSelected };
};

export default connect(mapStateToProps, null)(PreviewHeadquarterComponent);