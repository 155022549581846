// Actions
import { setStateCashClosingReducer } from '../../storage/reducers/cash-closing/cash-closing.actions';

//Assets
import { Assets } from '../../assets';

//Components
import WeekSelectorComponent from '../components/week-selector/week-selector.component';
import DataphonesComponent from './components/dataphones/dataphones.component';
import RefundsComponent from './components/refunds/refunds.component';
import ExpensesComponent from './components/expenses/expenses.component';
import TransfersComponent from './components/transfers/transfers.component';
import LoansComponent from './components/loans/loans.component';
import PaymentOfBondsmenComponent from './components/payment-of-bondsmen/payment-of-bondsmen.component';
import PackagingControlComponent from './components/packaging-control/packaging-control.component';
import ProvidersComponent from './components/providers/providers.component';
import InternalConsumptionComponent from './components/internal-consumption/internal-consumption.component';

//Components - Shared
import SuccessAlertComponent from '../../shared/components/alerts/success-alert/success-alert.component'
import ErrorAlertComponent from '../../shared/components/alerts/error-alert/error-alert.component';

//Libraries
import dayjs from 'dayjs';
import { Input, Modal } from 'antd';
import { connect } from "react-redux";
import React, { useEffect, useState } from 'react';
import { NumericFormat } from "react-number-format";
import { Link, useLocation } from 'react-router-dom';

//Services
import {
  createCashExpenseProviderService,
  createCashExpenseService, createCashExpenseTrustService,
  createCashReceiptMoneyTransfersService, createCashReceiptCardReadersService, getCashClosingService,
  updateCashExpenseProviderService,
  updateCashExpenseService, updateCashExpenseTrustService, updateCashReceiptBottleControlService,
  updateCashReceiptMoneyTransfersService, updateCashReceiptCardReadersService, updateCashRegistersObservationService,
  saveClosingCashRegisterService, publishClosingCashRegisterService, updateCashRegistersInitialBalanceService,
  createCashReceiptPaymentOfBondsmenService, updateCashReceiptPaymentOfBondsmenService, updateCashRegistersSalesCloudService,
  updateCashRegistersSalesPointColdService, updateCashRegistersKitchenService, updateCashRegistersRealCashService,
  createCashExpenseReturnsService, updateCashExpenseReturnsService, getNameHeadquartersService, createInternalConsumptionService, updateInternalConsumptionService
} from '../../services/cash-closing.services';
import { setModuleNameService } from '../../services/authentication.services';
import { createInventoryCardReadersService, createInventoryCashExpenseService, createInventoryInternalConsumptionService, createInventoryMoneyTransfersService, createInventoryPaymentOfBondsmenService, createInventoryProviderService, createInventoryReturnsService, createInventoryTrustService, getHeadquartersService, publishInventoryCashRegisterService, saveInventoryCashRegisterService, updateInventoryBottleControlService, updateInventoryCardReadersService, updateInventoryCashExpenseService, updateInventoryInitialBalanceService, updateInventoryInternalConsumptionService, updateInventoryKitchenService, updateInventoryMoneyTransfersService, updateInventoryObservationService, updateInventoryPaymentOfBondsmenService, updateInventoryProviderService, updateInventoryRealCashService, updateInventoryReturnsService, updateInventorySalesCloudService, updateInventorySalesPointColdService, updateInventoryTrustService } from '../../services/cash-closing-inventory.services';

//Styles
import './cash-closing.page.scss';

const PUBLISH_CLOSING = 'publishClosing';

const CashClosingPage = (props) => {

  const { state: locationState } = useLocation();

  const dateInventory = locationState?.selectedDateInventory;
  
  const {
    //Actions
    setStateCashClosingReducer,
    //Variables
    cashClosingData, paymentOfBondsmenForm,
    loansDataFrom, expensesForm,
    transfersForm, providersForm, packagingControlFrom,
    dataPhonesForm, refundsForm, internalConsumptionForm
  } = props;

  const selectedDate = () => {
    let respDate;
    if (dateInventory) {
      if (dayjs(dateInventory).isSame(dayjs())) {
        if (dayjs(dateInventory).isSameOrAfter(dayjs().set('hour', 6))) {
          respDate = dayjs(dateInventory)
        } else {
          respDate = dayjs().subtract(1, 'day')
        }
      } else {
        respDate = dayjs(dateInventory)
      }
    } else {
      if (dayjs().isSameOrAfter(dayjs().set('hour', 6))) {
        respDate = dayjs()
      } else {
        respDate = dayjs().subtract(1, 'day')
      }
    };
    return respDate;
  };

  const INITIAL_STATE = {
    daySelected: selectedDate(),
    dateSelected: selectedDate(),
    nameOfHeadquarters: "",
    initialBalance: 0,
    salesCloud: 0,
    salesPointCold: 0,
    kitchen: 0,
    totalCash: 0,
    realCash: 0,
    observation: "",
    isHoverSaveProcess: false,
    isViewModalFinalizeAndPublish: false
  };

  const [state, setState] = useState(INITIAL_STATE)
  const {
    dateSelected, daySelected, observation,
    initialBalance, salesCloud, salesPointCold,
    kitchen, totalCash, realCash, differenceCash,
    nameOfHeadquarters, isHoverSaveProcess, isViewModalFinalizeAndPublish,
  } = state;

  const handleDate = (date) => {
    setState({
      ...state,
      dateSelected: date
    })
  }
  const previousMonth = () => {
    const monthNew = dateSelected.subtract(1, 'month')
    setState({
      ...state,
      dateSelected: monthNew
    })
  }

  const nextMonth = () => {
    const monthNew = dateSelected.add(1, 'month')
    setState({
      ...state,
      dateSelected: monthNew
    })
  }

  const previousWeek = () => {
    const week = dateSelected.subtract(1, 'week')
    setState({
      ...state,
      dateSelected: week
    })
  }

  const nextWeek = () => {
    const week = dateSelected.add(1, 'week')
    setState({
      ...state,
      dateSelected: week
    })
  }

  const selectDay = (day) => {
    setState({
      ...state,
      daySelected: day,
      dateSelected: day
    })
  }

  useEffect(() => {
    if(!dateInventory){
      getDataCashClosing()
    }else{
      getDataHeadquarter()
    }
    setModuleNameService('Cierre de Caja');
    return () => {
      setStateCashClosingReducer('cashClosingData', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daySelected]);  

  const getDataHeadquarter = async () => {
    try {
      const getHeadquarter = await getHeadquartersService(dayjs(daySelected)?.format('YYYY-MM-DD'), locationState?.headquarter.id);
      if (getHeadquarter?.isPublish) {
        getHeadquarter.isPublish = 0
      };
      setState((prevState) => ({
        ...prevState,
        initialBalance: getHeadquarter?.closingCashRegisterCashRegister?.initialBalance ? getHeadquarter.closingCashRegisterCashRegister.initialBalance : 0,
        salesCloud: getHeadquarter?.closingCashRegisterCashRegister?.salesTNSCloud ? getHeadquarter.closingCashRegisterCashRegister.salesTNSCloud : 0,
        salesPointCold: getHeadquarter?.closingCashRegisterCashRegister?.salesPF ? getHeadquarter.closingCashRegisterCashRegister.salesPF : 0,
        kitchen: getHeadquarter?.closingCashRegisterCashRegister?.kitchen ? getHeadquarter.closingCashRegisterCashRegister.kitchen : 0,
        totalCash: getHeadquarter?.totalCash ? getHeadquarter.totalCash : 0,
        realCash: getHeadquarter?.realCash ? getHeadquarter.realCash : 0,
        differenceCash: getHeadquarter?.cashDifference ? getHeadquarter.cashDifference : '',
        observation: getHeadquarter?.closingCashRegisterCashRegister?.observation,
      }));
      setStateCashClosingReducer('cashClosingData', getHeadquarter);
    } catch (error) {
      ErrorAlertComponent()
    }
  }

  const getDataCashClosing = async () => {
    try {
      await getNameOfTheHeadquarters();
      await getAllCashClosing();
    } catch (error) {
      ErrorAlertComponent()
    }
  }

  const getNameOfTheHeadquarters = async () => {
    try {
      const res = await getNameHeadquartersService();
      setState((prevState) => ({
        ...prevState,
        nameOfHeadquarters: res?.name
      }))
    } catch (error) {
      ErrorAlertComponent()
    }
  };

  const getAllCashClosing = async () => {
    try {
      const res = await getCashClosingService(dayjs(daySelected)?.format('YYYY-MM-DD'));
      setState((prevState) => ({
        ...prevState,
        initialBalance: res?.closingCashRegisterCashRegister?.initialBalance ? res.closingCashRegisterCashRegister.initialBalance : 0,
        salesCloud: res?.closingCashRegisterCashRegister?.salesTNSCloud ? res.closingCashRegisterCashRegister.salesTNSCloud : 0,
        salesPointCold: res?.closingCashRegisterCashRegister?.salesPF ? res.closingCashRegisterCashRegister.salesPF : 0,
        kitchen: res?.closingCashRegisterCashRegister?.kitchen ? res.closingCashRegisterCashRegister.kitchen : 0,
        totalCash: res?.totalCash ? res.totalCash : 0,
        realCash: res?.realCash ? res.realCash : 0,
        differenceCash: res?.cashDifference ? res.cashDifference : '',
        observation: res?.closingCashRegisterCashRegister?.observation,
      }));
      setStateCashClosingReducer('cashClosingData', res);
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  };

  const saveProcess = async (params) => {
    const dataToCheck = [
      { typeFunctionService: initialBalanceServiceSave, typeCondition: initialBalance !== cashClosingData?.closingCashRegisterCashRegister?.initialBalance },
      { typeFunctionService: salesCloudService, typeCondition: salesCloud !== cashClosingData?.closingCashRegisterCashRegister?.salesTNSCloud },
      { typeFunctionService: salesPointColdService, typeCondition: salesPointCold !== cashClosingData?.closingCashRegisterCashRegister?.salesPF },
      { typeFunctionService: kitchenService, typeCondition: kitchen !== cashClosingData?.closingCashRegisterCashRegister?.kitchen },
      { typeFunctionService: realCashService, typeCondition: realCash !== cashClosingData?.realCash },
      { typeFunctionService: dataPhonesService, typeCondition: dataPhonesForm.length > 0 },
      { typeFunctionService: refundsFormService, typeCondition: refundsForm.length > 0 },
      { typeFunctionService: transfersFormService, typeCondition: transfersForm.length > 0 },
      { typeFunctionService: expensesFormService, typeCondition: expensesForm.length > 0 },
      { typeFunctionService: paymentOfBondsmenService, typeCondition: paymentOfBondsmenForm.length > 0 },
      { typeFunctionService: loansDataFromService, typeCondition: loansDataFrom.length > 0 },
      { typeFunctionService: observationServiceSave, typeCondition: observation !== cashClosingData?.closingCashRegisterCashRegister?.observation },
      { typeFunctionService: packagingControlFromService, typeCondition: packagingControlFrom.length > 0 },
      { typeFunctionService: providersFormService, typeCondition: providersForm.length > 0 },
      { typeFunctionService: internalConsumptionFormService, typeCondition: internalConsumptionForm.length > 0 },
    ];
    const functionServiceComplete = [];
    for (const item of dataToCheck) {
      if (item.typeCondition) {
        functionServiceComplete.push(item.typeFunctionService());
      }
    };
    try {
      await Promise.all(functionServiceComplete);
      if (dateInventory) {
        await saveInventoryCashRegisterService(cashClosingData?.closingCashRegisterCashRegister?.id);
      }else{
        await saveClosingCashRegisterService(cashClosingData?.closingCashRegisterCashRegister?.id);
      }
      if (params === PUBLISH_CLOSING) {
        if(dateInventory){
          await publishInventoryCashRegisterService(cashClosingData?.closingCashRegisterCashRegister?.id);
        }else{
          await publishClosingCashRegisterService(cashClosingData?.closingCashRegisterCashRegister?.id);
        }
        setState({ ...state, isViewModalFinalizeAndPublish: false })
      }
      SuccessAlertComponent()
      if (dateInventory) {
        await getDataHeadquarter()
      }else{
        await getAllCashClosing();
      }
    } catch (error) {
      ErrorAlertComponent()
    }
  };

  const createRecordsInTablesService = async (createService, data) => {
    try {
      const [result] = await Promise.allSettled([createService(data, cashClosingData?.closingCashRegisterCashRegister?.id)]);
      if (result.status === "fulfilled") {
      }
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  };

  const updateRecordsInTablesService = async (updateService, data, id) => {
    try {
      const [result] = await Promise.allSettled([updateService(data, id && id)]);
      if (result.status === "fulfilled") {
      }
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  };

  const initialBalanceServiceSave = async () => {
    await updateRecordsInTablesService( dateInventory ? updateInventoryInitialBalanceService : updateCashRegistersInitialBalanceService, initialBalance, cashClosingData?.closingCashRegisterCashRegister?.id);
  };

  const salesCloudService = async () => {
    await updateRecordsInTablesService( dateInventory ? updateInventorySalesCloudService :updateCashRegistersSalesCloudService, salesCloud, cashClosingData?.closingCashRegisterCashRegister?.id);
  };

  const salesPointColdService = async () => {
    await updateRecordsInTablesService(dateInventory ? updateInventorySalesPointColdService : updateCashRegistersSalesPointColdService, salesPointCold, cashClosingData?.closingCashRegisterCashRegister?.id);
  };

  const kitchenService = async () => {
    await updateRecordsInTablesService(dateInventory ? updateInventoryKitchenService : updateCashRegistersKitchenService, kitchen, cashClosingData?.closingCashRegisterCashRegister?.id);
  };

  const realCashService = async () => {
    await updateRecordsInTablesService(dateInventory ? updateInventoryRealCashService : updateCashRegistersRealCashService, realCash, cashClosingData?.id);
  };

  const observationServiceSave = async () => {
    await updateRecordsInTablesService(dateInventory ? updateInventoryObservationService : updateCashRegistersObservationService, observation, cashClosingData?.closingCashRegisterCashRegister?.id);
  };

  const dataPhonesService = async () => {
    const createNewRecords = [];
    const updateRecords = [];

    for (const dataPhoneObject of dataPhonesForm) {
      if (!dataPhoneObject.hasOwnProperty('id')) {
        if (dataPhoneObject.price && dataPhoneObject.voucherNumber) {
          const newDataObject = {
            price: dataPhoneObject.price,
            voucherNumber: dataPhoneObject.voucherNumber,
            idCashRegister: cashClosingData?.closingCashRegisterCashRegister?.id
          };
          createNewRecords.push(newDataObject);
        };
      } else {
        const dataPhoneReducerResult = cashClosingData?.cashRegisterCashReceiptCardReader.find(dataPhoneReducer => dataPhoneObject.id === dataPhoneReducer.id);
        if (dataPhoneObject.price !== dataPhoneReducerResult.price || dataPhoneObject.voucherNumber !== dataPhoneReducerResult.voucherNumber) {
          updateRecords.push(dataPhoneObject);
        };
      };
    };
    if (createNewRecords.length > 0) {
      await createRecordsInTablesService(dateInventory ? createInventoryCardReadersService : createCashReceiptCardReadersService, createNewRecords);
      setStateCashClosingReducer('dataPhonesForm', []);
    };
    if (updateRecords.length > 0) {
      await updateRecordsInTablesService(dateInventory ? updateInventoryCardReadersService : updateCashReceiptCardReadersService, updateRecords);
    };
  };

  const refundsFormService = async () => {
    const createNewRecords = [];
    const updateRecords = [];

    for (const refundObject of refundsForm) {
      if (!refundObject.hasOwnProperty('id')) {
        if (refundObject.price && refundObject.invoiceNumber) {
          const newDataObject = {
            price: refundObject.price,
            invoiceNumber: refundObject.invoiceNumber,
            idCashRegister: cashClosingData?.closingCashRegisterCashRegister?.id
          };
          createNewRecords.push(newDataObject);
        };
      } else {
        const refundReducerResult = cashClosingData?.cashRegisterCashExpenseReturns.find(refundReducer => refundObject.id === refundReducer.id);
        if (refundObject.price !== refundReducerResult.price || refundObject.invoiceNumber !== refundReducerResult.invoiceNumber) {
          updateRecords.push(refundObject);
        };
      };
    };
    if (createNewRecords.length > 0) {
      await createRecordsInTablesService(dateInventory ? createInventoryReturnsService : createCashExpenseReturnsService, createNewRecords);
      setStateCashClosingReducer('refundsForm', []);
    };
    if (updateRecords.length > 0) {
      await updateRecordsInTablesService(dateInventory ? updateInventoryReturnsService : updateCashExpenseReturnsService, updateRecords);
    };
  };

  const transfersFormService = async () => {
    const createNewRecords = [];
    const updateRecords = [];

    for (const transferObject of transfersForm) {
      if (!transferObject.hasOwnProperty('id')) {
        if (transferObject.price && transferObject.invoiceNumber) {
          const newDataObject = {
            price: transferObject.price,
            invoiceNumber: transferObject.invoiceNumber,
            idCashRegister: cashClosingData?.closingCashRegisterCashRegister?.id
          };
          createNewRecords.push(newDataObject);
        };
      } else {
        const transferReducerResult = cashClosingData?.cashRegisterCashReceiptMoneyTransfer.find(transferReducer => transferObject.id === transferReducer.id);
        if (transferObject.price !== transferReducerResult.price || transferObject.invoiceNumber !== transferReducerResult.invoiceNumber) {
          updateRecords.push(transferObject);
        };
      };
    };
    if (createNewRecords.length > 0) {
      await createRecordsInTablesService(dateInventory ? createInventoryMoneyTransfersService : createCashReceiptMoneyTransfersService, createNewRecords);
      setStateCashClosingReducer('transfersForm', []);
    };
    if (updateRecords.length > 0) {
      await updateRecordsInTablesService(dateInventory ? updateInventoryMoneyTransfersService : updateCashReceiptMoneyTransfersService, updateRecords);
    };
  };

  const expensesFormService = async () => {
    const createNewRecords = [];
    const updateRecords = [];

    for (const expenseObject of expensesForm) {
      if (!expenseObject.hasOwnProperty('id')) {
        if (expenseObject.price && expenseObject.invoiceNumber && expenseObject.subCategory?.value) {
          const newDataObject = {
            price: expenseObject.price,
            invoiceNumber: expenseObject.invoiceNumber,
            idExpenseSubcategory: expenseObject.subCategory?.value,
            idCashRegister: cashClosingData?.closingCashRegisterCashRegister?.id
          };
          createNewRecords.push(newDataObject);
        };
      } else {
        const expenseReducerResult = cashClosingData?.cashRegisterCashExpense.find(expenseReducer => expenseObject.id === expenseReducer.id);
        if (expenseObject.price !== expenseReducerResult.price ||
          expenseObject.invoiceNumber !== expenseReducerResult.invoiceNumber ||
          expenseObject.subCategory.value !== expenseReducerResult.expenseSubcategoryCashExpense.id) {
          const newDataUpdateObject = {
            id: expenseObject.id,
            price: expenseObject.price,
            invoiceNumber: expenseObject.invoiceNumber,
            idExpenseSubcategory: expenseObject.subCategory.value
          };
          updateRecords.push(newDataUpdateObject);
        };
      };
    };
    if (createNewRecords.length > 0) {
      await createRecordsInTablesService(dateInventory ? createInventoryCashExpenseService : createCashExpenseService, createNewRecords);
      setStateCashClosingReducer('expensesForm', []);
    };
    if (updateRecords.length > 0) {
      await updateRecordsInTablesService(dateInventory ? updateInventoryCashExpenseService : updateCashExpenseService, updateRecords);
    };
  };

  const paymentOfBondsmenService = async () => {
    const createNewRecords = [];
    const updateRecords = [];

    for (const paymentOfBondObject of paymentOfBondsmenForm) {
      if (!paymentOfBondObject.hasOwnProperty('id')) {
        if (paymentOfBondObject?.price && paymentOfBondObject?.concept && paymentOfBondObject?.invoiceNumber && paymentOfBondObject?.nameOfCharge?.value) {
          const newDataObject = {
            price: paymentOfBondObject.price,
            concept: paymentOfBondObject.concept,
            idCharge: paymentOfBondObject.nameOfCharge.value,
            invoiceNumber: paymentOfBondObject.invoiceNumber,
            idCashRegister: cashClosingData?.closingCashRegisterCashRegister?.id
          };
          createNewRecords.push(newDataObject);
        };
      } else {
        const paymentOfBondResult = cashClosingData?.cashRegisterCashReceiptPaymentOfBondsmen.find(paymentOfBondReducer => paymentOfBondObject.id === paymentOfBondReducer.id);
        if (paymentOfBondObject.price !== paymentOfBondResult.price ||
          paymentOfBondObject.concept !== paymentOfBondResult.concept ||
          paymentOfBondObject.invoiceNumber !== paymentOfBondResult.invoiceNumber ||
          paymentOfBondObject.nameOfCharge.value !== paymentOfBondResult?.chargeCashReceiptPaymentOfBondsmen?.id) {
          const newDataUpdateObject = {
            id: paymentOfBondObject?.id,
            price: paymentOfBondObject.price,
            concept: paymentOfBondObject?.concept,
            idCharge: paymentOfBondObject.nameOfCharge.value,
            invoiceNumber: paymentOfBondObject?.invoiceNumber
          };
          updateRecords.push(newDataUpdateObject);
        };
      };
    };
    if (createNewRecords.length > 0) {
      await createRecordsInTablesService(dateInventory ? createInventoryPaymentOfBondsmenService : createCashReceiptPaymentOfBondsmenService, createNewRecords);
      setStateCashClosingReducer('paymentOfBondsmenForm', []);
    };
    if (updateRecords.length > 0) {
      await updateRecordsInTablesService(dateInventory ? updateInventoryPaymentOfBondsmenService : updateCashReceiptPaymentOfBondsmenService, updateRecords);
    };
  };

  const loansDataFromService = async () => {
    const createNewRecords = [];
    const updateRecords = [];

    for (const loanObject of loansDataFrom) {
      if (!loanObject.hasOwnProperty('id')) {
        if (loanObject?.price && loanObject?.concept && loanObject?.invoiceNumber && loanObject?.nameOfCharge?.value) {
          const newDataObject = {
            price: loanObject.price,
            concept: loanObject.concept,
            idCharge: loanObject.nameOfCharge.value,
            invoiceNumber: loanObject.invoiceNumber,
            idCashRegister: cashClosingData?.closingCashRegisterCashRegister?.id
          };
          createNewRecords.push(newDataObject);
        };
      } else {
        const loanResult = cashClosingData?.cashRegisterCashExpenseTrust.find(loanReducer => loanObject.id === loanReducer.id);
        if (loanObject.price !== loanResult.price ||
          loanObject.concept !== loanResult.concept ||
          loanObject.invoiceNumber !== loanResult.invoiceNumber ||
          loanObject.nameOfCharge.value !== loanResult?.chargeCashExpenseTrust?.id) {
          const newDataUpdateObject = {
            id: loanObject.id,
            price: loanObject.price,
            concept: loanObject.concept,
            idCharge: loanObject.nameOfCharge.value,
            invoiceNumber: loanObject.invoiceNumber
          };
          updateRecords.push(newDataUpdateObject);
        };
      };
    };
    if (createNewRecords.length > 0) {
      await createRecordsInTablesService(dateInventory ? createInventoryTrustService : createCashExpenseTrustService, createNewRecords);
      setStateCashClosingReducer('loansDataFrom', []);
    };
    if (updateRecords.length > 0) {
      await updateRecordsInTablesService(dateInventory ? updateInventoryTrustService : updateCashExpenseTrustService, updateRecords);
    };
  };

  const packagingControlFromService = async () => {
    const updateRecords = [];
    cashClosingData?.cashRegisterCashReceiptBottleControl.forEach((cashReceiptItemReducer, index) => {
      if (cashReceiptItemReducer?.amount !== packagingControlFrom[index]?.amount) {
        updateRecords.push({
          id: packagingControlFrom[index].id,
          amount: packagingControlFrom[index].amount
        });
      }
    });
    if (updateRecords.length > 0) {
      await updateRecordsInTablesService(dateInventory ? updateInventoryBottleControlService : updateCashReceiptBottleControlService, updateRecords);
    }
  };

  const providersFormService = async () => {
    const createNewRecords = [];
    const updateRecords = [];

    for (const providerObject of providersForm) {
      if (!providerObject.hasOwnProperty('id')) {
        if (providerObject.price && providerObject.invoiceNumber && providerObject?.provider?.value && providerObject?.paymentMethods?.value) {
          const newDataObject = {
            price: providerObject.price,
            invoiceNumber: providerObject.invoiceNumber,
            idProvider: providerObject.provider.value,
            idPaymentMethod: providerObject.paymentMethods.value,
            idCashRegister: cashClosingData?.closingCashRegisterCashRegister?.id
          };
          createNewRecords.push(newDataObject);
        };
      } else {
        const providerResult = cashClosingData?.cashRegisterCashExpenseProvider.find(providerReducer => providerObject.id === providerReducer.id);
        if (providerObject.price !== providerResult.price ||
          providerObject.invoiceNumber !== providerResult.invoiceNumber ||
          providerObject.provider.value !== providerResult?.providerCashExpenseProvider?.id ||
          providerObject.paymentMethods.value !== providerResult.paymentMethodCashExpenseProvider.id) {
          const newDataUpdateObject = {
            id: providerObject.id,
            price: providerObject.price,
            invoiceNumber: providerObject.invoiceNumber,
            idProvider: providerObject.provider.value,
            idPaymentMethod: providerObject.paymentMethods.value,
          };
          updateRecords.push(newDataUpdateObject);
        };
      };
    };
    if (createNewRecords.length > 0) {
      await createRecordsInTablesService(dateInventory ? createInventoryProviderService : createCashExpenseProviderService, createNewRecords);
      setStateCashClosingReducer('providersForm', []);
    };
    if (updateRecords.length > 0) {
      await updateRecordsInTablesService(dateInventory ? updateInventoryProviderService : updateCashExpenseProviderService, updateRecords);
    };
  };

  const internalConsumptionFormService = async () => {
    const createNewRecords = [];
    const updateRecords = [];

    for (const internalConsumptionObject of internalConsumptionForm) {
      if (!internalConsumptionObject.hasOwnProperty('id')) {
        if (internalConsumptionObject.hour && internalConsumptionObject.price && internalConsumptionObject?.products?.value) {
          const newDataObject = {
            hour: internalConsumptionObject.hour,
            price: internalConsumptionObject.price,
            idDomesticConsumptionProduct: internalConsumptionObject.products.value,
            idCashRegister: cashClosingData?.closingCashRegisterCashRegister?.id
          };
          createNewRecords.push(newDataObject);
        };
      } else {
        const internalConsumptionResult = cashClosingData?.cashRegisterDomesticConsumption.find(internalConsumptionReducer => internalConsumptionObject.id === internalConsumptionReducer.id);
        if (internalConsumptionObject.hour !== internalConsumptionResult.hour ||
          internalConsumptionObject.price !== internalConsumptionResult.price ||
          internalConsumptionObject?.products?.value !== internalConsumptionResult?.productDomesticConsumption?.id) {
          const newDataUpdateObject = {
            id: internalConsumptionObject.id,
            hour: internalConsumptionObject.hour,
            price: internalConsumptionObject.price,
            idDomesticConsumptionProduct: internalConsumptionObject.products.value
          };
          updateRecords.push(newDataUpdateObject);
        };
      };
    };

    if (createNewRecords.length > 0) {
      await createRecordsInTablesService(dateInventory ? createInventoryInternalConsumptionService : createInternalConsumptionService, createNewRecords);
      setStateCashClosingReducer('internalConsumptionForm', []);
    };
    if (updateRecords.length > 0) {
      await updateRecordsInTablesService(dateInventory ? updateInventoryInternalConsumptionService :updateInternalConsumptionService, updateRecords);
    };
  };

  const handleInputNumber = (name, e) => {
    let { value } = e.target

    value = value.replace(/[^0-9.,]/g, '').replace(/(\..*)\./g, '$1').replace(/,/g, '')
    const parts = value.split('.');
    if (parts.length === 2) {
      const decimalPart = parts[1];
      if (decimalPart.length > 2) {
        parts[1] = decimalPart.slice(0, 2);
      }
    }
    value = parts.join('.');
    value = value || 0;

    setState({
      ...state,
      [name]: value
    })
  }

  return (
    <div className='cash-closing__container'>
      {dateInventory ? 
        <>
          <div className='grid-x justify-content-between portfolio-detail-page__content-header'>
            <div className='grid-x align-middle'>
              <Link
                to="/cashClosingInventory"
                className="grid-x portfolio-detail-page__content-header__link"
              >
                Cierre de caja
                <img
                  alt='icon_arrow'
                  src={Assets.SharedIcons.icon_arrow}
                  style={{ marginLeft: '10px' }}
                />
              </Link>
              <span className='portfolio-detail-page__name'>
                {locationState?.headquarter?.name}
              </span>
            </div>
            <div className='grid-x align-middle'>
              <button
                onClick={() => saveProcess()}
                disabled={cashClosingData?.isPublish}
                className='cash-closing__button__save'
                onMouseOver={() => setState((prevState) => ({ ...prevState, isHoverSaveProcess: true }))}
                onMouseOut={() => setState((prevState) => ({ ...prevState, isHoverSaveProcess: false }))}
              >
                <img
                  alt={cashClosingData?.isPublish
                    ? 'icon_save_disabled'
                    : isHoverSaveProcess && !cashClosingData?.isPublish
                      ? 'icon_save_hover'
                      : 'icon_save'
                  }
                  src={
                    cashClosingData?.isPublish
                      ? Assets.SharedIcons.icon_save_disabled
                      : isHoverSaveProcess && !cashClosingData?.isPublish
                        ? Assets.SharedIcons.icon_save_hover
                        : Assets.SharedIcons.icon_save}
                />
                <span>Guardar y actualizar</span>
              </button>
              <button
                disabled={cashClosingData?.isPublish}
                className='cash-closing__button__publish'
                onClick={() => setState({ ...state, isViewModalFinalizeAndPublish: true })}
              >
                <span>Finalizar y publicar</span>
                <img
                  alt={cashClosingData?.isPublish ? 'icon_publish_disabled' : 'icon_publish'}
                  src={cashClosingData?.isPublish ? Assets.SharedIcons.icon_publish_disabled : Assets.SharedIcons.icon_publish}
                />
              </button>
            </div>
          </div>
          <WeekSelectorComponent
            header={
              <div className='grid-x align-middle'>
                <img
                  alt="icon_cash_closing"
                  className='cash-closing__icon-cash-closing'
                  src={Assets.SharedIcons.icon_cash_closing}
                />
                <div className='grid-y'>
                  <span className='cash-closing__title'>
                    {`Sede ${nameOfHeadquarters}`}
                  </span>
                  <span className='cash-closing__title__info'>
                    Reporte del Cierre de caja diario
                  </span>
                </div>
              </div>
            }
            handleDate={(date) => handleDate(date)}
            previousDate={() => previousMonth()}
            nextDate={() => nextMonth()}
            previousWeek={() => previousWeek()}
            nextWeek={() => nextWeek()}
            selectDay={(date) => selectDay(date)}
            dateSelected={dateSelected}
            daySelected={daySelected}
            handleSave={() => saveProcess()}
            handlePublish={() => saveProcess(PUBLISH_CLOSING)}
          />
        </>
      :
      <div className='grid-x cash-closing__content-headquarters'>
        <div className='grid-x align-middle small-7'>
          <img
            alt="icon_cash_closing"
            className='cash-closing__icon-cash-closing'
            src={Assets.SharedIcons.icon_cash_closing}
          />
          <div className='grid-y'>
            <span className='cash-closing__title'>
              {`Sede ${nameOfHeadquarters}`}
            </span>
            <span className='cash-closing__title__info'>
              Reporte del Cierre de caja del día{' '}
              <span style={{ fontWeight: 'bold' }}>
                {dayjs(dateSelected).format('DD/MM/YYYY')}
              </span>
            </span>
          </div>
        </div>
        <div className='grid-x small-5 align-middle justify-content-end'>
          <button
            onClick={() => saveProcess()}
            disabled={cashClosingData?.isPublish}
            className='cash-closing__button__save'
            onMouseOver={() => setState((prevState) => ({...prevState, isHoverSaveProcess: true}))}
            onMouseOut={() => setState((prevState) => ({...prevState, isHoverSaveProcess: false}))}
          >
            <img
              alt={cashClosingData?.isPublish
                ? 'icon_save_disabled'
                : isHoverSaveProcess && !cashClosingData?.isPublish
                  ? 'icon_save_hover'
                  : 'icon_save'
                }
              src={
                cashClosingData?.isPublish
                  ? Assets.SharedIcons.icon_save_disabled
                  : isHoverSaveProcess && !cashClosingData?.isPublish
                    ? Assets.SharedIcons.icon_save_hover
                    : Assets.SharedIcons.icon_save}
            />
            <span>Guardar y actualizar</span>
          </button>
          <button
            disabled={cashClosingData?.isPublish}
            className='cash-closing__button__publish'
            onClick={() => setState({ ...state, isViewModalFinalizeAndPublish: true })}
          >
            <span>Finalizar y publicar</span>
            <img
              alt={cashClosingData?.isPublish ? 'icon_publish_disabled' : 'icon_publish'}
              src={cashClosingData?.isPublish ? Assets.SharedIcons.icon_publish_disabled : Assets.SharedIcons.icon_publish}
            />
          </button>
        </div>
      </div>
      }
      <div className='grid-x cash-closing__box'>
        <div className='grid-x small-12 justify-content-between'>
          <div className='cash-closing__summary__box--mod-initial-balance'>
            <span className='cash-closing__summary__text'>Saldo inicial</span>
            <div className='grid-x cash-closing__payments__table__content-inputs--mod'>
              <NumericFormat
                type='text'
                prefix={"$ "}
                placeholder='$ 0'
                thousandSeparator=","
                value={initialBalance}
                className='input-price'
                thousandsGroupStyle="thousand"
                disabled={!dateInventory}
                onChange={(e) => handleInputNumber("initialBalance", e)}
              />
              <div className='cash-closing__payments__table__content-icon-price'>
                <img
                  alt={cashClosingData?.isPublish ? "icon_edit_disabled" : "icon_edit"}
                  src={cashClosingData?.isPublish ? Assets.SharedIcons.icon_edit_disabled : Assets.SharedIcons.icon_edit}
                />
              </div>
            </div>
          </div>
          <div className='grid-x justify-content-between cash-closing__summary__box'>
            <div className='cash-closing__summary__box__sub-box'>
              <span className='cash-closing__summary__text'>Ventas Nube</span>
              <div className='grid-x cash-closing__payments__table__content-inputs--mod'>
                <NumericFormat
                  type='text'
                  prefix={"$ "}
                  placeholder='$ 0'
                  value={salesCloud}
                  thousandSeparator=","
                  className='input-price'
                  thousandsGroupStyle="thousand"
                  disabled={cashClosingData?.isPublish}
                  onChange={(e) => handleInputNumber("salesCloud", e)}
                />
                <div className='cash-closing__payments__table__content-icon-price'>
                  <img
                    alt="icon_add"
                    src={Assets.SharedIcons.icon_add}
                  />
                </div>
              </div>
            </div>
            <div className='cash-closing__summary__box__sub-box'>
              <span className='cash-closing__summary__text'>Ventas Punto Frío</span>
              <div className='grid-x cash-closing__payments__table__content-inputs--mod'>
                <NumericFormat
                  type='text'
                  prefix={"$ "}
                  placeholder='$ 0'
                  thousandSeparator=","
                  value={salesPointCold}
                  className='input-price'
                  thousandsGroupStyle="thousand"
                  disabled={cashClosingData?.isPublish}
                  onChange={(e) => handleInputNumber("salesPointCold", e)}
                />
                <div className='cash-closing__payments__table__content-icon-price'>
                  <img
                    alt="icon_add"
                    src={Assets.SharedIcons.icon_add}
                  />
                </div>
              </div>
            </div>
            <div className='cash-closing__summary__box__sub-box'>
              <span className='cash-closing__summary__text'>Ventas de Cocina</span>
              <div className='grid-x cash-closing__payments__table__content-inputs--mod'>
                <NumericFormat
                  type='text'
                  prefix={"$ "}
                  value={kitchen}
                  placeholder='$ 0'
                  thousandSeparator=","
                  className='input-price'
                  thousandsGroupStyle="thousand"
                  disabled={cashClosingData?.isPublish}
                  onChange={(e) => handleInputNumber("kitchen", e)}
                />
                <div className='cash-closing__payments__table__content-icon-price'>
                  <img
                    alt="icon_add"
                    src={Assets.SharedIcons.icon_add}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='grid-x justify-content-between cash-closing__summary__total-cash'>
            {/* TODO: To be set so that it can be displayed for the Inventory Control role. */}
            {dateInventory &&
              <div className='cash-closing__summary__box--mod'>
                <span className='cash-closing__summary__text'>Total Efectivo</span>
                <div className='grid-x cash-closing__payments__table__content-inputs--mod-total-cash'>
                  <NumericFormat
                    type='text'
                    prefix={"$ "}
                    disabled={true}
                    value={totalCash}
                    placeholder='$ 0'
                    thousandSeparator=","
                    className='input-price'
                    thousandsGroupStyle="thousand"
                  />
                  <div className='cash-closing__payments__table__content-icon-price'>
                    <img
                      alt={cashClosingData?.isPublish ? "icon_income_graph_disabled" : "icon_income_graph"}
                      src={cashClosingData?.isPublish ? Assets.SharedIcons.icon_income_graph_disabled : Assets.SharedIcons.icon_income_graph}
                    />
                  </div>
                </div>
              </div>
            }
            <div className='cash-closing__summary__box--mod-real-cash'>
              <span className='cash-closing__summary__text'>Efectivo real</span>
              <div className='grid-x cash-closing__payments__table__content-inputs--mod'>
                <NumericFormat
                  type='text'
                  prefix={"$ "}
                  value={realCash}
                  placeholder='$ 0'
                  thousandSeparator=","
                  className='input-price'
                  thousandsGroupStyle="thousand"
                  disabled={cashClosingData?.isPublish}
                  onChange={(e) => handleInputNumber("realCash", e)}
                />
                <div className='cash-closing__payments__table__content-icon-price'>
                  <img
                    alt="icon_add"
                    src={Assets.SharedIcons.icon_add}
                  />
                </div>
              </div>
            </div>

            {/* TODO: To be set so that it can be displayed for the Inventory Control role. */}
            {dateInventory &&
              <div className='cash-closing__summary__box--mod'>
                <span className='cash-closing__summary__text'>Diferencia</span>
                <div className='grid-x cash-closing__payments__table__content-inputs--mod'>
                  <NumericFormat
                    type='text'
                    prefix={"$ "}
                    disabled={true}
                    placeholder='$ 0'
                    thousandSeparator=","
                    value={differenceCash}
                    className='input-price'
                    style={{ paddingLeft: '10px' }}
                    thousandsGroupStyle="thousand"
                  />
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <div className='grid-x cash-closing__payments'>
        <DataphonesComponent dateInventory={dateInventory} getAllPortfolio={() => dateInventory ? getDataHeadquarter() : getAllCashClosing()}/>
        <RefundsComponent dateInventory={dateInventory} getAllPortfolio={() => dateInventory ? getDataHeadquarter() : getAllCashClosing()}/>
        <TransfersComponent dateInventory={dateInventory}  getAllPortfolio={() => dateInventory ? getDataHeadquarter() : getAllCashClosing()}/>
        <ExpensesComponent dateInventory={dateInventory}  getAllPortfolio={() => dateInventory ? getDataHeadquarter() : getAllCashClosing()}/>
        <PaymentOfBondsmenComponent dateInventory={dateInventory} getAllPortfolio={() => dateInventory ? getDataHeadquarter() : getAllCashClosing()}/>
        <LoansComponent dateInventory={dateInventory} getAllPortfolio={() => dateInventory ? getDataHeadquarter() : getAllCashClosing()}/>
        <div className='small-12'>
          <div className='cash-closing__payments__container'>
            <div className='cash-closing__payments__content'>
              <div className='grid-y'>
                <div className='cash-closing__payments__content-titles__fist'>
                  <div className='grid-x align-middle small-6'>
                    <img src={Assets.SharedIcons.icon_information} alt="icon_information" />
                    <span className='cash-closing__payments__titles cash-closing__payments__titles__space'>Observaciones</span>
                  </div>
                  <Input.TextArea
                    rows={14}
                    value={observation}
                    disabled={cashClosingData?.isPublish}
                    onChange={({ target: { value } }) => setState({ ...state, observation: value })}
                    placeholder="Aquí puedes dejarnos las observaciones sobre los datos anteriormente mencionados."
                    className={observation ? 'cash-closing__payments__text-area--mod' : 'cash-closing__payments__text-area'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='grid-x small-12'>
          <div className='small-6'>
            <PackagingControlComponent getAllPortfolio={() => dateInventory ? getDataHeadquarter() : getAllCashClosing()}/>
            <InternalConsumptionComponent dateInventory={dateInventory} getAllPortfolio={() => dateInventory ? getDataHeadquarter() : getAllCashClosing()}/>
          </div>
          <div className='small-6'>
            <ProvidersComponent dateInventory={dateInventory} getAllPortfolio={() => dateInventory ? getDataHeadquarter() : getAllCashClosing()}/>
          </div>
        </div>
      </div>
      {isViewModalFinalizeAndPublish &&
        (<Modal
          centered
          width={540}
          destroyOnClose
          open={isViewModalFinalizeAndPublish}
          closeIcon={<img src={Assets.SharedIcons.icon_close} alt="icon_close" />}
          onCancel={() => setState({ ...state, isViewModalFinalizeAndPublish: false })}
          footer={
            <div className='grid-x justify-content-center cash-closing__modal-finalize-and-publish__content-footer'>
              <button
                key="cancel"
                className='cash-closing__modal-finalize-and-publish__button__cancel'
                onClick={() => setState({ ...state, isViewModalFinalizeAndPublish: false })}
              >
                <span>Cancelar</span>
              </button>
              <button
                key="accept"
                className='cash-closing__modal-finalize-and-publish__button__confirm'
                onClick={() => saveProcess(PUBLISH_CLOSING)}
              >
                <span>Confirmar</span>
              </button>
            </div>
          }
        >
          <div className='grid-x align-center-middle cash-closing__modal-finalize-and-publish__content'>
            <div className='grid-y small-12 align-middle'>
              <img
                alt="icon_report"
                src={Assets.SharedIcons.icon_report}
              />
              <span className='cash-closing__modal-finalize-and-publish__title'>¡Estas a punto de finalizar y publicar!</span>
              <span className='cash-closing__modal-finalize-and-publish__text'>Antes de continuar, por favor ten en cuenta que la acción que estás a punto de realizar es irreversible.</span>
              <span className='cash-closing__modal-finalize-and-publish__text'>Una vez que finalices el cierre de caja, no podrás realizar cambios o modificaciones en los datos ingresados.</span>
            </div>
          </div>
        </Modal>)
      }
    </div>
  )
}

const mapStateToProps = ({ CashClosingReducer }) => {
  const {
    cashClosingData, expensesForm, transfersForm,
    paymentOfBondsmenForm, loansDataFrom,
    providersForm, packagingControlFrom,
    dataPhonesForm, refundsForm, internalConsumptionForm
  } = CashClosingReducer;
  return {
    cashClosingData, expensesForm, transfersForm,
    paymentOfBondsmenForm, loansDataFrom,
    providersForm, packagingControlFrom,
    dataPhonesForm, refundsForm, internalConsumptionForm
  };
};

const mapStateToPropsActions = {
  setStateCashClosingReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(CashClosingPage);