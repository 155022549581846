// Actions
import { resetCashClosingWholesaleReducer, setStateCashClosingWholesaleReducer } from '../../storage/reducers/cash-closing-wholesale/cash-closing-wholesale.actions';

//Assets
import { Assets } from '../../assets';

//Components - Shared
import ErrorAlertComponent from '../../shared/components/alerts/error-alert/error-alert.component';
import SuccessAlertComponent from '../../shared/components/alerts/success-alert/success-alert.component';
import InformationAlertComponent from '../../shared/components/alerts/information-alert/information-alert.component';

//Components
import ExpensesComponent from './components/expenses/expenses.component';
import WeekSelectorComponent from '../components/week-selector/week-selector.component';
import RecordKeepersComponent from './components/record-keepers/record-keepers.component';
import ConsignmentsOrTransfersComponent from './components/consignments-or-transfers/consignments-or-transfers.component';

// Libraries
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { NumericFormat } from "react-number-format";
import { Link, useLocation } from 'react-router-dom';

//Services
import { 
    createExpensesWholesalerService,createFinancialMovementService,getFindAllFinancialMovementService,
    getAllExpensesWholesalerService,getBankWholesalerService,getFinancialMovementService, 
    getTypeExpenseWholesalerService,updateExpensesWholesalerService,updateFinancialMovementService, updateTotalsService
} from '../../services/cash-closing-wholesale.services';

//Styles
import './detail-cash-closing.page.scss';

const DetailCashClosingPage = (props) => {

    const { state: locationState } = useLocation();

    const {
        //Actions
        setStateCashClosingWholesaleReducer,
        resetCashClosingWholesaleReducer,
        //Variables
        cashClosingData,
        listExpenses,
        shipmentRecords,
        totals,
        totalsOriginal
    } = props;

    const expensesList = [
        {typeExpense: {label:"",value:""}, price: "", concept: "", disabled: true, value: "" },
        {typeExpense: {label:"",value:""}, price: "", concept: "", disabled: true, value: "" },
        {typeExpense: {label:"",value:""}, price: "", concept: "", disabled: true, value: "" },
        {typeExpense: {label:"",value:""}, price: "", concept: "", disabled: true, value: "" },
        {typeExpense: {label:"",value:""}, price: "", concept: "", disabled: true, value: "" },
        {typeExpense: {label:"",value:""}, price: "", concept: "", disabled: true, value: "" },
        {typeExpense: {label:"",value:""}, price: "", concept: "", disabled: true, value: "" },
      ]

    const consignmentsList = [
          { idTable: "",  price: "", concept: {label:"",value:""}, disabled: true, value: "" },
          { idTable: "",  price: "", concept: {label:"",value:""}, disabled: true, value: "" },
          { idTable: "",  price: "", concept: {label:"",value:""}, disabled: true, value: "" },
          { idTable: "",  price: "", concept: {label:"",value:""}, disabled: true, value: "" },
          { idTable: "",  price: "", concept: {label:"",value:""}, disabled: true, value: "" },
          { idTable: "",  price: "", concept: {label:"",value:""}, disabled: true, value: "" },
          { idTable: "",  price: "", concept: {label:"",value:""}, disabled: true, value: "" },
        ]

    const INITIAL_STATE = {
        scrollEnd: false,
        dateSelected: dayjs(),
        daySelected: dayjs(),
        isHoverSaveProcess: false,
        nameOfHeadquarters: "",
        listExpensesTable: [],
        financeListing: [],
        expensesCategory: [],
        listOfBanks: [],
        isSaveData: false
    }

    const [state, setState] = useState(INITIAL_STATE)

    const {
        isHoverSaveProcess, listExpensesTable,
        nameOfHeadquarters, dateSelected, daySelected,
        financeListing,expensesCategory,listOfBanks,
        isSaveData
    } = state;

    useEffect(() => {
        allServices()
        return () => {
            resetCashClosingWholesaleReducer()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [daySelected])

    const allServices = async() => {
        try {
            const responseServices = await Promise.allSettled([
                getTypeExpenseWholesalerService(locationState?.id),
                getFinancialMovementService(locationState?.id),
                getAllExpensesWholesalerService(locationState?.id, dayjs(dateSelected).format("YYYY-MM-DD")),
                getFindAllFinancialMovementService(locationState?.id, dayjs(daySelected).format('YYYY-MM-DD')),
                getBankWholesalerService()
            ])

            let errors = false
            let respServices = {}

            for (let index = 0; index < responseServices.length; index++) {
                const elementResponse = responseServices[index];
                if (elementResponse.status === "fulfilled") {
                    switch (index) {
                        case 0:
                            let expenses = elementResponse?.value.find(item => item.type === 0 && item.state === 0)
                            if (expenses) {
                                expenses.name = "GASTOS"
                                respServices.expensesCategory = elementResponse?.value?.filter(item => item.type === 0 && item.state === 0)?.map(element => ({...element, value: element.id, label: element.name.charAt(0).toUpperCase() + element.name.slice(1).toLowerCase()}))
                            }
                            let listExpensesData = elementResponse?.value.filter(item => item.state !== 1 && item.type !== 0)
                            respServices.listExpensesTable = expenses? [expenses, ...listExpensesData] : listExpensesData
                        break;
                        case 1:
                            respServices.financeListing = elementResponse?.value.filter(item => item.state === 0)
                        break;
                        case 2:
                            respServices.listExpensesTable.forEach(element => {
                                const matchingObjects = elementResponse?.value.filter(object => object.type === element.type && object.state === element.state);
                                if (matchingObjects.length > 0) {
                                    element.expenseWholesaler = matchingObjects.flatMap(item => item.expenseWholesaler || [])?.map(item => ({ ...item, typeExpense: { value: item?.idTypeExpenseWholesaler } }));
                                    if (element.expenseWholesaler.length < expensesList.length) {
                                        const resp = element.expenseWholesaler.concat(expensesList.slice(0, (expensesList.length - element.expenseWholesaler.length)))
                                        element.expenseWholesaler = resp
                                    }
                                }
                            });
                        break;
                        case 3:
                            respServices.financeListing.forEach(element => {
                                const matchingObjects = elementResponse?.value.filter(object => object.type === element.type && object.state === element.state);
                                if (matchingObjects.length > 0) {
                                    element.financialMovement = matchingObjects.flatMap(item => item.financialMovement || [])?.map(item => ({ ...item, idTable: { value: item?.idType },concept:{value:item.concept} }));
                                    if (element.financialMovement.length < consignmentsList.length) {
                                        const resp = element.financialMovement.concat(consignmentsList.slice(0, (consignmentsList.length - element.financialMovement.length)))
                                        element.financialMovement = resp
                                    }
                                }
                            });
                        break;
                        case 4:
                            respServices.listOfBanks = elementResponse?.value.map((element) =>({ value: element}))
                        break;
                        default:
                        break;
                    }
                }else{
                  errors = true
                }
            };
            setStateCashClosingWholesaleReducer({
                listExpenses: respServices.listExpensesTable, 
                shipmentRecords: respServices.financeListing
            })
            setState((prevState) => ({
                ...prevState,
                ...respServices
            }))
            if (errors) {
              ErrorAlertComponent()
            }

        } catch (error) {
            ErrorAlertComponent()
        }
    }

    const handleDate = (date) => {
        setState({
            ...state,
            dateSelected: date
        })
    }

    const previousMonth = () => {
        const monthNew = dateSelected.subtract(1, 'month')
        setState({
            ...state,
            dateSelected: monthNew
        })
    }

    const nextMonth = () => {
        const monthNew = dateSelected.add(1, 'month')
        setState({
            ...state,
            dateSelected: monthNew
        })
    }

    const previousWeek = () => {
        const week = dateSelected.subtract(1, 'week')
        setState({
            ...state,
            dateSelected: week
        })
    }

    const nextWeek = () => {
        const week = dateSelected.add(1, 'week')
        setState({
            ...state,
            dateSelected: week
        })
    }

    const selectDay = (day) => {
        setState({
            ...state,
            daySelected: day,
            dateSelected: day
        })
    }

    const handleSave = async() => {
        try {
            setState({ ...state, isSaveData: true })
            let servicesAllPromise = []
            let incompleteInformation = false

            const expensesList = listExpenses.reduce((objectAdd, object,index) => {
                const expenseFilter = object.expenseWholesaler.filter(expense => !expense.disabled).map(
                    (expenseMap) => {
                        let expenseStructure = {
                            concept: expenseMap?.concept, 
                            price: Number(expenseMap?.price) ? expenseMap?.price : parseInt((expenseMap?.price || "0")?.replace(/[^0-9]/g, ''),10), 
                        }
                        if(expenseMap.value){
                            expenseStructure.value = Number(expenseMap?.value) ? String(expenseMap?.value) : String(parseInt((expenseMap?.value)?.replace(/[^0-9]/g, ''),10))?.trimStart()
                        }
                        if(expenseMap.id){ 
                            expenseStructure.id = expenseMap.id 
                        }else{
                            expenseStructure.idTypeExpenseWholesaler =  (index === 0) ? expenseMap?.typeExpense?.value : object.id
                        }
                        return (expenseStructure)
                    }
                );
                return objectAdd.concat(expenseFilter);
            }, []);

            const consignmentsList = shipmentRecords.reduce((objectAdd, object) => {
                const shippingFilter = object.financialMovement.filter(financialMovement => !financialMovement.disabled).map(
                    (shippingMap) => {
                        let shippingStructure = {
                            concept: shippingMap?.concept.value,
                            price: Number(shippingMap?.price) ? shippingMap?.price : parseInt((shippingMap?.price || "0")?.replace(/[^0-9]/g, ''),10),
                        }
                        if (shippingMap.value) {
                            shippingStructure.value = Number(shippingMap?.value) ? String(shippingMap?.value) : String(parseInt((shippingMap?.value)?.replace(/[^0-9]/g, ''),10))?.trimStart()
                        }
                        
                        if(shippingMap.id){ 
                            shippingStructure.id = shippingMap.id 
                        } 
                        else{
                            shippingStructure.idType = object.id
                        }
                        return (shippingStructure)
                    }
                );
                return objectAdd.concat(shippingFilter);
                
            }, []);

            const createExpensesList = expensesList?.filter(expense => !expense.id)
            const updateExpensesList = expensesList?.filter(expense => expense.id)

            const createConsignmentList = consignmentsList?.filter(financialMovement => !financialMovement.id)
            const updateConsignmentList = consignmentsList?.filter(financialMovement => financialMovement.id)

            incompleteInformation = expensesList?.every(expense => {
                let idElement = expense.hasOwnProperty("id") ? expense.id : expense.idTypeExpenseWholesaler
                return expense.concept.trim() !== '' && idElement.trim() !== '' && expense.price > 0
            })

            if (incompleteInformation) {
                incompleteInformation = consignmentsList?.every(financialMovement => {
                    let idItem = financialMovement.hasOwnProperty("id") ? financialMovement.id : financialMovement.idType
                    return financialMovement.concept.trim() !== '' && idItem.trim() !== '' && financialMovement.price > 0
                })
            }

            if(totals?.today){
                let changesTotals = {}

                if(totals?.today?.banknote !== totalsOriginal?.today?.banknote){
                   changesTotals.banknote = totals?.today?.banknote 
                }

                if (totals?.today?.coin !== totalsOriginal?.today?.coin) {
                    changesTotals.coin = totals?.today?.coin
                }

                if (Object.keys(changesTotals).length > 0) {
                    servicesAllPromise.push(updateTotalsService(locationState?.id,dayjs(dateSelected).format("YYYY-MM-DD"), {...changesTotals}))
                }
            }


            if(!incompleteInformation){
                InformationAlertComponent(undefined,"Faltan campos por completar")
            }else{
                createExpensesList?.length > 0 && servicesAllPromise.push(createExpensesWholesalerService(locationState?.id,dayjs(dateSelected).format("YYYY-MM-DD"),{ expense:createExpensesList }))
                updateExpensesList?.length > 0 && servicesAllPromise.push(updateExpensesWholesalerService({ expense:updateExpensesList }))
    
                createConsignmentList?.length > 0 && servicesAllPromise.push(createFinancialMovementService(locationState?.id, dayjs(daySelected).format('YYYY-MM-DD'), {financialMovement: createConsignmentList }))
                updateConsignmentList?.length > 0 && servicesAllPromise.push(updateFinancialMovementService({ financialMovement:updateConsignmentList }))

                const responseServices = await Promise.allSettled(servicesAllPromise)
    
                let errorServices = responseServices.find(item => item.status === "rejected")
    
                if (errorServices) {
                    ErrorAlertComponent()
                }else{
                    SuccessAlertComponent()
                }
    
                await allServices()
            }

        } catch (error) {
            ErrorAlertComponent()
        }
    }

    const onChanges = (e, name) => {
        let value = e.target ? e.target.value || '' : e;

        value = parseInt((value || "0")?.replace(/[^0-9]/g, ''),10)

        let dataNew = {...totals}

        if (name === "coin") {
            dataNew.today.coin = value
        }

        if (name === "banknote") {
            dataNew.today.banknote = value
        }

        setStateCashClosingWholesaleReducer({
            totals: dataNew
        })
        
    }

    return (
        <div className='detail-cash-closing__container'>
            <div className='grid-x justify-content-between detail-cash-closing__content-header'>
                <div className='grid-x align-middle'>
                    <Link
                        to="/reportCashClosingWholesaler"
                        className="grid-x detail-cash-closing__content-header__link"
                    >
                        Cierre de caja
                        <img
                            alt='icon_arrow'
                            src={Assets.SharedIcons.icon_arrow}
                            className='detail-cash-closing__content-header__image'
                        />
                    </Link>
                    <span className='detail-cash-closing__name'>
                        {locationState?.name}
                    </span>
                </div>
                <div className='grid-x align-middle'>
                    <button
                        onClick={() => handleSave()}
                        disabled={cashClosingData?.isPublish}
                        className='detail-cash-closing__button__save'
                        onMouseOver={() => setState((prevState) => ({ ...prevState, isHoverSaveProcess: true }))}
                        onMouseOut={() => setState((prevState) => ({ ...prevState, isHoverSaveProcess: false }))}
                    >
                        <img
                            alt={cashClosingData?.isPublish
                                ? 'icon_save_disabled'
                                : isHoverSaveProcess && !cashClosingData?.isPublish
                                    ? 'icon_save_hover'
                                    : 'icon_save'
                            }
                            src={
                                cashClosingData?.isPublish
                                    ? Assets.SharedIcons.icon_save_disabled
                                    : isHoverSaveProcess && !cashClosingData?.isPublish
                                        ? Assets.SharedIcons.icon_save_hover
                                        : Assets.SharedIcons.icon_save}
                        />
                        <span>Guardar y actualizar</span>
                    </button>
                    <button
                        disabled={cashClosingData?.isPublish}
                        className='detail-cash-closing__button__publish'
                    >
                        <span>Finalizar y publicar</span>
                        <img
                            alt={cashClosingData?.isPublish ? 'icon_publish_disabled' : 'icon_publish'}
                            src={cashClosingData?.isPublish ? Assets.SharedIcons.icon_publish_disabled : Assets.SharedIcons.icon_publish}
                        />
                    </button>
                </div>
            </div>
            <WeekSelectorComponent
                header={
                    <div className='grid-x align-middle'>
                        <img
                            alt="icon_cash_closing"
                            className='detail-cash-closing__icon-cash-closing'
                            src={Assets.SharedIcons.icon_cash_closing}
                        />
                        <div className='grid-y'>
                            <span className='detail-cash-closing__title'>
                                {`Sede ${nameOfHeadquarters}`}
                            </span>
                            <span className='detail-cash-closing__title__info'>
                                Reporte del Cierre de caja diario
                            </span>
                        </div>
                    </div>
                }
                handleDate={(date) => handleDate(date)}
                previousDate={() => previousMonth()}
                nextDate={() => nextMonth()}
                previousWeek={() => previousWeek()}
                nextWeek={() => nextWeek()}
                selectDay={(date) => selectDay(date)}
                dateSelected={dateSelected}
                daySelected={daySelected}
            />
            <div className='grid-x detail-cash-closing__box'>
                <div className='grid-x small-12  align-bottom'>
                    <div className='grid-x detail-cash-closing__summary__total-cash'>
                        <div className='grid-x justify-content-between detail-cash-closing__summary__box--mod-real-cash'>
                            <div className='grid-x detail-cash-closing__summary__box--mod-final-total'>
                                <span className='detail-cash-closing__summary__text'>Efectivo final</span>
                                <div className={`grid-x small-12 detail-cash-closing__payments__content-input-price`}>
                                    <NumericFormat
                                        type='text'
                                        prefix={"$ "}
                                        value={totals?.today?.banknote || 0}
                                        placeholder='$ 0'
                                        thousandSeparator=","
                                        className='input-price'
                                        thousandsGroupStyle="thousand"
                                        onChange={(e)=> onChanges(e,"banknote")}
                                        readOnly={totals?.today ? false : true}
                                    />
                                    { totals?.today &&
                                        <div className='detail-cash-closing__payments__content-icon-price'>
                                            <img
                                                className='detail-cash-closing__payments__icon'
                                                alt="icon_edit"
                                                src={Assets.SharedIcons.icon_edit}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='grid-x detail-cash-closing__summary__box--mod-final-total'>
                                <span className='detail-cash-closing__summary__text'>Moneda final</span>
                                <div className='grid-x small-12 detail-cash-closing__payments__content-input-price'>
                                    <NumericFormat
                                        type='text'
                                        prefix={"$ "}
                                        value={totals?.today?.coin || 0}
                                        placeholder='$ 0'
                                        thousandSeparator=","
                                        className='input-price'
                                        thousandsGroupStyle="thousand"
                                        onChange={(e)=> onChanges(e,"coin")}
                                        readOnly={totals?.today ? false : true}
                                    />
                                    { totals?.today &&
                                        <div className='detail-cash-closing__payments__content-icon-price'>
                                            <img
                                                className='detail-cash-closing__payments__icon'
                                                alt={"icon_edit"}
                                                src={Assets.SharedIcons.icon_edit}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='grid-x detail-cash-closing__summary__box--mod'>
                            <span className='detail-cash-closing__summary__text'>Billetes del dia anterior</span>
                            <div className='grid-x detail-cash-closing__payments__table__content-inputs--mod'>
                                <NumericFormat
                                    type='text'
                                    prefix={"$ "}
                                    value={totals?.yesterday?.banknote || 0}
                                    placeholder='$ 0'
                                    thousandSeparator=","
                                    className='input-price'
                                    thousandsGroupStyle="thousand"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className='grid-x detail-cash-closing__summary__box--mod'>
                            <span className='detail-cash-closing__summary__text'>Monedas del dia anterior</span>
                            <div className='grid-x detail-cash-closing__payments__table__content-inputs--mod'>
                                <NumericFormat
                                    type='text'
                                    prefix={"$ "}
                                    value={totals?.yesterday?.coin || 0}
                                    placeholder='$ 0'
                                    thousandSeparator=","
                                    className='input-price'
                                    thousandsGroupStyle="thousand"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='detail-cash-closing__contend-tables'>
                <RecordKeepersComponent
                    daySelected={daySelected}
                    locationState={locationState}
                    isSave={isSaveData}
                    setStateData={(data)=>{
                        setState((prevState)=>({
                            ...prevState,
                            ...data
                        }))
                    }}
                />
                <div className='grid-x small-12 detail-cash-closing__second-tables'>
                    <div className='grid-x small-6 align-content-start'>
                        {financeListing.map((item, index) => (
                            <ConsignmentsOrTransfersComponent 
                                key={index}
                                keyFinanceTable={index}
                                financeItem={item}
                                optionsBanks={listOfBanks}
                            />
                        ))}
                    </div>
                    <div className='grid-x small-6 align-content-start'>
                        { listExpensesTable.map((item, index) => {
                            return(
                                <ExpensesComponent 
                                    key={index}
                                    table={item}
                                    keyTable={index}
                                    optionsExpenses={expensesCategory}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>

    )
}

const mapStateToProps = ({ CashClosingWholesaleReducer }) => {
    const { listExpenses,shipmentRecords, totals, totalsOriginal } = CashClosingWholesaleReducer;
    return { 
        listExpenses,
        shipmentRecords, 
        totals,
        totalsOriginal 
    };
};

const mapStateToPropsActions = {
    setStateCashClosingWholesaleReducer,
    resetCashClosingWholesaleReducer
};
  
export default connect(mapStateToProps, mapStateToPropsActions)(DetailCashClosingPage);