// Libraries
import { Outlet } from 'react-router-dom'

//Components
import NavbarMainComponent from '../shared/components/nav-bars/navbar-main/navbar-main.component';
import SidenavMainComponent from '../shared/components/side-navs/sidenav-main/sidenav-main.component';

const InnerContent = () => {
  return(
    <div>
      <div className="grid-x" style={{ height: "100vh", minWidth: "1300px"}}>
        <NavbarMainComponent />
        <div className='grid-x' style={{ height: "calc(100vh - 60px)", width: "100%"}}>
          <SidenavMainComponent />
          <div style={{ width: "calc(100% - 200px)", height: "calc(100vh - 60px)", overflowY: "scroll"}}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default InnerContent;
