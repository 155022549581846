//Actions
import { setStateCashClosingReducer } from '../../../../storage/reducers/cash-closing/cash-closing.actions';

//Libraries
import { Input } from 'antd';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { NumericFormat } from "react-number-format";

//Assets
import { Assets } from '../../../../assets';

//Styles
import '../../cash-closing.page.scss';

//Utils
import { formattedNumber } from '../../../../utils/shared.utils';

const PackagingControlComponent = (props) => {

    const { cashClosingData, setStateCashClosingReducer } = props;

    const INITIAL_STATE = {
        scrollEnd: false,
        packagingControl: [],
    };

    const [state, setState] = useState(INITIAL_STATE);

    const { packagingControl } = state;

    useEffect(() => {
        if (cashClosingData?.cashRegisterCashReceiptBottleControl) {
            const packagingControl = cashClosingData.cashRegisterCashReceiptBottleControl.map((item, index) => {
                return {
                    id: item?.id,
                    type: item?.type,
                    price: item?.price,
                    amount: item?.amount,
                    totalPrice: (item?.price * item?.amount),
                }
            })
            setState((prevState) => ({
                ...prevState,
                packagingControl
            }))
        } else {
            setState(INITIAL_STATE)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cashClosingData?.cashRegisterCashReceiptBottleControl]);


    const handleChange = (e, index, name) => {
        let { value } = e.target;
        const newArray = JSON.parse(JSON.stringify(packagingControl));

        if (/^\d*$/.test(value)) {
            newArray[index][name] = value;
            newArray[index].totalPrice = (newArray[index].price * value);
            setState({
                ...state,
                packagingControl: newArray
            })
        };
        setStateCashClosingReducer('packagingControlFrom', newArray);
    };

    const handleTotal = (data) => {
        let totalPriceControl = 0;
        data.forEach(object => {
            totalPriceControl += (parseFloat(object.totalPrice) ? parseFloat(object.totalPrice) : 0);
        });
        totalPriceControl = parseFloat(totalPriceControl.toFixed(2));
        return totalPriceControl;
    };

    return (
        <div className='cash-closing__payments__box-padding'>
            <div className='cash-closing__payments__container'>
                <div className='cash-closing__payments__content'>
                    <div className='grid-y'>
                        <div className='grid-x cash-closing__payments__content-titles__fist'>
                            <div className='grid-x align-middle small-6'>
                                <img src={Assets.SharedIcons.icon_information} alt="icon_information" />
                                <span className='cash-closing__payments__titles cash-closing__payments__titles__space'>Control de envases</span>
                            </div>
                            <div className='grid-x align-middle small-6 justify-content-end'>
                                <span className='cash-closing__payments__titles'>Total:</span>
                                <div className='grid-x align-middle cash-closing__summary__details'>
                                    <div className='cash-closing__payments__table__content-icon-price'>
                                        <img
                                            alt="icon_income_blue"
                                            src={Assets.SharedIcons.icon_income_blue}
                                            className='cash-closing__summary__price__icon cash-closing__summary__price__icon__rotate'
                                        />
                                    </div>
                                    <NumericFormat
                                        type='text'
                                        prefix={"$ "}
                                        disabled={true}
                                        placeholder='$ 0'
                                        thousandSeparator=","
                                        className='input-price'
                                        thousandsGroupStyle="thousand"
                                        value={handleTotal(packagingControl)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='grid-x cash-closing__payments__content-titles__second'>
                            <span className='cash-closing__payments__titles cash-closing__payments__titles__ellipsis small-3'>Envase</span>
                            <span className='cash-closing__payments__titles cash-closing__payments__titles__space--mod small-3'>Valor de la caja</span>
                            <span className='cash-closing__payments__titles cash-closing__payments__titles__space--mod small-3'>Cantidad</span>
                            <span className='grid-x justify-content-end cash-closing__payments__titles small-3'>Valor</span>
                        </div>
                    </div>
                    <div className='cash-closing__packaging-control'>
                        {packagingControl.map((item, index) => {
                            return (
                                <div key={index} className='grid-x justify-content-between cash-closing__payments__table'>
                                    <div className='small-3'>
                                        <Input
                                            disabled={true}
                                            value={item?.type}
                                            placeholder='Tipo de envases'
                                            className='cash-closing__packaging-control__input-solid'
                                        />
                                    </div>
                                    <div className='grid-x justify-content-center small-3'>
                                        <Input
                                            disabled={true}
                                            className='cash-closing__packaging-control__input-solid'
                                            value={item?.price ? formattedNumber(item?.price) : '$ 0'}
                                        />
                                    </div>
                                    <div className='grid-x justify-content-center small-3'>
                                        <Input
                                            placeholder='0'
                                            disabled={cashClosingData?.isPublish}
                                            value={item?.amount ? item.amount : ''}
                                            onChange={(e) => handleChange(e, index, 'amount')}
                                            className='cash-closing__packaging-control__input-solid'
                                            prefix={<img src={Assets.SharedIcons.icon_edit} alt="icon_edit" />}
                                        />
                                    </div>
                                    <div className='grid-x justify-content-end small-3'>
                                        <Input
                                            disabled={true}
                                            value={item?.totalPrice ? formattedNumber(item?.totalPrice) : '$ 0'}
                                            className='cash-closing__packaging-control__input-solid cash-closing__packaging-control__input-solid__text-right'
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ CashClosingReducer }) => {
    const { cashClosingData } = CashClosingReducer;
    return {
        cashClosingData
    };
};

const mapStateToPropsActions = {
    setStateCashClosingReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(PackagingControlComponent);