//Actions
import { setItemCashClosingWholesaleReducer } from '../../../../storage/reducers/cash-closing-wholesale/cash-closing-wholesale.actions';

//Assets
import { Assets } from '../../../../assets'

//Libraries
import { NumericFormat } from 'react-number-format';
import React, { useEffect, useRef, useState } from 'react'
import { Input } from 'antd';
import { connect } from 'react-redux';

//Styles
import './inventory.component.scss'

//Utils
import { dataInventory } from '../../../../utils/cash-closing-wholesaler.utils'

const InventoryComponent = (props) => {

  const { 
    //Actions
    setItemCashClosingWholesaleReducer,
    //Variables
    listTable 
  } = props

  const INITIAL_STATE = {
    scrollEnd: false,
    inventory: Array(7).fill().map(() => ({ ...dataInventory }))
  };

  const [state, setState] = useState(INITIAL_STATE)

  const { inventory, scrollEnd } = state

  const formRef = useRef(null);

  useEffect(() => {
    if (formRef.current && scrollEnd) {
      const miDiv = formRef.current;
      miDiv.scrollTop = miDiv.scrollHeight;
    }
  }, [scrollEnd, inventory?.length]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      inventory: listTable ? listTable : dataInventory
    }))
  }, [listTable])

  const onChanges = (e, index, name) => {
    let value = e.target ? e.target.value || '' : e;
    let inventoryModified = inventory

    value = parseInt((value || "0")?.replace(/[^0-9]/g, ''),10)
    inventoryModified[index][name] = value;

    setState((prevState)=>({
      ...prevState,
      inventory: inventoryModified,
    }))

    setItemCashClosingWholesaleReducer("containerList",index, inventoryModified[index]);
  }

  const sumPrices = (list,name) => {
    return (list || []).reduce((accumulator, product) => {
      const price = !isNaN(Number(product[name])) ? Number(product[name]) : Number(product[name]?.replace(/[^0-9]/g, ''));
      return accumulator + (isNaN(price) ? 0 : price);
    }, 0);
  };

  return (
    <div>
      <div className='grid-x'>
        <span className='small-2 inventory__subtitle'>Código</span>
        <span className='small-2 inventory__subtitle'>Nombre del producto</span>
        <span className='small-2 inventory__subtitle'>Cantidad</span>
        <span className='small-2 inventory__subtitle'>Liquido</span>
        <span className='small-2 inventory__subtitle'>Envase</span>
        <span className='small-2 inventory__subtitle'>Total</span>
      </div>
      <div className='inventory__summary__content'>
        {inventory?.map((item, index) => {
          item.total = item?.amount * (item.liquid + item.package);
          return (
            <div key={index} className='grid-x inventory__products__container'>
              <div className='small-2'>
                <Input
                  disabled
                  value={item?.code}
                  placeholder='0000'
                  className='inventory__products__input inventory__products__input--mod'
                  prefix={<></>}
                />
              </div>
              <div className='small-2'>
                <Input
                  disabled
                  value={item?.nameProduct}
                  placeholder='Descripción'
                  className='inventory__products__input inventory__products__input--mod'
                  prefix={<></>}
                />
              </div>
              <div className='grid-x small-2'>
                <div className='grid-x inventory__products__content-input-price'>
                  <NumericFormat
                    type='text'
                    prefix={''}
                    placeholder='0000'
                    value={item?.amount}
                    thousandSeparator=","
                    className='input-price'
                    disabled={item?.disabled}
                    thousandsGroupStyle="thousand"
                    onChange={(e) => onChanges(e, index, "amount")}
                  />
                  <div className='inventory__products__content-icon-price'>
                    <img
                      className='inventory__products__icon'
                      alt={item.disabled ? "icon_add" : "icon_edit"}
                      src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                    />
                  </div>
                </div>
              </div>
              <div className='grid-x small-2'>
                <div className='grid-x inventory__products__content-input-price'>
                  <NumericFormat
                    type='text'
                    prefix={"$ "}
                    placeholder='$ 0'
                    value={item?.liquid}
                    thousandSeparator=","
                    className='input-price'
                    thousandsGroupStyle="thousand"
                    disabled={item?.disabled}
                    onChange={(e) => onChanges(e, index, "liquid")}
                  />
                  <div className='inventory__products__content-icon-price'>
                    <img
                      className='inventory__products__icon'
                      alt={item.disabled ? "icon_add" : "icon_edit"}
                      src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                    />
                  </div>
                </div>
              </div>
              <div className='grid-x small-2'>
                <div className='grid-x inventory__products__content-input-price'>
                  <NumericFormat
                    type='text'
                    prefix={"$ "}
                    placeholder='$ 0'
                    value={item?.package}
                    thousandSeparator=","
                    className='input-price'
                    thousandsGroupStyle="thousand"
                    disabled={item?.disabled}
                    onChange={(e) => onChanges(e, index, "package")}
                  />
                  <div className='inventory__products__content-icon-price'>
                    <img
                      className='inventory__products__icon'
                      alt={item.disabled ? "icon_add" : "icon_edit"}
                      src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                    />
                  </div>
                </div>
              </div>
              <div className='grid-x small-2'>
                <div className='grid-x small-12 inventory__products__content-input-price'>
                  <NumericFormat
                    type='text'
                    prefix={"$ "}
                    placeholder='$ 0'
                    thousandSeparator=","
                    className='input-price solid'
                    thousandsGroupStyle="thousand"
                    value={item?.total}
                    disabled={item?.disabled}
                    readOnly
                  />
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className='grid-x'>
        <div className='inventory__summary__line inventory__summary__line--mod'></div>
        <div className='grid-x small-4'>
          <span className='grid-x align-middle small-10 justify-content-end liquidation__subtitle'>
            Totalizadores
          </span>
        </div>
        <div className='grid-x small-2'>
          <div className='grid-x inventory__products__content-input-price'>
            <NumericFormat
              type='text'
              prefix={"$ "}
              placeholder='$ 0'
              value={sumPrices(inventory,'amount')}
              thousandSeparator=","
              className='input-price solid'
              thousandsGroupStyle="thousand"
            />
          </div>
        </div>
        <div className='grid-x small-2'>
          <div className='grid-x inventory__products__content-input-price'>
            <NumericFormat
              type='text'
              prefix={"$ "}
              placeholder='$ 0'
              value={sumPrices(inventory,'liquid')}
              thousandSeparator=","
              className='input-price solid'
              thousandsGroupStyle="thousand"
            />
          </div>
        </div>
        <div className='grid-x small-2'>
          <div className='grid-x inventory__products__content-input-price'>
            <NumericFormat
              type='text'
              prefix={"$ "}
              placeholder='$ 0'
              value={sumPrices(inventory,'package')}
              thousandSeparator=","
              className='input-price solid'
              thousandsGroupStyle="thousand"
            />
          </div>
        </div>
        <div className='grid-x small-2'>
          <div className='grid-x small-12 inventory__products__content-input-price'>
            <NumericFormat
              type='text'
              prefix={"$ "}
              placeholder='$ 0'
              value={sumPrices(inventory,'total')}
              thousandSeparator=","
              className='input-price solid'
              thousandsGroupStyle="thousand"
            />
          </div>
        </div>
      </div>
        <div className='inventory__summary__line inventory__summary__line--mod'></div>
    </div>
  )
}

const mapStateToPropsActions = {
  setItemCashClosingWholesaleReducer,
};

export default connect(null, mapStateToPropsActions)(InventoryComponent);