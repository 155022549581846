// Assets
import { Assets } from '../../../../../../assets';

// Libraries
import { Checkbox, Spin } from 'antd';
import React, { useEffect, useState } from 'react'

// Styles
import './list-of-unidentified-prefixes.component.scss';

const ListOfUnidentifiedPrefixesComponent = (props) => {

  const {
    // Props
    onSave,
    onCancel,
    isDisabled,
    indexOfList,
    isDiscardAll,
    isItemChecked,
    listOfUnidentified,
    validateDiscardAll,
    handleCheckboxChange
  } = props;

  const [loadingModal, setLoadingModal] = useState(false)

  useEffect(() => {
    setLoadingModal(true)
    setTimeout(() => {
      setLoadingModal(false)
    }, 250);
  }, [indexOfList]);
  

  const renderTitle = (index) => {
    const renderText = {
      0: 'TNS NUBE en reportes de ventas',
      1: 'TNS NUBE en utilidad por facturación',
      2: 'PUNTO FRIO en reportes de ventas',
      3: 'PUNTO FRIO en utilidad por facturación'
    };
    return renderText[index];
  };

  return (
    <>
      {loadingModal
        ?
          (<div className='grid-x align-center-middle list-of-unidentified-prefixes__spinner'>
            <Spin size='large' />
          </div>)
        :
          (<div className='grid-x align-center-middle list-of-unidentified-prefixes__container'>
            <div className='grid-x align-center-middle list-of-unidentified-prefixes__title__content' >
              <img
                alt="icon_warning"
                src={Assets.SharedIcons.icon_warning}
              />
              <span className='grid-x text-center list-of-unidentified-prefixes__title'>
                {`Prefijos no identificados de ${renderTitle(indexOfList)}`}
              </span>
            </div>
            <span className='list-of-unidentified-prefixes__text'>
              {`A continuación te compartimos el listado de los prefijos a los cuales no le encontramos de ${renderTitle(indexOfList)}.`}
            </span>
            <div className='grid-x align-center-middle list-of-unidentified-prefixes__content-titles'>
              <span className='list-of-unidentified-prefixes__names-title'>
                Número de prefijo
              </span>
              <span className='grid-x justify-content-center list-of-unidentified-prefixes__names-title'>
                Sede
              </span>
              <div className='grid-x align-center-middle justify-content-end list-of-unidentified-prefixes__discard__content'>
                <span className='list-of-unidentified-prefixes__discard'>
                  Descartar todo
                </span>
                <div className='list-of-unidentified-prefixes__discard__checkbox'>
                <Checkbox
                  checked={validateDiscardAll(isDiscardAll)}
                  onChange={(e) => handleCheckboxChange(e?.target?.checked, 'isDiscardAll')}
                />
                </div>
              </div>
            </div>
            <div className='list-of-unidentified-prefixes__info__content'>
              {listOfUnidentified?.[indexOfList].map((prefixes, index) => {
                return (
                  <div key={index} className='grid-x align-center-middle list-of-unidentified-prefixes__info__column'>
                    <span className='list-of-unidentified-prefixes__info__items__text'>
                      <strong>
                        {prefixes.namePrefix}
                      </strong>
                    </span>
                    <span className='list-of-unidentified-prefixes__info__items__text__headquarter'>
                      Sin identificar
                    </span>
                    <div className='grid-x align-center-middle justify-content-end list-of-unidentified-prefixes__info__discard__content'>
                      <span className='list-of-unidentified-prefixes__info__discard'>
                        Descartar
                      </span>
                      <div className='list-of-unidentified-prefixes__info__discard__checkbox'>
                        <Checkbox
                          checked={isItemChecked(prefixes)}
                          onChange={(e) => handleCheckboxChange(e?.target?.checked, 'isDiscard', prefixes)}
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className='grid-x justify-content-center list-of-unidentified-prefixes__button__content'>
              <button
                onClick={() => onCancel()}
                className='list-of-unidentified-prefixes__button__cancel'
              >
                <span>Cancelar</span>
              </button>
              <button
                disabled={isDisabled}
                onClick={() => onSave()}
                className='list-of-unidentified-prefixes__button__confirm'
              >
                <span>Finalizar y guardar</span>
              </button>
            </div>
          </div>)
      }
    </>
  )
}

export default ListOfUnidentifiedPrefixesComponent;