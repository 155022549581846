// Constants - Errors
import InfrastructureErrorCodesConst from "../../core/constants/errors/infrastructure/infrastructure-error-codes.const";
import InfrastructureErrorsConst from "../../core/constants/errors/infrastructure/infrastructure-error.const";

// Libraries
import axios from "axios";

// Models
import { createErrorInternalApp } from "../../models/aggregates/build-error-internal-app/build-error-internal-app.dao";
import ErrorExternalAppModel from "../../models/aggregates/error-external-app.model";

// Translation
import string from "../../shared/translations/I18n";

export const callExternalApi = async (options) => {
  try {
    const response = await axios(options.config);
    
    if (!response.status) {
      
      return {
        data: null,
        error: new ErrorExternalAppModel(response.code, response.message)
      };
    }

    const { data } = response;

    return {
      data,
      error: null,
    };

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      // client received an error response (5xx, 4xx)
      const { response } = error;
      
      if (!response) {
        return {
          data: null,
          error: createErrorInternalApp(InfrastructureErrorsConst.responseStatusFailed)
        };
      }

      const { data: {error:data}, statusText } = response;
      let message = data && data.message ? data.message : statusText;
      message = message.length === 0 ? 
      string.t(`${InfrastructureErrorsConst.responseErrorAxiosFound}.message`) : 
      message

      let code = data && data.code ? data.code : InfrastructureErrorCodesConst.RES_IFT_002;

      let description = data && data.description ? data.description : "";

      return {
        data: null,
        error: new ErrorExternalAppModel(code, message, description)
      };

    } else if (error.request) {
      // client never received a response, or request never left 
      const { request } = error;

      if (!request) {
        return {
          data: null,
          error: createErrorInternalApp(InfrastructureErrorsConst.requestNeverReceived)
        };
      }

      const { data, statusText } = request;

      let message = data && data.errors && data.errors.message ? data.errors.message : statusText;
      message = message.length === 0 ? 
      string.t(`${InfrastructureErrorsConst.responseErrorAxiosFound}.message`) : 
      message

      let code = data && data.errors && data.errors.code ? data.errors.code : InfrastructureErrorCodesConst.REQ_IFT_002;

      let description = data && data.errors && data.errors.description ? data.errors.description : "";

      return {
        data: null,
        error: new ErrorExternalAppModel(code, message, description)
      };

    } else {
      // anything else 
      // Something happened in setting up the request that triggered an Error
      return {
        data: null,
        error: {
          message: createErrorInternalApp(InfrastructureErrorsConst.responseOtherFail)
        },
      };
    }
  }
};
