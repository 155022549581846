import { Assets } from "../assets";

export const dataPayment = [
    {
        bankCreditType: {
            label: "",
            value: "",
        },
        headQuarter: {
            label: "",
            value: "",
        },
        conceptDescription: "",
        conceptCategory: {
            label: "",
            value: "",
        },
        subCategories: {
            label: "",
            value: "",
        },
        paymentMethod: {
            label: "",
            value: "",
        },
        price: "",
        disabled: true,
        isCredit: false, // Indicator for the aggregate button process.
        isNotReported: false,
        disabledCategory: true,
        disabledSubCategory: true,
        disabledPaymentMethod: true,
        listOfPaymentMethod: [],
        listOfExpenseSubCategory: [],
    },
    {
        bankCreditType: {
            label: "",
            value: "",
        },
        headQuarter: {
            label: "",
            value: "",
        },
        conceptDescription: "",
        conceptCategory: {
            label: "",
            value: "",
        },
        subCategories: {
            label: "",
            value: "",
        },
        paymentMethod: {
            label: "",
            value: "",
        },
        price: "",
        disabled: true,
        isCredit: false, // Indicator for the aggregate button process.
        isNotReported: false,
        disabledCategory: true,
        disabledSubCategory: true,
        disabledPaymentMethod: true,
        listOfPaymentMethod: [],
        listOfExpenseSubCategory: [],
    },
    {
        bankCreditType: {
            label: "",
            value: "",
        },
        headQuarter: {
            label: "",
            value: "",
        },
        conceptDescription: "",
        conceptCategory: {
            label: "",
            value: "",
        },
        subCategories: {
            label: "",
            value: "",
        },
        paymentMethod: {
            label: "",
            value: "",
        },
        price: "",
        disabled: true,
        isCredit: false, // Indicator for the aggregate button process.
        isNotReported: false,
        disabledCategory: true,
        disabledSubCategory: true,
        disabledPaymentMethod: true,
        listOfPaymentMethod: [],
        listOfExpenseSubCategory: [],
    },
    {
        bankCreditType: {
            label: "",
            value: "",
        },
        headQuarter: {
            label: "",
            value: "",
        },
        conceptDescription: "",
        conceptCategory: {
            label: "",
            value: "",
        },
        subCategories: {
            label: "",
            value: "",
        },
        paymentMethod: {
            label: "",
            value: "",
        },
        price: "",
        disabled: true,
        isCredit: false, // Indicator for the aggregate button process.
        isNotReported: false,
        disabledCategory: true,
        disabledSubCategory: true,
        disabledPaymentMethod: true,
        listOfPaymentMethod: [],
        listOfExpenseSubCategory: [],
    },
    {
        bankCreditType: {
            label: "",
            value: "",
        },
        headQuarter: {
            label: "",
            value: "",
        },
        conceptDescription: "",
        conceptCategory: {
            label: "",
            value: "",
        },
        subCategories: {
            label: "",
            value: "",
        },
        paymentMethod: {
            label: "",
            value: "",
        },
        price: "",
        disabled: true,
        isCredit: false, // Indicator for the aggregate button process.
        isNotReported: false,
        disabledCategory: true,
        disabledSubCategory: true,
        disabledPaymentMethod: true,
        listOfPaymentMethod: [],
        listOfExpenseSubCategory: [],
    },
    {
        bankCreditType: {
            label: "",
            value: "",
        },
        headQuarter: {
            label: "",
            value: "",
        },
        conceptDescription: "",
        conceptCategory: {
            label: "",
            value: "",
        },
        subCategories: {
            label: "",
            value: "",
        },
        paymentMethod: {
            label: "",
            value: "",
        },
        price: "",
        disabled: true,
        isCredit: false, // Indicator for the aggregate button process.
        isNotReported: false,
        disabledCategory: true,
        disabledSubCategory: true,
        disabledPaymentMethod: true,
        listOfPaymentMethod: [],
        listOfExpenseSubCategory: [],
    },
    {
        bankCreditType: {
            label: "",
            value: "",
        },
        headQuarter: {
            label: "",
            value: "",
        },
        conceptDescription: "",
        conceptCategory: {
            label: "",
            value: "",
        },
        subCategories: {
            label: "",
            value: "",
        },
        paymentMethod: {
            label: "",
            value: "",
        },
        price: "",
        disabled: true,
        isCredit: false, // Indicator for the aggregate button process.
        isNotReported: false,
        disabledCategory: true,
        disabledSubCategory: true,
        disabledPaymentMethod: true,
        listOfPaymentMethod: [],
        listOfExpenseSubCategory: [],
    },
    {
        bankCreditType: {
            label: "",
            value: "",
        },
        headQuarter: {
            label: "",
            value: "",
        },
        conceptDescription: "",
        conceptCategory: {
            label: "",
            value: "",
        },
        subCategories: {
            label: "",
            value: "",
        },
        paymentMethod: {
            label: "",
            value: "",
        },
        price: "",
        disabled: true,
        isCredit: false, // Indicator for the aggregate button process.
        isNotReported: false,
        disabledCategory: true,
        disabledSubCategory: true,
        disabledPaymentMethod: true,
        listOfPaymentMethod: [],
        listOfExpenseSubCategory: [],
    },
    {
        bankCreditType: {
            label: "",
            value: "",
        },
        headQuarter: {
            label: "",
            value: "",
        },
        conceptDescription: "",
        conceptCategory: {
            label: "",
            value: "",
        },
        subCategories: {
            label: "",
            value: "",
        },
        paymentMethod: {
            label: "",
            value: "",
        },
        price: "",
        disabled: true,
        isCredit: false, // Indicator for the aggregate button process.
        isNotReported: false,
        disabledCategory: true,
        disabledSubCategory: true,
        disabledPaymentMethod: true,
        listOfPaymentMethod: [],
        listOfExpenseSubCategory: [],
    },
    {
        bankCreditType: {
            label: "",
            value: "",
        },
        headQuarter: {
            label: "",
            value: "",
        },
        conceptDescription: "",
        conceptCategory: {
            label: "",
            value: "",
        },
        subCategories: {
            label: "",
            value: "",
        },
        paymentMethod: {
            label: "",
            value: "",
        },
        price: "",
        disabled: true,
        isCredit: false, // Indicator for the aggregate button process.
        isNotReported: false,
        disabledCategory: true,
        disabledSubCategory: true,
        disabledPaymentMethod: true,
        listOfPaymentMethod: [],
        listOfExpenseSubCategory: [],
    },
];

export const listBanksType = [
    {
        value: '0',
        text: "Pagos",
        label: (<img src={Assets.SharedIcons.icon_money} alt="icon_money" height={20} width={20}/>),
        icon: Assets.SharedIcons.icon_money,
    },
    {
        value: "1",
        text: "Traslados",
        label: (<img src={Assets.SharedIcons.icon_bank} alt="icon_bank" height={20} width={20}/>),
        icon: Assets.SharedIcons.icon_bank,
    }
];

export const listBanksTypeInitial = [
    {
        value: '0',
        text: "Pagos",
        label: (<img src={Assets.SharedIcons.icon_money} alt="icon_money" height={20} width={20}/>),
        icon: Assets.SharedIcons.icon_money,
    },
    {
        value: "1",
        text: "Traslados",
        label: (<img src={Assets.SharedIcons.icon_bank} alt="icon_bank" height={20} width={20}/>),
        icon: Assets.SharedIcons.icon_bank,
    },
    {
        value: "2",
        text: "Pago con tarjeta",
        label: (<img src={Assets.SharedIcons.icon_close_box} alt="icon_close_box" height={20} width={20}/>),
        icon: Assets.SharedIcons.icon_close_box,
    }
];