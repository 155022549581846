
import icon_headquarters from "./icon_headquarters.svg"
import icon_phone from "./icon_phone.svg"
import icon_email from "./icon_email.svg"
import icon_wallet from "./icon_wallet.svg"
import icon_sales from "./icon_sales.svg"
import icon_utils from "./icon_utils.svg"

export const HeadquartersIcons = {
    icon_headquarters,
    icon_phone,
    icon_email,
    icon_wallet,
    icon_sales,
    icon_utils
}