// Assets
import { Assets } from '../../../../../assets';

// Components
import TheyOweUsInventoryComponent from '../they-owe-us-inventory/they-owe-us-inventory.component';
import CreditsOrWeOweInventoryComponent from '../credits-or-we-owe-inventory/credits-or-we-owe-inventory.component';

// Libraries
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NumericFormat } from 'react-number-format';

// Styles
import './general-summary.component.scss';

// Utils
import { formattedNumber } from '../../../../../utils/shared.utils';

const GeneralSummaryComponent = (props) => {

  const {
    // Variables
    totalUtility,
    totalExpenses,
    totalTreasury,
    totalInventory,
    totalSales,
    totalDifferentialIva,
    inventory,
    charges,
    dataTables,
    orderTypes,
    totalTreasuryList,
    totalCredits
  } = props;

  const INITIAL_STATE = {
    weMustTotal: 0,
    theyOweUsTotal: 0
  }

  const [state, setState] = useState(INITIAL_STATE)
  let { weMustTotal, theyOweUsTotal } = state;

  const totalAmount = totalCredits?.providerList?.reduce((acc, Credits) => {
    return acc + (Credits.credit - Credits.payment);
  }, 0);

  useEffect (() => {

    calculateSummary()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ dataTables, orderTypes])
  
  const calculateSummary = () => {
    let weMustData = { total: 0 }
    let provisionsData = { total: 0 }
    let treasuryListTotal = [ ...totalTreasuryList]
    let weMustTotal = 0;
    let theyOweUsTotal = 0;
  
    if (dataTables && orderTypes?.length) {
      const idFilterTreasuryPortfolios = orderTypes[2]
      const idFilterWeMust = orderTypes[3]
      const idFilterProvisions = orderTypes[4]
  
      const treasuryPortfolios = dataTables[idFilterTreasuryPortfolios]
      const weMust = dataTables[idFilterWeMust]
      const provisions = dataTables[idFilterProvisions]
  
      if (treasuryPortfolios) {
        treasuryListTotal = [ ...treasuryListTotal, ...treasuryPortfolios?.data ]
      }
      if(weMust){
        weMustData = { ...weMustData, ...weMust }
      }
      if(provisions){
        provisionsData = { ...provisionsData, ...provisions }
      } 
      if (treasuryListTotal.length > 0) {
        treasuryListTotal.total = treasuryListTotal.reduce((valueAcumulated, item) => valueAcumulated + item.value, 0);
      }
  
      //Final balance totals - we owe, we are owed.
      weMustTotal = inventory?.total + charges?.total + (totalExpenses?.totalExpenses || 0);
      theyOweUsTotal = treasuryListTotal?.total + weMustData?.total + provisionsData?.total + totalAmount + (totalUtility?.totalUtility || 0);
    }

    setState((prevState) => ({
      ...prevState,
      weMustTotal,
      theyOweUsTotal,
    }));
  }

  return (
    <>
      <div className='grid-x justify-content-between'>
        <div className='general-summary__general-summary__table'>
          <div className='grid-x align-middle general-summary__general-summary__title__content'>
            <span className='general-summary__general-summary__title'>Resumen general</span>
          </div>
          <div className='grid-x align-middle general-summary__general-summary__content-box'>
            <div className='grid-x justify-content-center general-summary__general-summary__container'>
              <div className="grid-x general-summary__general-summary__box">
                <img
                  alt="icon_income_graph"
                  src={Assets.SharedIcons.icon_income_graph}
                />
                <span className="general-summary__general-summary__value">
                  {formattedNumber(totalUtility?.totalUtility - totalExpenses?.totalExpenses)}
                </span>
                <span className="general-summary__general-summary__text">Utilidad neta</span>
              </div>
            </div>
            <div className='grid-x justify-content-center general-summary__general-summary__container'>
              <div className="grid-x general-summary__general-summary__box">
                <img
                  alt="icon_income_graph"
                  src={Assets.SharedIcons.icon_income_graph}
                />
                <span className="general-summary__general-summary__value">
                  {formattedNumber(totalUtility?.totalUtility) ?? 0}
                </span>
                <span className="general-summary__general-summary__text">Utilidad bruta</span>
              </div>
            </div>
            <div className='grid-x justify-content-center general-summary__general-summary__container'>
              <div className="grid-x general-summary__general-summary__box">
                <img
                  alt="icon_income_graph"
                  src={Assets.SharedIcons.icon_income_graph}
                />
                <span className="general-summary__general-summary__value">
                  {formattedNumber(totalSales?.totalSales) ?? 0}
                </span>
                <span className="general-summary__general-summary__text">Ventas del mes</span>
              </div>
            </div>
            <div className='grid-x justify-content-center general-summary__general-summary__container'>
              <div className="grid-x general-summary__general-summary__box--mod">
                <img
                  alt="icon_falling_graph"
                  src={Assets.SharedIcons.icon_falling_graph}
                />
                <span className="general-summary__general-summary__value--mod">
                  {formattedNumber(totalDifferentialIva?.totalDifferentialIva) ?? 0}
                </span>
                <span className="general-summary__general-summary__text--mod">Diferencia del IVA</span>
              </div>
            </div>
            <div className='grid-x justify-content-center general-summary__general-summary__container'>
              <div className="grid-x general-summary__general-summary__box--mod">
                <img
                  alt="icon_falling_graph"
                  src={Assets.SharedIcons.icon_falling_graph}
                />
                <span className="general-summary__general-summary__value--mod">
                  {formattedNumber(totalExpenses?.totalExpenses) ?? 0}
                </span>
                <span className="general-summary__general-summary__text--mod">Gastos del mes</span>
              </div>
            </div>
            <div className='grid-x justify-content-center general-summary__general-summary__container'>
              <div className="grid-x general-summary__general-summary__box">
                <img
                  alt="icon_income_graph"
                  src={Assets.SharedIcons.icon_income_graph}
                />
                <span className="general-summary__general-summary__value">
                  {formattedNumber(totalTreasury?.totalTreasury) ?? 0}
                </span>
                <span className="general-summary__general-summary__text">Cartera con tesorería</span>
              </div>
            </div>
            <div className='grid-x justify-content-center general-summary__general-summary__container'>
              <div className="grid-x general-summary__general-summary__box">
                <img
                  alt="icon_income_graph"
                  src={Assets.SharedIcons.icon_income_graph}
                />
                <span className="general-summary__general-summary__value">
                  {formattedNumber(totalInventory?.totalInventory) ?? 0}
                </span>
                <span className="general-summary__general-summary__text">Valor de inventario</span>
              </div>
            </div>
          </div>
        </div>
        <div className='general-summary__final-balance__table'>
          <div className='grid-x align-middle justify-content-end general-summary__final-balance__title__content'>
            <span className='general-summary__final-balance__title'>Cuadre final</span>
          </div>
          <div className='general-summary__final-balance__content-info'>
            <div className='grid-x align-middle justify-content-end general-summary__final-balance__content-label'>
              <span className='general-summary__final-balance__content-label__title'>Nos deben</span>
              <NumericFormat
                value={weMustTotal}
                type='text'
                prefix={"$ "}
                placeholder='$ 0'
                thousandSeparator=","
                className='input-price'
                thousandsGroupStyle="thousand"
                disabled
              />
            </div>
            <div className='grid-x align-middle justify-content-end general-summary__final-balance__content-label'>
              <span className='general-summary__final-balance__content-label__title'>Debemos</span>
              <NumericFormat
                type='text'
                prefix={"$ "}
                placeholder='$ 0'
                thousandSeparator=","
                className='input-price'
                thousandsGroupStyle="thousand"
                disabled
                value={theyOweUsTotal}
              />
            </div>
            <div className='grid-x align-middle justify-content-end general-summary__final-balance__content-label'>
              <span className='general-summary__final-balance__content-label__title'>Sobrante/Faltante</span>
              <NumericFormat
                type='text'
                prefix={"$ "}
                placeholder='$ 0'
                thousandSeparator=","
                className='input-price'
                thousandsGroupStyle="thousand"
                disabled
                value={weMustTotal - theyOweUsTotal}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='grid-x small-12 general-summary__content-tables'>
        <div className='grid-x small-6 align-content-start'>
          <div className='grid-x align-center-middle general-summary__content-title-table'>
            <span className='general-summary__title-table'>NOS DEBEN A NOSOTROS</span>
          </div>
          <TheyOweUsInventoryComponent />
        </div>
        <div className='grid-x small-6 align-right align-content-start'>
          <div className='grid-x align-center-middle general-summary__content-title-table'>
            <span className='general-summary__title-table'>CRÉDITOS O DEBEMOS</span>
          </div>
          <CreditsOrWeOweInventoryComponent />
        </div>
      </div>
    </>
  )
};

const mapStateToProps = ({ HeadquartersReducer }) => {
  const { generalSummary, totalCredits } = HeadquartersReducer;
  
  const {
    totalUtility,
    totalExpenses,
    totalTreasury,
    totalInventory,
    totalSales,
    totalDifferentialIva,
    inventory,
    charges,
    dataTables,
    orderTypes,
  } = generalSummary || {};
  
  const totalTreasuryList = [generalSummary?.portfoliosList]

  return {
      totalUtility,
      totalExpenses,
      totalTreasury,
      totalInventory,
      totalSales,
      totalDifferentialIva,
      inventory,
      charges,
      dataTables,
      orderTypes,
      totalTreasuryList,
      totalCredits
  };
};

export default connect(mapStateToProps, null)(GeneralSummaryComponent);