// Components
import App from './pages/app/App';
import reportWebVitals from './reportWebVitals';

// Libraries
import React from 'react';
import ReactDOM from 'react-dom/client';

// Providers
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next';
import { ConfigProvider } from 'antd';

// Store
import store from './storage/store/configure-store.prod.store'

// Styles
import './index.scss';
import 'foundation-sites/dist/css/foundation.min.css';

// Translations
import string from './shared/translations/I18n'
import esEs from 'antd/locale/es_ES';

// Dayjs- configure
import 'dayjs/locale/es'
import updateLocale from 'dayjs/plugin/updateLocale';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

dayjs.locale('es');
dayjs.extend(updateLocale);
dayjs.updateLocale('es', {
  weekStart: 0
})
dayjs.extend(utc);
dayjs.extend(isSameOrAfter)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={string} >
    <ConfigProvider locale={esEs}>
      <Provider store={store}>
        <App />
      </Provider>
    </ConfigProvider>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
