// Constants
import LocalStorageConst from "../core/constants/local-storage.const"

// Infrastructures
import Http from "./infrastructure/http.infrastructure"
import LocalStorage from "./infrastructure/local-storage.infrastructure"

// Infrastructures - constants
import AuthenticationEndPoints from "./constants/authentication.end-points"

// Models
import UserModel from "../models/user/model/user.model"
import AuthenticationModel from "../models/authentication/model/authentication.model"

/** 
* Begin Block API Services 
**/
export async function loginService(credentials) {

    try {
        const { data:responseLogin, error } = await Http.post(AuthenticationEndPoints.LOGIN,credentials)
        if (responseLogin && responseLogin?.status) {
            
            const userLogged = responseLogin?.data
            const authenticationModel = new AuthenticationModel(userLogged?.token)
            const permissions = userLogged?.permissions
            const user = new UserModel(
                userLogged?.user.name, 
                userLogged?.user.lastName, 
                authenticationModel, 
                permissions
            )
    
            return user
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function logoutService(token) {

    try {
        const {data: responseLogin, error} = await Http.post(AuthenticationEndPoints.LOGOUT, token)
        if (responseLogin && responseLogin.status) {
            const userLogged = responseLogin.data
            return new UserModel(userLogged.name, userLogged.last)
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function validateUserService(user) {
    try {
        const urlWithParams = AuthenticationEndPoints.VALIDATE_USER.replace(":parameter", user)
        const { data: responseValidateUser, error } = await Http.get(urlWithParams, null)
        if (responseValidateUser && responseValidateUser.status) {
            return responseValidateUser.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function validateCodeService(body) {
    try {
        const { data: responseValidateCode, error } = await Http.post(AuthenticationEndPoints.VALIDATE_CODE, body)
        if (responseValidateCode && responseValidateCode.status) {
            return responseValidateCode.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function changePasswordService(body) {
    try {
        const { data: responseChangePassword, error } = await Http.post(AuthenticationEndPoints.CHANGE_PASSWORD, body)
        if (responseChangePassword && responseChangePassword.status) {
            return responseChangePassword.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function validateSessionService(token) {
    try {
        const { data:responseValidateCode, error } = await Http.get(AuthenticationEndPoints.VALIDATE_TOKEN_SESSION,null, token)
        if (responseValidateCode && responseValidateCode.status) {
            return true
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
}

/** 
* End Block API Services 
**/

/** 
* Begin Block LocalStorage Services 
**/
export function setTokenService(token) {
    LocalStorage.set(LocalStorageConst.TOKEN_SESSION, token)
}

export function clearKeysAuthenticationService() {
    LocalStorage.remove(LocalStorageConst.TOKEN_SESSION)
    LocalStorage.remove(LocalStorageConst.PERMISSIONS_USER)
    LocalStorage.remove(LocalStorageConst.LOGGED_USER)
    LocalStorage.remove(LocalStorageConst.MODULE_NAME)
}

export function getTokenService() {
    return LocalStorage.get(LocalStorageConst.TOKEN_SESSION, true)
}

export function getUserPermissionsService() {
    return LocalStorage.get(LocalStorageConst.PERMISSIONS_USER, true)
}

export function setUserPermissionsService(userPermissions) {
    LocalStorage.set(LocalStorageConst.PERMISSIONS_USER, userPermissions)
}

export function getLoggedUserService() {
    return LocalStorage.get(LocalStorageConst.LOGGED_USER, true)
}

export function setLoggedUserService(loggedUser) {
    LocalStorage.set(LocalStorageConst.LOGGED_USER, loggedUser)
}

export function getModuleNameService() {
    return LocalStorage.get(LocalStorageConst.MODULE_NAME, true)
}

export function setModuleNameService(moduleName) {
    LocalStorage.set(LocalStorageConst.MODULE_NAME, moduleName)
}

/** 
* End Block LocalStorage Services 
**/