// Libraries
import React from 'react';

import { Pagination } from 'antd';

// Styles
import './pagination.component.scss';

const PaginationComponent = (props) => {

    const { pageItem = 10, totalItems = 1, page = 1, onPage = () => {} } = props;

    return (
        <Pagination
            current={page}
            total={totalItems}
            pageSize={pageItem}
            defaultCurrent={1}
            onChange={(newPage) => onPage(newPage)}
            showSizeChanger={false}
            className='pagination-component'
        />
    )
}

export default PaginationComponent;