import * as cashClosingWholesaleTypes from './cash-closing-wholesale.types';

const INITIAL_STATE = {
	listExpenses: [],
	shipmentRecords: [],
	liquidationList: [],
	receivedList: [],
	inventoryList: [],
	containerList: [],
	totals: {
		yesterday: {
			coin:0,
			banknote:0
		},
		today: {
			coin:0,
			banknote:0
		}
	},
	totalsOriginal: {
		yesterday: {
			coin:0,
			banknote:0
		},
		today: {
			coin:0,
			banknote:0
		}
	},
	isDeletingRecords: false
};


const CashClosingWholesaleReducer = (state = INITIAL_STATE, action) => {

	switch (action.type) {

		case cashClosingWholesaleTypes.SET_STATE_CLASH_CLOSING_WHOLESALE:

			return {
				...state,
				...action.payload.prop
			};

		case cashClosingWholesaleTypes.SET_ITEM_CLASH_CLOSING_WHOLESALE:
			let newState = {...state }
			newState[action.payload.prop][action.payload.key] = action.payload.value
			return {
				...newState
			};

		case cashClosingWholesaleTypes.RESET_CASH_CLOSING_WHOLESALE:
			return {
				...INITIAL_STATE
			};
		default:
			return state;
	}

};

export default CashClosingWholesaleReducer;