const AlertConst = {
    // Buttons
    CANCEL_OPTION_BUTTON_TEXT: 'Cancel',
    CONFIRM_OPTION_BUTTON_TEXT: 'Confirm',
    
    // Icon
    SUCCESS_ICON_TEXT: 'success',
    ERROR_ICON_TEXT: 'error',
    INFO_ICON_TEXT: 'info',
    WARNING_ICON_TEXT: 'warning',
    QUESTION_ICON_TEXT: 'question',

    // Position
    TOP_POSITION_TEXT: 'top',
    TOP_START_POSITION_TEXT: 'top-start',
    TOP_END_POSITION_TEXT: 'top-end',
    CENTER_POSITION_TEXT: 'center',
    CENTER_START_POSITION_TEXT: 'center-start',
    CENTER_END_POSITION_TEXT: 'center-end',
    BOTTOM_POSITION_TEXT: 'bottom',
    BOTTOM_START_POSITION_TEXT: 'bottom-start',
    BOTTOM_END_POSITION_TEXT: 'bottom-end'

}

export default AlertConst