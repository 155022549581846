//Actions
import { setStateAuthenticationReducer } from '../../../storage/reducers/authentication/authentication.actions';

//Assets
import { Assets } from '../../../assets/index';

// Components - shared
import ErrorAlertComponent from '../../../shared/components/alerts/error-alert/error-alert.component';

//Libraries
import { Progress } from 'antd';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import PinInput from 'react-pin-input';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';

//Services
import { validateCodeService, validateUserService } from '../../../services/authentication.services';

//Styles
import './validate-code.page.scss';

//Utils
import { validateCode } from '../../../utils/validate-recovery-password.utils';

//Values
const timeInitial = 1 * 60;

const ValidateCodePage = (props) => {

  const {
    //Actions
    setStateAuthenticationReducer,
    //Variables
    parameterUser,
    idValidateUser
  } = props;

  let history = useNavigate();

  const { handleChange, errors } = useFormik({
    initialValues: {
      code: "",
    },
    initialErrors: {
      code: "",
    },
    validate: validateCode,
  });

  const pinInputRef = useRef(null);
  const [isError, setIsError] = useState('');
  const [isTimeUp, setIsTimeUp] = useState(false);
  const [timeLeft, setTimeLeft] = useState(timeInitial);
  const [progressPercent, setProgressPercent] = useState(100);
  const [statusProgress, setStatusProgress] = useState('normal');

  useEffect(() => {
    setProgressPercent((timeLeft / timeInitial) * 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft, timeInitial]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);
    if (timeLeft === 0) {
      clearInterval(intervalId);
      setIsTimeUp(true);
      setStatusProgress('exception');
    }
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  const resendCode = async () => {
    try {
      pinInputRef?.current && pinInputRef.current.clear();
      setIsError('');
      handleChange('code')('');
      setTimeLeft(timeInitial);
      setProgressPercent(100);
      setIsTimeUp(false);
      setStatusProgress('normal');
      const resp = await validateUserService(parameterUser);
      setStateAuthenticationReducer('idValidateUser', resp);
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  };

  const processValidateCode = async (code) => {
    try {
      await validateCodeService({ idUser: idValidateUser, code })
      setIsError('');
      setStatusProgress('success');
      history("/newPassword", { replace: true });
    } catch (error) {
      setStatusProgress('exception');
      setIsError(isTimeUp
        ? 'El código que ingresaste ha expirado'
        : 'El código que ingresaste es incorrecto, intenta de nuevo'
      );
      if (error.code === 'AUTH_010') {
        ErrorAlertComponent('Lo sentimos,', ' el código de verificación ingresado es incorrecto. Por favor, verifica el código e inténtalo nuevamente');
      } else {
        ErrorAlertComponent();
      }
    }
  };

  const formatTime = () => {
    const minutes = Math.floor(timeLeft / 60).toString().padStart(2, '0');
    const seconds = (timeLeft % 60).toString().padStart(2, '0');
    if (minutes === '00') {
      return seconds;
    } else {
      return `${minutes}:${seconds}`;
    }
  };

  const validStatus = () => {
    const aux = {
      'normal': {
        color: '#1499F2',
        state: 'normal',
        component:
          <div className='grid-y align-center-middle'>
            <span className='validate-code__ProgressCountDown'>{formatTime()}</span>
            <span className='validate-code__ProgressCountDown'>seg</span>
          </div>
      },
      'success': {
        color: '#0e7d80',
        state: 'success',
        component: <img src={Assets.AuthenticationIcons.icon_check} alt="icon_check" />
      },
      'exception': {
        color: '#f85f61',
        state: 'exception',
        component: <img src={Assets.AuthenticationIcons.icon_error} alt="icon_error" />
      },
    }
    if (isTimeUp && statusProgress !== 'success') return aux['exception'];
    return aux[statusProgress];
  };

  return (
    <div className='grid-x'>
      <div className='cell medium-6 flex-container align-center-middle validate-code'>
        <img
          alt="icon_logo_text"
          src={Assets.SharedIcons.icon_logo_text}
        />
      </div>
      <div className='cell medium-6 validate-code__main-container'>
        <div className='grid-padding-x validate-code__main-container__welcome-text'>
          Bienvenido al portal de Surtilicores, aquí podrás reportar de una manera más efectiva tu labor.
        </div>
        <div className='validate-code__container'>

          <div className='grid-x align-center-middle'>
            <span className='validate-code__title'>
              Recuperar contraseña
            </span>
            <span className='validate-code__text'>
              Escribe el código de 6 dígitos que enviamos a tu correo electrónico para poder recuperar tu contraseña
            </span>
          </div>
          <div className='grid-y small-12 align-center-middle'>
            <span className='validate-code__subtitle'>Código de recuperación</span>
            <div className='validate-code__pinInput__container'>
              <PinInput
                length={6}
                type="numeric"
                ref={pinInputRef}
                inputMode="number"
                style={{ display: 'flex' }}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                onChange={(value) => {
                  handleChange('code')(value)
                  isError && setIsError('');
                }}
                onComplete={(value) => processValidateCode(value)}
                inputStyle={{
                  width: '30px',
                  height: '35px',
                  margin: '0 12px',
                  color: '#265575',
                  background: '#fff',
                  fontWeight: 'bold',
                  borderRadius: '5px',
                  borderWidth: isError ? '2px' : '1px',
                  borderColor: isError ? '#f85f61' : '#214C69',
                }}
                inputFocusStyle={{ borderColor: isError ? '#f85f61' : '#265575' }}
              />
              <span className='validate-code__pinInput__error'>{errors.code || isError}</span>
            </div>
            <Progress
              size={60}
              type="circle"
              strokeWidth={7}
              status={validStatus().state}
              strokeColor={validStatus().color}
              format={() => validStatus().component}
              percent={isTimeUp || statusProgress === 'success' ? 100 : progressPercent}
            />
          </div>
          <div className='grid-x small-12 justify-content-center validate-code__content-button'>
            <span className='validate-code__content-button__question'>¿No recibiste el código?</span>
            <button
              onClick={() => resendCode()}
              className='validate-code__content-button__button'
              disabled={((Object.keys(errors).length === 0) || (statusProgress === 'exception')) ? false : true}
            >
              Reenviar código
            </button>
            <Link to='/validateUser' className='validate-code__content-button__link'>
              Regresar
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
};

const mapStateToProps = ({ AuthenticationReducer }) => {
  const { idValidateUser, parameterUser } = AuthenticationReducer;
  return { idValidateUser, parameterUser };
};

const mapStateToPropsActions = {
  setStateAuthenticationReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(ValidateCodePage);