export const dataProviders = [

    {
        price: "",
        provider: {
            label: "",
            value: ""
        },
        paymentMethods: {
            label: "",
            value: ""
        },
        invoiceNumber: "",
        disabled: true,
    },
    {
        price: "",
        provider: {
            label: "",
            value: ""
        },
        paymentMethods: {
            label: "",
            value: ""
        },
        invoiceNumber: "",
        disabled: true,
    },
    {
        price: "",
        provider: {
            label: "",
            value: ""
        },
        paymentMethods: {
            label: "",
            value: ""
        },
        invoiceNumber: "",
        disabled: true,
    },
    {
        price: "",
        provider: {
            label: "",
            value: ""
        },
        paymentMethods: {
            label: "",
            value: ""
        },
        invoiceNumber: "",
        disabled: true,
    },
    {
        price: "",
        provider: {
            label: "",
            value: ""
        },
        paymentMethods: {
            label: "",
            value: ""
        },
        invoiceNumber: "",
        disabled: true,
    },
    {
        price: "",
        provider: {
            label: "",
            value: ""
        },
        paymentMethods: {
            label: "",
            value: ""
        },
        invoiceNumber: "",
        disabled: true,
    },
    {
        price: "",
        provider: {
            label: "",
            value: ""
        },
        paymentMethods: {
            label: "",
            value: ""
        },
        invoiceNumber: "",
        disabled: true,
    }
]