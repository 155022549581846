//Assets
import { Assets } from '../../../../../../assets';

//Libraries
import React, { useState } from 'react';
import { Checkbox, Dropdown } from 'antd';
import { NumericFormat } from "react-number-format";

//Styles
import './sites-and-pubs.component.scss';

//Utils
import { formattedNumber } from '../../../../../../utils/shared.utils';

const SitesAndPubsComponent = (props) => {

    const {
        // Variables
        headQuartersAndPubs,
        selectedHeadQuarterId,
        // Function
        updateStateGeneralInventory,
        selectHistoryByHeadquarters
    } = props;

    const INITIAL_STATE = {
        filterKey: '',
        isHoverSelected: false
    };

    const [state, setState] = useState(INITIAL_STATE);

    const { filterKey, isHoverSelected } = state;
    
    const items = [
        {
            key: '0',
            label:
                <div className='sites-and-pubs__list-titles__select-sites__checkbox'>
                    <Checkbox
                        style={{ width: '100%' }}
                        checked={filterKey === "0"}
                        onChange={() => handleFilter("0")}
                    >
                        Ordenar ascendente A-Z
                    </Checkbox>
                </div>
        },
        {
            key: '1',
            label:
                <div className='sites-and-pubs__list-titles__select-sites__checkbox'>
                    <Checkbox
                        style={{ width: '100%' }}
                        checked={filterKey === "1"}
                        onChange={() => handleFilter("1")}
                    >
                        Ordenar descendente Z-A
                    </Checkbox>
                </div>
        }
    ];

    const handleFilter = (key) => {
        let sortedHeadQuarters = [...headQuartersAndPubs?.structuredByHeadQuartes];
        switch (key) {
            case '0':
                sortedHeadQuarters.sort((a, b) => a.headQuarterName.localeCompare(b.headQuarterName));
                break;
            case '1':
                sortedHeadQuarters.sort((a, b) => b.headQuarterName.localeCompare(a.headQuarterName));
                break;
            default:
                break;
        };
        setState((prevState) => ({ ...prevState, filterKey: key }));
        updateStateGeneralInventory({
            headQuartersAndPubs: {
                ...headQuartersAndPubs,
                structuredByHeadQuartes: sortedHeadQuarters,
            }
        });
    };

    return (
        <div className='sites-and-pubs__content-box'>
            <div className='sites-and-pubs__content-title'>
                <img
                    alt="icon_sites"
                    src={Assets.SharedIcons.icon_liquor_blue}
                />
                <span className='sites-and-pubs__title'>
                    Sedes y Licoreras
                </span>
            </div>
            <div className='sites-and-pubs__list-titles__content'>
                <div className='sites-and-pubs__list-titles__select-sites'>
                    <Dropdown
                        menu={{ items }}
                        trigger={['click']}
                        className='grid-x small-12'
                    >
                        <div
                            onMouseOver={() => setState((prevState) => ({ ...prevState, isHoverSelected: true }))}
                            onMouseOut={() => setState((prevState) => ({ ...prevState, isHoverSelected: false }))}
                            className='grid-x align-middle sites-and-pubs__list-titles__select-sites__content-selected'
                        >
                            <img
                                alt={isHoverSelected ? "icon_filter_hover" : "icon_filter"}
                                src={isHoverSelected ? Assets.SharedIcons.icon_filter_hover : Assets.SharedIcons.icon_filter}
                            />
                            <span className={`${isHoverSelected ? 'sites-and-pubs__list-titles__select-sites__text__hover' : 'sites-and-pubs__list-titles__select-sites__text'}`}>
                                Nombre de la sede
                            </span>
                        </div>
                    </Dropdown>
                </div>
                <span className='sites-and-pubs__list-titles__title-warehouse'>Bodega</span>
                <span className='sites-and-pubs__list-titles__title-total-inventory'>Total inventario</span>
            </div>
            <div className='sites-and-pubs__content-info'>
                {headQuartersAndPubs?.structuredByHeadQuartes.map((headQuarter, indexHeadQuarter) => {
                    return (
                        <button
                            key={indexHeadQuarter}
                            onClick={() => selectHistoryByHeadquarters(headQuarter.headQuarterId, headQuarter?.storesData)}
                            className={`grid-x sites-and-pubs__info-row${selectedHeadQuarterId === headQuarter.headQuarterId ? '__selected' : ''}`}
                        >
                            <div className='sites-and-pubs__info-row__content__description-site'>
                                <div className='sites-and-pubs__info-row__content__description-site__sub-content'>
                                    <span className='text-left sites-and-pubs__info-row__text-site'>
                                        {headQuarter.headQuarterName}
                                    </span>
                                </div>
                            </div>
                            <div className='grid-y sites-and-pubs__info-row__content__description-warehouse'>
                                {headQuarter.storesData.map((storeNameItem, storeNameIndex) => (
                                    <span key={storeNameIndex} className='text-left sites-and-pubs__info-row__text'>
                                        {storeNameItem.storeName}
                                    </span>
                                ))}
                            </div>
                            <div className='grid-x sites-and-pubs__info-row__content__description-total-inventory'>
                                <div className='grid-y sites-and-pubs__info-row__content__description-total-inventory__content-value'>
                                    {headQuarter.storesData.map((storeValueItem, storeValueIndex) => (
                                        <span key={storeValueIndex} className='sites-and-pubs__info-row__text'>
                                            {formattedNumber(storeValueItem.value)}
                                        </span>
                                    ))}
                                </div>
                                <div className='sites-and-pubs__info-row__content__description-total-inventory__Content-icon'>
                                    <img
                                        alt={selectedHeadQuarterId === headQuarter.headQuarterId ? "icon_arrow" : "icon_blue_arrow"}
                                        src={selectedHeadQuarterId === headQuarter.headQuarterId ? Assets.SharedIcons.icon_arrow : Assets.SharedIcons.icon_blue_arrow}
                                        className={`sites-and-pubs__info-row__content__description-total-inventory__icon${selectedHeadQuarterId === headQuarter.headQuarterId ? '' : '__rotate'}`}
                                    />
                                </div>
                            </div>
                        </button>
                    )
                })}
            </div>
            <div className='grid-x align-center-middle justify-content-end sites-and-pubs__total'>
                <div className='grid-x align-center-middle justify-content-end sites-and-pubs__total__content'>
                    <span className='sites-and-pubs__total__text'>
                        Total general
                    </span>
                    <div className='grid-x align-center-middle sites-and-pubs__total__content-input'>
                        <NumericFormat
                            disabled
                            type='text'
                            prefix={"$ "}
                            placeholder='$ 0'
                            thousandSeparator=","
                            className='input-price'
                            thousandsGroupStyle="thousand"
                            value={formattedNumber(headQuartersAndPubs?.totalInInventory)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SitesAndPubsComponent;