import icon_user from "./icon_user.svg"
import icon_password from "./icon_password.svg"
import icon_clear from "./icon_clear.svg"
import icon_error from "./icon_error.svg"
import icon_check from "./icon_check.svg"

export const AuthenticationIcons = {
    icon_user,
    icon_password,
    icon_clear,
    icon_error,
    icon_check
}