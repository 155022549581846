const CashClosingInventoryEndPoints =  {
    GET_ALL_HEADQUARTERS: `/api/reportCashClosingInventoryControl/findAllCashRegister`,
    GET_HEADQUARTER: `/api/reportCashClosingInventoryControl/findOneCashRegister`,
    UPDATE_CASH_REGISTERS_INITIAL_BALANCE: `/api/reportCashClosingInventoryControl/cashRegisters/initialBalance/:id`,
    UPDATE_CASH_REGISTERS_SALES_TNS: `/api/reportCashClosingInventoryControl/cashRegisters/salesTNSCloud/:id`,
    UPDATE_CASH_REGISTERS_SALES_PF: `/api/reportCashClosingInventoryControl/cashRegisters/salesPF/:id`,
    UPDATE_CASH_REGISTERS_KITCHEN: `/api/reportCashClosingInventoryControl/cashRegisters/kitchen/:id`,
    UPDATE_CASH_REGISTERS_REAL_CASH: `/api/reportCashClosingInventoryControl/closingCashRegister/realCash/:id`,
    UPDATE_CASH_REGISTERS_OBSERVATION: `/api/reportCashClosingInventoryControl/cashRegisters/observation/:id`,
    CREATE_RECEIPT_CARD_READERS: `/api/reportCashClosingInventoryControl/CashReceiptCardReaders/create/:id`,
    UPDATE_RECEIPT_CARD_READERS: `/api/reportCashClosingInventoryControl/cashReceiptCardReaders/update`,
    CREATE_CASH_RETURN: `/api/reportCashClosingInventoryControl/cashExpenseReturns/create/:id`,
    UPDATE_CASH_RETURN: `/api/reportCashClosingInventoryControl/cashExpenseReturns/update`,
    CREATE_CASH_TRANSFERS: `/api/reportCashClosingInventoryControl/cashReceiptMoneyTransfers/create/:id`,
    UPDATE_CASH_TRANSFERS: `/api/reportCashClosingInventoryControl/cashReceiptMoneyTransfers/update`,
    CREATE_CASH_EXPENSE: `/api/reportCashClosingInventoryControl/cashExpense/create/:id`,
    UPDATE_CASH_EXPENSE: `/api/reportCashClosingInventoryControl/cashExpense/update`,
    CREATE_CASH_RECEIPT_PAYMENT_OF_BONDSMEN: `/api/reportCashClosingInventoryControl/cashReceiptPaymentOfBondsmen/create/:id`,
    UPDATE_CASH_RECEIPT_PAYMENT_OF_BONDSMEN: `/api/reportCashClosingInventoryControl/cashReceiptPaymentOfBondsmen/update`,
    CREATE_CASH_EXPENSE_TRUST: `/api/reportCashClosingInventoryControl/cashExpenseTrust/create/:id`,
    UPDATE_CASH_EXPENSE_TRUST: `/api/reportCashClosingInventoryControl/cashExpenseTrust/update`,
    UPDATE_CASH_RECEIPT_BOTTLE_CONTROL: `/api/reportCashClosingInventoryControl/cashReceiptBottlesControl/update`,
    CREATE_CASH_EXPENSE_PROVIDER: `/api/reportCashClosingInventoryControl/cashExpenseProvider/create/:id`,
    UPDATE_CASH_EXPENSE_PROVIDER: `/api/reportCashClosingInventoryControl/cashExpenseProvider/update`,
    CREATE_INTERNAL_CONSUMPTION: `/api/reportCashClosingInventoryControl/domesticConsumptions/create/:id`,
    UPDATE_INTERNAL_CONSUMPTION: `/api/reportCashClosingInventoryControl/domesticConsumptions/update/`,
    SAVE_CLOSING_CASH_REGISTER: `/api/reportCashClosingInventoryControl/closingCashRegister/save/:id`,
    PUBLISH_CLOSING_CASH_REGISTER: `/api/reportCashClosingInventoryControl/closingCashRegister/publish/:id`,
    DELETED_RECORD_DATAPHONES_TABLE: `/api/reportCashClosingInventoryControl/cashReceiptCardReader`,
    DELETED_RECORD_REFUNDS_TABLE: `/api/reportCashClosingInventoryControl/cashExpenseReturns`,
    DELETED_RECORD_TRANSFERS_TABLE: `/api/reportCashClosingInventoryControl/cashReceiptMoneyTransfer`,
    DELETED_RECORD_EXPENSES_TABLE: `/api/reportCashClosingInventoryControl/cashExpense`,
    DELETED_RECORD_PAYMENT_OF_BONDSMEN_TABLE: `/api/reportCashClosingInventoryControl/cashReceiptPaymentOfBondsmen`,
    DELETED_RECORD_LOANS_TABLE: `/api/reportCashClosingInventoryControl/cashExpenseTrust`,
    DELETED_RECORD_PROVIDERS_TABLE: `/api/reportCashClosingInventoryControl/cashExpenseProvider`,
    DELETED_RECORD_INTERNAL_CONSUMPTION_TABLE: `/api/reportCashClosingInventoryControl/domesticConsumption`,
}

export default CashClosingInventoryEndPoints