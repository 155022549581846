// Assets
import { Assets } from '../../../../../assets';

// Libraries
import React, { useState } from 'react'
import { Input } from 'antd';
import { NumericFormat } from 'react-number-format';
import { connect } from 'react-redux';
import { setStateHeadquartersReducer } from '../../../../../storage/reducers/headquarters/headquarters.actions';

// Styles
import './they-owe-us-inventory.component.scss';

const TheyOweUsInventoryComponent = (props) => {

    const {
        // Actions
        setStateHeadquartersReducer,
        // Variables
        inventory,
        actualCash,
        bankBalance,
        charges,
        changesGeneralSummary
    } = props;

    const INITIAL_STATE = {
        isCollapsedInventory: false,
        isCollapsedAuthorized: false
    };

    const [state, setState] = useState(INITIAL_STATE);

    const { isCollapsedInventory, isCollapsedAuthorized } = state;

    const onChanges = (data, field, target) => {
        let value = data && data.target ? data.target.value || '' : data;
        value = value.trimStart()
        let changesReducer = changesGeneralSummary ? [...changesGeneralSummary] : []
        let newChanges = {}

        if (target === "inventory") {
            const existingObjectIndex = changesReducer.findIndex(x => x.idConcept === inventory?.id);
            if (field === "value") {
                value = parseFloat(value.replace(/[^0-9.]/g, '')) || 0
            }
        
            if (existingObjectIndex !== -1) {
                changesReducer[existingObjectIndex] = {
                    ...changesReducer[existingObjectIndex],
                    [field]: value
                };
            } else {
                newChanges = {
                    idConcept: inventory?.id,
                    [field]: value
                };
                changesReducer.push(newChanges);
            }
        }

        if(target === "actualCash"){

            const existingObjectIndex = changesReducer.findIndex(x => x.idConcept === actualCash?.idConcept);
            value = parseFloat(value.replace(/[^0-9.]/g, '')) || 0

            if (existingObjectIndex !== -1) {
                changesReducer[existingObjectIndex] = {
                    ...changesReducer[existingObjectIndex],
                    [field]: value
                };
            } else {
                newChanges = {
                    ...actualCash,
                    [field]: value
                };
                changesReducer.push(newChanges);
            }

        }

        if(target === "bankBalance"){

            const existingObjectIndex = changesReducer.findIndex(x => x.idConcept === bankBalance?.idConcept);
            value = parseFloat(value.replace(/[^0-9.]/g, '')) || 0

            if (existingObjectIndex !== -1) {
                changesReducer[existingObjectIndex] = {
                    ...changesReducer[existingObjectIndex],
                    [field]: value
                };
            } else {
                newChanges = {
                    ...bankBalance,
                    [field]: value
                };
                changesReducer.push(newChanges);
            }
        }

        setStateHeadquartersReducer("changesGeneralSummary", changesReducer)
    };

    return (
        <div className='they-owe-us-inventory__container'>
            {/* TODO: is hidden by customer request as this initial value should be in the creation of the site.  */}
            {/* <div className='grid-x align-middle justify-content-between they-owe-us-inventory__initial-investment__content'>
                <span className='they-owe-us-inventory__initial-investment__title'>Inversión inicial</span>
                <NumericFormat
                    value={inventory?.initialBalance ? inventory?.initialBalance : 0}
                    type='text'
                    prefix={"$ "}
                    placeholder='$ 0'
                    thousandSeparator=","
                    className='input-price'
                    thousandsGroupStyle="thousand"
                    disabled
                />
            </div> */}
            <div className='they-owe-us-inventory__table'>
                <div className='grid-x align-middle justify-content-between they-owe-us-inventory__table__title__content'>
                    <span className='they-owe-us-inventory__table__title'>Inventario en sede</span>
                    <button
                        onClick={() => setState({ ...state, isCollapsedInventory: !isCollapsedInventory })}
                    >
                        <img
                            alt='icon_blue_arrow_up'
                            src={Assets.SharedIcons.icon_blue_arrow_up}
                            className={`they-owe-us-inventory__table__icon ${isCollapsedInventory ? 'rotate' : ''}`}
                        />
                    </button>
                </div>
                <div className='they-owe-us-inventory__table__box'>
                    <div className={`they-owe-us-inventory__table__content-info ${isCollapsedInventory ? 'collapsed' : ''}`}>
                        <div className='grid-x align-middle justify-content-between they-owe-us-inventory__table__subtitle__content'>
                            <span className='they-owe-us-inventory__table__subtitle'>Concepto</span>
                            <span className='they-owe-us-inventory__table__subtitle'>Valor</span>
                        </div>
                        <div className='they-owe-us-inventory__table__content-info'>
                            {inventory?.totalByHeadquarters?.map((inventoryHeadquarter, index) => {
                                return (
                                    <div key={index} className='grid-x align-middle justify-content-between they-owe-us-inventory__table__add__content'>
                                        <Input
                                            disabled
                                            prefix={<></>}
                                            value={inventoryHeadquarter?.description}
                                            className='they-owe-us-inventory__table__add__input'
                                        />
                                        <div className='they-owe-us-inventory__table__add__inputs-price__disabled'>
                                            <NumericFormat
                                                disabled
                                                type='text'
                                                prefix={"$ "}
                                                placeholder='$ 0'
                                                thousandSeparator=","
                                                className='input-price'
                                                thousandsGroupStyle="thousand"
                                                value={inventoryHeadquarter?.value}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className='grid-x align-middle justify-content-between they-owe-us-inventory__table__add__content'>
                        <Input
                            value={changesGeneralSummary?.find(item => item?.idConcept === inventory?.id)?.description || ""}
                            placeholder='Descripción'
                            onChange={(e) => onChanges(e,"description","inventory")}
                            className='they-owe-us-inventory__table__add__input'
                            prefix={<img src={Assets.SharedIcons.icon_add} alt="icon_add" />}
                        />
                        <div className='grid-x they-owe-us-inventory__table__add__inputs-price'>
                            <NumericFormat
                                value={changesGeneralSummary?.find(item => item?.idConcept === inventory?.id)?.value || ""}
                                type='text'
                                prefix={"$ "}
                                placeholder='$ 0'
                                thousandSeparator=","
                                className='input-price'
                                thousandsGroupStyle="thousand"
                                onChange={(e) => onChanges(e,"value","inventory")}
                            />
                            <div className='they-owe-us-inventory__table__add__inputs-price__icon'>
                                <img
                                    alt="icon_add"
                                    src={Assets.SharedIcons.icon_add}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='grid-x align-middle justify-content-between they-owe-us-inventory__table__total__content'>
                        <div className='grid-x align-middle justify-content-end they-owe-us-inventory__table__total__text__content'>
                            <span className='they-owe-us-inventory__table__total__text'>Total</span>
                        </div>
                        <div className='they-owe-us-inventory__table__total__content-value'>
                            <NumericFormat
                                value={inventory?.total ? inventory?.total : 0}
                                disabled
                                type='text'
                                prefix={"$ "}
                                placeholder='$ 0'
                                thousandSeparator=","
                                className='input-price'
                                thousandsGroupStyle="thousand"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='grid-x align-middle justify-content-between they-owe-us-inventory__real-cash-content'>
                <div className='they-owe-us-inventory__real-cash-content__title__content'>
                    <span className='they-owe-us-inventory__real-cash-content__title'>Escribir efectivo real</span>
                </div>
                <div className='they-owe-us-inventory__real-cash-content__input'>
                    <NumericFormat
                        type='text'
                        prefix={"$ "}
                        placeholder='$ 0'
                        thousandSeparator=","
                        className='input-price'
                        thousandsGroupStyle="thousand"
                        value={actualCash ? actualCash?.value : 0}
                        onChange={(e) => onChanges(e,"value","actualCash")}
                    />
                </div>
            </div>
            <div className='grid-x align-middle justify-content-between they-owe-us-inventory__real-cash-content'>
                <div className='they-owe-us-inventory__real-cash-content__title__content'>
                    <span className='they-owe-us-inventory__real-cash-content__title'>Saldo bancario</span>
                </div>
                <div className='they-owe-us-inventory__real-cash-content__input'>
                    <NumericFormat
                        type='text'
                        prefix={"$ "}
                        placeholder='$ 0'
                        thousandSeparator=","
                        className='input-price'
                        thousandsGroupStyle="thousand"
                        value={bankBalance ? bankBalance?.value : 0}
                        onChange={(e) => onChanges(e,"value","bankBalance")}
                    />
                </div>
            </div>


            <div className='they-owe-us-inventory__table'>
                <div className='grid-x align-middle justify-content-between they-owe-us-inventory__table__title__content'>
                    <span className='they-owe-us-inventory__table__title'>Autorizados para fiados</span>
                    <button
                        onClick={() => setState({ ...state, isCollapsedAuthorized: !isCollapsedAuthorized })}
                    >
                        <img
                            alt='icon_blue_arrow_up'
                            src={Assets.SharedIcons.icon_blue_arrow_up}
                            className={`they-owe-us-inventory__table__icon ${isCollapsedAuthorized ? 'rotate' : ''}`}
                        />
                    </button>
                </div>
                <div className='they-owe-us-inventory__table__box'>
                    <div className={`they-owe-us-inventory__table__add__parentContainer ${isCollapsedAuthorized ? 'collapsed' : ''}`}>
                        <div className='grid-x align-middle justify-content-between they-owe-us-inventory__table__subtitle__content'>
                            <span className='they-owe-us-inventory__table__subtitle'>Nombre</span>
                            <span className='they-owe-us-inventory__table__subtitle'>Valor</span>
                        </div>
                        <div className='they-owe-us-inventory__table__add__parentContainer'>
                            {charges?.totalByCharge
                                ?.filter(item => item.total !== 0)
                                .map((item, index) => (
                                    <div key={index} className='grid-x align-middle justify-content-between they-owe-us-inventory__table__add__content'>
                                        <Input
                                            disabled
                                            prefix={<></>}
                                            value={item?.charge || ""}
                                            className='they-owe-us-inventory__table__add__input'
                                        />
                                        <div className='they-owe-us-inventory__table__add__inputs-price__disabled'>
                                            <NumericFormat
                                                disabled
                                                type='text'
                                                value={item?.total || 0}
                                                prefix={"$ "}
                                                placeholder='$ 0'
                                                thousandSeparator=","
                                                className='input-price'
                                                thousandsGroupStyle="thousand"
                                            />
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className='grid-x align-middle justify-content-between they-owe-us-inventory__table__total__content'>
                        <div className='grid-x align-middle justify-content-end they-owe-us-inventory__table__total__text__content'>
                            <span className='they-owe-us-inventory__table__total__text'>Total</span>
                        </div>
                        <div className='they-owe-us-inventory__table__total__content-value'>
                            <NumericFormat
                                disabled
                                type='text'
                                prefix={"$ "}
                                placeholder='$ 0'
                                thousandSeparator=","
                                className='input-price'
                                thousandsGroupStyle="thousand"
                                value={charges?.total || 0}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = ({ HeadquartersReducer }) => {
    const { generalSummary, changesGeneralSummary } = HeadquartersReducer;
    
    const orderTypes = generalSummary?.orderTypes;
    const dataTables = generalSummary?.dataTables;
    let inventory = generalSummary?.inventory;
    const charges = generalSummary?.charges;

    let actualCash = { value: 0 };
    let bankBalance = { value: 0 };

    if (orderTypes && dataTables &&  orderTypes?.length) {
        const idFilter = orderTypes[1]
        const dataFilter = dataTables[idFilter]?.data
        if(dataFilter?.length){
            actualCash = {
                ...dataFilter[0]
            }
            bankBalance = {
                ...dataFilter[1]
            }
        }
    }

    return {
        inventory,
        actualCash,
        bankBalance,
        charges,
        changesGeneralSummary
    };
};

const mapStateToPropsActions = {
    setStateHeadquartersReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(TheyOweUsInventoryComponent);