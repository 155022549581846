// Libraries
import * as Yup from 'yup';

export const authorizedSchema = Yup.object().shape({
    name: Yup.string()
        .required('Requerido'),
    documentNumber: Yup.string()
        .matches(/^[0-9]+$/, 'No es un número')
        .min(4, 'Mínimo 6 dígitos')
        .max(11, 'Máximo 10 dígitos')
        .required('Requerido'),
    telephone: Yup.string()
        .matches(/^[0-9]+$/, 'No es un número')
        .length(10, 'Número no valido')
        .required('Requerido'),
    permittedBalance: Yup.number()
        .required('Requerido'),
})