//Actions
import { setStateCashClosingWholesaleReducer } from '../../../../storage/reducers/cash-closing-wholesale/cash-closing-wholesale.actions';

// Assets
import { Assets } from '../../../../assets'

// Components
import DailyPayrollsComponent from '../daily-payrolls/daily-payrolls.component';
import LiquidationComponent from '../liquidation/liquidation.component';
import ReceiptsComponent from '../receipts/receipts.component';
import InventoryComponent from '../inventory/inventory.component';
import PackagesComponent from '../packages/packages.component';

// Components - shared
import ErrorAlertComponent from '../../../../shared/components/alerts/error-alert/error-alert.component';
import SuccessAlertComponent from '../../../../shared/components/alerts/success-alert/success-alert.component';
import InformationAlertComponent from '../../../../shared/components/alerts/information-alert/information-alert.component';

// Libraries
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';

//Services
import { 
  createLiquidationWholesalerService, getAllDriverService, getAllLiquidationWholesalerService, 
  getAllSellerService, getInventoryService, getTypeOfWholesaleSetService, updateInventoryService, updateLiquidationService, updateLiquidationWholesalerService 
} from '../../../../services/cash-closing-wholesale.services';

// Styles
import './record-keepers.component.scss'

//Utils
import { spreadsheets } from '../../../../utils/cash-closing-wholesale.utils';

const RecordKeepersComponent = (props) => {

  const {
    //Actions
    setStateCashClosingWholesaleReducer,
    //Functions
    setStateData=() => null,
    //Variables
    locationState,
    daySelected,
    isSave,
    liquidationList,
    isDeletingRecords
  } = props


  const INITIAL_STATE = {
    hoverIndex: null,
    storeWholesaler:[],
    tabSelected: 0,
    liquidationTemplate: [],
    driversList: [],
    sellersList: [],
    liquidationTemplateCopy: [],
    collection: [],
    collectionCopy: [],
    inventoryList: [],
    inventoryListCopy: [],
    containers: [],
    containersCopy: []
  }

  const [state, setState] = useState(INITIAL_STATE);

  const {
    hoverIndex, storeWholesaler, tabSelected,
    liquidationTemplate, driversList, sellersList,
    liquidationTemplateCopy, collection, collectionCopy,
    inventoryList, inventoryListCopy, containers, containersCopy
  } = state;

  useEffect(() => {
    if (isSave) {
      saveDataTabs()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSave]); 

  useEffect(() => {
    allServices()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daySelected]);

  useEffect(() => {
    if (isDeletingRecords) {
      setState({
        ...state,
        liquidationTemplate: liquidationList
      })
      setStateCashClosingWholesaleReducer({ isDeletingRecords: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeletingRecords]);

  const validatePropsHoverTab = (index) => {
    let alt, src, typeText;

    if (hoverIndex === index) {
        alt = 'icon_spreadsheet_active';
        src = Assets.SharedIcons.icon_spreadsheet_active;
        typeText = '__hover';
    } else if (tabSelected.type === index) {
        alt = 'icon_spreadsheet_active';
        src = Assets.SharedIcons.icon_spreadsheet_hover;
        typeText = '__active';
    } else {
        alt = 'icon_spreadsheet_inactive';
        src = Assets.SharedIcons.icon_spreadsheet_inactive;
        typeText = '__inactive';
    }

    return { alt, src, typeText };
  };

  const allServices = async() => {
    try {
      const responseServices = await Promise.allSettled([
        getAllDriverService(),
        getAllSellerService(),
        getTypeOfWholesaleSetService(locationState?.id),
        getAllLiquidationWholesalerService(locationState?.id, dayjs(daySelected).format('YYYY-MM-DD')),
      ])
      let errors = false
      let respServices = {}
      let totals ={
        yesterday: {
          coin:0,
          banknote:0
        },
        today: {
          coin:0,
          banknote:0
        }
      }
  
      for (let index = 0; index < responseServices?.length; index++) {
        const elementResponse = responseServices[index];
        if (elementResponse.status === "fulfilled") {
            switch (index) {
              case 0:
                respServices.driversList = elementResponse?.value?.map(element => ({...element, value: element.id, label: element.name}))
                break;
              case 1:
                respServices.sellersList = elementResponse?.value?.map(element => ({...element, value: element.id, label: element.name}))
                break;
              case 2:
                const transformedStoreWholesaler = elementResponse?.value.map(obj => {
                  const capitalized = obj.name.charAt(0).toUpperCase() + obj.name.slice(1).toLowerCase();
                  return { ...obj, name: capitalized };
                });
                respServices.storeWholesaler = transformedStoreWholesaler.sort((a, b) => a.type - b.type);
                respServices.tabSelected = tabSelected ||  respServices?.storeWholesaler[0];

                let typeStoreWholesalerInventory = respServices?.storeWholesaler.find(item => item?.type === 3)
                let typeStoreWholesalerContainers = respServices?.storeWholesaler.find(item => item?.type === 4)

                if(typeStoreWholesalerInventory){
                  let respGetInventory = await getInventoryService(locationState?.id, dayjs(daySelected).format('YYYY-MM-DD'),1)
                  respServices.inventoryList = respGetInventory.map(
                    (element)=>({
                      id: element.product.id,
                      code: element.product.code,  
                      nameProduct: element.product.name, 
                      amount: element.amountHistory.amount, 
                      liquid: Number(element.productHistory.liquid || 0), 
                      package: Number(element.productHistory.container || 0),
                      total: 0
                    })
                  )
                  respServices.inventoryListCopy = respServices?.inventoryList && JSON.parse(JSON.stringify(respServices?.inventoryList))
                }

                if(typeStoreWholesalerContainers){
                  let respGetContainers = await getInventoryService(locationState?.id, dayjs(daySelected).format('YYYY-MM-DD'),0)

                  respServices.containers = respGetContainers.map(
                    (element)=>({
                      id: element.product.id,
                      reference: element.product.name,  
                      price: Number(element.productHistory.container || 0), 
                      amount: element.amountHistory.amount, 
                      total: 0
                    })
                  )
                  respServices.containersCopy = respServices?.containers && JSON.parse(JSON.stringify(respServices?.containers))
                }

                break;
              case 3:
                const dataTabs = elementResponse?.value?.liquidationTemplate
                const dataTabsCopy = elementResponse?.value?.liquidationTemplate

                totals = {
                  yesterday: elementResponse?.value?.closingCashRegisterWholesalerBefore,
                  today: elementResponse?.value?.closingCashRegisterWholesaler
                }

                let idLiquidation = respServices?.storeWholesaler.find(item => item?.type === 0)?.id

                respServices.liquidationTemplate = dataTabs?.filter(item => item?.idTypeLiquidation === idLiquidation).map(element => ({
                    ...element,
                    driverLiquidationTemplate: {
                      label: element.driverLiquidationTemplate?.name,
                      value: element.driverLiquidationTemplate?.id
                    },
                    personLiquidationTemplate: {
                      label: element.personLiquidationTemplate?.name,
                      value: element.personLiquidationTemplate?.id
                    }
                }))
                
                let idCollection = respServices?.storeWholesaler.find(item => item?.type === 2)?.id

                respServices.collection = dataTabsCopy?.filter(item => item?.idTypeLiquidation === idCollection).map(element => ({
                    ...element,
                    driverLiquidationTemplate: {
                      label: element.driverLiquidationTemplate?.name,
                      value: element.driverLiquidationTemplate?.id
                    },
                    personLiquidationTemplate: {
                      label: element.personLiquidationTemplate?.name,
                      value: element.personLiquidationTemplate?.id
                    }
                }))

                const spreadsheetsData = JSON.parse(JSON.stringify(spreadsheets))
                const spreadsheetsDataCollection = JSON.parse(JSON.stringify(spreadsheetsData))
                if (respServices?.liquidationTemplate?.length < spreadsheetsDataCollection?.length) {
                  const resp = respServices?.liquidationTemplate?.concat(spreadsheetsDataCollection.slice(0, (spreadsheetsDataCollection.length - respServices?.liquidationTemplate?.length)))
                  respServices.liquidationTemplate = resp
                }
                respServices.liquidationTemplateCopy = respServices?.liquidationTemplate && JSON.parse(JSON.stringify(respServices?.liquidationTemplate))

                if (respServices?.collection?.length < spreadsheetsData?.length) {
                  const resp = respServices?.collection?.concat(spreadsheetsData.slice(0, (spreadsheetsData.length - respServices?.collection?.length)))
                  respServices.collection = resp
                }
                respServices.collectionCopy = respServices?.collection && JSON.parse(JSON.stringify(respServices?.collection))
                break;
              default:
              break;
            }
        }else{
          errors = true
        }
  
        if (errors) {
          ErrorAlertComponent()
        }

        setStateCashClosingWholesaleReducer({
          liquidationList: respServices.liquidationTemplate,
          receivedList: respServices.collection,
          inventoryList: respServices.inventoryList || [],
          containerList: respServices.containers || [],
          totals: totals,
          totalsOriginal: JSON.parse(JSON.stringify(totals)),
        })

        setState((prevState) => ({
          ...prevState,
          ...respServices
        }))
      
      };
    
    } catch (error) {
      ErrorAlertComponent()
    }

  }

  const saveDataTabs = async () => {
    try {
      let incompleteInformation = false
      let servicesAllPromise = []

      if(storeWholesaler.find(element => element.type === 0)){

        const liquidationFilter = liquidationTemplate?.filter(liquidation => !liquidation.disabled).map(
          (liquidationMap) => {
            let liquidationStructure = {
              idPersonPreSale: liquidationMap?.personLiquidationTemplate?.value,
              priceTemplate: liquidationMap?.priceTemplate,
              codeTemplate: liquidationMap?.codeTemplate
            }
  
            if (liquidationMap?.creditNote) {
              liquidationStructure.creditNote = liquidationMap?.creditNote
            }
  
            if (liquidationMap?.id) {
              liquidationStructure.id = liquidationMap.id
            }
            return (liquidationStructure)
          }
        );
  
        const liquidationFilterOriginal = liquidationTemplateCopy?.filter(liquidation => !liquidation.disabled).map(
          (liquidationMap) => {
            let liquidationStructure = {
              idPersonPreSale: liquidationMap?.personLiquidationTemplate?.value,
              priceTemplate: liquidationMap?.priceTemplate,
              codeTemplate: liquidationMap?.codeTemplate
            }
  
            if (liquidationMap?.creditNote) {
              liquidationStructure.creditNote = liquidationMap?.creditNote
            }
  
            if (liquidationMap?.id) {
              liquidationStructure.id = liquidationMap.id
            }
            return (liquidationStructure)
          }
        );
        
        const createLiquidationList = liquidationFilter?.filter(liquidation => !liquidation.id)
        const listUpdateModified = liquidationFilter?.filter(liquidation => liquidation.id)
        const listUpdateOriginal = liquidationFilterOriginal?.filter(liquidation => liquidation.id)
        const updateLiquidationList = compareArraysOfObjects(listUpdateOriginal,listUpdateModified)
  
        incompleteInformation = liquidationFilter?.every(liquidation => {
          return liquidation?.idPersonPreSale?.trim() !== '' && liquidation?.codeTemplate > 0 && liquidation?.priceTemplate > 0
        })

        if (!incompleteInformation) {
          InformationAlertComponent(undefined, "Faltan campos por completar")
          return
        }
  
        createLiquidationList?.length > 0 && servicesAllPromise.push(createLiquidationWholesalerService(locationState?.id, dayjs(daySelected).format('YYYY-MM-DD'), tabSelected?.id, { liquidationTemplate: createLiquidationList }))
        updateLiquidationList?.length > 0 && servicesAllPromise.push(updateLiquidationWholesalerService({ liquidationTemplate: updateLiquidationList }))
      }

      if(storeWholesaler.find(element => element.type === 1)){

        const liquidationFilter = liquidationTemplate?.filter(liquidation => !liquidation.disabled).map(
          (liquidationMap) => {
            let liquidationStructure = {}
  
            if (liquidationMap?.driverLiquidationTemplate?.value) {
              liquidationStructure.idTruckDriver = liquidationMap?.driverLiquidationTemplate?.value
            }

            if (liquidationMap?.codeLiquidation) {
              liquidationStructure.codeLiquidation = liquidationMap?.codeLiquidation
            }

            if (liquidationMap?.banknote) {
              liquidationStructure.banknote = liquidationMap?.banknote
            }

            if (liquidationMap?.coin) {
              liquidationStructure.coin = liquidationMap?.coin
            }
  
            if (liquidationMap?.id) {
              liquidationStructure.id = liquidationMap.id
            }

            return (liquidationStructure)
          }
        );
  
        const liquidationFilterOriginal = liquidationTemplateCopy?.filter(liquidation => !liquidation.disabled).map(
          (liquidationMap) => {
            let liquidationStructure = {}
  
            if (liquidationMap?.driverLiquidationTemplate) {
              liquidationStructure.idTruckDriver = liquidationMap?.driverLiquidationTemplate?.value
            }

            if (liquidationMap?.codeLiquidation) {
              liquidationStructure.codeLiquidation = liquidationMap?.codeLiquidation
            }

            if (liquidationMap?.banknote) {
              liquidationStructure.banknote = liquidationMap?.banknote
            }

            if (liquidationMap?.coin) {
              liquidationStructure.coin = liquidationMap?.coin
            }
  
            if (liquidationMap?.id) {
              liquidationStructure.id = liquidationMap.id
            }

            return (liquidationStructure)
          }
        );

        const updateLiquidationList = compareArraysOfObjects(liquidationFilterOriginal,liquidationFilter)

        updateLiquidationList?.length > 0 && servicesAllPromise.push(updateLiquidationService({ liquidationTemplate: updateLiquidationList }))
      }

      if(storeWholesaler.find(element => element.type === 2)){
        
        const receiptFilter = collection?.filter(receipt => !receipt.disabled).map(
          (receiptMap) => {
            let receiptStructure = {}

            if (receiptMap?.creditNote) {
              receiptStructure.creditNote = receiptMap?.creditNote
            }

            if (receiptMap?.codeTemplate) {
              receiptStructure.codeTemplate = receiptMap?.codeTemplate
            }

            if (receiptMap?.priceTemplate) {
              receiptStructure.priceTemplate = receiptMap?.priceTemplate
            }

            if (receiptMap?.driverLiquidationTemplate?.value) {
              receiptStructure.idTruckDriver = receiptMap?.driverLiquidationTemplate?.value
            }

            if (receiptMap?.banknote) {
              receiptStructure.banknote = receiptMap?.banknote
            }

            if (receiptMap?.coin) {
              receiptStructure.coin = receiptMap?.coin
            }

            if (receiptMap?.id) {
              receiptStructure.id = receiptMap.id
            }
            return (receiptStructure)
          }
        );
  
        const receiptFilterOriginal = collectionCopy?.filter(receipt => !receipt.disabled).map(
          (receiptMap) => {
            let receiptStructure = {
              creditNote: receiptMap?.creditNote,
              codeTemplate: receiptMap?.codeTemplate,
              priceTemplate: receiptMap?.priceTemplate,
              idTruckDriver: receiptMap?.driverLiquidationTemplate?.value,
              banknote: receiptMap?.banknote,
              coin: receiptMap?.coin
            }

            if (receiptMap?.id) {
              receiptStructure.id = receiptMap.id
            }
            return (receiptStructure)
          }
        );

        const listUpdateModified = receiptFilter?.filter(receipt => receipt.id)
        const listUpdateOriginal = receiptFilterOriginal?.filter(receipt => receipt.id)
        
        const updateLiquidationData = compareArraysOfObjects(listUpdateOriginal, listUpdateModified).map(
          (receiptMap) =>{
          let receiptStructure = {}

          if (receiptMap?.codeTemplate) {
            receiptStructure.codeTemplate = receiptMap?.codeTemplate
          }

          if (receiptMap?.idTruckDriver) {
            receiptStructure.idTruckDriver = receiptMap?.idTruckDriver
          }

          if (receiptMap?.banknote) {
            receiptStructure.banknote = receiptMap?.banknote
          }

          if (receiptMap?.coin) {
            receiptStructure.coin = receiptMap?.coin
          }

          if (receiptMap?.id) {
            receiptStructure.id = receiptMap?.id
          }

          if (receiptMap?.coin) {
            receiptStructure.coin = receiptMap?.coin
          }

          return (receiptStructure)
        })

        updateLiquidationData?.length > 0 && servicesAllPromise.push(updateLiquidationService({ liquidationTemplate: updateLiquidationData }))
        
        const createReceiptList = receiptFilter?.filter(receipt => !receipt.id).map( element => {
          return {
            priceTemplate: element?.priceTemplate || 0,
            codeTemplate: element?.codeTemplate,
            creditNote: element?.creditNote || 0,
          }
        })

        let dataComplete = createReceiptList.find(element => !element.codeTemplate)

        if (dataComplete) {
          InformationAlertComponent()
          return
        }

        const newCollections = await createCollections(createReceiptList)
        if (newCollections.find(object => object.id === null)) {
          ErrorAlertComponent(undefined,"No fue posible crear el registro. Verifica el numero de liquidación y vuelve a intentarlo.")
          return
        }
        
        let newReceiptFilter = collection.map(object => {
          const updatedObject = newCollections.find(obj => obj?.codeTemplate === object?.codeTemplate);
          if (updatedObject) {
            const { codeTemplate, ...objectFilter } = object;
            return { ...objectFilter, id: updatedObject.id };
          }
          return null
        }).filter(object => object !== null);

        let updateLiquidationList = [];
        if(receiptFilterOriginal.length > 0){
          updateLiquidationList = compareArraysOfObjects(receiptFilterOriginal,newReceiptFilter).map(
            (receiptMap) =>{
            let receiptStructure = {}
  
            if (receiptMap?.driverLiquidationTemplate?.value) {
              receiptStructure.idTruckDriver = receiptMap?.driverLiquidationTemplate?.value
            }
  
            if (receiptMap?.banknote) {
              receiptStructure.banknote = receiptMap?.banknote
            }
  
            if (receiptMap?.coin) {
              receiptStructure.coin = receiptMap?.coin
            }
  
            if (receiptMap?.id) {
              receiptStructure.id = receiptMap?.id
            }
  
            if (receiptMap?.coin) {
              receiptStructure.coin = receiptMap?.coin
            }
  
            return (receiptStructure)
          })
        }else{
          updateLiquidationList = newReceiptFilter.map(
            (receiptMap) =>{
            let receiptStructure = {}
  
            if (receiptMap?.driverLiquidationTemplate?.value) {
              receiptStructure.idTruckDriver = receiptMap?.driverLiquidationTemplate?.value
            }
  
            if (receiptMap?.banknote) {
              receiptStructure.banknote = receiptMap?.banknote
            }
  
            if (receiptMap?.coin) {
              receiptStructure.coin = receiptMap?.coin
            }
  
            if (receiptMap?.id) {
              receiptStructure.id = receiptMap?.id
            }
  
            if (receiptMap?.coin) {
              receiptStructure.coin = receiptMap?.coin
            }
  
            return (receiptStructure)
          })
        }
        updateLiquidationList?.length > 0 && servicesAllPromise.push(updateLiquidationService({ liquidationTemplate: updateLiquidationList }))
      }

      if (storeWholesaler.find(element => element.type === 3)) {
        const inventoryFilter = inventoryList?.map(
          (inventoryMap) => {
            let inventoryStructure = {}

            if (inventoryMap?.liquid) {
              inventoryStructure.liquid = inventoryMap?.liquid
            }

            if (inventoryMap?.amount) {
              inventoryStructure.amount = inventoryMap?.amount
            }

            if (inventoryMap?.package) {
              inventoryStructure.container = inventoryMap?.package
            }

            if (inventoryMap?.id) {
              inventoryStructure.idProduct = inventoryMap.id
            }
            return (inventoryStructure)
          }
        );
  
        const inventoryFilterOriginal = inventoryListCopy?.map(
          (inventoryMap) => {
            let inventoryStructure = {}

            if (inventoryMap?.liquid) {
              inventoryStructure.liquid = inventoryMap?.liquid
            }

            if (inventoryMap?.amount) {
              inventoryStructure.amount = inventoryMap?.amount
            }

            if (inventoryMap?.package) {
              inventoryStructure.container = inventoryMap?.package
            }

            if (inventoryMap?.id) {
              inventoryStructure.idProduct = inventoryMap.id
            }
            return (inventoryStructure)
          }
        );

        const updateInventoryData = compareObjectsArray(inventoryFilter, inventoryFilterOriginal)
        updateInventoryData?.length > 0 && servicesAllPromise.push(updateInventoryService(locationState?.id, dayjs(daySelected).format('YYYY-MM-DD'), { inventoryWholesaler: updateInventoryData }))
      }

      if (storeWholesaler.find(element => element.type === 4)) {
        const containersFilter = containers?.map(
          (containersMap) => {
            let containersStructure = {}

            if (containersMap?.price) {
              containersStructure.liquid = containersMap?.price
            }

            if (containersMap?.amount) {
              containersStructure.amount = containersMap?.amount
            }

            if (containersMap?.package) {
              containersStructure.container = containersMap?.package
            }

            if (containersMap?.id) {
              containersStructure.idProduct = containersMap.id
            }
            return (containersStructure)
          }
        );
  
        const containersFilterOriginal = containersCopy?.map(
          (containersMap) => {
            let containersStructure = {}

            if (containersMap?.price) {
              containersStructure.liquid = containersMap?.price
            }

            if (containersMap?.amount) {
              containersStructure.amount = containersMap?.amount
            }

            if (containersMap?.package) {
              containersStructure.container = containersMap?.package
            }

            if (containersMap?.id) {
              containersStructure.idProduct = containersMap.id
            }
            return (containersStructure)
          }
        );

        const updateContainerData = compareObjectsArray(containersFilter, containersFilterOriginal)
        updateContainerData?.length > 0 && servicesAllPromise.push(updateInventoryService( locationState?.id, dayjs(daySelected).format('YYYY-MM-DD'), { inventoryWholesaler: updateContainerData }))
      }

      if (servicesAllPromise.length > 0) {
        const responseServices = await Promise.allSettled(servicesAllPromise)

        let errorServices = responseServices.find(item => item?.status === "rejected")

        if (!errorServices && responseServices.find(item => item?.value.length > 0)) {
          responseServices.filter(item => item?.value.length > 0).map(element => {
            if(element?.value.find(item => item === null)){
              errorServices = true
            }
            return element
          })
        }

        if (errorServices) {
          ErrorAlertComponent(undefined, "Algunos registros no fueron guardados")
          await allServices()
        } else {
          SuccessAlertComponent()
          await allServices()
        }
      } else {
        InformationAlertComponent()
        await allServices()
      }

    } catch (error) {
      ErrorAlertComponent()
    } finally {
      setStateData({
        isSaveData: false
      })
    }
  }
  
  const compareArraysOfObjects = (array1, array2) => {
    const compareObjects = (object1, object2) => {
      for (let property in object1) {
        if (object1[property] !== object2[property]) {
          return false;
        }
      }
      return true;
    };
  
    const differentInArray2 = array2.filter(object2 =>
      !array1.some(object1 => compareObjects(object1, object2))
    );
  
    return differentInArray2;
  };

  const compareObjectsArray = (array1, array2) => {
    const modifiedObjects = [];
  
    for (let i = 0; i < array1.length; i++) {
        const object1 = array1[i];
        const object2 = array2[i];
        const changes = { idProduct: object1?.idProduct };
  
        for (const key in object1) {
            if (object1.hasOwnProperty(key) && !object2.hasOwnProperty(key)) {
                changes[key] =  object1[key];
            }
        }
  
        for (const key in object1) {
            if (object1.hasOwnProperty(key) && object2.hasOwnProperty(key)) {
                const value1 = object1[key];
                const value2 = object2[key];
  
                if (value1 !== value2) {
                    changes[key] =  value1;
                }
            }
        }
  
        if (Object.keys(changes).length > 1) {
            modifiedObjects.push(changes);
        }
    }
  
    return modifiedObjects;
  };

  const createCollections = async (createReceiptList) => {
    try {
      const respCreate = await createLiquidationWholesalerService(locationState?.id, dayjs(daySelected).format('YYYY-MM-DD'), tabSelected?.id, { liquidationTemplate: createReceiptList })
      createReceiptList.forEach((objectWithoutId, index) => {
        objectWithoutId.id = respCreate[index];
      });
      return createReceiptList
    }catch (error) {
      ErrorAlertComponent()
    }
  }

  return (
    <div className='record-keepers__container'>
      <div className='grid-x justify-content-between record-keepers__Secondary-container'>
        <div className= 'record-keepers__Secondary-container__content-buttons'>
          {storeWholesaler.map((tab, index) => {
            return (
              <button
                key={index}
                onMouseOver={() => setState({ ...state, hoverIndex: tab?.type })}
                onMouseOut={() => setState({ ...state, hoverIndex: null })}
                onClick={() => setState({ ...state, tabSelected: tab })}
                className={`record-keepers__content-options__buttons${tabSelected.type === tab.type ? '__active' : ''}`}
              >
                <img
                  alt={validatePropsHoverTab(tab?.type).alt}
                  src={validatePropsHoverTab(tab?.type).src}
                  className='record-keepers__content-options__icon-button'
                />
                <span className={`record-keepers__content-options__buttons__text${validatePropsHoverTab(tab?.type).typeText}`}>
                  {tab.name}
                </span>
              </button>
            )
          })}
        </div>
      </div>
      <div className='record-keepers__content-info'>
        {tabSelected?.type === 0 && <DailyPayrollsComponent listTable={liquidationTemplate} sellersList={sellersList} />}
        {tabSelected?.type === 1 && <LiquidationComponent listTable={liquidationTemplate} driversList={driversList} />}
        {tabSelected?.type === 2 && <ReceiptsComponent listTable={collection} driversList={driversList}/>}
        {tabSelected?.type === 3 && <InventoryComponent listTable={inventoryList} />}
        {tabSelected?.type === 4 && <PackagesComponent listTable={containers}/>}
      </div>
    </div>
  )

};

const mapStateToProps = ({ CashClosingWholesaleReducer }) => {

  const { liquidationList, isDeletingRecords } = CashClosingWholesaleReducer;

  return {
    liquidationList,
    isDeletingRecords
  };
};


const mapStateToPropsActions = {
  setStateCashClosingWholesaleReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(RecordKeepersComponent);