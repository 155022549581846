//Assets
import { Assets } from '../../../../assets';

//Libraries
import React, { useEffect, useState } from 'react'
import { Drawer, Collapse } from 'antd';
import PropTypes from 'prop-types'; 
import dayjs from 'dayjs';

//Services
import { getDailyTransactionCashClosingService } from '../../../../services/daily-movements.services';

//Styles
import "./cash-closing-treasury.component.scss"

//Utils
import { formattedNumber } from '../../../../utils/shared.utils';

const CashClosingTreasuryComponent = ({ open, onClose, date }) => {

    const INITIAL_STATE = {
        dailyCashClosing: {
            dailyCashOutflow: [],
            dailyCashReceived: [],
            previousBalance: 0,
            totalDailyCashOutflow: 0,
            totalDailyCashReceived: 0,
            totalFinalCash: 0,
        }
    }

    const [visible, setVisible] = useState(open);
    const [state, setState] = useState(INITIAL_STATE);
    const { dailyCashClosing } = state;

    useEffect (() => {
        getDailyTransactionCashClosing(dayjs(date).format('YYYY-MM-DD'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const closeModalClosing = () => {
        setVisible(false); 
        setTimeout(() => {
            onClose();
        }, 200); 
    };

    const getDailyTransactionCashClosing = async (dateFormat) => {
        try {
            const response = await getDailyTransactionCashClosingService(dateFormat);
            if(response) {
                setState((prevState) => ({
                    ...prevState,
                    dailyCashClosing: 
                    {
                        dailyCashOutflow: response?.dailyCashOutflow,
                        dailyCashReceived: response?.dailyCashReceived,
                        previousBalance: response?.previousBalance,
                        totalDailyCashOutflow: response?.totalDailyCashOutflow,
                        totalDailyCashReceived: response?.totalDailyCashReceived,
                        totalFinalCash: (response?.previousBalance) + response?.totalDailyCashReceived - response?.totalDailyCashOutflow
                    },
                }));
            }
        } catch (error) {

            // TODO: Implement error alert with code error.
        }
    };
    
    const dataCashReceived = [
        {
            key: '1',
            label: 
                <>
                    <img
                        src={Assets.SharedIcons.icon_income}
                        alt="icon_income"
                        height={"23px"}
                        width={"23px"}
                        className='cash-closing-treasury__icon-income' /> Efectivo Recibido
                </>,
            children:
                <div>
                    <div className='grid-x align-center-middle justify-content-between cash-closing-treasury__content-cash-headquearter'>
                        <div className='cash-closing-treasury__text-headquarter'>Saldo del día anterior</div>
                        <div className='cash-closing-treasury__cash-headquarter'>
                            {formattedNumber(dailyCashClosing?.previousBalance)}
                        </div>
                    </div>
                    {dailyCashClosing?.dailyCashReceived.map((headquarter, index) => (
                        <div key={index} className='grid-x align-center-middle justify-content-between cash-closing-treasury__content-cash-headquearter'>
                            <div className='grid-x align-center-middle cash-closing-treasury__text-headquarter'>
                                <img
                                alt="icon_liquor"
                                src={Assets.SharedIcons.icon_liquor_blue}
                                />
                                {headquarter?.name}
                            </div>
                            <div className='cash-closing-treasury__cash-headquarter'>
                                {formattedNumber(headquarter?.cashReceived)}
                            </div>
                        </div>
                    ))}
                </div>
            ,
        },
    ];

    const dataCashOutflows = [
        {
            key: '1',
            label: 
                <>
                    <img
                        src={Assets.SharedIcons.icon_egress}
                        alt="icon_egress"
                        height={"23px"}
                        width={"23px"}
                        className='cash-closing-treasury__icon_egress'
                    /> Pagos o salidas de efectivo
                </>,
            children:
                <div>
                    {dailyCashClosing?.dailyCashOutflow.map((headquarter, index) => (
                        <div key={index} className='grid-x align-center-middle justify-content-between cash-closing-treasury__content-cash-headquearter'>
                            <div className='grid-x align-center-middle cash-closing-treasury__text-headquarter'>
                                <span>{headquarter?.concept}</span>
                            </div>
                            <div className='cash-closing-treasury__cash-headquarter'>
                                {formattedNumber(headquarter?.cashOutflow)}
                            </div>
                        </div>
                    ))}
                </div>
            ,
        },
    ];

    const expandIcon = ({ isActive }) => (
        isActive ? 
            <img
                alt="icon_disabled_arrow"
                src={Assets.SharedIcons.icon_blue_arrow_up}
            /> : 
            <img
                alt="icon_disabled_arrow"
                src={Assets.SharedIcons.icon_blue_arrow_down}
            /> 
    );

    const closeIcon = (
        <img
            src={Assets.SharedIcons.icon_close_blue}
            alt="close_icon"
        />
    );

    return (
        <div className='cash-closing-treasury text-center'>
            <Drawer
                title={
                    <div className='cash-closing-treasury__title text-center'>Cierre de caja diario (Efectivo)</div>
                }
                placement="right"
                onClose={closeModalClosing}
                open={visible}
                className='cash-closing-treasury'
                width={400}
                closeIcon={closeIcon}
                rootStyle={{ 
                    backdropFilter: 'blur(10px)', 
                    width: `calc(100vw - 200px)`,
                    left: '200px',
                    height: `calc(100vh - 60px)`,
                    top: '60px',
                    zIndex: 1
                }}
            >
                <div className='cash-closing-treasury__content-final-cash text-center'>
                    <div className='cash-closing-treasury__text'>Efectivo Final</div>
                    <div className='cash-closing-treasury__title'>
                        {formattedNumber(dailyCashClosing?.totalFinalCash)}
                    </div>
                    <div className='grid-x cash-closing-treasury__container-totalizer'>
                        <div className='grid-x small-6 cash-closing-treasury__container-cash-received'>
                            <img
                                src={Assets.SharedIcons.icon_income}
                                alt="icon_income"
                                height={"23px"}
                                width={"23px"}
                                className='cash-closing-treasury__icon-income'
                            />
                            <div className='cash-closing-treasury__content-cash-received text-left'>
                                <div className='cash-closing-treasury__cash-received '>Efectivo recibido</div>
                                <div className='cash-closing-treasury__currency-received'>
                                    {formattedNumber(dailyCashClosing?.previousBalance + dailyCashClosing?.totalDailyCashReceived)}
                                </div>
                            </div>
                        </div>
                        <div className='grid-x small-6 align-center cash-closing-treasury__container-cash-outflows'>
                            <img
                                src={Assets.SharedIcons.icon_egress}
                                alt="icon_egress"
                                height={"23px"}
                                width={"23px"}
                                className='cash-closing-treasury__icon_egress'
                            />
                            <div className='cash-closing-treasury__content-cash-outflows text-left'>
                                <div className='cash-closing-treasury__cash-outflows'>Salidas de efectivo</div>
                                <div className='cash-closing-treasury__currency-outflows'>
                                    {formattedNumber(dailyCashClosing?.totalDailyCashOutflow)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='cash-closing-treasury__subtitle text-center'>
                    Detalle del movimiento diario
                </div>
                <div className='cash-closing-treasury__container-collapse'>
                    <Collapse 
                        items={dataCashReceived}
                        defaultActiveKey={['1']}
                        expandIconPosition='end'
                        expandIcon={expandIcon}
                    />
                    <div className='grid-x align-right cash-closing-treasury__container-total'>
                        <div className='grid-x align-center-middle cash-closing-treasury__cash-headquarter'>Total:</div>
                        <div className='cash-closing-treasury__total-cash-received justify-content-between'>
                            <div className='cash-closing-treasury__content-icon-price'>
                                =
                            </div>
                            <div className='grid-x align-center-middle justify-content-end cash-closing-treasury__input-price'>
                                {formattedNumber(dailyCashClosing?.previousBalance + dailyCashClosing?.totalDailyCashReceived)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='cash-closing-treasury__container-collapse'>
                    <Collapse 
                        items={dataCashOutflows}
                        defaultActiveKey={['1']}
                        expandIconPosition='end'
                        expandIcon={expandIcon}
                    />
                    <div className='grid-x align-right cash-closing-treasury__container-total'>
                        <div className='grid-x align-center-middle cash-closing-treasury__cash-headquarter'>Total:</div>
                        <div className='cash-closing-treasury__total-cash-received justify-content-between'>
                            <div className='cash-closing-treasury__content-icon-price'>
                                =
                            </div>
                            <div className='grid-x align-center-middle justify-content-end cash-closing-treasury__input-price'>
                                {formattedNumber(dailyCashClosing?.totalDailyCashOutflow)}
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

CashClosingTreasuryComponent.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    date: PropTypes.instanceOf(dayjs).isRequired 
};

export default CashClosingTreasuryComponent