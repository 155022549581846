import * as wholesaleFinalBalanceTypes from './wholesale-final-balance.types';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

const INITIAL_STATE = {
    tabList: [
        {
            name: 'Resumen General',
            icon: 'icon_summary'
        }
    ],
    indexTabSelected: 0,
    listsTableInformation: [],
    listsTableInformationCopy: [],
    isGeneralSummary: false,
    dateSelected: dayjs().locale('es'),
    daySelected: dayjs().locale('es'),
    isSave: false,
    isGetAllTabs: false,
    isNewAttribute : false,
    itemTabSelected: {},
    isLoading: true
};

const WholesaleFinalBalanceReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case wholesaleFinalBalanceTypes.SET_STATE_WHOLESALE_FINAL_BALANCE:

            return {
                ...state,
                [action.payload.prop]: action.payload.value
            };

        case wholesaleFinalBalanceTypes.SET_MULTIPLE_STATES_WHOLESALE_FINAL_BALANCE:

            return {
                ...state,
                ...action.payload.prop
            };

        case wholesaleFinalBalanceTypes.RESET_WHOLESALE_FINAL_BALANCE:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }

};

export default WholesaleFinalBalanceReducer;