// Assets
import { Assets } from "../../assets";

// Components
import GeneralInventoryComponent from "./components/general-inventory/general-inventory.component";
import TransfersComponent from "./components/transfers/transfers.component";
import UtilitiesComponent from "./components/utilities/utilities.component";
import ErrorAlertComponent from "../../shared/components/alerts/error-alert/error-alert.component";
import SuccessAlertComponent from "../../shared/components/alerts/success-alert/success-alert.component";

// Libraries
import { Modal } from "antd";
import React, { useState } from "react";

// Services
import { getDownloadInventoryService, getDownloadTransferService, getDownloadUtilitiesService } from "../../services/inventory.services";

// Styles
import "./reports.page.scss";

const ReportsPage = () => {
  
  const INITIAL_STATE = {
    hoverIndex: null,
    optionSelected: 0,
    isUpdated: false,
    isTransfers: false,
    isViewModalUpdate: false,
    isGeneralInventory: false,
    isHoverButtonDownload: false,
    isHoverButtonDownloadModal: false,
    isUtilities: false,
    lastUpdate: null
  };

  const [state, setState] = useState(INITIAL_STATE);

  const {
    isUpdated,
    hoverIndex,
    isTransfers,
    optionSelected,
    isViewModalUpdate,
    isGeneralInventory,
    isHoverButtonDownload,
    isHoverButtonDownloadModal,
    isUtilities,
    lastUpdate
  } = state;

  const tabs = [
    {
      label: "Inventario general",
      iconAlt: {
        active: "icon_inventory_control",
        inactive: "icon_inventory_control_inactive",
        hover: "icon_inventory_control_active",
      },
      iconSrc: {
        active: Assets.SharedIcons.icon_inventory_control,
        inactive: Assets.SharedIcons.icon_inventory_control_inactive,
        hover: Assets.SharedIcons.icon_inventory_control_active,
      },
    },
    {
      label: "Traslados",
      iconAlt: {
        active: "transfers_selected",
        inactive: "transfers_selected_inactive",
        hover: "transfers_selected_active",
      },
      iconSrc: {
        active: Assets.SharedIcons.transfers_selected,
        inactive: Assets.SharedIcons.transfers_selected_inactive,
        hover: Assets.SharedIcons.transfers_selected_active,
      },
    },
    {
      label: "Utilidades",
      iconAlt: {
        active: "icon_projections_selected",
        inactive: "icon_projections_inactive",
        hover: "icon_projections_active",
      },
      iconSrc: {
        active: Assets.SharedIcons.icon_projections_selected,
        inactive: Assets.SharedIcons.icon_projections_inactive,
        hover: Assets.SharedIcons.icon_projections_active,
      },
    },
  ];

  const validatePropsHoverTab = (index, typeIcon, typeAlt) => {
    let alt, src, typeText;

    if (hoverIndex === index) {
      alt = typeAlt.hover;
      src = typeIcon.hover;
      typeText = "__hover";
    } else if (optionSelected === index) {
      alt = typeAlt.active;
      src = typeIcon.active;
      typeText = "__active";
    } else {
      alt = typeAlt.inactive;
      src = typeIcon.inactive;
      typeText = "__inactive";
    }

    return { alt, src, typeText };
  };

  const showButtons = (tab) => {
    switch (tab) {
      case 1:
        if (isTransfers) {
          return true;
        }
        return false;
      case 2:
        if (isUtilities) {
          return true;
        }
        return false;
      default:
        if (isGeneralInventory) {
          return true;
        };
        return false;
    }
  };

  const validateTitleUpdate = (tabSelected) => {
    const title = {
      0: "inventario actual",
      1: "Traslados",
      2: "Utilidades",
    }
    return title[tabSelected];
  };

  const downloadFile = async (value) => {
    if (optionSelected === 0) {
      try {
        await getDownloadInventoryService();
        SuccessAlertComponent('', 'Se ha descargado el archivo con éxito.')
        setState({ ...state, isViewModalUpdate: false });
      } catch (error) {
        ErrorAlertComponent();
      }
    };
    if (optionSelected === 1) {
      try {
        await getDownloadTransferService();
        SuccessAlertComponent(undefined, 'Se ha descargado el archivo con éxito.')
        setState({ ...state, isViewModalUpdate: (value || false )});
      } catch (error) {
        ErrorAlertComponent();
      }
    };
    if (optionSelected === 2) {
      try {
        await getDownloadUtilitiesService();
        SuccessAlertComponent(undefined, 'Se ha descargado el archivo con éxito.')
        setState({ ...state, isViewModalUpdate: (value || false )});
      } catch (error) {
        ErrorAlertComponent();
      }
    }
  };

  return (
    <div className="reports__container">
      {isUpdated
        ? 
          (<div className="reports__update__content">
            <button
              className="grid-x align-middle"
              onClick={() => setState({ ...state, isUpdated: false })}
            >
              <img
                alt="icon_arrow"
                src={Assets.SharedIcons.icon_arrow}
                className="reports__update__icon"
              />
              <span className="reports__update__text">{`Volver a ${validateTitleUpdate(optionSelected)}`}</span>
            </button>
          </div>) 
        : 
          (<div className="grid-x align-middle justify-content-between reports__content-options">
            <div className="grid-x align-middle reports__content-options__container">
              <div className="grid-x align-middle justify-content-between reports__content-options__content-buttons">
                {tabs.map((tab, index) => {
                  return (
                    <button
                      key={index}
                      onClick={() => {
                        if (optionSelected !== index) {
                          setState({
                            ...state,
                            isUtilities: false,
                            isTransfers: false,
                            optionSelected: index,
                            isGeneralInventory: false,
                            lastUpdate: null
                          });
                        }
                      }}
                      onMouseOver={() => setState({ ...state, hoverIndex: index })}
                      onMouseOut={() => setState({ ...state, hoverIndex: null })}
                      className={`reports__content-options__buttons${optionSelected === index ? "__active" : ""}`}
                    >
                      <img
                        className="reports__content-options__icon-button"
                        alt={validatePropsHoverTab(index, tab.iconSrc, tab.iconAlt).alt}
                        src={validatePropsHoverTab(index, tab.iconSrc, tab.iconAlt).src}
                      />
                      <span className={`reports__content-options__buttons__text${validatePropsHoverTab(index, tab.iconSrc, tab.iconAlt).typeText}`}>
                        {tab.label}
                      </span>
                    </button>
                  );
                })}
              </div>
              {showButtons(optionSelected) && (
                <div className="grid-x align-middle reports__content-options__content-date">
                  <div className="grid-x align-middle">
                    <img
                      alt="icon_calendar"
                      src={Assets.SharedIcons.icon_calendar}
                    />
                    <div className="grid-y reports__content-options__content-date__content-text">
                      <span className="reports__content-options__content-date__text">
                        Última actualización:
                      </span>
                      <strong className="reports__content-options__content-date__description">
                        {lastUpdate}
                      </strong>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {showButtons(optionSelected) && (
              <div className="grid-x align-middle">
                <button
                  onClick={() => downloadFile()}
                  className="reports__content-options__button-save"
                  onMouseOver={() => setState({ ...state, isHoverButtonDownload: true })}
                  onMouseOut={() => setState({...state, isHoverButtonDownload: false })}
                >
                  <img
                    className="reports__content-options__icon-button"
                    alt={isHoverButtonDownload ? "icon_download_blue_hover" : "icon_download_blue"}
                    src={isHoverButtonDownload ? Assets.SharedIcons.icon_download_blue_hover : Assets.SharedIcons.icon_download_blue}
                  />
                  <span>Descargar { optionSelected === 0 ? "Inventario" : validateTitleUpdate(optionSelected)}</span>
                </button>
                <button
                  className="reports__content-options__button-publish"
                  onClick={() => setState({ ...state, isViewModalUpdate: true })}
                >
                  <img
                    alt="icon_update"
                    src={Assets.SharedIcons.icon_update}
                    className="reports__content-options__icon-button"
                  />
                  <span>Actualizar { optionSelected === 0 ? "Inventario" : validateTitleUpdate(optionSelected)}</span>
                </button>
              </div>
            )}
          </div>)
      }
      <div className="reports__content-info">
        {optionSelected === 0 && (
          <GeneralInventoryComponent
            isUpdated={isUpdated}
            isUpdateStateReports={(newState) => setState((prevState) => ({ ...prevState, ...newState }))}
          />
        )}
        {optionSelected === 1 && (
          <TransfersComponent
            isUpdated={isUpdated}
            onChangeTransfer={(newState) => setState({ ...state, ...newState })}
          />
        )}
        {optionSelected === 2 && (
          <UtilitiesComponent
            isUpdated={isUpdated}
            isUpdateStateReports={(newState) => setState((prevState) => ({ ...prevState, ...newState }))}
          />
        )}
      </div>
      {isViewModalUpdate &&
        (<Modal
          centered
          width={500}
          destroyOnClose
          closeIcon={null}
          open={isViewModalUpdate}
          onCancel={() => setState({ ...state, isViewModalUpdate: false })}
          footer={
            <div className='grid-x justify-content-center reports__modal__content-footer'>
              <button
                key="cancel"
                className='reports__modal__button__cancel'
                onClick={() => setState({ ...state, isViewModalUpdate: false })}
              >
                <span>Cancelar</span>
              </button>
              <button
                key="accept"
                className='grid-x align-center-middle reports__modal__button__confirm'
                onClick={() => setState({ ...state, isViewModalUpdate: false, isUpdated: true })}
              >
                <span>Continuar</span>
                <img
                  alt="icon_white_arrow"
                  src={Assets.SharedIcons.icon_white_arrow}
                  className='reports__modal__button__confirm__icon'
                />
              </button>
            </div>
          }
        >
          <div className='grid-x align-center-middle reports__modal__content'>
            <div className='grid-x align-center-middle reports__modal__content-title'>
              <span className='reports__modal__title'>Estas a punto de actualizar el { optionSelected === 0 ? "Inventario" : optionSelected === 1 ? "Traslado" : "Utilidades" }</span>
            </div>
            <div className='grid-y align-center-middle reports__modal__content-text'>
              <span className='reports__modal__text'>
                La información que vez actualmente será reemplazada por los datos nuevos que se adjuntaron, solo conservamos los costos por unidad de cada producto.
              </span>
              <span className='reports__modal__text__second'>
                De ser necesario puedes descargar el inventario actual en un formato .xlsx y archivarlo localmente.
              </span>
            </div>
            <div className='grid-x align-center-middle reports__modal__content-button-download'>
              <button
                onClick={() => downloadFile(true)}
                className='reports__modal__button-download'
                onMouseOver={() => setState({ ...state, isHoverButtonDownloadModal: true })}
                onMouseOut={() => setState({ ...state, isHoverButtonDownloadModal: false })}
              >
                <img
                  className='reports__modal__button-download__icon'
                  alt={isHoverButtonDownloadModal ? "con_download_blue_hover" : "con_download_blue"}
                  src={isHoverButtonDownloadModal ? Assets.SharedIcons.icon_download_blue_hover : Assets.SharedIcons.icon_download_blue}
                />
                <span>Descargar { optionSelected === 0 ? "Inventario" : validateTitleUpdate(optionSelected)}</span>
              </button>
            </div>
            <div className='grid-x align-center-middle reports__modal__content-icon'>
              <img
                alt="icon_inventory_illustration"
                src={Assets.SharedIcons.icon_inventory_illustration}
              />
            </div>
          </div>
        </Modal>)
      }
    </div>
  );
};

export default ReportsPage;
