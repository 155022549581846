// Assets
import { Assets } from '../../../../../../assets';

// Components
import FileUploadUtilComponent from '../file-upload/file-upload-util.component';
import ErrorAlertComponent from '../../../../../../shared/components/alerts/error-alert/error-alert.component';
import ListOfUnidentifiedPrefixesComponent from '../list-of-unidentified-prefixes/list-of-unidentified-prefixes.component';
import InformationAlertComponent from '../../../../../../shared/components/alerts/information-alert/information-alert.component';

// Libraries
import { Modal } from 'antd';
import React, { useState } from 'react';

// Services
import {
  extractSalesReportsCloudService,
  extractSalesReportsColdPointService,
  extractUtilityCloudService,
  extractUtilityColdPointService,
  getInvoicingProfitService,
  getSalesReportsPFService,
  getSalesReportsService,
  getUtilityReportsPFService
} from '../../../../../../services/inventory.services';

// Styles
import './file-upload-to-utilities.component.scss';

const FileUploadToUtilitiesComponent = (props) => {

  const {
    // Props
    updateStateUtilities,
    getListOfTnsValuations
  } = props;

  const INITIAL_STATE = {
    isViewModal: false,
    isDiscardAll: false,
    excelBillingFile: null,
    excelBillingFilePF: null,
    excelSalesReportsFile: null,
    excelSalesReportsFilePF: null,
    statusProgressBilling: 'normal',
    statusProgressBillingPF: 'normal',
    statusProgressSalesReports: 'normal',
    statusProgressSalesReportsPF: 'normal',
    indexOfList: null,
    lastIndexWithInfo: null,
    listOfUnidentified: [],
    checkedItems: [],
    listCheckedOld: [],
  };

  const [state, setState] = useState(INITIAL_STATE);

  const {
    indexOfList,
    isViewModal,
    isDiscardAll,
    excelBillingFile,
    excelBillingFilePF,
    excelSalesReportsFile,
    excelSalesReportsFilePF,
    statusProgressBilling,
    statusProgressBillingPF,
    statusProgressSalesReports,
    statusProgressSalesReportsPF,
    lastIndexWithInfo,
    listOfUnidentified,
    listCheckedOld,
    checkedItems
  } = state;

  const renderInfoAlertInformation = (params) => {
    const renderInfo = {
      'INVY_019': excelSalesReportsFile?.name,
      'INVY_018': excelBillingFile?.name,
      'INVY_020': excelSalesReportsFilePF?.name,
      'INVY_021': excelBillingFilePF?.name
    };
    return renderInfo[params];
  }

  const extractAndValidateFile = async (file, extractionFunctionService) => {
    if (file) {
      return await extractionFunctionService({ excel: file.originFileObj });
    }
    return null;
  };

  const validateFileProcess = async () => {
    try {
      let listOfUnidentified = [];
      updateStateUtilities({ isLoading: true })

      const respExtractSalesReportsCloudService = await extractAndValidateFile(excelSalesReportsFile, extractSalesReportsCloudService)
      listOfUnidentified.push(respExtractSalesReportsCloudService)

      const respExtractUtilityCloudService = await extractAndValidateFile(excelBillingFile, extractUtilityCloudService)
      listOfUnidentified.push(respExtractUtilityCloudService)

      const respExtractSalesReportsColdPointService = await extractAndValidateFile(excelSalesReportsFilePF, extractSalesReportsColdPointService)
      listOfUnidentified.push(respExtractSalesReportsColdPointService)

      const respExtractUtilityColdPointService = await extractAndValidateFile(excelBillingFilePF, extractUtilityColdPointService)
      listOfUnidentified.push(respExtractUtilityColdPointService)

      const hasInfo = listOfUnidentified.some(item => item !== null);
      if (hasInfo) {        
        const indexOfList = listOfUnidentified.findIndex(item => item !== null);
        const lastIndexWithInfo = listOfUnidentified.reduce((lastIndex, item, currentIndex) => (item !== null ? currentIndex : lastIndex), -1);
        setState((prevState) => ({
          ...prevState,
          indexOfList,
          lastIndexWithInfo,
          isViewModal: true,
          listOfUnidentified
        }));
        updateStateUtilities({ isLoading: false });
      } else {
        getListOfTnsValuations();
      }
    } catch (error) {
      setTimeout(() => {
        updateStateUtilities({ isLoading: false });
        if (error.code === 'INVY_019' || error.code === 'INVY_018' || error.code === 'INVY_020' || error.code === 'INVY_021') {
          InformationAlertComponent('Oops!', `No existen registros en el archivo de ${renderInfoAlertInformation(error.code)}`);
        } else {
          ErrorAlertComponent();
        }
      }, 500);
    }
  };

  const isItemChecked = (prefixes) => checkedItems.includes(prefixes);

  const validateDiscardAll = (isDiscardAll) => checkedItems.length === listOfUnidentified?.[indexOfList].length || isDiscardAll;

  const handleCheckboxChange = (value, target, prefixes) => {
    if (target === 'isDiscardAll') {
      setState((prevState) => ({
        ...prevState,
        isDiscardAll: value,
        checkedItems: value ? listOfUnidentified?.[indexOfList] : []
      }));
    };

    if (target === 'isDiscard') {
      const isChecked = checkedItems.includes(prefixes);
      setState((prevState) => ({
        ...prevState,
        isDiscardAll: false,
        checkedItems: isChecked ? checkedItems.filter(item => item !== prefixes) : [...checkedItems, prefixes]
      }));
    };
  };

  const onSave = async () => {
    try {
      let response;
      let listAllChecked = [...listCheckedOld, ...checkedItems];
      const headQuartersToAvoid = listAllChecked.map(item => item.namePrefix).join(',');

      if (indexOfList === 0) {
        response = await extractSalesReportsCloudService({ excel: excelSalesReportsFile?.originFileObj, headQuartersToAvoid });
      } else if (indexOfList === 1) {
        response = await extractUtilityCloudService({ excel: excelBillingFile?.originFileObj, headQuartersToAvoid });
      } else if (indexOfList === 2) {
        response = await extractSalesReportsColdPointService({ excel: excelSalesReportsFilePF?.originFileObj, headQuartersToAvoid });
      } else if (indexOfList === 3) {
        response = await extractUtilityColdPointService({ excel: excelBillingFilePF?.originFileObj, headQuartersToAvoid });
      };

      if (Array.isArray(response)) {
        const updatedListOfUnidentified = [...listOfUnidentified];
        updatedListOfUnidentified[indexOfList] = response;
        setState((prevState) => ({
          ...prevState,
          checkedItems: [],
          isDiscardAll: false,
          listCheckedOld: listAllChecked,
          listOfUnidentified: updatedListOfUnidentified
        }));
      } else {
        let newIndex = indexOfList + 1;
        if (newIndex <= lastIndexWithInfo) {
          for (let index = newIndex; index < listOfUnidentified.length; index++) {
            const currentItem = listOfUnidentified[index];
            if (currentItem !== null) {
              newIndex = index;
              break;
            }
          };
          setState((prevState) => ({
            ...prevState,
            checkedItems: [],
            listCheckedOld: [],
            isDiscardAll: false,
            indexOfList: newIndex,
          }));
        }
        else {
          setState((prevState) => ({
            ...prevState,
            checkedItems: [],
            listCheckedOld: [],
            isDiscardAll: false,
            isViewModal: false,
          }));
          updateStateUtilities({ isLoading: true });
          setTimeout(() => {
            getListOfTnsValuations();
          }, 500);
        };
      };
    } catch (error) {
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          checkedItems: [],
          listCheckedOld: [],
          isDiscardAll: false,
          isViewModal: false,
        }));
        updateStateUtilities({ isLoading: false });
        ErrorAlertComponent();
      }, 500);
    }
  };

  const onCancel = () => {
    setState({
      ...state,
      checkedItems: [],
      listCheckedOld: [],
      indexOfList: null,
      isViewModal: false,
      isDiscardAll: false,
      listOfUnidentified: [],
    })
  }

  const downloadSalesReportsFile = async() => {
    try {
      await getSalesReportsService();
    } catch (error) {
      ErrorAlertComponent();
    }
  };

  const downloadSalesReportsPFFile = async() => {
    try {
      await getSalesReportsPFService();
    } catch (error) {
      ErrorAlertComponent();
    }
  };

  const downloadInvoicingProfitFile = async() => {
    try {
      await getInvoicingProfitService();
    } catch (error) {
      ErrorAlertComponent();
    }
  };

  const downloadUtilityReportsPFFile = async() => {
    try {
      await getUtilityReportsPFService();
    } catch (error) {
      ErrorAlertComponent();
    }
  };

  return (
    <div className='grid-y align-center-middle file-upload-to-utilities__container'>
      <span className='text-center file-upload-to-utilities__description'>
        Bienvenido al submódulo de <strong>utilidades</strong>, aún no tenemos datos para mostrarte, ayúdanos adjuntando los archivos base
      </span>
      <div className='grid-x small-12 align-center-middle'>
        <div className='grid-x justify-content-between file-upload-to-utilities__container-upload__button__content'>
          <button 
            onClick={()=> downloadSalesReportsFile()}
            className='file-upload-to-utilities__container-upload__button'
          >
            <img
              src={Assets.SharedIcons.icon_download_white}
              alt='icon_download_white'
              className='file-upload-to-utilities__container-upload__button__icon'
            />
            <span>ReportesdeVentas.xlsx</span>
          </button>
          <button 
            className='file-upload-to-utilities__container-upload__button'
            onClick={()=> downloadSalesReportsPFFile()}
          >
            <img
              src={Assets.SharedIcons.icon_download_white}
              alt='icon_download_white'
              className='file-upload-to-utilities__container-upload__button__icon'
            />
            <span>ReportesdeVentasPF.xlsx</span>
          </button>
        </div>
        <div className='grid-x justify-content-between file-upload-to-utilities__container-upload__button__content'>
          <button 
            className='file-upload-to-utilities__container-upload__button'
            onClick={()=> downloadInvoicingProfitFile()}
          >
            <img
              src={Assets.SharedIcons.icon_download_white}
              alt='icon_download_white'
              className='file-upload-to-utilities__container-upload__button__icon'
            />
            <span>UtilidadporFacturación.xlsx</span>
          </button>
          <button 
            className='file-upload-to-utilities__container-upload__button'
            onClick={()=> downloadUtilityReportsPFFile()}
          >
            <img
              src={Assets.SharedIcons.icon_download_white}
              alt='icon_download_white'
              className='file-upload-to-utilities__container-upload__button__icon'
            />
            <span>ReportesdeUtilidadPF.xlsx</span>
          </button>
        </div>
      </div>
      <div className='grid-x  justify-content-between file-upload-to-utilities__container-upload'>
        <div className='grid-y align-center-middle file-upload-to-utilities__container-upload__content'>
          <span className='file-upload-to-utilities__container-upload__title'>Documentos TNS NUBE</span>
          <FileUploadUtilComponent
            isDisabledFile={false}
            target='excelSalesReportsFile'
            nameDocument='ReportesdeVentas'
            excelFile={excelSalesReportsFile}
            targetProgress='statusProgressSalesReports'
            statusProgress={statusProgressSalesReports}
            setUpdateState={(newState) => setState((prevState) => ({ ...prevState, ...newState }))}
          />
          <FileUploadUtilComponent
            isDisabledFile={false}
            target='excelBillingFile'
            excelFile={excelBillingFile}
            nameDocument='UtilidadporFacturación'
            statusProgress={statusProgressBilling}
            targetProgress='statusProgressBilling'
            setUpdateState={(newState) => setState((prevState) => ({ ...prevState, ...newState }))}
          />
        </div>
        <div className='grid-y  align-center-middle file-upload-to-utilities__container-upload__content'>
          <span className='file-upload-to-utilities__container-upload__title'>Documentos TNS PUNTO FRÍO</span>
          <FileUploadUtilComponent
            isDisabledFile={false}
            target='excelSalesReportsFilePF'
            nameDocument='ReportesdeVentasPF'
            excelFile={excelSalesReportsFilePF}
            statusProgress={statusProgressSalesReportsPF}
            targetProgress='statusProgressSalesReportsPF'
            setUpdateState={(newState) => setState((prevState) => ({ ...prevState, ...newState }))}
          />
          <FileUploadUtilComponent
            isDisabledFile={false}
            target='excelBillingFilePF'
            excelFile={excelBillingFilePF}
            nameDocument='ReportesdeUtilidadPF'
            statusProgress={statusProgressBillingPF}
            targetProgress='statusProgressBillingPF'
            setUpdateState={(newState) => setState((prevState) => ({ ...prevState, ...newState }))}
          />
        </div>
      </div>
      <div className='grid-x align-center-middle file-upload-to-utilities__button-create-inventory__content'>
        <button
          onClick={() => validateFileProcess()}
          className='file-upload-to-utilities__button-create-inventory'
          disabled={![statusProgressSalesReports, statusProgressBilling, statusProgressSalesReportsPF, statusProgressBillingPF].some(status => status === 'done')}
        >
          <span>
            Crear nuevas utilidades
          </span>
        </button>
      </div>
      {isViewModal &&
        <Modal
          centered
          width={600}
          footer={null}
          destroyOnClose
          closeIcon={null}
          open={isViewModal}
          className='file-upload-to-utilities__modal'
          onCancel={() => onCancel()}
        >
          <ListOfUnidentifiedPrefixesComponent
            onSave={() => onSave()}
            onCancel={() => onCancel()}
            indexOfList={indexOfList}
            isDiscardAll={isDiscardAll}
            isItemChecked={isItemChecked}
            isDisabled={!checkedItems?.length}
            listOfUnidentified={listOfUnidentified}
            validateDiscardAll={validateDiscardAll}
            handleCheckboxChange={handleCheckboxChange}
          />
        </Modal>
      }
    </div>
  )
}

export default FileUploadToUtilitiesComponent;