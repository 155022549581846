
export const formattedNumber = (inputNumber) => {

  if (Number.isNaN(inputNumber) || inputNumber === undefined || inputNumber === null) {
    return "$ 0"
  }

  if (Number.isInteger(Number(inputNumber)) && !inputNumber.toString().includes(".")) {
    return `$ ${parseFloat(inputNumber).toLocaleString()}`
  }

  const formattedNumber = parseFloat(inputNumber).toLocaleString(undefined, { maximumFractionDigits: 2 })

  if (inputNumber === `${Math.floor(inputNumber)}`) {
    return `$ ${formattedNumber.replace(/\.\d+$/, "")}`;
  } else if (formattedNumber.includes(".")) {
    return `$ ${formattedNumber}`;
  } else {
    return `$ ${(isNaN(parseFloat(formattedNumber)) ? "0" : formattedNumber) + "."}`;
  }

}

// TODO: number formatting with correction to integers
export const formattedNumberInteger = (inputNumber) => {

  if (Number.isNaN(inputNumber) || inputNumber === undefined || inputNumber === null) {
    return "$ 0"
  }

  if (Number.isInteger(Number(inputNumber)) && !inputNumber.toString().includes(".")) {
    return `$ ${parseInt(inputNumber).toLocaleString()}`
  }

  const formattedNumber = parseFloat(inputNumber).toLocaleString(undefined, { maximumFractionDigits: 2 })
  
  if (inputNumber === `${Math.floor(inputNumber)}`) {
    return `$ ${formattedNumber.replace(/\.\d+$/, "")}`;
  } else if (formattedNumber.includes(".")) {
    return `$ ${formattedNumber}`;
  } else {
    return `$ ${isNaN(parseFloat(formattedNumber)) ? "0" : formattedNumber}`;
  }
};