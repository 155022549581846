// Assets
import { Assets } from '../../../../assets';

// Libraries
import React, { useState } from 'react'
import { Input } from 'antd';
import { NumericFormat } from 'react-number-format';

// Styles
import './credits-or-we-owe-treasury.component.scss';

const CreditsOrWeOweTreasuryComponent = (props) => {

    const {
        //Props
        listOfReceivedNotReported,
        totalOfReceivedNotReported
    } = props;

    const INITIAL_STATE = {
        isCollapsedNotReported: false
    }

    const [state, setState] = useState(INITIAL_STATE);

    const { isCollapsedNotReported } = state;

    return (
        <div className='credits-or-we-owe-treasury__container'>
            <div className='credits-or-we-owe-treasury__table'>
                <div className='grid-x align-middle justify-content-between credits-or-we-owe-treasury__table__title__content'>
                    <span className='credits-or-we-owe-treasury__table__title'>No reportado</span>
                    <button 
                        onClick={() => setState({ ...state, isCollapsedNotReported: !isCollapsedNotReported })}>
                        <img
                            alt='icon_blue_arrow_up'
                            src={Assets.SharedIcons.icon_blue_arrow_up}
                            className={`credits-or-we-owe-treasury__table__icon ${isCollapsedNotReported ? 'rotate' : ''}`}
                        />
                    </button>
                </div>
                <div className={`credits-or-we-owe-treasury__table__box ${isCollapsedNotReported ? 'collapsed' : ''}`}>
                    <div className='grid-x align-middle justify-content-between credits-or-we-owe-treasury__table__subtitle__content'>
                        <span className='credits-or-we-owe-treasury__table__subtitle__not-reported'>Sede o negocio</span>
                        <span className='credits-or-we-owe-treasury__table__subtitle__not-reported'>Concepto</span>
                        <span className='credits-or-we-owe-treasury__table__subtitle__not-reported text-end'>Valor</span>
                    </div>
                    {listOfReceivedNotReported.map((receivedNotReported, index) => {
                        return (
                            <div key={index} className='grid-x align-middle justify-content-between credits-or-we-owe-treasury__table__add__content'>
                                <Input
                                    disabled
                                    prefix={<></>}
                                    placeholder='Nombre de la sede'
                                    className='credits-or-we-owe-treasury__table__add__input'
                                    value={receivedNotReported?.headQuarterTransaction?.name}
                                />
                                <Input
                                    disabled
                                    prefix={<></>}
                                    placeholder='Concepto'
                                    value={receivedNotReported?.concept}
                                    className='credits-or-we-owe-treasury__table__add__input'
                                />
                                <div className='credits-or-we-owe-treasury__table__add__inputs-price__disabled'>
                                    <NumericFormat
                                        disabled
                                        type='text'
                                        prefix={"$ "}
                                        placeholder='$ 0'
                                        thousandSeparator=","
                                        className='input-price'
                                        thousandsGroupStyle="thousand"
                                        value={receivedNotReported?.cashReceived}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className='grid-x align-middle justify-content-between credits-or-we-owe-treasury__table__total__content'>
                    <div className='grid-x align-middle justify-content-end credits-or-we-owe-treasury__table__total__text__content'>
                        <span className='credits-or-we-owe-treasury__table__total__text'>Total</span>
                    </div>
                    <div className='grid-x align-middle credits-or-we-owe-treasury__table__total__content-value'>
                        <NumericFormat
                            disabled
                            type='text'
                            prefix={"$ "}
                            placeholder='$ 0'
                            thousandSeparator=","
                            className='input-price'
                            thousandsGroupStyle="thousand"
                            value={totalOfReceivedNotReported}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreditsOrWeOweTreasuryComponent;