// Actions
import { setStateGeneralAdjustmentReducer } from '../../../../../storage/reducers/headquarters/headquarters.actions';

// Assets
import { Assets } from '../../../../../assets';

// Libraries
import dayjs from "dayjs";
import { connect } from 'react-redux';
import { DatePicker, Input } from 'antd';
import React, { useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format';

// Styles
import "./provision-for-vat.component.scss"

const ProvisionForVatComponent = (props) => {

  const {
    // Actions
    setStateGeneralAdjustmentReducer,
    // Variables
    generalAdjustment
  } = props;

  const INITIAL_STATE = {
    provisionVat: {
      total: 0,
      listOfProvisions: []
    }
  };

  const [state, setState] = useState(INITIAL_STATE);

  const { provisionVat } = state;

  useEffect(() => {
    setState({
      ...state,
      provisionVat: {
        total: generalAdjustment?.ivaProvisions?.total,
        listOfProvisions: generalAdjustment?.ivaProvisions?.ivaProvisionByMonths

      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalAdjustment?.ivaProvisions]);

  const onChangeInput = (data, index, name) => {
    let value = data && data.target ? data.target.value || '' : data;
    const copyProvisionVat = JSON.parse(JSON.stringify(provisionVat.listOfProvisions));

    if (name === 'value') {
      const valueNumber = parseFloat(value.replace(/[^0-9.]/g, '')) || 0;
      copyProvisionVat[index][name] = valueNumber;
    } else {
      copyProvisionVat[index][name] = value;
    }

    setState({
      ...state,
      provisionVat: {
        ...provisionVat,
        listOfProvisions: copyProvisionVat
      }
    });
    setStateGeneralAdjustmentReducer('changeGeneralAdjustment', 'ivaProvisions', copyProvisionVat);
  };

  return (
    <div className="provision-for-vat__content-tables__table">
      <div className="grid-x align-middle justify-content-between provision-for-vat__content-tables__content-values">
        <div className="grid-x align-middle">
          <img alt="icon_information" src={Assets.SharedIcons.icon_information} />
          <span className="provision-for-vat__content-tables__title">
            Provisión del IVA
          </span>
        </div>
        <div className="grid-x align-middle">
          <span className="provision-for-vat__content-tables__title">
            Total:
          </span>
          <div className="grid-x provision-for-vat__content-tables__values--mod">
            <div className="provision-for-vat__content-tables__values__content-icon">
              <img
                alt="icon_egress"
                src={Assets.SharedIcons.icon_egress}
                className="provision-for-vat__content-tables__values__content-icon__icon"
              />
            </div>
            <NumericFormat
              disabled
              type="text"
              prefix={"$ "}
              placeholder="$ 0"
              thousandSeparator=","
              className="input-price"
              value={provisionVat?.total}
              thousandsGroupStyle="thousand"
            />
          </div>
        </div>
      </div>
      <div className="grid-x small-12 provision-for-vat__content-tables__content-info">
        <div className="grid-x align-content-end provision-for-vat__content-tables__content-info__content-title">
          <span className="small-4 provision-for-vat__content-tables__content-info__title">
            Mes
          </span>
          <span className="small-4 provision-for-vat__content-tables__content-info__title">
            Fecha
          </span>
          <span className="small-4 grid-x justify-content-end provision-for-vat__content-tables__content-info__title">
            Valor
          </span>
        </div>
        <div className="provision-for-vat__content-tables__content-info__content-scroll">
          {provisionVat?.listOfProvisions.map((item, index) => {
            return (
              <div
                key={index}
                className="grid-x align-middle provision-for-vat__content-tables__content-info__information-row">
                <div className='small-4 provision-for-vat__content-tables__content-input'>
                  <Input
                    disabled
                    prefix={null}
                    placeholder="Mes"
                    value={item?.month}
                  />
                </div>
                <div className={`small-4 provision-for-vat__content-tables__content-input${item?.edit ? "__disabled" : ""}`}>
                  <DatePicker
                    suffixIcon={null}
                    allowClear={false}
                    format="YYYY/MM/DD"
                    disabled={!item?.edit}
                    placeholder="AAAA/MM/DD"
                    className='input-date--mod'
                    value={item?.date ? dayjs(item?.date, "YYYY:MM:DD") : null}
                    onChange={(e, date) => onChangeInput(date, index, 'date')}
                  />
                </div>
                <div className={`grid-x small-4 provision-for-vat__content-tables__content-input${item.edit ? "__disabled" : ""}`}>
                  <div className="grid-x provision-for-vat__table__content-inputs">
                    <NumericFormat
                      type="text"
                      prefix={"$ "}
                      placeholder="$ 0"
                      value={item?.value}
                      thousandSeparator=","
                      disabled={!item?.edit}
                      className="input-price"
                      thousandsGroupStyle="thousand"
                      onChange={(value) => onChangeInput(value, index, 'value')}
                    />
                    <div className="provision-for-vat__table__content-icon-price">
                      <img
                        alt="icon_edit"
                        src={Assets.SharedIcons.icon_edit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ HeadquartersReducer }) => {
  const { generalAdjustment } = HeadquartersReducer;

  return {
    generalAdjustment,
  };
};

const mapStateToPropsActions = {
  setStateGeneralAdjustmentReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(ProvisionForVatComponent);