// Libraries
import string from "../shared/translations/I18n";

export const validateLogin = (values) => {
  const errors = {};

  if (!values.user) {
    errors.user = string.t("authentication.validations.required");
  } else if (values.user.length < 4) {
    errors.user = string.t("authentication.validations.fourCharacters");
  }

  if (!values.password) {
    errors.password = string.t("authentication.validations.required");
  } else if (values.password.length < 8) {
    errors.password = string.t("authentication.validations.eightCharacters");
  }

  return errors;
};

export const validateEmail = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = string.t("authentication.validations.required");
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = string.t("authentication.validations.invalidEmail");
  }
  return errors;
};

export const validateCode = (values) => {
  const errors = {};

  if (!values.code) {
    errors.code = string.t("authentication.validations.required");
  } else if (values.code.length < 6) {
    errors.code = string.t("authentication.validations.invalidCode");
  }
  return errors;
};

export const validatePassword = (values) => {
  const errors = {};

  if (!values.password) {
    errors.password = string.t("authentication.validations.required");
  }

  if (!values.passwordConfirmation) {
    errors.passwordConfirmation = string.t("authentication.validations.required");
  } else if (values.password !== values.passwordConfirmation) {
    errors.passwordConfirmation = string.t("authentication.validations.invalidPasswordConfirmation");
  } else if (values.password.split(" ").length >= 2) {
    errors.passwordConfirmation = string.t("authentication.validations.spaces");
  }


  return errors;
};

export const validateRecoveryPassword = (values) => {
  const errors = {};

  if (!values.user) {
    errors.user = string.t("authentication.validations.required");
  } else if (values.user.length < 4) {
    errors.user = string.t("authentication.validations.fourCharacters");
  }

  return errors;
}
