const DashboardEndPoints =  {
    GET_DAILY_CASH_CLOSING_REPORTS: `/api/dashboard/closingCashRegister`,
    GET_DATA_CASH_CLOSING: `/api/dashboard/cashClosing/:date`,
    GET_CONTROL_PANEL: `/api/dashboard/controlPanel/:date`,
    GET_TOTAL_UTILITY: `/api/dashboard/getTotalUtility/:headQuarterId/:date`,
    GET_TOTAL_EXPENSES: `/api/dashboard/getTotalExpenses/:headQuarterId/:date`,
    GET_TOTAL_TREASURY: `/api/dashboard/getTotalTreasury/:headQuarterId/:date`,
    GET_TOTAL_INVENTORY: `/api/dashboard/getTotalInventory/:headQuarterId/:date`,
    GET_TOTAL_SALES: `/api/dashboard/getTotalSales/:headQuarterId/:date`,
    GET_TOTAL_DIFFERENTIAL: `/api/dashboard/getTotalDifferential/:headQuarterId/:date`,
}

export default DashboardEndPoints;