// Constants
import BanksEndPoints from "./constants/banks.end-points"

// Infrastructures
import Http from "./infrastructure/http.infrastructure"

// Libraries
import dayjs from 'dayjs';

// Services
import { getTokenService } from "./authentication.services";


export async function getAllBanksService() {
    try {
        const {data: responseGetBanks, error} = await Http.get(BanksEndPoints.GET_ALL_BANKS)
        if (responseGetBanks && responseGetBanks.status) {
            const responseBanks = responseGetBanks.data.map((bank) => ({
                value: bank.id,
                label: bank.name
            }));
            return responseBanks
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getTypeAccountService() {
    try {
        const {data: responseGetTypeAccount, error} = await Http.get(BanksEndPoints.GET_TYPE_ACCOUNT)
        if (responseGetTypeAccount && responseGetTypeAccount.status) {
            const responseTypeAccount = responseGetTypeAccount.data.map((typeAccount) => ({
                value: typeAccount.id,
                label: typeAccount.name
            }));
            return responseTypeAccount
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createBankService(data) {
    try {
        let token = getTokenService()
        const {data: responseCreateBank, error} = await Http.post(BanksEndPoints.CREATE_BANK, data, token)
        if (responseCreateBank && responseCreateBank.status) {
            const bank = responseCreateBank.data
            const responseBank ={
                bankName: {
                    value: bank?.bankPaymentMethodDailyTransaction.id,
                    label: bank?.bankPaymentMethodDailyTransaction.name
                },
                typeAccount:{
                    value: bank?.typeAccountPaymentMethodDailyTransaction.id,
                    label: bank?.typeAccountPaymentMethodDailyTransaction.name
                },
                numberAccount: bank?.accountNumber,
                identificationCode: bank?.name,
                associatedOperation: {
                    value: bank?.headquarterPaymentMethodDailyTransaction.id,
                    label: bank?.headquarterPaymentMethodDailyTransaction.name
                },
                initialBalance: bank?.initialBalance,
                dateOfCreation: dayjs(bank?.date)
            }

            return [responseBank, bank.id]
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateBankService(data) {
    try {
        let token = getTokenService()
        const {data: responseUpdateBank, error} = await Http.patch(BanksEndPoints.CREATE_BANK, data, token)
        if (responseUpdateBank && responseUpdateBank.status) {
            return responseUpdateBank.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getBankByIdService(id) {
    try {
        let token = getTokenService()
        const urlWithParams = BanksEndPoints.GET_BANK_BY_ID.replace(":id", id)
        const {data: responseGetBankById, error} = await Http.get(urlWithParams, null, token)
        if (responseGetBankById && responseGetBankById.status) {
            return responseGetBankById.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};


export async function getListBanksService(date, filter) {
    try {
        let filterValue = filter ? {value: filter} : null
        let token = getTokenService()
        const urlWithParams = BanksEndPoints.GET_LIST_BANK.replace(":date", date)
        const {data: responseGetListBanks, error} = await Http.get(urlWithParams,filterValue, token)
        if (responseGetListBanks && responseGetListBanks.status) {
            const responseListBanks = responseGetListBanks.data.map((bank) => ({
                ...bank,
                id: bank.id,
                code: bank.name,
                bank: bank.bankPaymentMethodDailyTransaction.name,
                accountType: bank.typeAccountPaymentMethodDailyTransaction.name,
                headquarters:  bank.headquarterPaymentMethodDailyTransaction.name,
                accountNumber: bank.accountNumber,
                currentBalance: bank.totalPrice
            }))
            return responseListBanks
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};