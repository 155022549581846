// Constants
import CashClosingInventoryEndPoints from "./constants/cash-closing-inventory.end-points";

// Infrastructures
import Http from "./infrastructure/http.infrastructure";

// Services
import { getTokenService } from "./authentication.services";

export async function getAllHeadquartersService(date) {
    try {
        const token = getTokenService();
        const { data: responseGetAllHeadquarters, error } = await Http.get(CashClosingInventoryEndPoints.GET_ALL_HEADQUARTERS, { date: date }, token)
        if (responseGetAllHeadquarters && responseGetAllHeadquarters.status) {
            const response = responseGetAllHeadquarters.data.map((headQuarter) => ({
                id: headQuarter.id,
                name: headQuarter.name,
                cashOutflow: headQuarter.totalCashExpense || 0,
                liquorSales: headQuarter.totalSalesTNSCloud + headQuarter.totalSalesPF,
                kitchenSales: headQuarter.totalKitchen,
                percentageExecution: headQuarter.percentageSales,
                percentageCompliance: headQuarter.percentageExpenditure
            }))
            return response
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getHeadquartersService(date, idHeadQuarter) {
    try {
        const token = getTokenService();
        const { data: responseGetHeadquarters, error } = await Http.get(CashClosingInventoryEndPoints.GET_HEADQUARTER, { date: date, idHeadQuarter: idHeadQuarter }, token)
        if (responseGetHeadquarters && responseGetHeadquarters.status) {
            return responseGetHeadquarters.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateInventoryObservationService(observation, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingInventoryEndPoints.UPDATE_CASH_REGISTERS_OBSERVATION.replace(":id", id)
        const { data: responseCashRegistersObservation, error } = await Http.patch(urlWithParams, { observation }, token)
        if (responseCashRegistersObservation && responseCashRegistersObservation.status) {
            return responseCashRegistersObservation.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateInventoryInitialBalanceService(initialBalance, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingInventoryEndPoints.UPDATE_CASH_REGISTERS_INITIAL_BALANCE.replace(":id", id)
        const { data: responseCashRegistersInitialBalance, error } = await Http.patch(urlWithParams, { initialBalance }, token)
        if (responseCashRegistersInitialBalance && responseCashRegistersInitialBalance.status) {
            return responseCashRegistersInitialBalance.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateInventorySalesCloudService(salesTNSCloud, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingInventoryEndPoints.UPDATE_CASH_REGISTERS_SALES_TNS.replace(":id", id)
        const { data: responseCashRegistersSalesCloud, error } = await Http.patch(urlWithParams, { salesTNSCloud }, token)
        if (responseCashRegistersSalesCloud && responseCashRegistersSalesCloud.status) {
            return responseCashRegistersSalesCloud.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateInventorySalesPointColdService(salesPF, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingInventoryEndPoints.UPDATE_CASH_REGISTERS_SALES_PF.replace(":id", id)
        const { data: responseCashRegistersSalesPointCold, error } = await Http.patch(urlWithParams, { salesPF }, token)
        if (responseCashRegistersSalesPointCold && responseCashRegistersSalesPointCold.status) {
            return responseCashRegistersSalesPointCold.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateInventoryKitchenService(kitchen, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingInventoryEndPoints.UPDATE_CASH_REGISTERS_KITCHEN.replace(":id", id)
        const { data: responseCashRegistersKitchen, error } = await Http.patch(urlWithParams, { kitchen }, token)
        if (responseCashRegistersKitchen && responseCashRegistersKitchen.status) {
            return responseCashRegistersKitchen.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateInventoryRealCashService(realCash, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingInventoryEndPoints.UPDATE_CASH_REGISTERS_REAL_CASH.replace(":id", id)
        const { data: responseCashRegistersRealCash, error } = await Http.patch(urlWithParams, { realCash }, token)
        if (responseCashRegistersRealCash && responseCashRegistersRealCash.status) {
            return responseCashRegistersRealCash.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createInventoryCardReadersService(cardReaders, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingInventoryEndPoints.CREATE_RECEIPT_CARD_READERS.replace(":id", id)
        const { data: responseCashReceiptCardReaders, error } = await Http.post(urlWithParams, { cardReaders }, token)
        if (responseCashReceiptCardReaders && responseCashReceiptCardReaders.status) {
            return responseCashReceiptCardReaders.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateInventoryCardReadersService(cashReceiptCardReader) {
    const token = getTokenService();
    try {
        const { data: responseCashReceiptCardReaders, error } = await Http.patch(CashClosingInventoryEndPoints.UPDATE_RECEIPT_CARD_READERS, { cashReceiptCardReader }, token)
        if (responseCashReceiptCardReaders && responseCashReceiptCardReaders.status) {
            return responseCashReceiptCardReaders.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createInventoryReturnsService(returns, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingInventoryEndPoints.CREATE_CASH_RETURN.replace(":id", id)
        const { data: responseCashExpenseReturns, error } = await Http.post(urlWithParams, { returns }, token)
        if (responseCashExpenseReturns && responseCashExpenseReturns.status) {
            return responseCashExpenseReturns.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateInventoryReturnsService(returns) {
    const token = getTokenService();
    try {
        const { data: responseCashExpenseReturns, error } = await Http.patch(CashClosingInventoryEndPoints.UPDATE_CASH_RETURN, { returns }, token)
        if (responseCashExpenseReturns && responseCashExpenseReturns.status) {
            return responseCashExpenseReturns.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createInventoryMoneyTransfersService(moneyTransfer, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingInventoryEndPoints.CREATE_CASH_TRANSFERS.replace(":id", id)
        const { data: responseCashReceiptMoneyTransfers, error } = await Http.post(urlWithParams, { moneyTransfer }, token)
        if (responseCashReceiptMoneyTransfers && responseCashReceiptMoneyTransfers.status) {
            return responseCashReceiptMoneyTransfers.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateInventoryMoneyTransfersService(moneyTransfers) {
    const token = getTokenService();
    try {
        const { data: responseCashReceiptMoneyTransfers, error } = await Http.patch(CashClosingInventoryEndPoints.UPDATE_CASH_TRANSFERS, { moneyTransfers }, token)
        if (responseCashReceiptMoneyTransfers && responseCashReceiptMoneyTransfers.status) {
            return responseCashReceiptMoneyTransfers.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createInventoryCashExpenseService(expense, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingInventoryEndPoints.CREATE_CASH_EXPENSE.replace(":id", id)
        const { data: responseCashExpense, error } = await Http.post(urlWithParams, { expense }, token)
        if (responseCashExpense && responseCashExpense.status) {
            return responseCashExpense.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateInventoryCashExpenseService(cashExpenses) {
    const token = getTokenService();
    try {
        const { data: responseCashExpense, error } = await Http.patch(CashClosingInventoryEndPoints.UPDATE_CASH_EXPENSE, { cashExpenses }, token)
        if (responseCashExpense && responseCashExpense.status) {
            return responseCashExpense.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createInventoryPaymentOfBondsmenService(paymentOfBondsmen, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingInventoryEndPoints.CREATE_CASH_RECEIPT_PAYMENT_OF_BONDSMEN.replace(":id", id)
        const { data: responseCashReceiptPaymentOfBondsmen, error } = await Http.post(urlWithParams, { paymentOfBondsmen }, token)
        if (responseCashReceiptPaymentOfBondsmen && responseCashReceiptPaymentOfBondsmen.status) {
            return responseCashReceiptPaymentOfBondsmen.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateInventoryPaymentOfBondsmenService(paymentOfBondsmen) {
    const token = getTokenService();
    try {
        const { data: responseCashReceiptPaymentOfBondsmen, error } = await Http.patch(CashClosingInventoryEndPoints.UPDATE_CASH_RECEIPT_PAYMENT_OF_BONDSMEN, { paymentOfBondsmen }, token)
        if (responseCashReceiptPaymentOfBondsmen && responseCashReceiptPaymentOfBondsmen.status) {
            return responseCashReceiptPaymentOfBondsmen.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createInventoryTrustService(expenseTrust, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingInventoryEndPoints.CREATE_CASH_EXPENSE_TRUST.replace(":id", id)
        const { data: responseCashExpenseTrust, error } = await Http.post(urlWithParams, { expenseTrust }, token)
        if (responseCashExpenseTrust && responseCashExpenseTrust.status) {
            return responseCashExpenseTrust.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateInventoryTrustService(expensesTrust) {
    const token = getTokenService();
    try {
        const { data: responseCashExpenseTrust, error } = await Http.patch(CashClosingInventoryEndPoints.UPDATE_CASH_EXPENSE_TRUST, { expensesTrust }, token)
        if (responseCashExpenseTrust && responseCashExpenseTrust.status) {
            return responseCashExpenseTrust.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateInventoryBottleControlService(cashReceiptBottleControl) {
    const token = getTokenService();
    try {
        const { data: responseCashReceiptBottleControl, error } = await Http.patch(CashClosingInventoryEndPoints.UPDATE_CASH_RECEIPT_BOTTLE_CONTROL, { cashReceiptBottleControl }, token)
        if (responseCashReceiptBottleControl && responseCashReceiptBottleControl.status) {
            return responseCashReceiptBottleControl.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createInventoryProviderService(expenseProvider, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingInventoryEndPoints.CREATE_CASH_EXPENSE_PROVIDER.replace(":id", id)
        const { data: responseCashExpenseProvider, error } = await Http.post(urlWithParams, { expenseProvider }, token)
        if (responseCashExpenseProvider && responseCashExpenseProvider.status) {
            return responseCashExpenseProvider.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateInventoryProviderService(cashExpensesProvider) {
    const token = getTokenService();
    try {
        const { data: responseCashReceiptMoneyTransfers, error } = await Http.patch(CashClosingInventoryEndPoints.UPDATE_CASH_EXPENSE_PROVIDER, { cashExpensesProvider }, token)
        if (responseCashReceiptMoneyTransfers && responseCashReceiptMoneyTransfers.status) {
            return responseCashReceiptMoneyTransfers.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createInventoryInternalConsumptionService(domesticConsumption, id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingInventoryEndPoints.CREATE_INTERNAL_CONSUMPTION.replace(":id", id)
        const { data: responseInternalConsumption, error } = await Http.post(urlWithParams, { domesticConsumption }, token)
        if (responseInternalConsumption && responseInternalConsumption.status) {
            return responseInternalConsumption.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateInventoryInternalConsumptionService(domesticConsumption) {
    const token = getTokenService();
    try {
        const { data: responseInternalConsumption, error } = await Http.patch(CashClosingInventoryEndPoints.UPDATE_INTERNAL_CONSUMPTION, { domesticConsumption }, token)
        if (responseInternalConsumption && responseInternalConsumption.status) {
            return responseInternalConsumption.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function saveInventoryCashRegisterService(id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingInventoryEndPoints.SAVE_CLOSING_CASH_REGISTER.replace(":id", id)
        const { data: responseClosingCashRegister, error } = await Http.patch(urlWithParams, null, token)
        if (responseClosingCashRegister && responseClosingCashRegister.status) {
            return responseClosingCashRegister.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function publishInventoryCashRegisterService(id) {
    const token = getTokenService();
    try {
        const urlWithParams = CashClosingInventoryEndPoints.PUBLISH_CLOSING_CASH_REGISTER.replace(":id", id)
        const { data: responseClosingCashRegister, error } = await Http.patch(urlWithParams, null, token)
        if (responseClosingCashRegister && responseClosingCashRegister.status) {
            return responseClosingCashRegister.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function deletedInventoryDataphonesTableService(body) {
    const token = getTokenService();
    try {
        const { data: responseDeletedRecordDataphonesTable, error } = await Http.delete(CashClosingInventoryEndPoints.DELETED_RECORD_DATAPHONES_TABLE, body, token)
        if (responseDeletedRecordDataphonesTable && responseDeletedRecordDataphonesTable.status) {
            return responseDeletedRecordDataphonesTable.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function deletedInventoryRefundsTableService(body) {
    const token = getTokenService();
    try {
        const { data: responseDeletedRecordRefundsTable, error } = await Http.delete(CashClosingInventoryEndPoints.DELETED_RECORD_REFUNDS_TABLE, body, token)
        if (responseDeletedRecordRefundsTable && responseDeletedRecordRefundsTable.status) {
            return responseDeletedRecordRefundsTable.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function deletedInventoryTransfersTableService(body) {
    const token = getTokenService();
    try {
        const { data: responseDeletedRecordTransfersTable, error } = await Http.delete(CashClosingInventoryEndPoints.DELETED_RECORD_TRANSFERS_TABLE, body, token)
        if (responseDeletedRecordTransfersTable && responseDeletedRecordTransfersTable.status) {
            return responseDeletedRecordTransfersTable.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function deletedInventoryExpensesTableService(body) {
    const token = getTokenService();
    try {
        const { data: responseDeletedRecordExpensesTable, error } = await Http.delete(CashClosingInventoryEndPoints.DELETED_RECORD_EXPENSES_TABLE, body, token)
        if (responseDeletedRecordExpensesTable && responseDeletedRecordExpensesTable.status) {
            return responseDeletedRecordExpensesTable.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function deletedInventoryPaymentOfBondsmenTableService(body) {
    const token = getTokenService();
    try {
        const { data: responseDeletedRecordPaymentOfBondsmenTable, error } = await Http.delete(CashClosingInventoryEndPoints.DELETED_RECORD_PAYMENT_OF_BONDSMEN_TABLE, body, token)
        if (responseDeletedRecordPaymentOfBondsmenTable && responseDeletedRecordPaymentOfBondsmenTable.status) {
            return responseDeletedRecordPaymentOfBondsmenTable.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function deletedInventoryLoansTableService(body) {
    const token = getTokenService();
    try {
        const { data: responseDeletedRecordLoansTable, error } = await Http.delete(CashClosingInventoryEndPoints.DELETED_RECORD_LOANS_TABLE, body, token)
        if (responseDeletedRecordLoansTable && responseDeletedRecordLoansTable.status) {
            return responseDeletedRecordLoansTable.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function deletedInventoryProvidersTableService(body) {
    const token = getTokenService();
    try {
        const { data: responseDeletedRecordProvidersTable, error } = await Http.delete(CashClosingInventoryEndPoints.DELETED_RECORD_PROVIDERS_TABLE, body, token)
        if (responseDeletedRecordProvidersTable && responseDeletedRecordProvidersTable.status) {
            return responseDeletedRecordProvidersTable.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function deletedInventoryInternalConsumptionTableService(body) {
    const token = getTokenService();
    try {
        const { data: responseDeletedRecordInternalConsumptionTable, error } = await Http.delete(CashClosingInventoryEndPoints.DELETED_RECORD_INTERNAL_CONSUMPTION_TABLE, body, token)
        if (responseDeletedRecordInternalConsumptionTable && responseDeletedRecordInternalConsumptionTable.status) {
            return responseDeletedRecordInternalConsumptionTable.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};