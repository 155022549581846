// Constants
import CashClosingWholesaleEndPoints from "./constants/cash-closing-wholesale.end-points";
import SharedEndPoints from "./constants/shared.end-points";

// Infrastructures
import Http from "./infrastructure/http.infrastructure"

// Services
import { getTokenService } from "./authentication.services";


export async function getFindAllWholesalerService() {
    try {
        let token = getTokenService()
        const {data: responseGetFindAllWholesaler, error} = await Http.get(CashClosingWholesaleEndPoints.GET_FIND_ALL_WHOLESALER, null, token)
        if (responseGetFindAllWholesaler && responseGetFindAllWholesaler.status) {
            return responseGetFindAllWholesaler.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getTypeExpenseWholesalerService(id) {
    try {
        let token = getTokenService()
        const urlWithParams = SharedEndPoints.GET_TYPE_EXPENSES_WHOLESALE.replace(":id", id)
        const {data: responseGetTypeExpense, error} = await Http.get(urlWithParams, null, token)
        if (responseGetTypeExpense && responseGetTypeExpense.status) {
            return responseGetTypeExpense.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getFinancialMovementService(id) {
    try {
        let token = getTokenService()
        const urlWithParams = SharedEndPoints.GET_FINANCIAL_MOVEMENT.replace(":id", id)
        const {data: responseGetFinancialMovement, error} = await Http.get(urlWithParams, null, token)
        if (responseGetFinancialMovement && responseGetFinancialMovement.status) {
            return responseGetFinancialMovement.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getTypeOfWholesaleSetService(id) {
    try {
        let token = getTokenService()
        const urlWithParams = SharedEndPoints.GET_TYPE_OF_WHOLESALE_SET.replace(":id", id)
        const {data: responseGetFindAllWholesaler, error} = await Http.get(urlWithParams, null, token)
        if (responseGetFindAllWholesaler && responseGetFindAllWholesaler.status) {
            return responseGetFindAllWholesaler.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getAllExpensesWholesalerService(id,date) {
    try {
        let token = getTokenService()
        const urlWithParams = CashClosingWholesaleEndPoints.GET_ALL_EXPENSES_WHOLESALE.replace(":id", id).replace(":date", date)
        const {data: responseGetFindAllWholesaler, error} = await Http.get(urlWithParams, null, token)
        if (responseGetFindAllWholesaler && responseGetFindAllWholesaler.status) {
            return responseGetFindAllWholesaler.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getFindAllFinancialMovementService(id, date) {
    try {
        let token = getTokenService()
        const urlWithParams = CashClosingWholesaleEndPoints.GET_FIND_ALL_FINANCIAL_MOVEMENT.replace(":id", id).replace(":date", date)
        const {data: responseGetFindAllFinancial, error} = await Http.get(urlWithParams, null, token)
        if (responseGetFindAllFinancial && responseGetFindAllFinancial.status) {
            return responseGetFindAllFinancial.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createExpensesWholesalerService(id,date,data) {
    try {
        let token = getTokenService()
        const urlWithParams = CashClosingWholesaleEndPoints.CREATE_EXPENSES_WHOLESALE.replace(":id", id).replace(":date", date)
        const {data: responseCreateExpensesWholesaler, error} = await Http.post(urlWithParams, data, token)
        if (responseCreateExpensesWholesaler && responseCreateExpensesWholesaler.status) {
            return responseCreateExpensesWholesaler.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateExpensesWholesalerService(data) {
    try {
        let token = getTokenService()
        const {data: responseUpdateExpensesWholesaler, error} = await Http.put(CashClosingWholesaleEndPoints.UPDATE_EXPENSES_WHOLESALE, data, token)
        if (responseUpdateExpensesWholesaler && responseUpdateExpensesWholesaler.status) {
            return responseUpdateExpensesWholesaler.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function deleteExpensesWholesalerService(data) {
    try {
        let token = getTokenService()
        const {data: responseDeleteExpensesWholesaler, error} = await Http.delete(CashClosingWholesaleEndPoints.DELETE_EXPENSES_WHOLESALE, data, token)
        if (responseDeleteExpensesWholesaler && responseDeleteExpensesWholesaler.status) {
            return responseDeleteExpensesWholesaler.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function createFinancialMovementService(id, date, data) {
    try {
        let token = getTokenService()
        const urlWithParams = CashClosingWholesaleEndPoints.CREATE_FINANCIAL_MOVEMENT.replace(":id", id).replace(":date", date)
        const {data: responseCreateFinancial, error} = await Http.post(urlWithParams, data, token)
        if (responseCreateFinancial && responseCreateFinancial.status) {
            return responseCreateFinancial.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateFinancialMovementService(data) {
    try {
        let token = getTokenService()
        const {data: responseUpdateFinancialMovement, error} = await Http.put(CashClosingWholesaleEndPoints.UPDATE_FINANCIAL_MOVEMENT, data, token)
        if (responseUpdateFinancialMovement && responseUpdateFinancialMovement.status) {
            return responseUpdateFinancialMovement.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getAllLiquidationWholesalerService(id,date) {
    try {
        let token = getTokenService()
        const urlWithParams = CashClosingWholesaleEndPoints.GET_ALL_LIQUIDATION_WHOLESALE.replace(":id", id).replace(":date", date)
        const {data: responseGetFindAllLiquidation, error} = await Http.get(urlWithParams, null, token)
        if (responseGetFindAllLiquidation && responseGetFindAllLiquidation.status) {
            return responseGetFindAllLiquidation.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function deleteFinancialMovementService(data) {
    try {
        let token = getTokenService()
        const {data: responseDeleteFinancialMovement, error} = await Http.delete(CashClosingWholesaleEndPoints.DELETE_FINANCIAL_MOVEMENT, data, token)
        if (responseDeleteFinancialMovement && responseDeleteFinancialMovement.status) {
            return responseDeleteFinancialMovement.data

        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getAllDriverService() {
    try {
        const {data: responseGetDriver, error} = await Http.get(SharedEndPoints.GET_DRIVER)
        if (responseGetDriver && responseGetDriver.status) {
            return responseGetDriver.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getBankWholesalerService() {
    try {
        const {data: responseGetBankWholesaler, error} = await Http.get(SharedEndPoints.GET_BANK_WHOLESALER)
        if (responseGetBankWholesaler && responseGetBankWholesaler.status) {
            return responseGetBankWholesaler.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    } 
};

export async function getAllSellerService() {
    try {
        const {data: responseGetSeller, error} = await Http.get(SharedEndPoints.GET_SELLER)
        if (responseGetSeller && responseGetSeller.status) {
            return responseGetSeller.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createLiquidationWholesalerService(id, date, idTypeLiquidation, data) {
    try {
        let token = getTokenService()
        const urlWithParams = CashClosingWholesaleEndPoints.CREATE_LIQUIDATION_WHOLESALE.replace(":id", id).replace(":date", date).replace(":liquidationId", idTypeLiquidation)
        const {data: responseCreateFinancial, error} = await Http.post(urlWithParams, data, token)
        if (responseCreateFinancial && responseCreateFinancial.status) {
            return responseCreateFinancial.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateLiquidationWholesalerService(data) {
    try {
        let token = getTokenService()
        const {data: responseCreateFinancial, error} = await Http.put(CashClosingWholesaleEndPoints.UPDATE_LIQUIDATION_WHOLESALE, data, token)
        if (responseCreateFinancial && responseCreateFinancial.status) {
            return responseCreateFinancial.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateLiquidationService(data) {
    try {
        let token = getTokenService()
        const {data: responseCreateFinancial, error} = await Http.put(CashClosingWholesaleEndPoints.UPDATE_LIQUIDATION, data, token)
        if (responseCreateFinancial && responseCreateFinancial.data.length === 0) {
            return responseCreateFinancial.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function deleteLiquidationService(data) {
    try {
        let token = getTokenService()
        const {data: responseDeleteDailyPayroll, error} = await Http.delete(CashClosingWholesaleEndPoints.DELETE_LIQUIDATION, data, token)
        if (responseDeleteDailyPayroll && responseDeleteDailyPayroll.status) {
            return responseDeleteDailyPayroll.data

        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getInventoryService(id, date, type) {
    try {
        let token = getTokenService()
        const urlWithParams = CashClosingWholesaleEndPoints.GET_ALL_INVENTORY.replace(":id", id).replace(":date", date).replace(":type", type)
        const {data: responseDeleteDailyPayroll, error} = await Http.get(urlWithParams,null, token)
        if (responseDeleteDailyPayroll && responseDeleteDailyPayroll.status) {
            return responseDeleteDailyPayroll.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateInventoryService(id, date, data) {
    try {
        let token = getTokenService()
        const urlWithParams = CashClosingWholesaleEndPoints.UPDATE_INVENTORY.replace(":id", id).replace(":date", date)
        const {data: responseDeleteDailyPayroll, error} = await Http.post(urlWithParams,data, token)
        if (responseDeleteDailyPayroll && responseDeleteDailyPayroll.status) {
            return responseDeleteDailyPayroll.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateTotalsService(id, date, data) {
    try {
        let token = getTokenService()
        const urlWithParams = CashClosingWholesaleEndPoints.UPDATE_TOTALS.replace(":id", id).replace(":date", date)
        const {data: responseUpdateTotals, error} = await Http.put(urlWithParams,data, token)
        if (responseUpdateTotals && responseUpdateTotals.status) {
            return responseUpdateTotals.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};