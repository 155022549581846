// Actions
import { setMultipleStatesWholesaleFinalBalanceReducer } from "../../../../storage/reducers/wholesale-final-balance/wholesale-final-balance.actions";

// Components
import TheyOweUsFinalReconciliationComponent from "../they-owe-us-final-reconciliation/they-owe-us-final-reconciliation.component";
import CreditsWeOweFinalReconciliationComponent from "../credits-we-owe-final-reconciliation/credits-we-owe-final-reconciliation.component";

// Components - Shared
import ErrorAlertComponent from "../../../../shared/components/alerts/error-alert/error-alert.component";
import SuccessAlertComponent from "../../../../shared/components/alerts/success-alert/success-alert.component";

// Libraries
import React, { useEffect } from "react";
import { connect } from "react-redux";
import dayjs from 'dayjs';

// Services
import { createGeneralSummaryService, updateGeneralSummaryService } from "../../../../services/final-reconciliation.services";

const GeneralSummaryContainerComponent = (props) => {

    const {
        setMultipleStatesWholesaleFinalBalanceReducer,
        isSave,
        idHeadquarter,
        indexTabSelected,
        listsTableInformation,
        listsTableInformationCopy,
        selectedDate
    } = props

    useEffect(() => {
        if (isSave) {
          handleSave()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSave])

    const handleSave = async() => {

        try {
          if(indexTabSelected === 0){
            const dataOriginalInFavor = listsTableInformationCopy[indexTabSelected].inFavor.variables
            const dataModifiedInFavor = listsTableInformation[indexTabSelected].inFavor.variables.filter((element)=> !element.disabled)
    
            const dataOriginalAgainst = listsTableInformationCopy[indexTabSelected].against.variables
            const dataModifiedAgainst = listsTableInformation[indexTabSelected].against.variables.filter((element)=> !element.disabled)
       
            const dataCreatedInFavor = dataModifiedInFavor.filter((element)=> !element.id).map((element)=>(
              {
                initialDate: dayjs(selectedDate).format("YYYY-MM-DD"),
                finalDate: null,
                price: element.total,
                concept: element.name,
                state: 1,
                isClosing: 0
              }
            ))
    
            const dataCreatedAgainst = dataModifiedAgainst.filter((element)=> !element.id).map((element)=>(
              {
                initialDate: dayjs(selectedDate).format("YYYY-MM-DD"),
                finalDate: null,
                price: element.total,
                concept: element.name,
                state: 0,
                isClosing: 0
              }
            ))
    
            const dataModifiedInFavorUpdate = dataModifiedInFavor.filter((element)=> element.id)
            const dataOriginalInFavorUpdate = dataOriginalInFavor.filter((element)=> element.id)
            const listUpdateInFavor = compareObjectsArray(dataModifiedInFavorUpdate,dataOriginalInFavorUpdate).map((element)=>{

              let dataMovement = {}

              if(element.name){
                dataMovement.concept = element.name
              }

              if(element.checked){
                dataMovement.finalDate = dayjs().format("YYYY-MM-DD")
              }

              let dataUpdate = {
                id: element.id,
                movementData: Object.keys(dataMovement).length > 0 ? dataMovement : null,
                price: element.total ? element.total : null
              }
    
              return({
                ...dataUpdate
              })
            })
    
            const dataModifiedAgainstUpdate = dataModifiedAgainst.filter((element)=> element.id)
            const dataOriginalAgainstUpdate = dataOriginalAgainst.filter((element)=> element.id)
            const listUpdateAgainst = compareObjectsArray(dataModifiedAgainstUpdate,dataOriginalAgainstUpdate).map((element)=>{

              let dataMovement = {}

              if(element.name){
                dataMovement.concept = element.name
              }

              if(element.checked){
                dataMovement.finalDate = dayjs().format("YYYY-MM-DD")
              }

              let dataUpdate = {
                id: element.id,
                movementData: Object.keys(dataMovement).length > 0 ? dataMovement : null,
                price: element.total ? element.total : null
              }
    
              return({
                ...dataUpdate
              })
            })
    
            dataCreatedInFavor.length > 0 && await createGeneralSummaryService(idHeadquarter,dataCreatedInFavor)
            dataCreatedAgainst.length > 0 && await createGeneralSummaryService(idHeadquarter,dataCreatedAgainst)
            listUpdateInFavor.length > 0 && await updateGeneralSummaryService(listUpdateInFavor)
            listUpdateAgainst.length > 0 && await updateGeneralSummaryService(listUpdateAgainst)
    
            SuccessAlertComponent()
          }
        } catch (error) {
          ErrorAlertComponent(undefined,"Algunas creaciones o actualizaciones no han sido exitosas")
        } finally {
          setMultipleStatesWholesaleFinalBalanceReducer({ isSave: false, isGetAllTabs: true, isLoading: true })
        }
    
      }

      const compareObjectsArray = (array1, array2) => {
        const modifiedObjects = [];
      
        for (let i = 0; i < array1.length; i++) {
            const object1 = array1[i];
            const object2 = array2[i];
            const changes = { id: object1?.id };
      
            for (const key in object1) {
                if (object1?.hasOwnProperty(key) && !object2?.hasOwnProperty(key)) {
                    changes[key] =  object1[key];
                }
            }
      
            for (const key in object1) {
                if (object1?.hasOwnProperty(key) && object2?.hasOwnProperty(key)) {
                    const value1 = object1[key];
                    const value2 = object2[key];
      
                    if (value1 !== value2) {
                        changes[key] =  value1;
                    }
                }
            }
      
            if (Object.keys(changes).length > 1) {
                modifiedObjects.push(changes);
            }
        }
      
        return modifiedObjects;
      };

    return (
        <>
          <TheyOweUsFinalReconciliationComponent />
          <CreditsWeOweFinalReconciliationComponent />
        </>
    );
};

const mapStateToProps = ({ WholesaleFinalBalanceReducer }) => {
    const {
      listsTableInformation, listsTableInformationCopy, selectedDate,
      isGeneralSummary, indexTabSelected,
      isSave
    } = WholesaleFinalBalanceReducer;
  
    return {
      listsTableInformation,
      isGeneralSummary,
      indexTabSelected,
      listsTableInformationCopy,
      selectedDate,
      isSave
    };
  };
  
  const mapStateToPropsActions = {
    setMultipleStatesWholesaleFinalBalanceReducer
  };
  
  export default connect(mapStateToProps, mapStateToPropsActions)(GeneralSummaryContainerComponent);
