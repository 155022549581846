import * as headquartersTypes from './headquarters.types';

const INITIAL_STATE = {
	generalSummary: undefined,
	generalAdjustment: undefined,
	projections: undefined,
	changesGeneralSummary: [],
	changeGeneralAdjustment: {
		industryAndCommerce: undefined,
		retention: undefined,
		provisionForConsumption: undefined,
		ivaProvisions: undefined
	},
	projectionsOriginal: undefined,
	totalCredits: undefined
};


const HeadquartersReducer = (state = INITIAL_STATE, action) => {

	switch (action.type) {

		case headquartersTypes.SET_STATE_HEADQUARTERS:

			return {
				...state,
				[action.payload.prop]: action.payload.value
			};

		case headquartersTypes.SET_STATE_GENERALLY:
			return {
				...state,
				...action.payload.value
			};
		
		case headquartersTypes.SET_STATE_GENERAL_ADJUSTMENT:

			return {
				...state,
				[action.payload.prop]: {
					...state[action.payload.prop],
					[action.payload.key]: action.payload.value
				}
			}
		default:
			return state;
	}

};

export default HeadquartersReducer;