//Assets
import { Assets } from '../../../../assets';

//Libraries
import React, { useState } from 'react';
import { Button, Progress, Upload } from 'antd';

//Styles
import './file-upload.component.scss';

const FileUploadComponent = (props) => {

    const {
        // Variables
        typeFile,
        excelFile,
        isDisabledFile,
        statusProgress,
        // Functions
        setUpdateState,
        nameDocument = "nombreDelArchivo"
    } = props;

    const [progress, setProgress] = useState(0);

    const validStatus = () => {
        const aux = {
            'normal': {
                states: 'normal',
                color: '#1499F2',
                component: `${progress}%`
            },
            'uploading': {
                states: 'uploading',
                color: '#1499F2',
                component: `${progress}%`,
            },
            'done': {
                states: 'done',
                color: '#1499F2',
                component: `${progress}%`,
            },
            'error': {
                states: 'error',
                color: '#f85f61',
                component: <img src={Assets.AuthenticationIcons.icon_error} alt="icon_error" />
            },
        };
        return aux[statusProgress];
    };

    const handleFileChange = ({ fileList }) => {
        const file = fileList[0];
        if (typeFile === 'costs') {
            setUpdateState({
                excelCostFile: file,
                statusProgressCost: file?.status,
                isDisabledFile: file?.status === 'done' ? false : true,
            })
        } else {
            setUpdateState({
                excelInventoryFile: file,
                statusProgressInventory: file?.status,
            })
        }
    };

    const cancelUploadingFile = () => {
        if (typeFile === 'costs') {
            setUpdateState({
                excelCostFile: null,
                isDisabledFile: true,
                excelInventoryFile: null,
                statusProgressCost: 'normal',
                statusProgressInventory: 'normal'
            })
        } else {
            setUpdateState({
                excelInventoryFile: null,
                statusProgressInventory: 'normal'
            })
        }
    };

    const customRequest = ({ file, onSuccess, onError }) => {
        setProgress(0);
        try {
            let currentProgress = 0;
            const reader = new FileReader();
            reader.onload = (event) => {
                setTimeout(() => {
                    const interval = setInterval(() => {
                        currentProgress += 1;
                        setProgress(currentProgress);
                        if (currentProgress === 100) {
                            clearInterval(interval);
                            onSuccess();
                        }
                    }, 20);
                },1000);
            };
            reader.readAsText(file);
        } catch (error) {
            onError(error);
        }
    };

    return (
        <div className='grid-y file-upload__content'>
            <div className={`${isDisabledFile ? 'file-upload__box-disabled' : 'file-upload__box'} ${excelFile ? 'file-upload__box__visibility' : ''}`}>
                <Upload
                    maxCount={1}
                    accept='.xlsx'
                    listType="picture"
                    showUploadList={false}
                    disabled={isDisabledFile}
                    onChange={handleFileChange}
                    customRequest={customRequest}
                    className={`file-upload__box${isDisabledFile ? '-disabled' : ''}__upload`}
                >
                    <Button
                        icon={
                            <img
                                alt={isDisabledFile ? 'icon_upload_disabled' : 'icon_upload'}
                                src={isDisabledFile ? Assets.SharedIcons.icon_upload_disabled : Assets.SharedIcons.icon_upload}
                            />
                        }
                        disabled={isDisabledFile}
                    >
                        <div className='grid-y'>
                            <span className={`file-upload__box${isDisabledFile ? '-disabled' : ''}__title`}>
                                Adjunta aquí el archivo {nameDocument}.xlsx
                            </span>
                            <span className={`file-upload__box${isDisabledFile ? '-disabled' : ''}__text`}>
                                Recuerda que el peso máximo es de 100 MB
                            </span>
                        </div>
                    </Button>
                </Upload>
            </div>
            <div className={`file-upload__box__active ${!excelFile ? 'file-upload__box__visibility' : ''}`}>
                <span className='file-upload__box__title'>
                    {statusProgress === 'done' ? 'Archivo adjuntado' : 'Cargando archivo…'}
                </span>
                <div className='grid-x justify-content-between file-upload__box__info'>
                    <div className='grid-x small-10 align-middle'>
                        <img
                            alt={statusProgress === 'done' ? 'icon_document_excel_active' : 'icon_document_excel'}
                            src={statusProgress === 'done' ? Assets.SharedIcons.icon_document_excel_active : Assets.SharedIcons.icon_document_excel}
                            style={{ marginRight: '10px' }}
                        />
                        <div className='grid-y small-9 justify-content-center'>
                            <span className={`file-upload__box__name-file ${statusProgress === 'done' ? 'file-upload__box__name-file--mod' : ''}`}>{excelFile?.name}</span>
                            <span className='file-upload__box__size-file'>{(excelFile?.size / (1024 * 1024)).toFixed(2)} / 30 mb</span>
                        </div>
                    </div>
                    <div className='grid-x align-middle'>
                        <Progress
                            size={45}
                            type="circle"
                            strokeWidth={8}
                            status={validStatus()?.states}
                            strokeColor={validStatus()?.color}
                            format={() => validStatus()?.component}
                            className='file-upload__box__progress'
                            percent={statusProgress === 'error' ? 100 : progress}
                        />
                    </div>
                </div>
                <div className='file-upload__box__content-cancel'>
                    <button
                        className='file-upload__box__content-cancel__button'
                        onClick={() => cancelUploadingFile()}
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FileUploadComponent;